<script setup lang="ts">
import { backendOld } from "~/api";
import Button from "~/components/dumb/Button.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { copyAndNotify } from "~/components/notifications";
import SettingsCopyText from "~/components/settings/SettingsCopyText.vue";
import { PlusIcon } from "~/icons";
import { ButtonStyle } from "~/shared/enums";
import { useUserStore } from "~/stores";

const ROTATE_AUTH_TOKEN_DIALOG_DESCRIPTION =
  "Rotating the authentication token will invalidate the old token. This can't be undone. Are you sure you want to proceed?";

const userStore = useUserStore();

const copyAuthToken = () => {
  if (userStore.authToken === null) {
    return;
  }
  copyAndNotify("Authentication token", userStore.authToken);
};

const rotateAuthToken = async () => {
  const { data } = await backendOld.profile.rotateAuthToken();
  userStore.authToken = data.authToken;
  copyAuthToken();
};
</script>

<template>
  <div class="h-full overflow-auto">
    <div class="flex gap-1 text-lt">
      <template v-if="userStore.authToken">
        <SettingsCopyText
          class="w-72"
          :text="userStore.authToken"
          :rotate-dialog-description="ROTATE_AUTH_TOKEN_DIALOG_DESCRIPTION"
          label="Authentication token"
          @rotate="rotateAuthToken" />
      </template>
      <Tooltip v-else text="Create authentication token">
        <Button
          :btn-style="ButtonStyle.CHIP"
          :icon="PlusIcon"
          a11y-label="Create authentication token"
          @click="rotateAuthToken" />
      </Tooltip>
    </div>
  </div>
</template>
