<script setup lang="ts">
import { isAxiosError } from "axios";
import { computed, ref } from "vue";

import { backendOld } from "~/api";
import Button from "~/components/dumb/Button.vue";
import Input from "~/components/dumb/Input.vue";
import Modal from "~/components/dumb/Modal.vue";
import { ButtonSize, ButtonStyle, ModalWidth } from "~/shared/enums";
import type { ValidationFunctionResult } from "~/shared/types";
import { useUserStore } from "~/stores";
import { validatePassword } from "~/utils/common";

const userStore = useUserStore();

const open = ref(false);
const errorMsg = ref("");
const newPasswordInputRef = ref<InstanceType<typeof Input> | null>(null);
const oldPasswordInputRef = ref<InstanceType<typeof Input> | null>(null);

const title = computed(() => (userStore.hasPassword ? "Change password" : "Set password"));
const isValid = computed(
  () =>
    newPasswordInputRef?.value?.isValid === true &&
    (!userStore.hasPassword || oldPasswordInputRef?.value?.isValid === true)
);

const updatePassword = async () => {
  if (!newPasswordInputRef.value) {
    return;
  }
  errorMsg.value = "";

  try {
    await backendOld.profile.changePassword(newPasswordInputRef.value.value, oldPasswordInputRef.value?.value);
    open.value = false;

    if (!userStore.hasPassword) {
      userStore.setHasPassword(true);
    }
  } catch (error) {
    if (isAxiosError<{ errors: string[] }>(error)) {
      errorMsg.value = error.response?.data.errors[0] ?? "";
    }
  }
};

const validatePasswordOrShowError = (value: string): ValidationFunctionResult => {
  if (errorMsg.value.length > 0) {
    return { isValid: false, error: errorMsg.value };
  }
  return validatePassword(value);
};
</script>

<template>
  <div class="flex w-60 items-center justify-end">
    <Button
      :btn-style="ButtonStyle.SECONDARY"
      :text="title"
      :size="ButtonSize.LARGE"
      text-style="text-sm text-md"
      borderless
      class="rounded-md border !px-4 !py-1 shadow-sm border-md"
      @click="open = true" />
  </div>
  <Modal :entity="open" :title="title" :width="ModalWidth.S" @close="open = false">
    <template #default>
      <form class="mb-1 mt-4" @submit.prevent>
        <Input
          v-if="userStore.hasPassword"
          ref="oldPasswordInputRef"
          input-type="password"
          required
          placeholder="Old password"
          label="Old password"
          hide-label
          :validate="validatePasswordOrShowError"
          @change="errorMsg = ''" />
        <Input
          ref="newPasswordInputRef"
          input-type="password"
          required
          placeholder="New password"
          label="New password"
          hide-label
          :validate="validatePassword" />
      </form>
    </template>

    <template #actions>
      <div class="-mt-2 mb-1 w-full">
        <Button
          :btn-style="ButtonStyle.PRIMARY"
          :text="userStore.hasPassword ? 'Change' : 'Set'"
          :disabled="!isValid"
          block
          @click="updatePassword" />
      </div>
    </template>
  </Modal>
</template>
