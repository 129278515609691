<script setup lang="ts">
import { backend } from "~/api";
import { OpenExternalLinkIcon, ZohoFlowIcon } from "~/icons";
import { EventKind } from "~/shared/enums";

const onClick = () => {
  backend.event.create(EventKind.HELP_RESOURCE_CLICK, { resource: "cli" });
};
</script>

<template>
  <div class="flex flex-col gap-y-16">
    <div class="flex flex-col justify-center space-y-3">
      <div class="flex justify-between">
        <div class="flex gap-x-4">
          <h2 class="flex select-none items-center text-xl text-md">Zoho Flow integration</h2>
        </div>
      </div>
      <p class="select-none text-sm/relaxed text-lt">
        Integrate Zoho Flow with Dart to automate your workflows and connect Dart with thousands of other apps.
      </p>
    </div>

    <a
      href="https://www.zohoflow.com/apps/dart/integrations/"
      target="_blank"
      rel="noopener noreferrer"
      class="flex select-none items-center justify-center gap-2 self-center rounded border px-3 py-2 text-center shadow-sm text-md border-md focus-ring-std hover:bg-lt"
      @click="onClick"
      @keydown.enter="onClick">
      <ZohoFlowIcon class="icon-md" />
      Link Dart and Zoho Flow
      <OpenExternalLinkIcon class="icon-md" />
    </a>
  </div>
</template>
