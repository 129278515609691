import type { Column, GridApi, IRowNode, SelectionEventSourceType } from "ag-grid-community";

import { isFillerRow } from "~/common/groupBy";
import type Board from "~/components/visualization/board/Board.vue";
import type Calendar from "~/components/visualization/calendar/Calendar.vue";
import type TitleCellEditor from "~/components/visualization/list/cellEditors/TitleCellEditor.vue";
import { RelationshipKindKind } from "~/shared/enums";
import type { RowItem, Task, TaskWithGroup } from "~/shared/types";
import type { useAppStore } from "~/stores";
import type InboxView from "~/views/InboxView.vue";

import { getReactiveTaskNode } from "./list/common";

type RowNodeEventType = Parameters<IRowNode["addEventListener"]>[0];

type AppStore = ReturnType<typeof useAppStore>;
type TaskRowNodeOptions = { appStore?: AppStore; selectedOverride?: boolean };

export type Visualization = {
  count(): number;
  getSelected(): Task[];
  getAll(): Task[];
  getChildren(duid: string | null): Task[];
  scrollTo(duid: string): void;
  selectAndScrollTo(duid: string, options?: { deselectOthers?: boolean }): void;
  getHtml(duid: string): Element | null;
  selectAll(): void;
  deselectAll(): void;
  editTitle(duid: string): void;
  // TODO still fully row-related - remove?
  getRow(duid: string): IRowNode<Task> | undefined;
  getRowByIndex(index: number): IRowNode<Task> | undefined;
  getSelectedRows(): IRowNode<Task>[];
  getAllRows(): IRowNode<Task>[];
};

class TaskRowNode implements IRowNode<Task> {
  id: string;

  data: Task;

  options: TaskRowNodeOptions;

  displayed = true;

  rowPinned = undefined;

  selectable = true;

  rowHeight = undefined;

  rowTop = null;

  group = undefined;

  firstChild = false;

  lastChild = false;

  childIndex = -1;

  level = 0;

  uiLevel = 0;

  stub = false;

  failedLoad = false;

  sourceRowIndex = -1;

  quickFilterAggregateText = null;

  master = false;

  detail = false;

  field = null;

  key = null;

  groupData = null;

  aggData = undefined;

  rowGroupColumn = null;

  rowGroupIndex = null;

  expanded = false;

  leafGroup = false;

  allLeafChildren = [];

  allChildrenCount = null;

  childrenAfterGroup = null;

  childrenAfterSort = null;

  childrenAfterFilter = null;

  footer = false;

  sibling: IRowNode<Task>;

  constructor(task: Task, options?: TaskRowNodeOptions) {
    this.id = task.duid;
    this.data = task;
    this.options = options ?? {};
    this.sibling = this;
  }

  public get parent(): IRowNode<Task> | null {
    const { appStore } = this.options;
    if (appStore === undefined) {
      return null;
    }
    const dataStore = appStore.$useDataStore();

    const parent = dataStore.getParentTask(this.data);
    if (parent === undefined) {
      return null;
    }
    return new TaskRowNode(parent, { appStore });
  }

  public get rowIndex(): number | null {
    const { appStore } = this.options;
    if (appStore === undefined) {
      return null;
    }
    const res = appStore.filteredAndSortedTasksInPage.findIndex((e) => e.duid === this.id);
    return res !== -1 ? res : null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSelected(newValue: boolean, clearSelection?: boolean, source?: SelectionEventSourceType) {
    const { selectedOverride, appStore } = this.options;
    if (selectedOverride !== undefined || appStore === undefined) {
      return;
    }
    if (clearSelection) {
      appStore.selectedTaskDuids = new Set();
    }
    if (newValue) {
      appStore.selectedTaskDuids.add(this.id);
    } else {
      appStore.selectedTaskDuids.delete(this.id);
    }
  }

  isSelected() {
    const { selectedOverride, appStore } = this.options;
    if (selectedOverride !== undefined) {
      return selectedOverride;
    }
    if (appStore === undefined) {
      return false;
    }
    return appStore.selectedTaskDuids.has(this.id);
  }

  // eslint-disable-next-line class-methods-use-this
  isRowPinned() {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  isExpandable() {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  setExpanded(expanded: boolean, e?: MouseEvent | KeyboardEvent) {}

  // eslint-disable-next-line class-methods-use-this
  isFullWidthCell() {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  isHovered() {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types, class-methods-use-this, @typescript-eslint/no-unused-vars
  addEventListener(eventType: RowNodeEventType, listener: Function) {}

  // eslint-disable-next-line @typescript-eslint/ban-types, class-methods-use-this, @typescript-eslint/no-unused-vars
  removeEventListener(eventType: RowNodeEventType, listener: Function) {}

  // eslint-disable-next-line class-methods-use-this
  resetQuickFilterAggregateText() {}

  depthFirstSearch(callback: (rowNode: IRowNode<Task>) => void) {
    callback(this);
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  setRowHeight(rowHeight: number | undefined | null, estimated?: boolean) {}

  setData(data: Task) {
    this.data = data;
  }

  updateData(data: Task) {
    this.data = data;
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  setDataValue(colKey: string | Column, newValue: any, eventSource?: string) {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  getRoute() {
    return undefined;
  }
}

const isTaskNotGroupOrButton = (e: RowItem | undefined): e is TaskWithGroup => !!e && !e.isRoot && !isFillerRow(e.id);

const isRowTaskNotGroupOrButton = (e: IRowNode<RowItem> | undefined): e is IRowNode<TaskWithGroup> =>
  !!e && isTaskNotGroupOrButton(e.data);

export const getListVisualization = (getApi: () => GridApi<RowItem> | null, appStore: AppStore): Visualization => ({
  count() {
    return getApi()?.getDisplayedRowCount() ?? 0;
  },
  getSelected() {
    return this.getSelectedRows()
      .map((e) => e.data)
      .filter((e): e is Task => !!e);
  },
  getAll() {
    const res: RowItem[] = [];
    getApi()?.forEachNode((e) => {
      if (!isRowTaskNotGroupOrButton(e)) {
        return;
      }
      const node = getReactiveTaskNode(e, appStore);
      if (!node.data) {
        return;
      }
      res.push(node.data);
    });
    return res.filter(isTaskNotGroupOrButton);
  },
  getChildren(duid) {
    const row = duid ? this.getRow(duid) : this.getRowByIndex(0)?.parent;
    return (
      row?.childrenAfterFilter?.map((e) => getReactiveTaskNode(e, appStore).data).filter((e): e is Task => !!e) ?? []
    );
  },
  selectAll() {
    getApi()?.selectAll("filtered");
  },
  deselectAll() {
    getApi()?.deselectAll();
  },
  scrollTo(duid) {
    const row = this.getRow(duid);
    if (!row) {
      return;
    }

    getApi()?.ensureNodeVisible(row as IRowNode<RowItem>);
  },
  selectAndScrollTo(duid, options = {}) {
    const { deselectOthers = true } = options;
    const row = this.getRow(duid);
    if (!row) {
      return;
    }
    row.setSelected(true, deselectOthers);

    this.scrollTo(duid);
  },
  getHtml(duid) {
    return document.querySelector(`[row-id="${duid}"]`);
  },
  editTitle(duid) {
    const row = this.getRow(duid);
    if (!row) {
      return;
    }

    const renderer = getApi()?.getCellRendererInstances({
      rowNodes: [row as IRowNode<RowItem>],
      columns: ["title", "ag-Grid-AutoColumn"],
    })[0];
    if (!renderer) {
      return;
    }

    (renderer as unknown as { $root: typeof TitleCellEditor }).$root.focus();
  },
  getRow(duid) {
    const row = getApi()?.getRowNode(duid);
    return isRowTaskNotGroupOrButton(row) ? getReactiveTaskNode(row, appStore) : undefined;
  },
  getRowByIndex(index) {
    const row = getApi()?.getDisplayedRowAtIndex(index);
    return isRowTaskNotGroupOrButton(row) ? getReactiveTaskNode(row, appStore) : undefined;
  },
  getSelectedRows() {
    const nodes = getApi()?.getSelectedNodes() ?? [];
    return nodes.filter(isRowTaskNotGroupOrButton).map((e) => getReactiveTaskNode(e, appStore));
  },
  getAllRows() {
    const res: IRowNode<TaskWithGroup>[] = [];
    getApi()?.forEachNode((e) => {
      if (!isRowTaskNotGroupOrButton(e)) {
        return;
      }
      res.push(getReactiveTaskNode(e, appStore));
    });
    return res;
  },
});

export const getInboxVisualization = (getInbox: () => InstanceType<typeof InboxView> | null): Visualization => ({
  count() {
    throw Error("Not implemented");
  },
  getSelected() {
    return getInbox()?.getSelectedTasks() ?? [];
  },
  getAll() {
    throw Error("Not implemented");
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getChildren(duid) {
    throw Error("Not implemented");
  },
  selectAll() {},
  deselectAll() {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scrollTo(duid) {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectAndScrollTo(duid, options) {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getHtml(duid) {
    throw Error("Not implemented");
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  editTitle(duid) {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getRow(duid) {
    throw Error("Not implemented");
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getRowByIndex(index) {
    throw Error("Not implemented");
  },
  getSelectedRows() {
    return this.getSelected().map((e) => new TaskRowNode(e, { selectedOverride: true }));
  },
  getAllRows() {
    throw Error("Not implemented");
  },
});

export const getBoardOrCalendarVisualization = (
  getBoardOrCalendar: () => InstanceType<typeof Board> | InstanceType<typeof Calendar> | null,
  appStore: AppStore
): Visualization => ({
  count() {
    return this.getAll().length;
  },
  getSelected() {
    const dataStore = appStore.$useDataStore();

    return dataStore.getTasksByDuidsOrdered([...appStore.selectedTaskDuids]);
  },
  getAll() {
    return appStore.filteredAndSortedTasksInPage;
  },
  getChildren(duid) {
    const dataStore = appStore.$useDataStore();

    if (duid === null) {
      return this.getAll().filter((e) => !dataStore.getParentTask(e));
    }
    const task = dataStore.getTaskByDuid(duid);
    if (task === undefined) {
      return [];
    }

    const subtaskDuids = dataStore
      .getRelationshipsByKindKind(task, RelationshipKindKind.PARENT_OF, true)
      .map((e) => e.targetDuid);
    return dataStore.getTasksByDuidsOrdered(subtaskDuids);
  },
  selectAll() {
    // eslint-disable-next-line no-param-reassign
    appStore.selectedTaskDuids = new Set(this.getAll().map((e) => e.duid));
  },
  deselectAll() {
    // eslint-disable-next-line no-param-reassign
    appStore.selectedTaskDuids = new Set();
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scrollTo(duid) {},
  selectAndScrollTo(duid, options = {}) {
    const { deselectOthers = true } = options;
    if (!deselectOthers) {
      appStore.selectedTaskDuids.add(duid);
      return;
    }
    // eslint-disable-next-line no-param-reassign
    appStore.selectedTaskDuids = new Set([duid]);
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getHtml(duid) {
    return null;
  },
  editTitle(duid) {
    getBoardOrCalendar()?.startEditingTask(duid);
  },
  getRow(duid) {
    const dataStore = appStore.$useDataStore();

    const task = dataStore.getTaskByDuid(duid);
    if (task === undefined) {
      return undefined;
    }
    return new TaskRowNode(task, { appStore });
  },
  getRowByIndex(index) {
    const task = this.getAll()[index];
    if (task === undefined) {
      return undefined;
    }
    return new TaskRowNode(task, { appStore });
  },
  getSelectedRows() {
    return this.getSelected().map((e) => new TaskRowNode(e, { appStore }));
  },
  getAllRows() {
    return this.getAll().map((e) => new TaskRowNode(e, { appStore }));
  },
});

export const getDefaultVisualization = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getComponent: () => unknown
): Visualization => ({
  count() {
    throw Error("Not implemented");
  },
  getSelected() {
    throw Error("Not implemented");
  },
  getAll() {
    throw Error("Not implemented");
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getChildren(duid) {
    throw Error("Not implemented");
  },
  selectAll() {},
  deselectAll() {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scrollTo(duid) {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectAndScrollTo(duid, options) {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getHtml(duid) {
    throw Error("Not implemented");
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  editTitle(duid) {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getRow(duid) {
    throw Error("Not implemented");
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getRowByIndex(index) {
    throw Error("Not implemented");
  },
  getSelectedRows() {
    throw Error("Not implemented");
  },
  getAllRows() {
    throw Error("Not implemented");
  },
});
