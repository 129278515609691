import { addClassNamesToElement } from "@lexical/utils";
import { renderToString } from "@vue/server-renderer";
import type { EditorConfig, LexicalNode, NodeKey, SerializedTextNode } from "lexical";
import { $applyNodeReplacement, TextNode } from "lexical";
import { h } from "vue";

import { getPropertyTypeaheadOption } from "~/common/properties";
import type { TypeaheadOption } from "~/shared/types";
import { run } from "~/utils/common";

import SmartMatchClose from "./SmartMatchClose.vue";

type Value = string | boolean | number | null;
export type SerializedSmartMatchNode = SerializedTextNode & { propertyDuid: string; value: Value };

let closeButton: string;
run(async () => {
  closeButton = await renderToString(h(SmartMatchClose));
});

export class SmartMatchNode extends TextNode {
  __propertyDuid: string;

  __value: Value;

  static getType(): string {
    return "smart-match";
  }

  static clone(node: SmartMatchNode): SmartMatchNode {
    return new SmartMatchNode(node.__text, node.__propertyDuid, node.__value, node.__key);
  }

  constructor(text: string, propertyDuid: string, value: Value, key?: NodeKey) {
    super(text, key);
    this.__propertyDuid = propertyDuid;
    this.__value = value;
  }

  getPropertyDuid(): string {
    return this.__propertyDuid;
  }

  getValue(): Value {
    return this.__value;
  }

  async #getOptionAndSet(element: HTMLElement) {
    const result = await getPropertyTypeaheadOption(
      this.getPropertyDuid(),
      this.getValue() as TypeaheadOption["value"]
    );
    const [property, option] = result ?? [];
    if (!property || !option) {
      return;
    }

    // TODO make more robust and use a proper tooltip
    element.setAttribute("title", `${property.title}: ${option.label}`);
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = super.createDOM(config);
    addClassNamesToElement(element, config.theme.smartMatch, "group/smart-match", "relative");
    this.#getOptionAndSet(element);
    element.insertAdjacentHTML("beforeend", closeButton);
    return element;
  }

  static importJSON(serializedNode: SerializedSmartMatchNode): SmartMatchNode {
    // eslint-disable-next-line no-use-before-define
    const node = $createSmartMatchNode(serializedNode.text, serializedNode.propertyDuid, serializedNode.value);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  exportJSON(): SerializedSmartMatchNode {
    return {
      ...super.exportJSON(),
      type: SmartMatchNode.getType(),
      propertyDuid: this.getPropertyDuid(),
      value: this.getValue(),
      version: 1,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  canInsertTextBefore(): false {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  isTextEntity(): true {
    return true;
  }
}

export function $createSmartMatchNode(text: string, propertyDuid: string, value: Value): SmartMatchNode {
  return $applyNodeReplacement(new SmartMatchNode(text ?? "", propertyDuid, value));
}

export function $isSmartMatchNode(node: LexicalNode | null | undefined): node is SmartMatchNode {
  return node instanceof SmartMatchNode;
}
