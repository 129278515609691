import { type ElementTransformer } from "@lexical/markdown";
import {
  $createParagraphNode,
  DecoratorNode,
  ElementNode,
  type LexicalNode,
  type SerializedLexicalNode,
} from "lexical";
import type { Component } from "vue";
import { h } from "vue";

import HorizontalRule from "./HorizontalRule.vue";

export class HorizontalRuleNode extends DecoratorNode<Component> {
  static getType(): string {
    return "horizontal-rule";
  }

  static clone(node: HorizontalRuleNode): HorizontalRuleNode {
    return new HorizontalRuleNode(node.__key);
  }

  // eslint-disable-next-line class-methods-use-this
  createDOM(): HTMLElement {
    const element = document.createElement("div");
    element.classList.add("contents");
    return element;
  }

  // eslint-disable-next-line class-methods-use-this
  updateDOM(): false {
    return false;
  }

  static importJSON(): LexicalNode {
    return new HorizontalRuleNode();
  }

  // eslint-disable-next-line class-methods-use-this
  exportJSON(): SerializedLexicalNode {
    return {
      type: HorizontalRuleNode.getType(),
      version: 1,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  decorate(): Component {
    return h(HorizontalRule);
  }

  // eslint-disable-next-line class-methods-use-this
  isInline(): true {
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  isKeyboardSelectable(): false {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  getTextContent(): string {
    return "---";
  }

  getTextContentSize(): number {
    return this.getTextContent().length;
  }
}

export function $createHorizontalRuleNode(): HorizontalRuleNode {
  return new HorizontalRuleNode();
}

export function $isHorizontalRuleNode(node: LexicalNode | null): node is HorizontalRuleNode {
  return node instanceof HorizontalRuleNode;
}

export const HORIZONTAL_RULE_TRANSFORMER: ElementTransformer = {
  dependencies: [HorizontalRuleNode],
  export: (node: LexicalNode) => {
    if (!$isHorizontalRuleNode(node)) {
      return null;
    }
    return "---";
  },
  regExp: /---|—-|-—/,
  replace: (parentNode: ElementNode) => {
    const hrNode = $createHorizontalRuleNode();
    parentNode.replace(hrNode);

    const paragraphNode = $createParagraphNode();
    hrNode.insertAfter(paragraphNode);
    paragraphNode.selectStart();
  },
  type: "element",
};
