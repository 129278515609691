<script setup lang="ts">
import moment from "moment";
import { NotionRenderer as NotionRendererReact } from "react-notion-x";
import { applyPureReactInVue } from "veaury";
import { computed, defineAsyncComponent, onUnmounted, ref, watch } from "vue";

import Tooltip from "~/components/dumb/Tooltip.vue";
import { colorsByTheme } from "~/constants/style";
import { OpenExternalLinkIcon, RefreshIcon } from "~/icons";
import { TaskLinkKind } from "~/shared/enums";
import type { Task } from "~/shared/types";
import { useDataStore, usePageStore } from "~/stores";
import { deepCopy, getNotionPageUrl } from "~/utils/common";

// Dynamically import large NotionRenderer components
const Code = defineAsyncComponent(() => import("react-notion-x/build/third-party/code"));
const Collection = defineAsyncComponent(() => import("react-notion-x/build/third-party/collection"));
const Equation = defineAsyncComponent(() => import("react-notion-x/build/third-party/equation"));
const Modal = defineAsyncComponent(() => import("react-notion-x/build/third-party/modal"));
const Pdf = defineAsyncComponent(() => import("react-notion-x/build/third-party/pdf"));

const MIN_HEIGHT = 200;

const props = defineProps<{
  task: Task;
}>();

const dataStore = useDataStore();
const pageStore = usePageStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const NotionRenderer = applyPureReactInVue(NotionRendererReact);

const pageId = computed(() => props.task.notionDocument?.pageId);
const recordMap = ref<Record<string, unknown> | null>(null);
const failedParsingDocument = ref(false);
const doesntExistOrNoAccess = computed(() => props.task.notionDocument?.existsAndAccessGranted === false);
// Show loading animation while adding or refreshing
const refreshing = computed(() => props.task.notionDocument?.refreshing);
const loading = computed(() => !failedParsingDocument.value && !refreshing.value && !recordMap.value);

// Refresh document every hour if it's open
let refreshTimeout: ReturnType<typeof setTimeout> | undefined;
const updateRefreshTimeout = () => {
  if (refreshTimeout) {
    clearTimeout(refreshTimeout);
  }
  const lastRefreshAt = props.task.notionDocument?.lastRefreshAt;
  if (!lastRefreshAt) {
    return;
  }
  const msToRefresh = Math.max(moment(lastRefreshAt).add(1, "hour").diff(moment()), 0);
  refreshTimeout = setTimeout(() => {
    dataStore.refreshNotionDocument(props.task.duid);
  }, msToRefresh);
};
updateRefreshTimeout();
onUnmounted(() => {
  if (!refreshTimeout) {
    return;
  }
  clearTimeout(refreshTimeout);
});

// Offload parsing to a Web Worker
const worker = new Worker(new URL("./NotionCompatAdapterWorker.ts", import.meta.url), {
  type: "module",
});
worker.addEventListener("message", (event) => {
  if (!event.data) {
    failedParsingDocument.value = true;
    return;
  }
  recordMap.value = event.data;
  updateRefreshTimeout();
});

// Convert the document to a link if there is an error
watch([() => failedParsingDocument.value, () => doesntExistOrNoAccess.value], ([failed, doesntExist]) => {
  if (!(failed || doesntExist) || !pageId.value) {
    return;
  }
  const taskDuid = props.task.duid;
  const taskListKind = failed ? TaskLinkKind.NOTION_DOCUMENT_PARSE_FAILED : TaskLinkKind.NOTION_DOCUMENT_DOESNT_EXIST;
  dataStore.removeNotionDocument(taskDuid);
  dataStore.addLink(taskDuid, taskListKind, getNotionPageUrl(pageId.value), null);
});

// Refresh document when pageId or lastRefreshAt changes
watch(
  [() => props.task.notionDocument?.pageId, () => props.task.notionDocument?.lastRefreshAt],
  async () => {
    recordMap.value = null;
    failedParsingDocument.value = false;

    if (
      props.task.notionDocument?.existsAndAccessGranted &&
      props.task.notionDocument?.lastRefreshAt &&
      !props.task.notionDocument.refreshing
    ) {
      const task = await dataStore.getNotionDocument(props.task.duid);
      worker.postMessage({
        data: deepCopy(task?.notionDocument),
      });
    }
  },
  { immediate: true }
);

const isDark = computed(() => pageStore.theme === "dark");
</script>

<template>
  <div
    :style="{ 'min-height': `${MIN_HEIGHT}px` }"
    class="group/notion relative w-full rounded border bg-std border-lt hover:border-md">
    <!-- Refreshing animation -->
    <div
      v-if="loading || refreshing"
      :style="{ 'min-height': `${MIN_HEIGHT - 2}px` }"
      class="flex size-full items-center justify-center">
      <RefreshIcon class="size-16 animate-spin text-vlt" />
    </div>
    <!-- Notion document -->
    <!-- eslint-disable vue/attribute-hyphenation vue/prefer-true-attribute-shorthand -->
    <NotionRenderer
      v-else-if="recordMap && !doesntExistOrNoAccess && !failedParsingDocument"
      :components="{
        Code,
        Collection,
        Equation,
        Modal,
        Pdf,
      }"
      :fullPage="true"
      :disableHeader="true"
      :showTableOfContents="false"
      :mapPageUrl="getNotionPageUrl"
      :rootPageId="pageId"
      :recordMap="recordMap"
      :darkMode="isDark" />
    <!-- eslint-enable vue/attribute-hyphenation vue/prefer-true-attribute-shorthand -->
    <!-- Open in Notion button -->
    <div
      class="absolute -right-px -top-px size-24 overflow-hidden rounded border border-transparent opacity-0 transition-opacity duration-100 group-hover/notion:opacity-100">
      <div
        class="absolute -right-11 -top-11 size-28 bg-gradient-radial from-white/80 via-white/60 dark:from-zinc-850/80 dark:via-zinc-850/60" />
      <a
        v-if="pageId"
        class="absolute right-2.5 top-2.5"
        :href="getNotionPageUrl(pageId)"
        target="_blank"
        rel="noopener noreferrer">
        <span class="sr-only">Open in Notion</span>
        <Tooltip text="Open in Notion">
          <OpenExternalLinkIcon class="text-md icon-md" />
        </Tooltip>
      </a>
    </div>
  </div>
</template>

<style>
@import "react-notion-x/src/styles.css";
/* used for code syntax highlighting (optional) */
@import "prismjs/themes/prism-tomorrow.css";
/* used for rendering equations (optional) */
@import "katex/dist/katex.min.css";

/* Doc radius */
.notion-app {
  border-radius: 4px;
}

/* Page cover top radius */
.notion-page-cover {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

/* Remove extra padding on whole frame */
.notion-frame {
  padding: 0;
}

/* Reduce bottom padding */
.notion-full-page {
  width: inherit;
  padding-bottom: 16px;
}

/* Custom styles */
.dark-mode {
  background: v-bind("colors.bgStd") !important;
}

/* Hide the anchor link */
.notion-hash-link {
  display: none !important;
}
</style>
