<script setup lang="ts">
import { computed, ref } from "vue";

import { backendOld } from "~/api";
import AuthToken from "~/components/AuthToken.vue";
import Button from "~/components/dumb/Button.vue";
import FormError from "~/components/dumb/FormError.vue";
import Input from "~/components/dumb/Input.vue";
import Toggle from "~/components/dumb/Toggle.vue";
import SettingsCopyText from "~/components/settings/SettingsCopyText.vue";
import SettingsRow from "~/components/settings/SettingsRow.vue";
import SettingsSection from "~/components/settings/SettingsSection.vue";
import { ButtonStyle, SamlConfigStatus } from "~/shared/enums";
import { useEnvironmentStore, useTenantStore } from "~/stores";
import { validateUrl, validateXml } from "~/utils/common";

const environmentStore = useEnvironmentStore();
const tenantStore = useTenantStore();

const urlInput = ref<InstanceType<typeof Input> | null>(null);
const xmlTextarea = ref<HTMLTextAreaElement | null>(null);

const toggleSamlEnabled = async (value: boolean) => {
  const newStatus = value ? SamlConfigStatus.ENABLED : SamlConfigStatus.DISABLED;
  if (tenantStore.samlConfig?.status === newStatus) {
    return;
  }

  tenantStore.samlConfig = {
    idpUrl: "",
    idpXml: "",
    ...tenantStore.samlConfig,
    status: newStatus,
  };
  if (value) {
    await backendOld.workspace.enableSamlSso();
    return;
  }
  await backendOld.workspace.disableSamlSso();
};

const newIdpXml = ref(tenantStore.samlConfig?.idpXml ?? "");
const xmlTouched = ref(false);
const xmlError = computed(() => {
  const res = validateXml(newIdpXml.value);
  if (res.isValid) {
    return undefined;
  }
  return res.error;
});
const xmlTouchedAndError = computed(() => xmlTouched.value && !!xmlError.value);

const idpDetailsOnUrl = ref(true);
const setIdpDetailsOnUrl = (value: boolean) => {
  idpDetailsOnUrl.value = value;
  newIdpXml.value = tenantStore.samlConfig?.idpXml ?? "";
  xmlTouched.value = false;
};

const changeSamlDetails = () => {
  if (idpDetailsOnUrl.value) {
    const url = urlInput.value?.value ?? "";
    if (tenantStore.samlConfig) {
      tenantStore.samlConfig.idpUrl = url;
    }
    backendOld.workspace.configureSamlSso({ url });
    return;
  }
  const xml = newIdpXml.value;
  if (tenantStore.samlConfig) {
    tenantStore.samlConfig.idpXml = xml;
  }
  backendOld.workspace.configureSamlSso({ xml });
};
</script>

<template>
  <div>
    <SettingsSection title="SAML SSO" class="!mt-0">
      <div class="select-none text-sm/relaxed text-lt">
        <p>Allow users in your organization to use SAML single sign-on (SSO) to log in.</p>
        <p>
          Read
          <a href="https://help.itsdart.com/articles/9857197-saml-sso" target="_blank" rel="noopener noreferrer">
            <span class="underline text-vlt">this help center article</span>
          </a>
          for more information on how to get this configured.
        </p>
      </div>
      <SettingsRow title="Enable SAML SSO" class="!font-semibold">
        <Toggle :value="tenantStore.samlEnabled" @update="toggleSamlEnabled" />
      </SettingsRow>
      <template v-if="tenantStore.samlEnabled && tenantStore.samlConfig">
        <SettingsRow title="Entity ID">
          <SettingsCopyText :text="environmentStore.samlEntityId" label="Entity ID" class="w-72" />
        </SettingsRow>
        <SettingsRow title="ACS URL">
          <SettingsCopyText :text="environmentStore.samlAcsUrl" label="ACS URL" class="w-72" />
        </SettingsRow>
        <div class="flex flex-col">
          <span class="select-none font-semibold text-md">IdP Details</span>
          <div class="mb-2 flex items-center">
            <Button
              :btn-style="ButtonStyle.SECONDARY"
              text="URL"
              :text-style="idpDetailsOnUrl ? undefined : 'text-vlt'"
              borderless
              class="mt-[9px] rounded-b-none border-b-2 !px-2 pb-1"
              :class="idpDetailsOnUrl && 'border-b-primary-base'"
              @click="setIdpDetailsOnUrl(true)" />
            <Button
              :btn-style="ButtonStyle.SECONDARY"
              text="XML"
              borderless
              :text-style="!idpDetailsOnUrl ? undefined : 'text-vlt'"
              class="mt-[9px] rounded-b-none border-b-2 !px-2 pb-1"
              :class="!idpDetailsOnUrl && 'border-b-primary-base'"
              @click="setIdpDetailsOnUrl(false)" />
          </div>
          <Input
            v-if="idpDetailsOnUrl"
            ref="urlInput"
            :init-value="tenantStore.samlConfig.idpUrl"
            input-type="url"
            hide-label
            label="Link URL"
            placeholder="Type or paste in the URL of the IdP metadata"
            :validate="validateUrl" />
          <template v-else>
            <textarea
              ref="xmlTextarea"
              v-model="newIdpXml"
              aria-label="XML"
              class="min-h-20 w-full rounded border text-sm bg-std text-md focus-ring-none placeholder:text-vlt focus:border-primary-base dark:focus:border-primary-base"
              :class="{
                'border-danger-base hover:border-danger-hover-light dark:hover:border-danger-hover-dark':
                  xmlTouchedAndError,
                'border-md hover:border-hvy': !xmlTouchedAndError,
              }"
              placeholder="Type or paste in XML describing the IdP metadata"
              @focus="xmlTouched = false"
              @focusout="xmlTouched = true" />
            <FormError :msg="xmlError" :show="xmlTouchedAndError" />
          </template>
          <Button
            :btn-style="ButtonStyle.PRIMARY"
            text="Save"
            :disabled="
              idpDetailsOnUrl
                ? !urlInput?.isValid || urlInput?.value === tenantStore.samlConfig.idpUrl
                : newIdpXml === tenantStore.samlConfig.idpXml || !!xmlError
            "
            class="self-end"
            @click="changeSamlDetails" />
        </div>
      </template>
    </SettingsSection>

    <SettingsSection :key="`${tenantStore.samlEnabled}`" title="SCIM" no-divider>
      <div class="select-none text-sm/relaxed text-lt">
        <p>Use SCIM provisioning to automatically create, update, and delete users in your organization.</p>
        <p>
          Read
          <a href="https://help.itsdart.com/articles/9855051-scim" target="_blank" rel="noopener noreferrer">
            <span class="underline text-vlt">this help center article</span>
          </a>
          for more information on how to get this configured.
        </p>
      </div>
      <SettingsRow title="SCIM URL">
        <SettingsCopyText :text="environmentStore.scimUrl" label="SCIM URL" class="w-72" />
      </SettingsRow>
      <SettingsRow title="Authentication token">
        <AuthToken />
      </SettingsRow>
    </SettingsSection>
  </div>
</template>
