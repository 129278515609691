import { PomodoroBackgroundKind } from "~/shared/enums";
import type { PomodoroBackground } from "~/shared/types";

const colorHexes = ["#C07373", "#3B527D", "#793C4E", "#306151", "#8376C5", "#1E1E21"];

const gradientFiles = ["gradient_1", "gradient_2", "gradient_3", "gradient_4", "gradient_5", "gradient_6"];

const imageFiles = ["1", "2", "3", "4", "5", "6", "7", "8"];

export const COLOR_BACKGROUNDS: PomodoroBackground[] = colorHexes.map((fileNameOrColorHex) => ({
  kind: PomodoroBackgroundKind.COLOR,
  fileNameOrColorHex,
}));

export const GRADIENT_BACKGROUNDS: PomodoroBackground[] = gradientFiles.map((fileNameOrColorHex) => ({
  kind: PomodoroBackgroundKind.GRADIENT,
  fileNameOrColorHex,
}));

export const IMAGE_BACKGROUNDS: PomodoroBackground[] = imageFiles.map((fileNameOrColorHex) => ({
  kind: PomodoroBackgroundKind.IMAGE,
  fileNameOrColorHex,
}));
