import type { PiniaPluginContext } from "pinia";
import { markRaw } from "vue";

import { backend, backendOld, requestManager } from "~/api";
import { entityListManager } from "~/api/entityListManager";

/* Make api available inside stores. */
export default ({ store }: PiniaPluginContext) => {
  store.$backendOld = markRaw(backendOld);
  store.$backend = markRaw(backend);
  store.$requestManager = markRaw(requestManager);
  store.$entityListManager = markRaw(entityListManager);
};
