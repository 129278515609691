<script setup lang="ts">
import { ref } from "vue";

import Modal from "~/components/dumb/Modal.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { XIcon } from "~/icons";
import { ModalPosition, ModalWidth } from "~/shared/enums";
import type { PomodoroBackground as PomodoroBackgroundType } from "~/shared/types";
import { COLOR_BACKGROUNDS, GRADIENT_BACKGROUNDS, IMAGE_BACKGROUNDS } from "~/views/pomodoro/constants";
import PomodoroBackground from "~/views/pomodoro/PomodoroBackground.vue";

const BACKGROUND_SECTIONS = [
  { title: "Colors", backgrounds: COLOR_BACKGROUNDS },
  { title: "Gradients", backgrounds: GRADIENT_BACKGROUNDS },
  { title: "Images", backgrounds: IMAGE_BACKGROUNDS },
];

defineProps<{
  selectedBg: PomodoroBackgroundType;
}>();

const emit = defineEmits<{
  select: [PomodoroBackgroundType];
}>();

const open = ref(false);

const close = () => {
  open.value = false;
};

const onSelect = (bg: PomodoroBackgroundType) => {
  emit("select", bg);
  close();
};

defineExpose({
  open: () => {
    open.value = true;
  },
});
</script>

<template>
  <Modal
    :entity="open"
    title="Focus themes"
    hide-title
    :width="ModalWidth.CUSTOM"
    :custom-width-pixels="440"
    overflow-clip
    close-icon-styles="hidden"
    custom-styles="overflow-hidden h-full !px-0 !rounded-xl"
    :position="ModalPosition.RIGHT"
    @close="close">
    <template #default>
      <div class="dart-font-nunito relative flex size-full flex-col gap-6">
        <div class="flex w-full items-center justify-between px-6">
          <div class="text-lg font-semibold text-gray-700">Focus themes</div>
          <Tooltip
            text="Close"
            class="cursor-pointer rounded p-0.5 text-gray-600 hover:bg-gray-200"
            @click="close"
            @keydown.enter="close">
            <XIcon class="size-4 focus:outline-none" />
          </Tooltip>
        </div>
        <div class="flex w-full flex-col gap-6 overflow-y-auto pb-2 pl-6 pr-2" :style="{ scrollbarGutter: 'stable' }">
          <div v-for="section in BACKGROUND_SECTIONS" :key="section.title" class="flex w-full flex-col gap-1.5">
            <div class="text-sm text-gray-500">{{ section.title }}</div>
            <div class="grid grid-cols-3 gap-4">
              <PomodoroBackground
                v-for="bg in section.backgrounds"
                :key="bg.fileNameOrColorHex"
                :bg="bg"
                :selected="selectedBg.fileNameOrColorHex === bg.fileNameOrColorHex"
                thumbnail
                class="aspect-[4/3] w-full rounded-lg"
                @select="onSelect" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<!-- Also downloaded in Pomodoro.vue -->
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap");

.dart-font-nunito {
  font-family: "Nunito", sans-serif;
}
</style>
