<script setup lang="ts">
import { ref } from "vue";

import { ChevronDownIcon, ChevronUpIcon } from "~/icons";
import { makeUuid } from "~/utils/common";

const props = defineProps<{
  value: number;
  label: string;
  min?: number;
}>();

const emit = defineEmits<{
  change: [newValue: number];
}>();

const change = (newNumber: number) => {
  emit("change", Math.max(Math.round(newNumber), 1));
};

const onInput = (event: Event) => {
  change(Number((event.target as HTMLInputElement)?.value));
};

const onKeyDown = (e: KeyboardEvent) => {
  if (e.key === "." || e.key === "," || e.key === "-" || e.key === "+") {
    e.preventDefault();
  }

  if (e.key === "ArrowDown" && props.min !== undefined && Number((e.target as HTMLInputElement).value) <= props.min) {
    e.preventDefault();
  }
};

const id = ref(`number-${makeUuid()}`);
</script>

<template>
  <div
    class="relative flex h-8 flex-row gap-1.5 rounded-md border py-0.5 pl-3 pr-0.5 text-lt border-md focus-within:border-hvy hover:border-hvy">
    <label :for="id" class="sr-only">{{ label }}</label>
    <input
      :id="id"
      :value="value"
      class="w-6 rounded-md border-0 bg-transparent p-0 font-mono text-xs tabular-nums focus-ring-none"
      type="number"
      @keydown.stop="onKeyDown"
      @input="onInput" />
    <div class="flex flex-col justify-center">
      <button
        type="button"
        class="cursor-pointer rounded focus-ring-none hover:bg-md"
        @click="change(value + 1)"
        @keydown.enter="change(value + 1)">
        <ChevronUpIcon class="size-[13px]" />
      </button>
      <button
        type="button"
        class="cursor-pointer rounded focus-ring-none hover:bg-md"
        @click="change(value - 1)"
        @keydown.enter="change(value - 1)">
        <ChevronDownIcon class="size-[13px]" />
      </button>
    </div>
  </div>
</template>
