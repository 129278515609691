<script setup lang="ts">
import { computed, ref } from "vue";

import { getPropertyConfig, getPropertyPartialTask, getPropertyValueFromTask } from "~/common/properties";
import Button from "~/components/dumb/Button.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import StatusIcon from "~/components/dumb/StatusIcon.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { UNCHANGED_STATUS_LABEL, UNSET_STATUS_LABEL, UNSET_STATUS_PSUEDO_DUID } from "~/constants/status";
import { colorsByTheme } from "~/constants/style";
import { CheckIcon, ChevronRightIcon, StatusFieldIcon } from "~/icons";
import {
  ButtonSize,
  ButtonStyle,
  CommandId,
  DropdownMenuItemKind,
  EditorMode,
  IconSize,
  Placement,
  StatusKind,
  ViewKind,
} from "~/shared/enums";
import type { DropdownMenuItem, PropertyAnyStatus, PropertyValueForKind, Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";

const STATUS_KIND_TO_SHORTCUT_MAP = new Map([
  [StatusKind.UNSTARTED, CommandId.CYCLE_UNSTARTED_STATUSES],
  [StatusKind.STARTED, CommandId.CYCLE_STARTED_STATUSES],
  [StatusKind.BLOCKED, CommandId.CYCLE_BLOCKED_STATUSES],
  [StatusKind.FINISHED, CommandId.CYCLE_FINISHED_STATUSES],
  [StatusKind.CANCELED, CommandId.CYCLE_CANCELED_STATUSES],
]);

type Value = PropertyValueForKind<PropertyAnyStatus>;

const props = defineProps<{
  property: PropertyAnyStatus;
  tasks: Task[];
  value?: Value;
  hideUnset?: boolean;
  showText?: boolean;
  editorMode: EditorMode;
  onAfterClose?: (statusDuid?: string) => void;
}>();

const emit = defineEmits<{
  select: [value: Value];
  update: [value: Value];
  reject: [];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const dropdownMenu = ref<InstanceType<typeof DropdownMenu> | null>(null);
const colors = computed(() => colorsByTheme[pageStore.theme]);

const propertyConfig = computed(() => getPropertyConfig(props.property.kind));
const isDefault = computed(() => propertyConfig.value.isDefault);
const isPropertyNameShown = computed(() => props.property.adtl.isNameShown);

const taskKinds = computed(() =>
  dataStore.getTaskKindsByDuidsOrdered([...new Set(props.tasks.map((task) => task.kindDuid))])
);
const statusList = computed(() => dataStore.getStatusList(props.property, taskKinds.value));

const isTrashPage = computed(() => appStore.currentPage?.kind === ViewKind.TRASH);

const isChipMode = computed(
  () => props.editorMode === EditorMode.CHIP || props.editorMode === EditorMode.CHIP_RECOMMENDATION
);
const isListMiniNonTcmMode = computed(
  () => props.editorMode === EditorMode.LIST_MINI || props.editorMode === EditorMode.LIST_MINI_ROADMAP
);
const isListMiniTcmMode = computed(() => props.editorMode === EditorMode.LIST_MINI_TCM);
const isListMiniNonRoadmapMode = computed(
  () => props.editorMode === EditorMode.LIST_MINI || props.editorMode === EditorMode.LIST_MINI_TCM
);
const isListMode = computed(
  () => props.editorMode === EditorMode.LIST || isListMiniNonTcmMode.value || isListMiniTcmMode.value
);
const isGithubChipMode = computed(() => props.editorMode === EditorMode.CHIP_GITHUB);
const isTaskDetailMode = computed(() => props.editorMode === EditorMode.DETAIL);
const isContextMenuMode = computed(() => props.editorMode === EditorMode.CONTEXT_MENU);
const isFormMode = computed(() => props.editorMode === EditorMode.FORM);
const isPropertyDefaultMode = computed(() => props.editorMode === EditorMode.PROPERTY_DEFAULT);
const isFormOrDefaultPropertyMode = computed(() => isFormMode.value || isPropertyDefaultMode.value);

const hasTwoStatuses = computed(
  () => statusList.value.length === 2 && !isContextMenuMode.value && !isFormOrDefaultPropertyMode.value
);

const selectedTasks = computed(() =>
  isChipMode.value || isListMiniNonRoadmapMode.value
    ? props.tasks
    : dataStore.getTasksByDuidsOrdered([...appStore.selectedTaskDuids])
);

const selectedStatusDuid = computed(() => {
  if (isGithubChipMode.value || isFormOrDefaultPropertyMode.value) {
    return props.value ?? UNSET_STATUS_PSUEDO_DUID;
  }

  const firstTaskStatus = getPropertyValueFromTask(props.property, props.tasks[0]);
  return props.tasks.every((task) => getPropertyValueFromTask(props.property, task) === firstTaskStatus)
    ? firstTaskStatus
    : undefined;
});
const selectedStatus = computed(() => dataStore.getStatusByDuid(selectedStatusDuid.value ?? ""));

const updateStatus = (duid: string) =>
  dataStore.updateTasks(
    selectedTasks.value.map((task) => ({ duid: task.duid, ...getPropertyPartialTask(props.property, task, duid) }))
  );

const nextStatus = computed(() => {
  const indexOfCurrentStatus = statusList.value.findIndex((status) => status.duid === selectedStatusDuid.value);
  return statusList.value[(indexOfCurrentStatus + 1) % statusList.value.length];
});

const finishedStatuses = computed(() => dataStore.getStatusesByKinds([StatusKind.FINISHED], props.property));
const defaultFinishedStatus = computed(
  () => finishedStatuses.value.find((status) => status.locked) ?? finishedStatuses.value[0]
);

const showMarkComplete = computed(() => {
  if (
    hasTwoStatuses.value ||
    finishedStatuses.value.length === 0 ||
    defaultFinishedStatus.value.duid === selectedStatusDuid.value
  ) {
    return false;
  }

  const finishedStatusDuids = new Set(finishedStatuses.value.map((status) => status.duid));
  return selectedTasks.value.map((task) => !finishedStatusDuids.has(getPropertyValueFromTask(props.property, task)));
});

const cycleToNextStatus = () => {
  if (!nextStatus.value) {
    return;
  }
  // There is a race condition with grid selection that requires us to wait for it
  setTimeout(() => updateStatus(nextStatus.value.duid), 1);
};

const markFinished = () => {
  if (!defaultFinishedStatus.value) {
    return;
  }

  updateStatus(defaultFinishedStatus.value.duid);
};

const dropdownSections = computed(() => {
  const firstTaskStatus =
    isGithubChipMode.value || isFormOrDefaultPropertyMode.value
      ? undefined
      : getPropertyValueFromTask(props.property, props.tasks[0]);
  const statusDuidOfSelectedTasks =
    isGithubChipMode.value || isFormOrDefaultPropertyMode.value
      ? undefined
      : selectedTasks.value.every((task) => getPropertyValueFromTask(props.property, task) === firstTaskStatus)
        ? firstTaskStatus
        : undefined;

  const items: DropdownMenuItem[] = [];
  if ((isGithubChipMode.value || isFormOrDefaultPropertyMode.value) && !props.hideUnset) {
    items.push({
      title: isGithubChipMode.value ? UNCHANGED_STATUS_LABEL : UNSET_STATUS_LABEL,
      kind: DropdownMenuItemKind.BUTTON,
      disabled: selectedStatusDuid.value === UNSET_STATUS_PSUEDO_DUID,
      icon: StatusFieldIcon,
      iconArgs: { class: "icon-md text-vlt" },
      onClick: () => {
        emit("select", UNSET_STATUS_PSUEDO_DUID);
      },
    });
  }
  items.push(
    ...statusList.value.map((status) => ({
      title: status.title,
      kind: DropdownMenuItemKind.BUTTON,
      icon: StatusIcon,
      iconArgs: {
        duid: status.duid,
        class: "icon-md",
      },
      disabled:
        isGithubChipMode.value || isFormOrDefaultPropertyMode.value
          ? status.duid === selectedStatusDuid.value
          : status.duid === statusDuidOfSelectedTasks,
      commandId:
        isGithubChipMode.value || !isDefault.value || isFormOrDefaultPropertyMode.value
          ? undefined
          : status.locked && status.kind === StatusKind.FINISHED
            ? CommandId.CHANGE_STATUS_TO_DEFAULT_FINISHED
            : STATUS_KIND_TO_SHORTCUT_MAP.get(status.kind),
      onClick: () => {
        if (isGithubChipMode.value || isFormOrDefaultPropertyMode.value) {
          emit("select", status.duid);
          return;
        }
        updateStatus(status.duid);
      },
    }))
  );
  return [{ title: "Status", items }];
});

const handleAfterClose = () => {
  props.onAfterClose?.(selectedStatusDuid.value);
};

defineExpose({
  open: () => dropdownMenu.value?.open(),
});
</script>

<template>
  <DropdownMenu
    ref="dropdownMenu"
    :container="isChipMode ? '#dart-task-creation-modal-wrapper' : isFormMode ? '#dart-form-wrapper' : undefined"
    :disabled="hasTwoStatuses"
    :sections="dropdownSections"
    :block="isListMode || isTaskDetailMode || isFormOrDefaultPropertyMode"
    :height-block="isListMode || isFormOrDefaultPropertyMode"
    :distance="isFormOrDefaultPropertyMode ? 2 : undefined"
    :skidding="isFormOrDefaultPropertyMode ? -1 : undefined"
    :show-on-hover="isContextMenuMode"
    :cover="!isContextMenuMode && !isFormOrDefaultPropertyMode"
    :placement="isContextMenuMode ? Placement.RIGHT_TOP : Placement.BOTTOM_LEFT"
    :style="{ '--background': colors.borderVlt, '--highlight': colors.borderMd }"
    :class="hasTwoStatuses ? 'cursor-pointer' : ''"
    :on-after-close="handleAfterClose"
    @click="() => (hasTwoStatuses ? cycleToNextStatus() : undefined)">
    <DropdownMenuItemContent
      v-if="isContextMenuMode"
      :icon="StatusFieldIcon"
      :title="`Change ${property.title.toLowerCase()}`"
      is-submenu />
    <Tooltip
      v-else
      :disabled="isFormOrDefaultPropertyMode"
      :class="{ 'overflow-hidden': isFormOrDefaultPropertyMode }"
      :command-id="isDefault && !hasTwoStatuses ? CommandId.CHANGE_STATUS : undefined"
      :text="
        hasTwoStatuses
          ? `Change ${property.title.toLowerCase()} to ${nextStatus.title}`
          : isDefault
            ? undefined
            : `Change ${property.title.toLowerCase()}`
      "
      :block="isListMode || isTaskDetailMode || isFormOrDefaultPropertyMode"
      :height-block="isListMode || isFormOrDefaultPropertyMode">
      <div
        v-if="isTaskDetailMode"
        class="flex h-fit w-full items-center justify-start gap-1 rounded py-1 pl-1.5 pr-2 text-left hover:bg-lt">
        <div v-if="!isListMode && !selectedStatus" class="text-vlt">
          {{ `Set ${property.title.toLowerCase()}` }}
        </div>
        <template v-else>
          <StatusIcon
            :duid="selectedStatus?.duid ?? ''"
            :color-hex="selectedStatus === undefined ? colors.textVlt : undefined"
            class="icon-md" />
          <span :title="selectedStatus?.title" class="flex-1 select-none hyphens-auto break-words">
            {{ selectedStatus?.title }}
          </span>
          <!-- Quick actions-->
          <Tooltip
            v-if="!pageStore.isPublicView && !isTrashPage && showMarkComplete"
            text="Complete task"
            :placement="Placement.TOP"
            :distance="10">
            <Button
              :btn-style="ButtonStyle.SECONDARY"
              :size="ButtonSize.SMALL"
              is-contrast
              :icon="CheckIcon"
              :icon-size="IconSize.S"
              a11y-label="Complete task"
              class="!p-0.5 print:hidden"
              @click.stop="markFinished" />
          </Tooltip>
          <Tooltip
            v-if="!pageStore.isPublicView && !isTrashPage && !hasTwoStatuses && nextStatus.duid !== selectedStatusDuid"
            :text="`Next ${property.title.toLowerCase()} (${nextStatus.title})`"
            :distance="10"
            :placement="Placement.TOP"
            disable-click-hide>
            <Button
              :btn-style="ButtonStyle.SECONDARY"
              :size="ButtonSize.SMALL"
              is-contrast
              :icon="ChevronRightIcon"
              :icon-size="IconSize.S"
              a11y-label="Next status"
              class="!p-0.5 print:hidden"
              @click.stop="cycleToNextStatus" />
          </Tooltip>
        </template>
      </div>
      <Button
        v-else
        :btn-style="ButtonStyle.CHIP"
        :text="
          showText || (isPropertyNameShown && isListMode)
            ? selectedStatus
              ? selectedStatus.title
              : isFormOrDefaultPropertyMode
                ? 'Set status'
                : property.title
            : ''
        "
        :icon="
          isFormOrDefaultPropertyMode && !selectedStatus
            ? undefined
            : isDefault || selectedStatus
              ? StatusIcon
              : StatusFieldIcon
        "
        block
        :icon-args="{
          duid: selectedStatus?.duid ?? '',
          colorHex: selectedStatus === undefined && !showText ? colors.textVlt : undefined,
        }"
        :disable-hover="isListMode || isFormOrDefaultPropertyMode"
        :borderless="!isChipMode"
        :text-style="isFormOrDefaultPropertyMode && !selectedStatus ? 'text-vlt' : undefined"
        :class="{
          rounded: !isListMode,
          'gap-2': !isChipMode,
          'p-1': isGithubChipMode,
          'pr-3': isListMiniNonTcmMode,
          'pr-5': isListMiniTcmMode,
          'px-1 py-0.5': !isListMode && !isGithubChipMode && !isFormOrDefaultPropertyMode,
          '!justify-start px-[7px] pr-7': isFormOrDefaultPropertyMode,
          'max-w-xs truncate': isChipMode,
          '!justify-start truncate': isListMode && isPropertyNameShown,
        }"
        a11y-label="Status" />
    </Tooltip>
  </DropdownMenu>
</template>
