<script setup lang="ts">
import { backendOld } from "~/api";
import Button from "~/components/dumb/Button.vue";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import { TrashIcon } from "~/icons";
import { ButtonStyle, DialogMode } from "~/shared/enums";
import { useUserStore } from "~/stores";

const DELETE_USER_DIALOG_DESCRIPTION =
  "Permanently deleting your account can't be undone, but it is necessary to accept your invitation. Are you sure you want to proceed?";

const userStore = useUserStore();

const deleteAccount = () => {
  backendOld.profile.deleteAccount();
  userStore.forceLogout();
};
</script>

<template>
  <div class="mx-auto flex size-full flex-col items-center justify-start gap-10 px-10">
    <span class="text-sm/relaxed text-md">
      You have been invited to transfer your Dart account and join a different workspace. In order to accept, you first
      need to delete your current account. Once you do that you'll get an email with the invitation.
    </span>

    <ConfirmationDialog
      :mode="DialogMode.DELETE"
      title="Delete account and send invitation email"
      :description="DELETE_USER_DIALOG_DESCRIPTION"
      confirm-text="Delete"
      cancel-text="Keep"
      :icon="TrashIcon"
      @confirm="deleteAccount">
      <Button :btn-style="ButtonStyle.DANGER" text="Delete account and send invitation email" />
    </ConfirmationDialog>
  </div>
</template>
