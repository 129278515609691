<script setup lang="ts">
import { computed } from "vue";

import actions from "~/actions";
import DartboardChip from "~/components/dumb/DartboardChip.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { UNSET_DARTBOARD_LABEL, UNSET_DARTBOARD_PSUEDO_DUID } from "~/constants/dartboard";
import { colorsByTheme } from "~/constants/style";
import { DartboardFieldIcon } from "~/icons";
import { CommandId, DartboardKind, DropdownMenuItemKind, EditorMode, Placement } from "~/shared/enums";
import type { DropdownMenuSection, Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { getPageDisplayName } from "~/utils/common";

const DARTBOARD_COMMAND_MAP = new Map([
  [DartboardKind.ACTIVE, CommandId.MOVE_TO_ACTIVE],
  [DartboardKind.NEXT, CommandId.MOVE_TO_NEXT],
  [DartboardKind.BACKLOG, CommandId.MOVE_TO_BACKLOG],
]);

const props = defineProps<{
  tasks: Task[];
  editorMode: EditorMode;
  value?: string;
  hideUnset?: boolean;
}>();

const emit = defineEmits<{
  update: [newDartboardDuid: string];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const isChipMode = computed(
  () => props.editorMode === EditorMode.CHIP || props.editorMode === EditorMode.CHIP_RECOMMENDATION
);
const isListMode = computed(() => props.editorMode === EditorMode.LIST);
const isTaskDetailMode = computed(() => props.editorMode === EditorMode.DETAIL);
const isContextMenuMode = computed(() => props.editorMode === EditorMode.CONTEXT_MENU);
const isFormMode = computed(() => props.editorMode === EditorMode.FORM);

const workspaceSpace = computed(() => dataStore.workspaceSpace);

const selectedTasks = computed(() =>
  isChipMode.value ? props.tasks : dataStore.getTasksByDuidsOrdered([...appStore.selectedTaskDuids])
);

const selectedDartboardDuid = computed(() => {
  if (isFormMode.value) {
    return props.value ?? UNSET_DARTBOARD_PSUEDO_DUID;
  }

  return props.tasks.every((task) => task.dartboardDuid === props.tasks[0].dartboardDuid)
    ? props.tasks[0].dartboardDuid
    : undefined;
});

const dropdownSections = computed(() => {
  const dartboardDuidInSelectedTasks =
    (props.value ?? selectedTasks.value.every((task) => task.dartboardDuid === selectedTasks.value[0]?.dartboardDuid))
      ? selectedTasks.value[0]?.dartboardDuid
      : undefined;

  const res: DropdownMenuSection[] = [];
  if (isFormMode.value && !props.hideUnset) {
    res.push({
      title: UNSET_DARTBOARD_LABEL,
      items: [
        {
          title: UNSET_DARTBOARD_LABEL,
          kind: DropdownMenuItemKind.BUTTON,
          disabled: selectedDartboardDuid.value === UNSET_DARTBOARD_PSUEDO_DUID,
          icon: DartboardFieldIcon,
          iconArgs: { class: "icon-md text-vlt" },
          onClick: () => {
            emit("update", UNSET_DARTBOARD_PSUEDO_DUID);
          },
        },
      ],
    });
  }
  res.push(
    ...dataStore.spaceList.map((space) => ({
      title: space.title,
      showTitle: true,
      items: dataStore.getDartboardsBySpaceDuidOrdered(space.duid, { excludeFinished: true }).map((dartboard) => ({
        title: getPageDisplayName(dartboard, dataStore.getSpaceByDuid),
        kind: DropdownMenuItemKind.BUTTON,
        disabled: isFormMode.value
          ? dartboard.duid === selectedDartboardDuid.value
          : dartboard.duid === dartboardDuidInSelectedTasks,
        commandId: isFormMode.value
          ? undefined
          : dartboard.spaceDuid === workspaceSpace.value?.duid
            ? DARTBOARD_COMMAND_MAP.get(dartboard.kind)
            : undefined,
        icon: PageIcon,
        iconArgs: { page: dartboard },
        onClick: () => {
          emit("update", dartboard.duid);
          if (isFormMode.value) {
            return;
          }

          actions.visualization.moveTasksToDartboard(dartboard.duid);
        },
      })),
    }))
  );
  return res;
});
</script>

<template>
  <DropdownMenu
    :container="isChipMode ? '#dart-task-creation-modal-wrapper' : isFormMode ? '#dart-form-wrapper' : undefined"
    :sections="dropdownSections"
    :block="isListMode || isFormMode"
    :height-block="isListMode || isFormMode"
    :skidding="isFormMode ? -1 : undefined"
    :distance="isFormMode ? 2 : undefined"
    :show-on-hover="isContextMenuMode"
    :cover="!isContextMenuMode && !isTaskDetailMode && !isFormMode"
    :placement="isContextMenuMode ? Placement.RIGHT_TOP : Placement.BOTTOM_LEFT"
    :style="{ '--background': colors.borderVlt, '--highlight': colors.borderMd }">
    <DropdownMenuItemContent v-if="isContextMenuMode" :icon="DartboardFieldIcon" title="Change dartboard" is-submenu />

    <Tooltip
      v-else
      class="relative w-full"
      :disabled="isFormMode"
      :command-id="CommandId.CHANGE_DARTBOARD"
      :block="isListMode || isFormMode"
      :height-block="isListMode || isFormMode">
      <div
        v-if="selectedDartboardDuid === UNSET_DARTBOARD_PSUEDO_DUID"
        class="flex h-full items-center text-sm text-vlt"
        :class="isFormMode ? 'ml-2' : 'ml-1.5'">
        Set dartboard
      </div>
      <template v-else>
        <DartboardChip :selected-dartboard-duid="selectedDartboardDuid" :editor-mode="editorMode" />
      </template>
    </Tooltip>
  </DropdownMenu>
</template>
