<script setup lang="ts">
import { useResizeObserver } from "@vueuse/core";
import equal from "deep-equal";
import { Dropdown, Menu } from "floating-vue";
import moment from "moment";
import { computed, getCurrentInstance, nextTick, onMounted, onUnmounted, ref, watch } from "vue";

import { getPropertyPartialTask, getPropertyValueFromTask } from "~/common/properties";
import Avatar from "~/components/dumb/Avatar.vue";
import Button from "~/components/dumb/Button.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import UserTimeTracking from "~/components/timeTracking/UserTimeTracking.vue";
import { colorsByTheme } from "~/constants/style";
import { FORM_USER_PSEUDO_USER } from "~/constants/user";
import { PlayIcon, PlusIcon, StopIcon, TimeTrackingFieldIcon } from "~/icons";
import { ButtonStyle, CommandId, DropdownMenuItemKind, EditorMode, Placement } from "~/shared/enums";
import type {
  PropertyAnyTimeTracking,
  PropertyValueForKind,
  Task,
  TimeTracking,
  TimeTrackingEntry,
  User,
} from "~/shared/types";
import { useDataStore, usePageStore, useUserStore } from "~/stores";
import { timeTrackingEntryComparator } from "~/utils/comparator";
import { getDurationText, getTimeTrackingDurationText } from "~/utils/time";

type Value = PropertyValueForKind<PropertyAnyTimeTracking>;

const props = defineProps<{
  property: PropertyAnyTimeTracking;
  tasks: Task[];
  editorMode: EditorMode;
  value?: Value;
}>();

const emit = defineEmits<{
  update: [value?: Value];
}>();

const currentInstance = getCurrentInstance();
const dataStore = useDataStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const isChipMode = computed(
  () => props.editorMode === EditorMode.CHIP || props.editorMode === EditorMode.CHIP_RECOMMENDATION
);
const isBoardMode = computed(() => props.editorMode === EditorMode.BOARD);
const isContextMenuMode = computed(() => props.editorMode === EditorMode.CONTEXT_MENU);
const isFormMode = computed(() => props.editorMode === EditorMode.FORM);
const isListMode = computed(() => props.editorMode === EditorMode.LIST);
const isTaskDetailMode = computed(() => props.editorMode === EditorMode.DETAIL);

// TODO this only works for one value
const timeTracking = computed(() => {
  if (isFormMode.value) {
    return props.value ?? [];
  }
  return getPropertyValueFromTask(props.property, props.tasks[0]);
});

const hasTimeTracking = computed(() => timeTracking.value.length > 0);

const defaultDistance = computed(() => (isFormMode.value ? 28 : 0));
const calcDistance = ref(defaultDistance.value);
const dropdownRef = ref<InstanceType<typeof Dropdown> | null>(null);
const buttonRef = ref<HTMLButtonElement | null>(null);
const recalculateDistance = () => {
  calcDistance.value = defaultDistance.value;
  if (!buttonRef.value) {
    return;
  }
  calcDistance.value -= buttonRef.value.getBoundingClientRect().height + 1;
};
const assignButtonRef = (elem: HTMLButtonElement | null) => {
  buttonRef.value = elem;
  recalculateDistance();
};
useResizeObserver(buttonRef, recalculateDistance);

// Time elapsed for all time tracking entries
const totalTimeElapsed = computed(() => getTimeTrackingDurationText(timeTracking.value));

const now = ref(new Date());

const focusDiv = ref<HTMLDivElement | null>(null);
const onShow = () => {
  nextTick(() => {
    focusDiv.value?.focus();
  });
};

const currentUserEntry = computed(() =>
  timeTracking.value.find((entry) => entry.userDuid === userStore.duid && !entry.finishedAt)
);
const timeElapsedText = computed(() =>
  currentUserEntry.value
    ? getDurationText(moment.duration(moment(now.value).diff(moment(currentUserEntry.value.startedAt))))
    : totalTimeElapsed.value
);

const sortEntries = (tt: TimeTracking) => tt.sort(timeTrackingEntryComparator);

const stopTimeTracking = () => {
  if (!currentUserEntry.value) {
    return;
  }

  currentUserEntry.value.finishedAt = new Date().toISOString();

  if (isFormMode.value) {
    const updatedTimeTracking = sortEntries(
      timeTracking.value.map((entry) => {
        if (!currentUserEntry.value || entry !== currentUserEntry.value) {
          return entry;
        }
        return currentUserEntry.value;
      })
    );
    emit("update", updatedTimeTracking);
    return;
  }

  dataStore.updateTasks(
    props.tasks.map((task) => ({
      duid: task.duid,
      ...getPropertyPartialTask(props.property, task, sortEntries(timeTracking.value)),
    }))
  );
};

const startOrStopTimeTracking = () => {
  if (currentUserEntry.value) {
    stopTimeTracking();
    return;
  }

  const updatedEntries = sortEntries([
    ...timeTracking.value,
    {
      userDuid: userStore.duid,
      startedAt: new Date().toISOString(),
      finishedAt: null,
    },
  ]);

  if (isFormMode.value) {
    emit("update", updatedEntries);
    return;
  }

  dataStore.updateTasks(
    props.tasks.map((task) => ({ duid: task.duid, ...getPropertyPartialTask(props.property, task, updatedEntries) }))
  );
};

const usersAndTimeTracking = computed(() => {
  const userDuidToTimeTracking = new Map<string, TimeTracking>();
  timeTracking.value.forEach((entry: TimeTrackingEntry) => {
    const userEntries: TimeTracking = userDuidToTimeTracking.get(entry.userDuid) ?? [];
    userEntries.push(entry);
    userDuidToTimeTracking.set(entry.userDuid, userEntries);
  });

  const res: [User, TimeTracking][] = [];
  const currentUser = dataStore.getUserByDuid(userStore.duid);
  const currentUserTimeTracking = userDuidToTimeTracking.get(userStore.duid);
  if (currentUser && currentUserTimeTracking) {
    res.push([currentUser, currentUserTimeTracking]);
  }

  [...dataStore.getUserList(), FORM_USER_PSEUDO_USER].forEach((user) => {
    if (user.duid === userStore.duid) {
      return;
    }
    const tracking = userDuidToTimeTracking.get(user.duid);
    if (!tracking) {
      return;
    }
    res.push([user, tracking]);
  });
  return res;
});

let interval: ReturnType<typeof setInterval> | undefined;

const startTimer = () => {
  if (!currentUserEntry.value) {
    return;
  }

  if (interval) {
    clearInterval(interval);
  }

  now.value = new Date();
  const elapsedMs = moment().diff(currentUserEntry.value.startedAt);
  const timeoutMs = Math.ceil(elapsedMs / 1000) * 1000 - elapsedMs;
  setTimeout(() => {
    now.value = new Date();
    interval = setInterval(() => {
      now.value = new Date();
    }, 1000);
  }, timeoutMs);
};

const removeEntry = (entry: TimeTrackingEntry) => {
  if (isFormMode.value) {
    emit(
      "update",
      timeTracking.value.filter((e) => !equal(e, entry))
    );
    return;
  }

  dataStore.updateTasks(
    props.tasks.map((task) => ({
      duid: task.duid,
      ...getPropertyPartialTask(
        props.property,
        task,
        timeTracking.value.filter((e) => !equal(e, entry))
      ),
    }))
  );
};

const addEntryForUser = (userDuid: string) => {
  const updatedEntries = sortEntries([
    ...timeTracking.value,
    {
      userDuid,
      startedAt: moment().subtract(1, "hours").toISOString(),
      finishedAt: moment().toISOString(),
    },
  ]);

  if (isFormMode.value) {
    emit("update", updatedEntries);
    return;
  }

  dataStore.updateTasks(
    props.tasks.map((task) => ({ duid: task.duid, ...getPropertyPartialTask(props.property, task, updatedEntries) }))
  );
};

const updateEntry = (current: TimeTrackingEntry, updated: TimeTrackingEntry) => {
  const updatedEntries = sortEntries(timeTracking.value.map((entry) => (equal(entry, current) ? updated : entry)));

  if (isFormMode.value) {
    emit("update", updatedEntries);
    return;
  }

  dataStore.updateTasks(
    props.tasks.map((task) => ({ duid: task.duid, ...getPropertyPartialTask(props.property, task, updatedEntries) }))
  );
};

const addEntryDropdownSections = computed(() => [
  {
    title: "Add entry",
    items: dataStore.getUserList().map((user) => ({
      title: user.name.trim() === "" ? user.email : user.name,
      kind: DropdownMenuItemKind.BUTTON,
      icon: Avatar,
      iconArgs: {
        abrev: user.abrev,
        imageUrl: user.imageUrl,
        colorHex: user.colorHex,
        circle: true,
        borderless: true,
        class: "size-5 mr-0.5",
      },
      onClick: () => addEntryForUser(user.duid),
    })),
  },
]);

watch(
  () => currentUserEntry.value,
  (newValue) => {
    if (!newValue) {
      return;
    }
    startTimer();
  }
);

onMounted(() => {
  if (!currentInstance) {
    return;
  }
  const instanceElem = currentInstance.subTree.el;
  if (!instanceElem?.children) {
    return;
  }

  assignButtonRef(instanceElem.children[0]);
});

onMounted(() => {
  if (!currentUserEntry.value) {
    return;
  }
  startTimer();
});

onUnmounted(() => {
  clearInterval(interval);
});
</script>

<template>
  <Dropdown
    v-if="!isBoardMode || hasTimeTracking"
    ref="dropdownRef"
    :container="isChipMode ? '#dart-task-creation-modal-wrapper' : isFormMode ? '#dart-form-wrapper' : undefined"
    :disabled="isBoardMode"
    :triggers="isContextMenuMode ? ['hover', 'focus'] : undefined"
    :popper-triggers="isContextMenuMode ? ['hover', 'focus'] : undefined"
    :placement="isContextMenuMode ? Placement.RIGHT_TOP : Placement.BOTTOM_LEFT"
    :distance="calcDistance"
    :skidding="isFormMode ? -1 : undefined"
    no-auto-focus
    dont-propagate-close
    :theme="`dropdown-${pageStore.theme}`"
    :style="{ '--background': colors.borderVlt, '--highlight': colors.borderMd }"
    class="flex cursor-pointer"
    :class="{ 'w-full max-w-full': isListMode || isFormMode }"
    @apply-show="onShow">
    <template #default>
      <DropdownMenuItemContent
        v-if="isContextMenuMode"
        :icon="TimeTrackingFieldIcon"
        :title="`${currentUserEntry ? 'Stop' : 'Start'} timer`"
        is-submenu />

      <Tooltip
        v-else
        :disabled="isFormMode"
        :command-id="!isBoardMode ? CommandId.START_TIMER : undefined"
        :text="isBoardMode ? property.title : undefined"
        :block="isListMode || isFormMode"
        :height-block="isListMode || isFormMode"
        :class="isTaskDetailMode && 'w-full'">
        <div
          ref="feedbackTooltipNode"
          class="group/time-tracking flex size-full max-w-full cursor-pointer items-center text-sm"
          :class="{
            'px-1.5': isListMode,
            'justify-start rounded hover:bg-lt': isTaskDetailMode,
            'justify-end': isListMode,
          }">
          <div
            class="flex select-none items-center justify-center rounded text-md"
            :class="{
              'border border-oncolor': isChipMode || isBoardMode,
              'h-[26px] gap-1 py-0.5 text-sm hover:bg-opposite/10': isChipMode,
              'h-4': isBoardMode,
              'gap-2': !isChipMode,
              'h-5 text-xs': !isChipMode && !isTaskDetailMode && !isFormMode,
              'px-2 text-sm hover:bg-lt': isFormMode || isTaskDetailMode,
              'px-1': !isFormMode && !isTaskDetailMode,
            }">
            <TimeTrackingFieldIcon v-if="isChipMode && !hasTimeTracking" class="icon-sm" />
            <Button
              v-else-if="isTaskDetailMode"
              :btn-style="ButtonStyle.SECONDARY"
              is-contrast
              :icon="currentUserEntry ? StopIcon : PlayIcon"
              :icon-args="{ class: 'text-primary-base' }"
              borderless
              :a11y-label="`${currentUserEntry ? 'Stop' : 'Start'} timer`"
              class="-mx-1 cursor-pointer"
              @click.stop="startOrStopTimeTracking" />
            <span
              :class="[
                ((isTaskDetailMode && !hasTimeTracking) || (isFormMode && !hasTimeTracking)) && 'text-vlt',
                hasTimeTracking && 'tabular-nums',
              ]">
              {{
                hasTimeTracking
                  ? timeElapsedText
                  : isChipMode
                    ? "Timer"
                    : isFormMode
                      ? `${currentUserEntry ? "Stop" : "Start"} timer`
                      : isListMode
                        ? ""
                        : "None"
              }}
            </span>
          </div>
          <div
            v-if="isListMode && !hasTimeTracking"
            class="flex size-full items-center justify-center text-transparent group-hover/time-tracking:text-vlt">
            <TimeTrackingFieldIcon class="icon-xs" />
          </div>
        </div>
      </Tooltip>
    </template>

    <template #popper>
      <Menu no-auto-focus>
        <div
          class="flex w-96 max-w-96 flex-col divide-y divide-gray-200 overflow-hidden rounded border app-drag-none bg-lt border-md focus-ring-none dark:divide-zinc-700"
          @click.stop
          @keydown.stop>
          <div ref="focusDiv" tabindex="0" class="focus:outline-none" />
          <div class="flex items-center justify-between px-2 py-1">
            <div class="flex items-center gap-2">
              <Button
                :btn-style="ButtonStyle.SECONDARY"
                :a11y-label="`${currentUserEntry ? 'Stop' : 'Start'} timer`"
                is-contrast
                :icon="currentUserEntry ? StopIcon : PlayIcon"
                :icon-args="{ class: 'text-primary-base' }"
                borderless
                @click="startOrStopTimeTracking" />

              <div class="select-none tabular-nums text-lt">{{ currentUserEntry ? timeElapsedText : "0:00:00" }}</div>
            </div>

            <div class="select-none pr-1 text-sm tabular-nums text-lt">{{ totalTimeElapsed }}</div>
          </div>

          <div v-if="usersAndTimeTracking.length" class="flex w-full flex-col divide-y divide-md">
            <UserTimeTracking
              v-for="[user, tracking] in usersAndTimeTracking"
              :key="user.duid"
              :user="user"
              :time-tracking="tracking"
              @update="(current, updated) => updateEntry(current, updated)"
              @delete="removeEntry" />
          </div>

          <DropdownMenu :sections="addEntryDropdownSections" :placement="Placement.RIGHT_TOP" class="py-1">
            <DropdownMenuItemContent :icon="PlusIcon" title="Add entry" is-submenu class="text-lt hover:bg-md" />
          </DropdownMenu>
        </div>
      </Menu>
    </template>
  </Dropdown>
</template>

<style>
.v-popper--theme-dropdown .v-popper__arrow-container {
  display: none;
}
.v-popper--theme-dropdown .v-popper__wrapper {
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.v-popper--theme-dropdown .v-popper__inner {
  border-width: 0px;
  border-radius: 4px;
  background-color: transparent;
}
</style>
