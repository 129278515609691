<script setup lang="ts">
import { useMediaQuery } from "@vueuse/core";
import type {
  CellMouseOverEvent,
  ColDef,
  IRowNode,
  IsFullWidthRowParams,
  IsGroupOpenByDefaultParams,
  RowGroupOpenedEvent,
  RowHeightParams,
} from "ag-grid-community";
import { computed, ref } from "vue";

import { isFillerRow, isMarginRow, MARGIN_ROW_ID } from "~/common/groupBy";
import { getShownPropertyWithConfigList } from "~/common/properties";
import BaseList from "~/components/visualization/list/BaseList.vue";
import DartboardCellEditor from "~/components/visualization/list/cellEditors/DartboardCellEditor.vue";
import DuidCellEditor from "~/components/visualization/list/cellEditors/DuidCellEditor.vue";
import OrderCellEditor from "~/components/visualization/list/cellEditors/OrderCellEditor.vue";
import TitleCellEditor from "~/components/visualization/list/cellEditors/TitleCellEditor.vue";
import ConfigHeaderRenderer from "~/components/visualization/list/headerRenderers/ConfigHeaderRenderer.vue";
import StandardHeaderRenderer from "~/components/visualization/list/headerRenderers/StandardHeaderRenderer.vue";
import { IGNORED_DIV_CLASSES_FOR_DESELECTION } from "~/components/visualization/list/utils";
import {
  CONFIG_COLUMN_DEF,
  CONFIG_PROPERTY_PSUEDO_DUID,
  DUID_COLUMN_DEF,
  DUID_PROPERTY_PSUEDO_DUID,
  ORDER_COLUMN_DEF,
  ORDER_PROPERTY_PSUEDO_DUID,
} from "~/constants/property";
import { CheckedIcon } from "~/icons";
import { EditorMode, PageKind, PropertyKind, ViewKind } from "~/shared/enums";
import { type RowItem, type Task, type TaskWithGroup } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { hasClassInPath } from "~/utils/common";
import { makePropertyComparator, sign } from "~/utils/comparator";

import { listValueGetter } from "../list/common";
import { TASK_ROW_HEIGHT } from "./constants";
import RoadmapRootCellRenderer from "./RoadmapRootCellRenderer.vue";
import type { RoadmapConfig } from "./shared";

const NEW_TASK_ID = "new-task";
const FINAL_COLUMN_ORDER_OVERRIDES = new Map(
  [PropertyKind.DEFAULT_ASSIGNEES, PropertyKind.DEFAULT_STATUS].map((e, i) => [e, i])
);

const props = defineProps<{
  roadmapConfig: RoadmapConfig;
  tasks: Task[];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const isPrinting = useMediaQuery("print");

const list = ref<InstanceType<typeof BaseList> | null>(null);
const gridApi = computed(() => list.value?.api ?? null);
const editorMode = EditorMode.LIST_MINI_ROADMAP;

const isGroupOpenByDefault = (params: IsGroupOpenByDefaultParams<RowItem>) =>
  !!dataStore.getTaskByDuid(params.key)?.expanded || params.rowNode.data?.isRoot;

const taskDuids = computed(() => new Set(appStore.filteredAndSortedTasksInPage.map((e) => e.duid)));
const getDataPath = (rowItem: RowItem) => {
  if (rowItem.isRoot) {
    return [rowItem.id];
  }

  const task = rowItem as TaskWithGroup;

  let ancestorDuids = dataStore.getAncestorDuids(task);
  if (!appStore.showAbsentees) {
    const firstAbsent = ancestorDuids.findLastIndex((e) => !taskDuids.value.has(e));
    if (firstAbsent !== -1) {
      ancestorDuids = ancestorDuids.slice(firstAbsent + 1);
    }
  }
  return [...ancestorDuids, task.duid];
};

const tasksNorm = computed<RowItem[]>(() => {
  const groupRes: RowItem[] = [
    {
      id: `${NEW_TASK_ID}/new-task`,
      value: NEW_TASK_ID,
      title: "",
      colorHex: "",
      icon: CheckedIcon,
      isRoot: true,
      relationships: [],
    },
  ];
  const taskRes: RowItem[] = props.tasks.map((e) => Object.assign(e, { id: e.duid, isRoot: false, group: "" }));
  return [
    ...taskRes,
    ...groupRes,
    {
      id: MARGIN_ROW_ID,
      isRoot: false,
      relationships: [],
      group: "",
    },
  ];
});

const defaultColDef = computed(() => ({
  headerComponent: StandardHeaderRenderer,
  cellStyle: {
    fontSize: "14px",
    paddingLeft: "0px",
    paddingRight: "0px",
    textOverflow: "clip",
  },
  lockPinned: true,
  resizable: !pageStore.isMobile && !pageStore.isPublicView,
  valueGetter: listValueGetter,
}));

const titleColDef = computed<ColDef<Task>>(() => {
  const property = dataStore.defaultTitleProperty;
  return {
    field: property.duid as keyof Task,
    headerName: "Title",
    headerComponentParams: {
      property,
    },
    cellRenderer: TitleCellEditor,
    cellRendererParams: {
      editorMode,
    },
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    lockPosition: "left",
    rowDrag: pageStore.hasTouch,
    suppressKeyboardEvent: () => true,
    ...appStore.getColumnWidthParams(property.duid),
  };
});

const isAnyView = computed(() => appStore.currentPage?.pageKind === PageKind.VIEW);
const isPublicViewOrTrash = computed(() => pageStore.isPublicView || appStore.currentPage?.kind === ViewKind.TRASH);

const properties = computed(() =>
  getShownPropertyWithConfigList()
    .map(([property, config]) => ({
      property,
      config,
      values: config.listColumn(property),
    }))
    .sort(
      (a, b) =>
        sign(FINAL_COLUMN_ORDER_OVERRIDES.get(a.property.kind) ?? -1) -
        (FINAL_COLUMN_ORDER_OVERRIDES.get(b.property.kind) ?? -1)
    )
);

const columnDefs = computed<ColDef<Task>[]>(() => {
  const res: ColDef<Task>[] = [
    {
      ...DUID_COLUMN_DEF,
      cellRenderer: DuidCellEditor,
      hide: pageStore.isMobile || !pageStore.showDebugInfo,
      ...appStore.getColumnWidthParams(DUID_PROPERTY_PSUEDO_DUID),
    },
    {
      ...ORDER_COLUMN_DEF,
      hide: pageStore.isMobile || !pageStore.showDebugInfo,
      cellRenderer: OrderCellEditor,
      ...appStore.getColumnWidthParams(ORDER_PROPERTY_PSUEDO_DUID),
    },
    {
      field: dataStore.defaultDartboardProperty.duid as keyof Task,
      headerName: "Dartboard",
      headerComponentParams: {
        property: dataStore.defaultDartboardProperty,
        editorMode: EditorMode.LIST_MINI_ROADMAP,
      },
      hide: pageStore.isMobile || !isAnyView.value || pageStore.isPublicView,
      cellRenderer: DartboardCellEditor,
      sortable: true,
      suppressHeaderMenuButton: true,
      ...appStore.getColumnWidthParams(dataStore.defaultDartboardProperty.duid),
    },
    ...properties.value.map(({ property, config, values }) => {
      // eslint-disable-next-line no-param-reassign
      delete values.getSearchValue;
      // eslint-disable-next-line no-param-reassign
      delete values.comparatorFn;

      const isDefaultStatus = property.kind === PropertyKind.DEFAULT_STATUS;
      return {
        ...values,
        field: property.duid as keyof Task,
        headerName: property.title,
        suppressMovable: isDefaultStatus,
        lockPosition: config.lockPosition,
        headerComponentParams: {
          property,
          editorMode: EditorMode.LIST_MINI_ROADMAP,
        },
        cellRendererParams: {
          property,
          ...values.cellRendererParams,
        },
        ...appStore.getColumnWidthParams(property.duid),
      };
    }),
  ];

  const comparator = makePropertyComparator(appStore.propertyOrderDuids);
  res.sort((a, b) => comparator(a.field, b.field));
  if (!isPrinting.value && !pageStore.isMobile && !isPublicViewOrTrash.value) {
    res.push({
      ...CONFIG_COLUMN_DEF,
      headerComponent: ConfigHeaderRenderer,
      ...appStore.getColumnWidthParams(CONFIG_PROPERTY_PSUEDO_DUID),
    });
  }
  return res;
});

const getRowId = (params: IRowNode<RowItem>) => params.data?.id;

const isFullWidthRow = (params: IsFullWidthRowParams<RowItem>) =>
  !!params.rowNode.data?.isRoot || !!(params.rowNode.id && isFillerRow(params.rowNode.id));

const getRowHeight = (params: RowHeightParams<RowItem>) =>
  params.data !== undefined && isMarginRow(params.data.id) ? 300 : TASK_ROW_HEIGHT;

const onGridReady = () => {
  if (pageStore.isPublicView) {
    return;
  }

  pageStore.pageLoaded = true;
};

const onRowGroupOpened = (event: RowGroupOpenedEvent<RowItem>) => {
  if (!event.data || pageStore.isPublicView || !("duid" in event.data)) {
    return;
  }

  const task = dataStore.getTaskByDuid(event.data.duid);
  const newExpanded = event.expanded;
  if (!task || task.expanded === newExpanded) {
    return;
  }

  dataStore.updateTasks([{ duid: task.duid, expanded: newExpanded }]);
};

const onCellMouseOver = (event: CellMouseOverEvent<Task>) => {
  const target = event.event?.target as Element;
  const rowElement = target?.closest(".ag-row");
  const scrollableParent = rowElement?.closest(".ag-center-cols-viewport");
  const task = dataStore.getTaskByDuid(event.data?.duid ?? "");
  if (!rowElement || !task || !scrollableParent) {
    return;
  }
  appStore.openTaskPreviewTooltip(rowElement, scrollableParent, task);
};

const onClick = (event: Event) => {
  if (list.value?.justDragSelected || hasClassInPath(event, IGNORED_DIV_CLASSES_FOR_DESELECTION)) {
    return;
  }

  appStore.getActiveVisualization().deselectAll();
};

defineExpose({
  api: gridApi,
});
</script>

<template>
  <div v-scroll-sync:roadmap.y.list class="h-full border-r border-md" @click="onClick" @keydown.enter="onClick">
    <BaseList
      ref="list"
      :editor-mode="editorMode"
      :default-col-def="defaultColDef"
      :columns="columnDefs"
      :tasks="tasksNorm"
      :get-row-id="getRowId"
      indent-drag
      tree-data
      :get-data-path="getDataPath"
      :is-full-width-row="isFullWidthRow"
      :is-group-open-by-default="isGroupOpenByDefault"
      :full-width-cell-renderer="RoadmapRootCellRenderer"
      :auto-group-column-def="titleColDef"
      :header-height="37"
      :get-row-height="getRowHeight"
      class="h-full"
      @grid-ready="onGridReady"
      @row-group-opened="onRowGroupOpened"
      @cell-mouse-over="onCellMouseOver" />
  </div>
</template>
