<script setup lang="ts">
import { computed, ref } from "vue";

import Animated from "~/components/dumb/Animated.vue";
import Avatar from "~/components/dumb/Avatar.vue";
import Button from "~/components/dumb/Button.vue";
import Input from "~/components/dumb/Input.vue";
import RadioCardGroup from "~/components/dumb/RadioCardGroup.vue";
import Toggle from "~/components/dumb/Toggle.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { copyAndNotify } from "~/components/notifications";
import { colorsByTheme } from "~/constants/style";
import { CopyIcon, OpenExternalLinkIcon, PublicIcon, UsersInviteIcon, WorkspaceSettingsIcon } from "~/icons";
import { ButtonSize, ButtonStyle, IconSize, PageKind, ViewKind } from "~/shared/enums";
import type { PageWithPermissions } from "~/shared/types";
import { useDataStore, usePageStore, useTenantStore, useUserStore } from "~/stores";
import { getPublicViewLink } from "~/utils/common";

const TEAMMATES_VALUE = "teammates";
const WORKSPACE_VALUE = "workspace";
const PUBLIC_VALUE = "public";

const props = defineProps<{
  page: PageWithPermissions;
  embedded?: boolean;
  disabled?: boolean;
  fullHeight?: boolean;
}>();

const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();
const userStore = useUserStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const searchInput = ref<InstanceType<typeof Input> | null>(null);

const publicOptionAvailable = computed(
  () => props.page.pageKind === PageKind.VIEW && props.page.kind === ViewKind.CUSTOM
);

const userHasDirectAccess = (userDuid: string) => props.page.accessibleByUserDuids.includes(userDuid);

const userOptions = computed(() =>
  [...dataStore.getUserList()]
    .sort((a, b) => {
      if (userStore.duid === a.duid) {
        return -1;
      }
      if (userStore.duid === b.duid) {
        return 1;
      }
      return (userHasDirectAccess(a.duid) ? 0 : 1) - (userHasDirectAccess(b.duid) ? 0 : 1);
    })
    .map((e) => ({
      ...e,
      hasAccess: e.duid === userStore.duid || userHasDirectAccess(e.duid),
      disabled: e.duid === userStore.duid,
    }))
);

const filteredUserOptions = computed(() => {
  if (searchInput.value?.value.trim() === "") {
    return userOptions.value;
  }

  return userOptions.value.filter((user) => {
    const search = searchInput.value?.value.trim()?.toLowerCase();
    return user.email.toLowerCase().includes(search ?? "") || user.name.toLowerCase().includes(search ?? "");
  });
});

const updateAccess = (access: string) => {
  const publicHasAccess = access === PUBLIC_VALUE;
  const teamHasAccess = access === WORKSPACE_VALUE;
  if (publicOptionAvailable.value) {
    dataStore.updateView(
      publicHasAccess
        ? {
            duid: props.page.duid,
            accessibleByTeam: true,
            accessibleByUserDuids: [],
            public: true,
          }
        : {
            duid: props.page.duid,
            accessibleByTeam: teamHasAccess,
            accessibleByUserDuids: teamHasAccess ? [] : [userStore.duid],
            public: false,
          }
    );
    return;
  }

  const updateFn = props.page.pageKind === PageKind.DASHBOARD ? dataStore.updateDashboard : dataStore.updateSpace;
  updateFn({
    duid: props.page.duid,
    accessibleByTeam: teamHasAccess,
    accessibleByUserDuids: teamHasAccess ? [] : [userStore.duid],
  });
};

const updateAccessors = (userDuid: string, hasAccess: boolean) =>
  dataStore.updatePageAccessors(props.page, [userDuid], hasAccess);

const isPublic = computed(() => props.page.pageKind === PageKind.VIEW && props.page.public);
const publicViewLink = computed(() => props.page.pageKind === PageKind.VIEW && getPublicViewLink(props.page));
const permissionOptions = computed(() => [
  {
    title: "Specific teammates",
    description: "Select the teammates that can access this page",
    value: TEAMMATES_VALUE,
    selected: !props.page.accessibleByTeam && !isPublic.value,
    icon: UsersInviteIcon,
    dataTestid: "specific-teammates",
  },
  {
    title: "Entire workspace",
    description: `${props.page.pageKind === PageKind.SPACE ? "All teammates except guests" : `Everyone at ${tenantStore.name}`} can access this page`,
    value: WORKSPACE_VALUE,
    selected: props.page.accessibleByTeam && !isPublic.value,
    icon: WorkspaceSettingsIcon,
    isDefault: true,
  },
  ...(publicOptionAvailable.value
    ? [
        {
          title: "Public",
          description: "Anyone with the link can access this page",
          value: PUBLIC_VALUE,
          selected: isPublic.value,
          icon: PublicIcon,
        },
      ]
    : []),
]);

const viewPublicView = () => {
  if (!publicViewLink.value) {
    return;
  }

  window.open(publicViewLink.value, "_blank", "noopener,noreferrer");
};

const copyPublicViewLink = () => {
  if (!publicViewLink.value) {
    return;
  }

  copyAndNotify("Public link", publicViewLink.value, props.page.title);
};
</script>

<template>
  <div
    class="flex w-full flex-col gap-8"
    :class="disabled && 'pointer-events-none select-none opacity-50'"
    :style="{ '--background': colors.bgStd, '--highlight': colors.highlight }">
    <div class="flex flex-col gap-2">
      <span v-if="embedded" class="select-none font-medium text-md">Sharing</span>
      <RadioCardGroup :items="permissionOptions" @select="updateAccess" />
    </div>

    <!-- Public view -->
    <div
      class="flex w-full overflow-y-auto"
      :class="[
        fullHeight ? 'h-full' : 'sm:max-h-[240px] sm:min-h-[240px]',
        !embedded && 'max-h-[calc(min(864px,100vh-288px))]',
      ]">
      <div v-if="isPublic" class="flex w-full flex-col gap-6">
        <div class="flex w-full flex-col gap-2">
          <div class="flex items-center gap-3">
            <div class="select-none font-medium text-md">Public link</div>
            <div
              class="relative flex select-none items-center gap-1 rounded-full border border-success-base px-1.5 py-0.5 text-xs font-medium text-success-base">
              <div class="size-3 animate-pulse rounded-full bg-success-base/50" />
              <div class="absolute inset-y-[7px] left-[9px] w-1.5 rounded-full bg-success-base" />
              Live
            </div>
          </div>

          <div class="flex items-center gap-2">
            <span
              title="Public link"
              class="grow select-none truncate rounded border px-2 py-1 text-sm text-md border-md">
              {{ publicViewLink }}
            </span>
            <Tooltip text="Copy link">
              <Button
                :size="ButtonSize.SMALL"
                :btn-style="ButtonStyle.SECONDARY"
                :icon-size="IconSize.S"
                :icon="CopyIcon"
                a11y-label="Copy link"
                class="size-7"
                @click="copyPublicViewLink" />
            </Tooltip>
            <Tooltip text="View public site">
              <Button
                :size="ButtonSize.SMALL"
                :btn-style="ButtonStyle.SECONDARY"
                :icon-size="IconSize.S"
                :icon="OpenExternalLinkIcon"
                a11y-label="View public site"
                class="size-7"
                @click="viewPublicView" />
            </Tooltip>
          </div>
        </div>
      </div>

      <!-- Specific teammates -->
      <div v-else-if="!page.accessibleByTeam" class="flex w-full flex-col gap-3">
        <Input ref="searchInput" label="Search teammates" hide-label placeholder="Search teammates" />

        <Animated element="table" class="flex flex-col gap-1.5 overflow-y-scroll pl-2 pr-3">
          <tr
            v-for="userOption in filteredUserOptions"
            :key="userOption.email"
            class="flex min-h-[38px] w-full items-center justify-between">
            <td class="flex items-center overflow-hidden">
              <Avatar
                :abrev="userOption.abrev"
                circle
                :color-hex="userOption.colorHex"
                :image-url="userOption.imageUrl"
                img-border
                class="mr-2 text-sm icon-lg" />

              <div class="flex flex-col justify-center overflow-hidden pr-2">
                <span
                  :title="userOption.name ? userOption.name : userOption.email"
                  class="flex-1 select-none truncate text-sm text-md">
                  {{ userOption.name ? userOption.name : userOption.email }}
                </span>
                <span
                  v-if="userOption.name"
                  :title="userOption.email"
                  class="flex-1 select-text truncate text-xs text-lt">
                  {{ userOption.email }}
                </span>
              </div>
            </td>
            <td class="flex items-center">
              <Toggle
                :value="userOption.hasAccess"
                :disabled="userOption.disabled"
                label="Access"
                hide-label
                :size="ButtonSize.SMALL"
                @update="(hasAccess) => updateAccessors(userOption.duid, hasAccess)" />
            </td>
          </tr>
        </Animated>
      </div>
    </div>
  </div>
</template>
