<script setup lang="ts">
import { type Component, computed, getCurrentInstance, nextTick, onMounted, onUnmounted, ref } from "vue";

import { backendOld } from "~/api";
import { getFilterDefinitionList, TRASH_VIEW_KEY } from "~/common/filter";
import { getPropertyWithConfigList } from "~/common/properties";
import Animated from "~/components/dumb/Animated.vue";
import Button from "~/components/dumb/Button.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import AddFilter from "~/components/filters/AddFilter.vue";
import Filter from "~/components/filters/Filter.vue";
import SortDropdown from "~/components/filters/SortDropdown.vue";
import { normalizeEmoji } from "~/constants/iconAndEmoji";
import { DEFAULT_CHIP_COLOR } from "~/constants/style";
import { DartboardIcon, SaveViewIcon, XIcon } from "~/icons";
import {
  ButtonSize,
  ButtonStyle,
  FilterApplicability,
  FilterConnector,
  FilterType,
  IconKind,
  IconSize,
  PageKind,
  PropertyKind,
  ViewKind,
} from "~/shared/enums";
import type { Filter as IFilter, FilterDefinition, Layout } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { deepCopy, makeDuid, makeUuid } from "~/utils/common";
import { getOrdersBetween } from "~/utils/orderManager";

const DEFAULT_DARTBOARD_FILTER_PARTIAL = {
  id: "dartboard",
  applicability: FilterApplicability.IS,
  connector: FilterConnector.OR,
  values: [],
};
const DEFAULT_ASSIGNEES_FILTER_PARTIAL = {
  id: "assignee",
  applicability: FilterApplicability.IS,
  connector: FilterConnector.OR,
  values: [],
};
const DEFAULT_STATUS_FILTER_PARTIAL = {
  id: "status",
  applicability: FilterApplicability.IS,
  connector: FilterConnector.OR,
  values: [],
};

const props = defineProps<{
  filters?: IFilter[];
  isSearchMode?: boolean;
}>();

const emit = defineEmits<{
  setFilter: [filter: IFilter];
  removeFilter: [propertyDuid: string];
}>();

const currentInstance = getCurrentInstance();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const sortRef = ref<InstanceType<typeof SortDropdown> | null>(null);
const addFilterRef = ref<InstanceType<typeof AddFilter> | null>(null);

const filtersRef = new Map();
const assignFilterRef = (index: number, e: Component | null) => {
  if (!e) {
    return;
  }
  filtersRef.set(index, e);
};

const disabledAddButton = computed(() => [...filtersRef.values()].some((e) => e.working));
const onSearchPage = computed(() => appStore.currentPage?.kind === ViewKind.SEARCH);
const isTrash = computed(() => appStore.currentPage?.kind === ViewKind.TRASH);

const rawFilters = computed(() => props.filters ?? appStore.filters);
const filterDefinitions = computed(() => getFilterDefinitionList(getPropertyWithConfigList()));

const displayedFilters = computed(() => {
  const remaining = [...rawFilters.value];
  const res: IFilter[] = [];

  const rearrangeForKind = (propertyKind: PropertyKind, defaultFilter: Omit<IFilter, "propertyDuid">) => {
    const property = getPropertyWithConfigList()
      .map((e) => e[0])
      .find((e) => e.kind === propertyKind);
    if (!property) {
      return;
    }
    const propertyDuid = property.duid;
    const index = remaining.findIndex((e) => e.propertyDuid === propertyDuid);
    if (index !== -1) {
      const filter = remaining[index];
      const filterDefinition = filterDefinitions.value.find((e) => e.propertyDuid === propertyDuid);
      if (filterDefinition?.config.options) {
        filter.values = filter.values.filter((value) =>
          filterDefinition.config.options.some((option) => option.value === value)
        );
      }
      res.push(filter);
      remaining.splice(index, 1);
    } else {
      res.push({ ...defaultFilter, propertyDuid });
    }
  };

  if (appStore.currentPage?.pageKind !== PageKind.DARTBOARD || props.isSearchMode) {
    rearrangeForKind(PropertyKind.DEFAULT_DARTBOARD, DEFAULT_DARTBOARD_FILTER_PARTIAL);
  }

  rearrangeForKind(PropertyKind.DEFAULT_ASSIGNEES, DEFAULT_ASSIGNEES_FILTER_PARTIAL);
  rearrangeForKind(PropertyKind.DEFAULT_STATUS, DEFAULT_STATUS_FILTER_PARTIAL);

  return res.concat(remaining);
});

const canSaveView = computed(() => onSearchPage.value && displayedFilters.value.length > 0);

const setFilter = (filter: IFilter) => {
  if (props.isSearchMode) {
    emit("setFilter", filter);
  } else {
    appStore.setFilter(filter);
  }
};

const removeFilter = (propertyDuid: string) => {
  if (props.isSearchMode) {
    emit("removeFilter", propertyDuid);
  } else {
    appStore.removeFilter(propertyDuid);
  }
};

const openFilter = (propertyDuid: string) => {
  const index = displayedFilters.value.findIndex((filter) => filter.propertyDuid === propertyDuid);
  if (index === -1) {
    return;
  }
  filtersRef.get(index).openDropdown();
};

const addFilter = (propertyDuid: string) => {
  const definition = filterDefinitions.value.find((e) => e.propertyDuid === propertyDuid);
  if (!definition) {
    return;
  }

  setFilter({
    id: makeUuid(),
    propertyDuid,
    connector: FilterConnector.OR,
    applicability:
      definition.config.applicabilities.length > 0 ? definition.config.applicabilities[0] : FilterApplicability.IS,
    values: [],
  });

  nextTick(() => openFilter(propertyDuid));
};

const onReset = () => {
  appStore.resetFilters();
  appStore.resetSorts();
};

const onSaveView = async () => {
  const { layoutDuid } = dataStore.getViewByKind(ViewKind.SEARCH);
  const searchLayout = dataStore.getLayoutByDuid(layoutDuid);

  // Create view & redirect to it.
  const partialLayout: Partial<Layout> = {
    ...deepCopy(searchLayout),
    duid: makeDuid(),
    filterGroup: {
      filters: displayedFilters.value,
      connector: FilterConnector.AND,
    },
  };

  const newViewOrder = getOrdersBetween(undefined, dataStore.viewList[0].order)[0];
  const newView = await dataStore.createView(newViewOrder, { title: "Saved search" }, partialLayout);
  appStore.setCurrentPage(newView);

  if (!pageStore.isOnline) {
    return;
  }

  const res = await backendOld.recommendations.getFiltersSummary(layoutDuid);
  const { summary, emoji } = res.data.item;

  dataStore.updateView({
    duid: newView.duid,
    title: summary,
    iconKind: IconKind.EMOJI,
    iconNameOrEmoji: normalizeEmoji(emoji),
  });
};

const newFilter = () => {
  addFilterRef.value?.openDropdown();
};

const newSort = () => {
  sortRef.value?.openDropdown();
};

const dartboardFilter = computed<IFilter>(() => ({
  id: "dartboard",
  propertyDuid: "dartboard",
  locked: true,
  applicability: FilterApplicability.IS,
  connector: FilterConnector.AND,
  values: [TRASH_VIEW_KEY],
}));
const dartboardDefinition = computed<FilterDefinition>(() => ({
  propertyDuid: "dartboard",
  title: "Dartboard",
  icon: DartboardIcon,
  getValue: () => null,
  config: {
    type: FilterType.VALUE,
    available: true,
    singleton: true,
    applicabilities: [FilterApplicability.IS],
    options: [
      {
        value: TRASH_VIEW_KEY,
        label: "Trash",
        colorHex: DEFAULT_CHIP_COLOR,
        icon: PageIcon,
        iconArgs: { page: dataStore.getViewByKind(ViewKind.TRASH), size: IconSize.S },
      },
    ],
    test: () => true,
  },
}));

onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filterArea = (currentInstance?.exposeProxy ?? currentInstance?.exposed ?? null) as any;
  if (props.isSearchMode) {
    appStore.searchFilterArea = filterArea;
  } else {
    appStore.filterArea = filterArea;
  }
});

onUnmounted(() => {
  if (props.isSearchMode) {
    appStore.searchFilterArea = null;
  } else {
    appStore.filterArea = null;
  }
});

defineExpose({
  addFilter,
  newFilter,
  newSort,
  openFilter,
});
</script>

<template>
  <div class="flex items-center gap-3 border-t border-md">
    <template v-if="!isSearchMode">
      <SortDropdown ref="sortRef" />
      <div class="h-full w-px bg-md" />
    </template>
    <Animated class="-mr-1.5 -mt-1.5 flex flex-1 flex-wrap gap-1 truncate pr-1.5 pt-1.5">
      <Filter
        v-if="isTrash && !isSearchMode"
        :filter="dartboardFilter"
        :definition="dartboardDefinition"
        :open-new-filter="() => {}"
        class="select-none" />
      <Filter
        v-for="(filter, index) in displayedFilters"
        :ref="(elem) => assignFilterRef(index, elem)"
        :key="filter.id"
        :filter="filter"
        :definition="filterDefinitions.find((e) => e.propertyDuid === filter.propertyDuid)"
        :open-new-filter="newFilter"
        @set-filter="setFilter"
        @remove-filter="removeFilter" />
      <AddFilter
        ref="addFilterRef"
        :hide-ai="isSearchMode"
        :disabled="disabledAddButton"
        :filters="displayedFilters"
        @select="addFilter" />
    </Animated>
    <div v-if="!isSearchMode" class="flex items-center">
      <Tooltip
        v-if="!appStore.usingDefaultFiltersAndSorts && !onSearchPage"
        text="Reset any active filters and sorts to their defaults">
        <Button
          :btn-style="ButtonStyle.SECONDARY"
          text="Reset"
          class="h-6 pl-1.5"
          :size="ButtonSize.CHIP"
          :icon-after="XIcon"
          :icon-size="IconSize.XS"
          @click="onReset" />
      </Tooltip>

      <Tooltip v-if="canSaveView" text="Save this search as a view for future use">
        <Button
          :btn-style="ButtonStyle.SECONDARY"
          text="Save view"
          class="h-6"
          :size="ButtonSize.CHIP"
          :icon="SaveViewIcon"
          :icon-size="IconSize.XS"
          @click="onSaveView" />
      </Tooltip>
    </div>
  </div>
</template>
