<script setup lang="ts">
import { mergeRegister } from "@lexical/utils";
import { $createTextNode, $getNodeByKey, type LexicalEditor, type LexicalNode, type TextNode } from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { computed, onMounted, onUnmounted, reactive } from "vue";

import { backend } from "~/api";
import { getShownPropertyWithConfigList } from "~/common/properties";
import { $createSmartMatchNode, $isSmartMatchNode, SmartMatchNode } from "~/components/text/nodes/SmartMatchNode";
import { EventKind } from "~/shared/enums";
import { hasClassInPath, isString } from "~/utils/common";

import NodeEventPlugin from "../NodeEventPlugin.vue";
import { removeConflictingEntitiesAndSmartMatches } from "../typeahead/common";
import getSmartMatchRegex from "./regex";
import useLexicalTextEntity from "./useLexicalTextEntity";

const CLOSE_SMART_MATCH_CLASSES = new Set(["dart-smart-match-close"]);

const editor = useLexicalComposer();

const options = computed(() =>
  getShownPropertyWithConfigList()
    .filter(([, config]) => config.smartMatch !== null)
    .map(([property, config]) => config.smartMatch!(property).getOptions)
    .flat()
);
const blacklist = reactive(new Set<string>());
const matchRegex = computed(() => getSmartMatchRegex(options.value.map((e) => e("")).flat(), blacklist));

const createSmartMatchNode = (textNode: TextNode) => {
  const text = textNode.getTextContent();
  const token = options.value
    .map((e) => e(text))
    .flat()
    .find((option) => new RegExp(option.match, "i").test(text));
  if (!token) {
    throw new Error("SmartMatchPlugin: createSmartMatchNode called with text that doesn't match any options");
  }

  const smartMatchNode = $createSmartMatchNode(text, token.propertyDuid, token.value);
  removeConflictingEntitiesAndSmartMatches(editor, smartMatchNode);
  backend.event.create(EventKind.USAGE_NLP_RAW_CREATE, { text });
  return smartMatchNode;
};

const getMatch = (text: string) => {
  const match = matchRegex.value.exec(text);
  if (!match || !match.groups) {
    return null;
  }

  const startOffset = match.index + match.groups.whitespace.length;
  const endOffset = startOffset + match.groups.query.length;
  return { start: startOffset, end: endOffset };
};

const onReverse = (text: string) => {
  if (text.length === 0) {
    return;
  }

  blacklist.add(text);
  backend.event.create(EventKind.USAGE_NLP_RAW_DELETE, { text });
};

const onNodeClick = (event: Event, innerEditor: LexicalEditor, node: LexicalNode) => {
  if (!hasClassInPath(event, CLOSE_SMART_MATCH_CLASSES)) {
    return;
  }

  innerEditor.update(() => {
    const text = node.getTextContent();
    onReverse(text);
    node.replace($createTextNode(text));
  });
};

useLexicalTextEntity<SmartMatchNode>(getMatch, SmartMatchNode, createSmartMatchNode, onReverse);

// allows undo or paste, for example, to make nodes even if they were in the blacklist
const handleSmartMatchNodeMutations = (mutatedNodes: Map<string, string>) =>
  editor.getEditorState().read(() => {
    const mutatedNodeEntries = [...mutatedNodes.entries()];
    const createdNodes = mutatedNodeEntries.filter((e) => e[1] === "created").map((e) => e[0]);
    const creates = createdNodes
      .map((e) => {
        const node = $getNodeByKey(e);
        if (!node || !$isSmartMatchNode(node)) {
          return null;
        }

        return node.getTextContent();
      })
      .filter(isString);
    creates.forEach((e) => blacklist.delete(e));
  });

const resetBlacklist = () => {
  blacklist.clear();
};

let unregisterListeners: () => void;

onMounted(() => {
  if (!editor.hasNodes([SmartMatchNode])) {
    throw new Error("SmartMatchPlugin: SmartMatchNode not registered on editor");
  }

  unregisterListeners = mergeRegister(editor.registerMutationListener(SmartMatchNode, handleSmartMatchNodeMutations));
});

onUnmounted(() => {
  unregisterListeners?.();
});

defineExpose({
  resetBlacklist,
});
</script>

<template>
  <NodeEventPlugin :node-type="SmartMatchNode" event-type="click" :event-listener="onNodeClick" />
</template>
