import type { IRowNode, ValueGetterParams } from "ag-grid-community";

import type { TaskAbsenteeMaybe } from "~/shared/types";
import type { DataStore } from "~/stores/DataStore";

type AppStore = ReturnType<DataStore["$useAppStore"]>;

export const listValueGetter = (params: ValueGetterParams<TaskAbsenteeMaybe>): boolean => !!params.data?.absentee;

export const getReactiveTaskNode = <T extends { duid: string }>(node: IRowNode<T>, appStore: AppStore) => {
  if (!node.data) {
    return node;
  }
  const task = appStore.$useDataStore().getTaskByDuid(node.data.duid);
  if (!task) {
    return node;
  }
  // Keep all properties from node.data that aren't in task
  Object.assign(node.data, task);
  return node;
};
