<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { backendOld } from "~/api";
import Avatar from "~/components/dumb/Avatar.vue";
import AvatarGroup from "~/components/dumb/AvatarGroup.vue";
import Button from "~/components/dumb/Button.vue";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import DatePicker from "~/components/dumb/DatePicker.vue";
import Modal from "~/components/dumb/Modal.vue";
import MultiselectDropdownMenu from "~/components/dumb/MultiselectDropdownMenu.vue";
import RadioCardGroup from "~/components/dumb/RadioCardGroup.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import UserDropdownItem from "~/components/dumb/UserDropdownItem.vue";
import { notify } from "~/components/notifications";
import { colorsByTheme } from "~/constants/style";
import {
  ArrowRightIcon,
  CalendarIcon,
  ChevronDownIcon,
  OrderFieldIcon,
  PlanProjectIcon,
  PriorityFieldIcon,
  SizeFieldIcon,
  UserFieldIcon,
} from "~/icons";
import { ButtonStyle, EditorMode, LayoutKind, ModalWidth, TutorialName } from "~/shared/enums";
import type { SimpleDateRange } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore, useUserStore } from "~/stores";
import { getItemCountText } from "~/utils/common";
import { getRelativeTimeForDatesDate } from "~/utils/time";

const dateStyle =
  "flex h-8 flex-1 items-center justify-center text-left overflow-hidden gap-2 rounded border bg-std hover:bg-lt border-md pl-2 pr-1 py-1 text-sm text-md";

const DATES_VALUE = "dates";
const ORDER_VALUE = "order";
const SIZE_VALUE = "size";
const PRIORITY_VALUE = "priority";
const ASSIGNEE_VALUE = "assignee";

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();
const userStore = useUserStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const hovering = ref(false);

const enabledProperties = computed(() =>
  [
    {
      title: "Date range",
      id: "dates",
      value: DATES_VALUE,
      icon: CalendarIcon,
      enabled: tenantStore.dueDateEnabled,
    },
    {
      title: "Orders",
      id: "orders",
      value: ORDER_VALUE,
      icon: OrderFieldIcon,
      enabled: true,
    },
    {
      title: "Sizes",
      id: "sizes",
      value: SIZE_VALUE,
      icon: SizeFieldIcon,
      enabled: tenantStore.sizeEnabled,
    },
    {
      title: "Priorities",
      id: "priorities",
      value: PRIORITY_VALUE,
      icon: PriorityFieldIcon,
      enabled: tenantStore.priorityEnabled,
    },
    {
      title: "Assignees",
      id: "assignees",
      value: ASSIGNEE_VALUE,
      icon: UserFieldIcon,
      enabled: tenantStore.assigneeEnabled,
    },
  ].filter((e) => e.enabled)
);
const selectedProperties = ref<string[]>(enabledProperties.value.map((e) => e.value));
const propertyOptions = computed(() =>
  enabledProperties.value.map((option) => ({
    ...option,
    selected: selectedProperties.value.includes(option.value),
  }))
);

const selectedDateRange = ref<SimpleDateRange>({ start: null, end: null });
const selectedAssigneeDuids = ref<string[]>([]);

const startDisabled = computed(() => selectedProperties.value.length === 0);
const isWorking = ref(false);

const startDate = computed(() =>
  selectedDateRange.value.start ? getRelativeTimeForDatesDate(selectedDateRange.value.start) : null
);
const endDate = computed(() =>
  selectedDateRange.value.end ? getRelativeTimeForDatesDate(selectedDateRange.value.end) : null
);
const selectedTaskDuids = computed(() => appStore.selectedTaskDuids);
const modifyingTasks = computed(() =>
  selectedTaskDuids.value.size > 0
    ? [...selectedTaskDuids.value]
    : appStore.currentDartboardDuid
      ? dataStore.getTasksByDartboardDuidOrdered(appStore.currentDartboardDuid).map((e) => e.duid)
      : []
);

const onPropertySelect = (value: string) => {
  if (selectedProperties.value.includes(value)) {
    selectedProperties.value = selectedProperties.value.filter((option) => option !== value);
  } else {
    selectedProperties.value = [...selectedProperties.value, value];
  }
};

/* Project Assignees */
const users = computed(() =>
  dataStore.getUserList().map((user) => {
    const value = user?.duid;
    const label = user.name || user.email;

    return {
      value,
      label,
      adtlSearchTerms: [user.email],
      selected: selectedAssigneeDuids.value.includes(value),
      component: UserDropdownItem,
      componentArgs: {
        label,
        replaceEnabled: false,
        icon: Avatar,
        iconArgs: {
          abrev: user?.abrev ?? "",
          circle: true,
          colorHex: user?.colorHex,
          hover: false,
          imageUrl: user?.imageUrl,
          imgBorder: true,
          class: "icon-lg",
        },
      },
    };
  })
);

const onAddAssignee = (duid: string) => {
  selectedAssigneeDuids.value = [...selectedAssigneeDuids.value, duid];
};
const onRemoveAssignee = (duid: string) => {
  selectedAssigneeDuids.value = selectedAssigneeDuids.value.filter((assignee) => assignee !== duid);
};

watch(
  () => appStore.planProjectModalOpen,
  (open) => {
    if (!open || !appStore.currentDartboardDuid) {
      return;
    }

    const dartboard = dataStore.getDartboardByDuid(appStore.currentDartboardDuid);
    if (!dartboard) {
      return;
    }

    selectedProperties.value = enabledProperties.value.map((e) => e.value);
    selectedDateRange.value = { start: null, end: null };
    selectedAssigneeDuids.value = dataStore.getRelevantUsersByDartboards([dartboard]).map((e) => e.duid);
  }
);

const onStart = async () => {
  if (!appStore.currentDartboardDuid || !appStore.projectAiDropdown) {
    return;
  }

  isWorking.value = true;
  appStore.projectAiDropdown.setWorking(true);
  appStore.setPlanProjectModalOpen(false);
  if (appStore.layoutKind !== LayoutKind.ROADMAP) {
    notify({
      message: "Check out the new project plan in the roadmap for the best experience",
      actions: [
        {
          label: "Switch to roadmap",
          onClick: () => appStore.setLayoutKind(LayoutKind.ROADMAP),
        },
      ],
    });
  }

  userStore.updateTutorialStatuses([{ name: TutorialName.PLAN_WITH_AI, status: 2 }]);
  const recommendation: { item: { recommendationDuid: string } } = (
    await backendOld.recommendations.startPlanningProject(
      appStore.currentDartboardDuid,
      modifyingTasks.value,
      selectedProperties.value,
      selectedDateRange.value.start ?? null,
      selectedDateRange.value.end ?? null,
      selectedAssigneeDuids.value
    )
  ).data;

  isWorking.value = false;
  appStore.projectAiDropdown.setWorking(false);
  appStore.showFeedbackTooltip(appStore.projectAiDropdown?.button, [recommendation.item.recommendationDuid]);
};

const onClose = () => {
  isWorking.value = false;
  appStore.setPlanProjectModalOpen(false);
};
</script>

<template>
  <Modal
    :entity="appStore.planProjectModalOpen"
    title="Plan this project"
    :width="ModalWidth.M"
    description="Use AI to plan your project and organize its tasks"
    custom-styles="sm:h-fit"
    @close="onClose">
    <template #default>
      <div class="mt-6 flex h-full flex-col gap-4">
        <div class="flex flex-col gap-2">
          <div class="flex flex-col">
            <div class="font-medium text-md">Properties</div>
            <div class="text-sm text-lt">Choose the properties you'd like AI to plan</div>
          </div>
          <RadioCardGroup
            vertical
            show-checkboxes
            :items="propertyOptions"
            :no-hover="hovering"
            @select="onPropertySelect">
            <template #dates>
              <div v-if="selectedProperties.includes(DATES_VALUE)" class="flex w-full flex-col gap-2" @click.stop>
                <div class="mt-1 text-sm text-lt">Select the overall project timeline</div>
                <DatePicker
                  ref="picker"
                  :value="selectedDateRange"
                  :editor-mode="EditorMode.FILTER"
                  :distance="-4"
                  :skidding="-1"
                  cover
                  block
                  class="flex h-full items-center justify-center"
                  @mouseover="hovering = true"
                  @mouseleave="hovering = false"
                  @focus="hovering = true"
                  @blur="hovering = false"
                  @select-range="(date) => (selectedDateRange = date)">
                  <div class="mb-1 flex w-full items-center gap-2">
                    <div :class="dateStyle">
                      <CalendarIcon class="text-primary-base icon-sm" />
                      <span :class="[!startDate && 'text-vlt']" class="flex-1 truncate">
                        {{ startDate ?? "Start date" }}
                      </span>
                    </div>
                    <ArrowRightIcon class="size-3 shrink-0 text-lt" />
                    <div :class="dateStyle">
                      <CalendarIcon class="text-primary-base icon-sm" />
                      <span :class="[!endDate && 'text-vlt']" class="flex-1 truncate">
                        {{ endDate ?? "End date" }}
                      </span>
                    </div>
                  </div>
                </DatePicker>
              </div>
            </template>

            <template #assignees>
              <div v-if="selectedProperties.includes(ASSIGNEE_VALUE)" class="flex w-full flex-col gap-2" @click.stop>
                <div class="mt-1 text-sm text-lt">Select teammates you'd like AI to assign</div>
                <MultiselectDropdownMenu
                  :items="users"
                  :style="{ '--background': colors.borderVlt, '--highlight': colors.borderMd }"
                  :distance="0"
                  placeholder="Select assignees..."
                  block
                  cover
                  @mouseover="hovering = true"
                  @mouseleave="hovering = false"
                  @focus="hovering = true"
                  @blur="hovering = false"
                  @add="onAddAssignee"
                  @remove="onRemoveAssignee">
                  <div
                    class="flex size-full items-center gap-2 rounded border px-3 py-2 text-left text-sm shadow-sm bg-std text-md border-md focus-ring-std hover:bg-lt">
                    <UserFieldIcon class="icon-sm" />
                    <AvatarGroup
                      v-if="selectedAssigneeDuids.length > 0"
                      class="flex-1"
                      :duids="selectedAssigneeDuids"
                      :editor-mode="EditorMode.DETAIL"
                      :limit="15" />
                    <span v-else class="flex-1 text-lt">Add assignees</span>
                    <ChevronDownIcon class="mx-1 text-lt icon-sm" />
                  </div>
                </MultiselectDropdownMenu>
              </div>
            </template>
          </RadioCardGroup>
        </div>
        <div class="mb-7 flex flex-col text-sm text-lt">
          {{
            selectedTaskDuids.size > 0
              ? `You have ${getItemCountText(selectedTaskDuids.size, "task", { noSpecial: true })} selected in this page, so this will only affect ${selectedTaskDuids.size === 1 ? "it" : "those"}.`
              : `This will update ${`${modifyingTasks.length > 1 ? "all " : ""}${getItemCountText(modifyingTasks.length, "task", { definite: true })} in this page`}.`
          }}
        </div>
      </div>
    </template>
    <template #actions>
      <div class="flex items-center gap-2">
        <Button :btn-style="ButtonStyle.SECONDARY" text="Cancel" is-contrast @click="onClose" />
        <ConfirmationDialog
          title="Start planning project"
          :description="`Dart AI will update the selected properties of ${selectedTaskDuids.size > 0 ? `the ${getItemCountText(selectedTaskDuids.size, 'selected task', { noSpecial: true })}` : `${modifyingTasks.length > 1 ? 'all ' : ''}${getItemCountText(modifyingTasks.length, 'task', { definite: true })}`} in this page. This can't be undone. Are you sure you want to proceed?`"
          confirm-text="Start"
          cancel-text="Cancel"
          :icon="PlanProjectIcon"
          @confirm="onStart">
          <Tooltip :disabled="!startDisabled" text="Select at least one property">
            <Button
              :btn-style="ButtonStyle.RECOMMENDATION"
              text="Start planning"
              is-contrast
              :disabled="startDisabled"
              :working="isWorking" />
          </Tooltip>
        </ConfirmationDialog>
      </div>
    </template>
  </Modal>
</template>
