import axios from "axios";

import { notify } from "~/components/notifications";
import { NotificationType } from "~/shared/enums";
import type { Attachment } from "~/shared/types";

const MAX_IMAGE_SIZE_BYTES = 8 * 1024 * 1024;
const MAX_FILE_SIZE_BYTES = 5 * 1024 * 1024 * 1024;

export const saveFile = (blob: Blob, filename: string) => {
  // TODO maybe should be based on `pageStore.isMobileApp && pageStore.isIos`
  if (window.webkit) {
    const reader = new FileReader();
    reader.onload = () => {
      const base64data = reader.result as string;
      webkit.messageHandlers.downloadFile.postMessage({
        base64data,
        filename,
        mimeType: blob.type,
      });
    };
    reader.readAsDataURL(blob);
  }

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
  URL.revokeObjectURL(link.href);
  notify({ message: `${filename} downloaded`, type: NotificationType.SUCCESS });
};

export const downloadFile = async (path: string, filename?: string) => {
  const response = await axios.get(path, { responseType: "blob" });
  const blob = new Blob([response.data]);
  const contentParsed = response.headers["content-disposition"]?.match(/^(?:attachment|inline); filename="(.*)"$/);
  const filenameNorm = filename || (contentParsed ? contentParsed[1] : "");
  saveFile(blob, filenameNorm);
};

export const checkFileSize = (file: File) => {
  if (file.type.startsWith("image") && file.size > MAX_IMAGE_SIZE_BYTES) {
    notify({
      message: `${file.name} is too big, upload an image that is smaller than 8MB`,
      type: NotificationType.ERROR,
    });
    return false;
  }
  if (file.size > MAX_FILE_SIZE_BYTES) {
    notify({
      message: `${file.name} is too big, upload a file that is smaller than 5GB`,
      type: NotificationType.ERROR,
    });
    return false;
  }
  return true;
};

export const truncateFilename = (fileName: string, maxLength: number) => {
  if (!maxLength) {
    return fileName;
  }

  const lastDotIndex = fileName.lastIndexOf(".");
  if (lastDotIndex === -1 || lastDotIndex === 0) {
    return fileName.slice(0, maxLength);
  }

  const fileRoot = fileName.slice(0, lastDotIndex);
  const fileExt = fileName.slice(lastDotIndex);

  const truncate = Math.max(maxLength - fileExt.length, 0);
  return fileRoot.slice(0, truncate) + fileExt;
};

export const createFilesFromAttachments = (attachments: Attachment[]) =>
  Promise.all(
    attachments.map(async (attachment: Attachment) => {
      const { data } = await axios.get(attachment.fileUrl, { responseType: "blob" });
      return new File([data], attachment.name, { type: attachment.kind });
    })
  );

export const fileToBase64 = (file: File): Promise<string | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const res = reader.result;
      if (res instanceof ArrayBuffer) {
        resolve(new TextDecoder().decode(res));
        return;
      }
      resolve(res);
    };
    reader.onerror = reject;
  });

export const encodeArray = (key: string, array: string[]) => {
  const encodedKey = encodeURIComponent(key);
  return array.map((value) => `${encodedKey}=${encodeURIComponent(value)}`).join("&");
};
