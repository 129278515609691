<script setup lang="ts">
import type { ICellRendererParams } from "ag-grid-community";
import { computed, onMounted } from "vue";

import DartboardEditor from "~/components/dumb/DartboardEditor.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import TaskClickWrapper from "~/components/visualization/components/TaskClickWrapper.vue";
import { EditorMode, ViewKind } from "~/shared/enums";
import type { TaskAbsenteeMaybe } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";

const props = defineProps<{
  params: ICellRendererParams<TaskAbsenteeMaybe>;
}>();

const dataStore = useDataStore();
const appStore = useAppStore();

const task = computed(() => (props.params?.data ? dataStore.getTaskByDuid(props.params.data.duid) : undefined));
const isSearchAndTaskInTrash = computed(() => appStore.currentPage?.kind === ViewKind.SEARCH && task.value?.inTrash);

const trashPage = computed(() => dataStore.getViewByKind(ViewKind.TRASH));

const pageStore = usePageStore();

onMounted(() => {
  pageStore.incrementCellRenderCount();
});
</script>

<template>
  <TaskClickWrapper v-if="task" :task="task" :editor-mode="EditorMode.LIST">
    <template #default="args">
      <div v-if="isSearchAndTaskInTrash" class="flex size-full max-w-full items-center gap-2 px-2.5">
        <PageIcon v-if="trashPage" :page="trashPage" />
        <span class="truncate text-start text-sm/5">Trash</span>
      </div>
      <DartboardEditor
        v-else-if="!params.data?.absentee"
        v-bind="args"
        :tasks="[task]"
        :editor-mode="EditorMode.LIST"
        class="h-full" />
    </template>
  </TaskClickWrapper>
</template>
