<script setup lang="ts">
import { AxiosError } from "axios";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import { backendOld } from "~/api";
import Button from "~/components/dumb/Button.vue";
import DartBranding from "~/components/dumb/DartBranding.vue";
import EmptyState from "~/components/dumb/EmptyState.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import { convertFormValuesToTask, isFieldInvalid } from "~/components/forms/common";
import FormFieldCard from "~/components/forms/FormFieldCard.vue";
import { FORM_TELEPORT_KEY } from "~/components/text/const";
import { colorsByTheme } from "~/constants/style";
import { DartboardIcon, PlusIcon, UnknownPageIcon } from "~/icons";
import { ButtonStyle, PageKind } from "~/shared/enums";
import type { Form, FormField, PropertyValue } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { getPublicFormLink } from "~/utils/common";

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const form = ref<Form | undefined>(undefined);
const formFields = computed<FormField[]>(() =>
  form.value ? dataStore.getFormFieldRelatedToFormOrdered(form.value.duid).filter((e) => !e.hidden) : []
);
const fieldDuidToValueMap = ref<Map<string, PropertyValue>>(new Map());

const formIsInvalid = computed(
  () =>
    !form.value ||
    form.value.hidden ||
    formFields.value.some((field) => isFieldInvalid(field, fieldDuidToValueMap.value.get(field.duid)))
);

const showEmptyState = ref(false);

const setPageInfo = () => {
  pageStore.pageLoaded = true;
  const title = form.value?.title ?? "Unknown form";
  pageStore.pageTitle = title;
  document.title = title;
};

const resetValues = () => {
  if (!form.value) {
    return;
  }

  fieldDuidToValueMap.value = new Map();
  dataStore.getFormFieldRelatedToFormOrdered(form.value.duid).forEach((field: FormField) => {
    fieldDuidToValueMap.value.set(field.duid, field.default.value);
  });
};

const setUserData = async () => {
  const formDuid = router.currentRoute.value.params.formDuid.toString();
  let res;
  try {
    res = await backendOld.forms.getData(formDuid);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.code !== AxiosError.ERR_BAD_REQUEST) {
      throw error;
    }
    setPageInfo();
    return;
  }
  const { data } = res;

  dataStore.$setSpaces(data.spaces);
  dataStore.$setDartboards(data.dartboards);

  const dataForm = { ...data.form, pageKind: PageKind.FORM };
  dataStore.$setForms([dataForm]);
  dataStore.$setFormFields(data.formFields);
  dataStore.$setUsers(data.users);
  dataStore.$setOptions(data.options);
  dataStore.$setProperties(data.properties);
  dataStore.$setStatuses(data.statuses);
  dataStore.$setTaskKinds(data.taskKinds);

  appStore.init();
  appStore.setPageIconOptions(data.pageIconOptions);

  form.value = dataForm;
  resetValues();

  setPageInfo();

  setTimeout(() => {
    if (form.value) {
      router.replace({
        params: { slugSep: "-", slug: getPublicFormLink(form.value).params.slug },
      });
    }
  });
};

const submitForm = () => {
  if (!form.value) {
    return;
  }

  showEmptyState.value = true;

  const task = convertFormValuesToTask(form.value, fieldDuidToValueMap.value, true);
  backendOld.tasks.createFromExternalForm(task, appStore.pendingAttachmentCreates);
  appStore.pendingAttachmentCreates = [];
};

const resetForm = () => {
  resetValues();
  showEmptyState.value = false;
};

onMounted(setUserData);
</script>

<template>
  <div
    id="dart-form-wrapper"
    class="relative flex size-full flex-col items-center gap-8 overflow-y-scroll p-4 bg-lt text-md"
    :data-toolbar="FORM_TELEPORT_KEY"
    :style="{ '--background': colors.borderVlt, '--highlight': colors.borderMd }"
    @click.stop>
    <div class="flex w-full grow flex-col justify-evenly">
      <div class="flex flex-col items-center gap-2">
        <EmptyState
          v-if="!form"
          title="Form not found"
          description="This form does not exist or you do not have access to it."
          :main-icon="UnknownPageIcon" />

        <div
          v-else-if="!showEmptyState"
          class="my-4 flex w-full max-w-lg flex-col overflow-y-auto rounded-lg border border-t-0 align-middle shadow-lg bg-std border-hvy dark:shadow-zinc-50/10">
          <div
            class="flex items-center gap-2 rounded-t-lg border-t bg-primary-hover-dark px-4 pb-2 pt-2.5 text-oncolor border-md">
            <PageIcon :page="form" />
            <span class="select-none hyphens-auto text-lg font-semibold break-words">
              {{ form.title }}
            </span>
          </div>

          <div class="mx-2 my-4 flex flex-col">
            <FormFieldCard
              v-for="field in formFields"
              :key="field.duid"
              :field="field"
              :value="fieldDuidToValueMap.get(field.duid)"
              is-submit-mode
              is-public
              @update="(duid, value) => fieldDuidToValueMap.set(duid, value)" />
          </div>

          <div class="mx-5 mb-5 flex justify-end">
            <Button :disabled="formIsInvalid" :btn-style="ButtonStyle.PRIMARY" text="Submit" @click="submitForm" />
          </div>
        </div>

        <EmptyState
          v-else
          title="Response submitted"
          description="Your response has been submitted and recorded"
          :main-icon="DartboardIcon"
          button-text="Submit another"
          :button-icon="PlusIcon"
          @click="resetForm" />
      </div>
      <div />
    </div>

    <DartBranding is-contrast class="mb-4" />
  </div>
</template>
