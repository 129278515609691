<script setup lang="ts">
import type { ICellRendererParams } from "ag-grid-community";
import { computed, onMounted } from "vue";

import { copyAndNotify } from "~/components/notifications";
import TaskClickWrapper from "~/components/visualization/components/TaskClickWrapper.vue";
import { EditorMode } from "~/shared/enums";
import type { TaskAbsenteeMaybe } from "~/shared/types";
import { useDataStore, usePageStore } from "~/stores";
import { prettifyOrder } from "~/utils/orderManager";

const props = defineProps<{
  params: ICellRendererParams<TaskAbsenteeMaybe>;
}>();

const dataStore = useDataStore();
const pageStore = usePageStore();

const task = computed(() => (props.params?.data ? dataStore.getTaskByDuid(props.params.data.duid) : undefined));

const pretty = computed(() => (task.value ? prettifyOrder(task.value.order) : ""));

const copy = () => {
  if (!task.value) {
    return;
  }
  copyAndNotify("Order", task.value.order);
};

onMounted(() => {
  pageStore.incrementCellRenderCount();
});
</script>

<template>
  <TaskClickWrapper v-if="task" :task="task" :editor-mode="EditorMode.LIST">
    <template #default="args">
      <div
        v-if="!params.data?.absentee"
        v-bind="args"
        class="flex size-full items-center font-mono text-[10px] tracking-tighter"
        @click="copy"
        @keydown.enter="copy">
        {{ pretty }}
      </div>
    </template>
  </TaskClickWrapper>
</template>
