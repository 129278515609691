<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";
import type { ComponentExposed } from "vue-component-type-helpers";

import Button from "~/components/dumb/Button.vue";
import DragArea from "~/components/dumb/DragArea.vue";
import LoadingSpinner from "~/components/dumb/LoadingSpinner.vue";
import PageEmptyState from "~/components/dumb/PageEmptyState.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import PageCard from "~/components/PageCard.vue";
import { PlusIcon } from "~/icons";
import { ButtonStyle, CommandId, IconSize } from "~/shared/enums";
import type { Doc } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { makeStringComparator } from "~/utils/comparator";
import usePaginatedList from "~/utils/usePaginatedList";

enum SortBy {
  RECENT = "-recent",
  TITLE = "title",
  ORDER = "order",
}

const props = defineProps<{
  folderDuid: string;
  slugSep?: string;
  slug?: string;
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const sortBy = ref(SortBy.RECENT);

const folder = computed(() => dataStore.getFolderByDuid(props.folderDuid));
const docs = computed(() => dataStore.getDocsByFolderDuidOrdered(props.folderDuid, { order: sortBy.value }));
const docsSorted = computed(() => {
  const res = [...docs.value];
  switch (sortBy.value) {
    case SortBy.ORDER: {
      res.sort(makeStringComparator((doc) => doc.order));
      break;
    }
    case SortBy.RECENT: {
      res.sort(makeStringComparator((doc) => doc.updatedAt, true));
      break;
    }
    case SortBy.TITLE: {
      res.sort(makeStringComparator((doc) => doc.title.toLowerCase()));
      break;
    }
    default: {
      throw new Error(`Unknown sort by: ${sortBy.value}`);
    }
  }
  return res;
});

const scrollContainer = ref<HTMLDivElement | null>(null);
const { isLoading, canLoadMore, reset } = usePaginatedList(scrollContainer, docs, async () =>
  !folder.value ? 0 : dataStore.loadPaginatedDocsForFolderDuid(folder.value.duid, sortBy.value)
);

watch(
  () => props.folderDuid,
  () => {
    reset();
  }
);

const changeSort = (newSortBy: SortBy) => {
  sortBy.value = newSortBy;
  reset();
};

const dragArea = ref<ComponentExposed<typeof DragArea<Doc, typeof PageCard>> | null>(null);

const createDocAndStartEditing = () => {
  if (!appStore.spaceOrFolder) {
    return;
  }
  appStore.spaceOrFolder.createDoc(false);

  nextTick(() => {
    const itemRefs = dragArea.value?.itemRefs ?? [];
    if (itemRefs.length === 0) {
      return;
    }

    itemRefs[itemRefs.length - 1].startEditingTitle();
  });
};

const getComponentProps = (doc: Doc) => ({
  page: doc,
});

const moveDoc = (_: string, doc: Doc) => {
  dataStore.updateDocs([
    {
      duid: doc.duid,
      order: doc.order,
    },
  ]);
};
</script>

<template>
  <div v-if="folder" ref="scrollContainer" class="flex size-full justify-center overflow-y-scroll">
    <div class="mx-6 flex h-fit max-w-xl grow flex-col extra-overscroll" :class="pageStore.isMobile ? 'mt-6' : 'mt-28'">
      <div class="mr-8 max-w-sm select-none truncate text-2xl font-semibold text-hvy sm:max-w-full">
        {{ folder.title }}
      </div>
      <div class="flex items-end justify-between border-b px-2 pt-2 border-md">
        <div class="flex gap-1">
          <Button
            :btn-style="ButtonStyle.SECONDARY"
            text="Recent"
            :text-style="sortBy === SortBy.RECENT ? undefined : 'text-vlt'"
            borderless
            class="rounded-b-none border-b-2"
            :class="sortBy === SortBy.RECENT && 'border-b-primary-base'"
            @click="changeSort(SortBy.RECENT)" />
          <Button
            :btn-style="ButtonStyle.SECONDARY"
            text="A → Z"
            :text-style="sortBy === SortBy.TITLE ? undefined : 'text-vlt'"
            borderless
            class="rounded-b-none border-b-2"
            :class="sortBy === SortBy.TITLE && 'border-b-primary-base'"
            @click="changeSort(SortBy.TITLE)" />
          <Button
            :btn-style="ButtonStyle.SECONDARY"
            text="All"
            :text-style="sortBy === SortBy.ORDER ? undefined : 'text-vlt'"
            borderless
            class="rounded-b-none border-b-2"
            :class="sortBy === SortBy.ORDER && 'border-b-primary-base'"
            @click="changeSort(SortBy.ORDER)" />
        </div>
        <Tooltip :command-id="CommandId.CREATE_DOC" class="mb-3">
          <Button
            :btn-style="ButtonStyle.SECONDARY"
            :icon="PlusIcon"
            :icon-size="IconSize.M"
            borderless
            class="!p-0.5"
            a11y-label="Create a doc"
            @click="createDocAndStartEditing" />
        </Tooltip>
      </div>
      <DragArea
        ref="dragArea"
        :no-reorder="sortBy !== SortBy.ORDER"
        :no-reorder-to-last="canLoadMore"
        group="doc-editor"
        category="docs"
        class="!h-auto w-full divide-y divide-lt"
        :items="docsSorted"
        :component="PageCard"
        :get-component-props="getComponentProps"
        @change="moveDoc" />
      <LoadingSpinner v-if="isLoading" class="mt-4" />
      <PageEmptyState v-else-if="docs.length === 0" />
    </div>
  </div>
</template>
