<script setup lang="ts">
import { backendOld } from "~/api";
import Toggle from "~/components/dumb/Toggle.vue";
import SettingsRow from "~/components/settings/SettingsRow.vue";
import { useTenantStore } from "~/stores";

type TenantStore = ReturnType<typeof useTenantStore>;

const tenantStore = useTenantStore();

const updateAutomationSetting = (settingName: keyof TenantStore, newValue: boolean) => {
  // TODO fix this type hack
  tenantStore[settingName as "closeParentOnCloseAllSubtasks"] = newValue;
  backendOld.workspace.edit(settingName, newValue);
};
</script>

<template>
  <div class="space-y-10">
    <SettingsRow
      title="Parents complete when all subtasks complete"
      subtitle="When all of the subtasks of a given task complete, the parent will automatically complete">
      <Toggle
        :value="tenantStore.closeParentOnCloseAllSubtasks"
        @update="(e) => updateAutomationSetting('closeParentOnCloseAllSubtasks', e)" />
    </SettingsRow>

    <SettingsRow
      title="Subtasks change dartboards with parent"
      subtitle="When a parent task moves between dartboards, all of its subtasks in that dartboard will automatically move">
      <Toggle
        :value="tenantStore.moveSubtasksOnMoveParent"
        @update="(e) => updateAutomationSetting('moveSubtasksOnMoveParent', e)" />
    </SettingsRow>

    <SettingsRow
      title="Subtask statuses update with parent"
      subtitle="When a parent status changes, all of its subtasks at or behind that status will automatically update">
      <Toggle
        :value="tenantStore.updateSubtasksStatusOnUpdateParentStatus"
        @update="(e) => updateAutomationSetting('updateSubtasksStatusOnUpdateParentStatus', e)" />
    </SettingsRow>

    <SettingsRow
      title="New subtasks replicate parent"
      subtitle="When a subtask is first created, it will automatically inherit most of its parent's properties">
      <Toggle
        :value="tenantStore.copyParentFieldsOnCreate"
        @update="(e) => updateAutomationSetting('copyParentFieldsOnCreate', e)" />
    </SettingsRow>

    <SettingsRow
      title="Blocked task dates update with blocker"
      subtitle="When a blocker's due date changes, all of the tasks that are blocked will automatically update">
      <Toggle
        :value="tenantStore.updateBlockeeDatesOnUpdateBlockerDueDate"
        @update="(e) => updateAutomationSetting('updateBlockeeDatesOnUpdateBlockerDueDate', e)" />
    </SettingsRow>
  </div>
</template>
