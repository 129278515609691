<script lang="ts" setup>
import type { SerializedEditorState } from "lexical";
import { computed } from "vue";
import { useRouter } from "vue-router";

import actions from "~/actions";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import EntityDropdownItem from "~/components/dumb/EntityDropdownItem.vue";
import MultiselectDropdownMenu from "~/components/dumb/MultiselectDropdownMenu.vue";
import { MergeIcon } from "~/icons";
import { Placement, PropertyKind, RelationshipKindKind, StatusKind } from "~/shared/enums";
import type { PropertyValue, Task, TaskAndUpdate } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";
import { getTaskLink, isLexicalStateEmpty, timeout } from "~/utils/common";
import { sign } from "~/utils/comparator";

const props = defineProps<{
  targetTask: Task;
  selectedTasks: Task[];
  onAfterClose?: () => void;
}>();

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();

const taskOptions = computed(() => {
  const recentTaskCount = appStore.recentTaskStack.length;
  return dataStore
    .getTaskList()
    .sort((a, b) => {
      const aIndex = appStore.recentTaskStack.indexOf(a.duid);
      const bIndex = appStore.recentTaskStack.indexOf(b.duid);
      return sign((aIndex > -1 ? aIndex : recentTaskCount) - (bIndex > -1 ? bIndex : recentTaskCount));
    })
    .filter((task) => task.duid !== props.targetTask.duid)
    .map((task) => ({
      value: task.duid,
      label: task.title,
      selected: props.selectedTasks.some((selectedTask) => selectedTask.duid === task.duid),
      disabled: false,
      component: EntityDropdownItem,
      componentArgs: {
        showOpen: true,
        onOpen: () => {
          router.push(getTaskLink(task));
        },
      },
    }));
});

const mergeDescriptions = (
  targetDescription: SerializedEditorState,
  sourceDescription: SerializedEditorState
): SerializedEditorState => {
  if (isLexicalStateEmpty(sourceDescription)) {
    return targetDescription;
  }
  if (isLexicalStateEmpty(targetDescription)) {
    return sourceDescription;
  }
  const targetChildren = targetDescription.root.children;
  const sourceChildren = sourceDescription.root.children;
  const divider = { type: "horizontal-rule", version: 1 };
  return {
    ...targetDescription,
    root: {
      ...targetDescription.root,
      children: [...targetChildren, divider, ...sourceChildren],
    },
  };
};

const mergeLinks = (targetTaskDuid: string, sourceTask: Task) => {
  sourceTask.links.forEach((link) => dataStore.addLink(targetTaskDuid, link.kind, link.url, link.title || null));
};

const mergeProperties = (targetTask: Task, sourceTask: Task): Record<string, PropertyValue> =>
  Object.entries(sourceTask.properties).reduce(
    (acc, [key, value]) => {
      const property = dataStore.getPropertyByDuid(key);
      if (!property) {
        return acc;
      }
      if (property.kind === PropertyKind.MULTISELECT || property.kind === PropertyKind.USER) {
        // eslint-disable-next-line no-param-reassign
        acc[key] = [...new Set([...(acc[key] as string[]), ...(value as string[])])];
        return acc;
      }
      if (!(key in acc) || acc[key] === null) {
        // eslint-disable-next-line no-param-reassign
        acc[key] = value;
      }
      return acc;
    },
    { ...targetTask.properties }
  );

const mergeRelationships = (targetTask: Task, sourceTask: Task) => {
  const parentKindDuid = dataStore.getRelationshipKindByKind(RelationshipKindKind.PARENT_OF).duid;
  const sourceTaskSubtasks = dataStore.getRelationshipsByKindKind(sourceTask, RelationshipKindKind.PARENT_OF, true);

  if (sourceTaskSubtasks.length > 0) {
    const tasksAndUpdates = sourceTaskSubtasks
      .map((relationship) => {
        const task = dataStore.getTaskByDuid(relationship.targetDuid);
        if (!task) {
          return undefined;
        }
        return { task, order: task.order };
      })
      .filter((e): e is TaskAndUpdate => !!e);

    dataStore.changeParent(tasksAndUpdates, targetTask);
  }

  sourceTask.relationships.forEach((relationship) => {
    if (relationship.kindDuid === parentKindDuid) {
      return;
    }
    // TODO handle loops
    dataStore.createRelationship(targetTask.duid, relationship.targetDuid, relationship.kindDuid);
  });
};

const mergeTasks = async (targetTask: Task, sourceTask: Task) => {
  const mergedTitle = targetTask.title.trim() || sourceTask.title;
  const mergedSubscriberDuids = [...new Set([...targetTask.subscriberDuids, ...sourceTask.subscriberDuids])];
  const mergedTagDuids = [...new Set([...targetTask.tagDuids, ...sourceTask.tagDuids])];
  // TODO replicate the attachments in the target
  const mergedAttachmentDuids = [...new Set([...targetTask.attachmentDuids, ...sourceTask.attachmentDuids])];

  mergeLinks(targetTask.duid, sourceTask);
  mergeRelationships(targetTask, sourceTask);

  const mergedProperties = mergeProperties(targetTask, sourceTask);

  const canceledStatusMaybe = dataStore.getStatusesByKinds([StatusKind.CANCELED], dataStore.defaultStatusProperty)[0];
  const newStatus = canceledStatusMaybe ?? dataStore.defaultDefaultFinishedStatus;

  await dataStore.updateTasks([
    {
      duid: targetTask.duid,
      title: mergedTitle,
      subscriberDuids: mergedSubscriberDuids,
      tagDuids: mergedTagDuids,
      attachmentDuids: mergedAttachmentDuids,
      properties: mergedProperties,
    },
    {
      duid: sourceTask.duid,
      statusDuid: newStatus.duid,
    },
  ]);
};

const onSelect = async (taskDuid: string) => {
  const targetTask = dataStore.getTaskByDuid(taskDuid);
  const sourceTask = dataStore.getTaskByDuid(props.targetTask.duid);
  if (!targetTask || !sourceTask) {
    return;
  }

  const relatesToRelationshipKindDuid = dataStore.getRelationshipKindByKind(RelationshipKindKind.DUPLICATES).duid;

  // TODO transactionify
  mergeTasks(targetTask, sourceTask);
  dataStore.createRelationship(props.targetTask.duid, targetTask.duid, relatesToRelationshipKindDuid);
  dataStore.deleteTasks([props.targetTask]);

  await actions.visualization.navigateToTask(taskDuid);
  for (let i = 0; i < 10; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    await timeout(100);
    if (appStore.taskDetail?.isDescriptionReady) {
      break;
    }
  }
  if (!appStore.taskDetail || !appStore.taskDetail.isDescriptionReady) {
    return;
  }

  appStore.taskDetail.setDescription(mergeDescriptions(targetTask.description, sourceTask.description));
};
</script>

<template>
  <MultiselectDropdownMenu
    :items="taskOptions"
    placeholder="Merge into task..."
    :placement="Placement.RIGHT_TOP"
    show-on-hover
    :width-pixels="448"
    :on-after-close="onAfterClose"
    close-on-select
    @add="onSelect">
    <DropdownMenuItemContent :icon="MergeIcon" title="Merge into other task" is-submenu />
  </MultiselectDropdownMenu>
</template>
