<script setup lang="ts">
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import { backendOld } from "~/api";
import Button from "~/components/dumb/Button.vue";
import Modal from "~/components/dumb/Modal.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import { ChevronRightIcon } from "~/icons";
import { getQueryParam } from "~/router/common";
import { ButtonStyle, ModalWidth } from "~/shared/enums";
import type { Attachment, Layout, RelationshipKind, Space, Task, View } from "~/shared/types";
import { useDataStore } from "~/stores";
import { getDartboardLink, run } from "~/utils/common";
import { getOrdersBetween } from "~/utils/orderManager";

const DEFAULT_TITLE = "template";

const router = useRouter();
const dataStore = useDataStore();

const data = ref<{
  attachments: Attachment[];
  layout: Layout;
  relationshipKinds: RelationshipKind[];
  view: View;
  tasks: Task[];
} | null>(null);

const viewTitle = ref<string | null>(null);
const viewDuid = getQueryParam("template");

if (viewDuid) {
  viewTitle.value = DEFAULT_TITLE;
  run(async () => {
    const response = await backendOld.views.getData(viewDuid, false);
    viewTitle.value = response.data.view.title;
    data.value = response.data;

    const query = { ...router.currentRoute.value.query };
    delete query.template;
    router.replace({ query });
  });
}

const workingSpaceDuid = ref<string | null>(null);
const spaceConfigs = computed(() =>
  dataStore.spaceList.map((e) => [e, e.duid === workingSpaceDuid.value] satisfies [Space, boolean])
);

const close = () => {
  viewTitle.value = null;
};

const importToSpace = async (spaceDuid: string) => {
  if (!data.value) {
    return;
  }

  workingSpaceDuid.value = spaceDuid;

  const { attachments, view, layout, tasks, relationshipKinds } = data.value;
  const topDartboardOrder = dataStore.getDartboardsBySpaceDuidOrdered(spaceDuid)[0]?.order;
  const order = getOrdersBetween(undefined, topDartboardOrder)[0];
  const dartboard = await dataStore.importViewAsDartboard(
    view,
    layout,
    relationshipKinds,
    tasks,
    attachments,
    spaceDuid,
    order
  );
  if (!dartboard) {
    return;
  }

  router.push(getDartboardLink(dartboard, dataStore.getSpaceByDuid));
  close();
};
</script>

<template>
  <Modal
    :entity="viewTitle"
    :title="(e) => `Set up ${e ?? DEFAULT_TITLE}`"
    description="Choose a space to import this template into"
    :width="ModalWidth.S"
    @close="close">
    <template #default>
      <div class="-mx-2 mt-4 flex max-h-80 flex-col gap-1 overflow-y-auto">
        <Button
          v-for="[space, working] in spaceConfigs"
          :key="space.duid"
          :btn-style="ButtonStyle.SECONDARY"
          :text="space.title"
          :working="working"
          :icon="PageIcon"
          :icon-args="{ page: space }"
          :icon-after="ChevronRightIcon"
          :icon-after-args="{ class: 'text-lt ml-auto hidden group-hover/space-button:flex' }"
          borderless
          class="group/space-button !justify-start !px-2 !py-1.5 focus-visible:!ring-0"
          @click="importToSpace(space.duid)" />
      </div>
    </template>
  </Modal>
</template>
