<script setup lang="ts">
import { computed, onUnmounted, ref } from "vue";

import actions from "~/actions";
import { getPropertyPartialTask, getPropertyValueFromTask } from "~/common/properties";
import AiSpinner from "~/components/dumb/AiSpinner.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import NumberEditor from "~/components/dumb/NumberEditor.vue";
import SizeChip from "~/components/dumb/SizeChip.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { notify } from "~/components/notifications";
import {
  EXPONENTIAL_SIZE,
  FIBONACCI_SIZE,
  getSizeOptions,
  LINEAR_SIZE,
  TSHIRT_SIZE_TO_NUMBER_SIZE,
  TSHIRT_SIZE_TO_PRETTY_LABEL,
  TSHIRT_SIZES,
  UNSIZED_SIZE_LABEL,
} from "~/constants/size";
import { colorsByTheme } from "~/constants/style";
import { SizeFieldIcon } from "~/icons";
import { CommandId, DropdownMenuItemKind, EditorMode, NotificationType, Placement, SizeFormat } from "~/shared/enums";
import type { PropertyDefaultSize, PropertyValueForKind, Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { generateFieldRecommendation } from "~/utils/recommendation";

type Value = PropertyValueForKind<PropertyDefaultSize>;

const SIZE_FORMATS = new Map([
  [SizeFormat.LINEAR, getSizeOptions(LINEAR_SIZE)],
  [SizeFormat.FIBONACCI, getSizeOptions(FIBONACCI_SIZE)],
  [SizeFormat.EXPONENTIAL, getSizeOptions(EXPONENTIAL_SIZE)],
  [
    SizeFormat.TSHIRT,
    TSHIRT_SIZES.map((size) => ({
      size: TSHIRT_SIZE_TO_NUMBER_SIZE.get(size) ?? null,
      label: TSHIRT_SIZE_TO_PRETTY_LABEL.get(size) ?? UNSIZED_SIZE_LABEL,
    })),
  ],
]);

const props = defineProps<{
  property: PropertyDefaultSize;
  tasks: Task[];
  defaultValue?: Value;
  editorMode: EditorMode;
  value?: Value;
  showText?: boolean;
  onAfterClose?: (value: Value) => void;
}>();

const emit = defineEmits<{
  update: [size?: Value];
  reject: [];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const feedbackTooltipNode = ref<HTMLDivElement | null>(null);
const dropdownMenu = ref<InstanceType<typeof DropdownMenu> | null>(null);

const selectedSize = computed(() => {
  if (props.editorMode === EditorMode.FORM || props.editorMode === EditorMode.PROPERTY_DEFAULT) {
    return props.value ?? null;
  }

  const firstTaskSize = getPropertyValueFromTask(props.property, props.tasks[0]);
  return props.tasks.every((task) => getPropertyValueFromTask(props.property, task) === firstTaskSize)
    ? firstTaskSize
    : null;
});

const loading = computed(() => appStore.isLoadingTasksProperty(props.tasks, props.property.duid));

const isChipRecommendationMode = computed(() => props.editorMode === EditorMode.CHIP_RECOMMENDATION);
const isChipMode = computed(() => props.editorMode === EditorMode.CHIP || isChipRecommendationMode.value);
const isListMode = computed(() => props.editorMode === EditorMode.LIST);
const isDetailBaseMode = computed(() => props.editorMode === EditorMode.DETAIL);
const isDetailRecommendationMode = computed(() => props.editorMode === EditorMode.DETAIL_RECOMMENDATION);
const isTaskDetailMode = computed(() => isDetailBaseMode.value || isDetailRecommendationMode.value);
const isContextMenuMode = computed(() => props.editorMode === EditorMode.CONTEXT_MENU);
const isFormMode = computed(() => props.editorMode === EditorMode.FORM);
const isPropertyDefaultMode = computed(() => props.editorMode === EditorMode.PROPERTY_DEFAULT);
const isFormOrDefaultPropertyMode = computed(() => isFormMode.value || isPropertyDefaultMode.value);

const isFreeEntry = computed(() => props.property.adtl.format === SizeFormat.FREE_ENTRY);

const selectedTasks = computed(() =>
  isChipMode.value ? props.tasks : dataStore.getTasksByDuidsOrdered([...appStore.selectedTaskDuids])
);
const selectedTaskDuids = computed(() => selectedTasks.value.map((e) => e.duid));

const updateSize = (size: Value) => {
  dataStore.updateTasks(
    selectedTasks.value.map((task) => ({ duid: task.duid, ...getPropertyPartialTask(props.property, task, size) }))
  );
};

const generateRecommendation = async () => {
  if (selectedTaskDuids.value.length > 100) {
    notify({ message: "Dart AI cannot fill out more than 100 tasks at once", type: NotificationType.ERROR });
    return;
  }

  appStore.startLoadingAiTaskProperties(selectedTaskDuids.value, props.property.duid);
  const [taskUpdates, recDuids] = await generateFieldRecommendation(
    selectedTasks.value,
    "size",
    props.defaultValue ?? null
  );
  dataStore.updateTasks(taskUpdates);
  appStore.finishLoadingAiTaskProperties(selectedTaskDuids.value, props.property.duid);

  actions.visualization.showFeedbackTooltipForTask(
    selectedTaskDuids.value,
    recDuids,
    props.editorMode,
    feedbackTooltipNode.value ?? null
  );
};

const rejectRecommendation = () => {
  updateSize(props.defaultValue ?? null);
  emit("update");
  emit("reject");
};

const dropdownSections = computed(() => {
  const firstTaskSize = isFormOrDefaultPropertyMode.value
    ? props.value
    : getPropertyValueFromTask(props.property, props.tasks[0]);
  const sizeOfSelectedTasks = isFormOrDefaultPropertyMode.value
    ? props.value
    : selectedTasks.value.every((task) => getPropertyValueFromTask(props.property, task) === firstTaskSize)
      ? firstTaskSize
      : undefined;

  return [
    {
      title: props.property.title,
      items: (SIZE_FORMATS.get(props.property.adtl.format) ?? []).map(({ size: numberSize, label: title }) => ({
        title,
        kind: DropdownMenuItemKind.BUTTON,
        disabled: numberSize === sizeOfSelectedTasks,
        onClick: () => {
          if (isFormOrDefaultPropertyMode.value) {
            emit("update", numberSize);
            return;
          }

          updateSize(numberSize);
          emit("update");
        },
      })),
    },
  ];
});

const handleAfterClose = () => {
  props.onAfterClose?.(selectedSize.value);
};

onUnmounted(() => {
  appStore.showFeedbackTooltip(null, []);
});

defineExpose({
  open: () => dropdownMenu.value?.open(),
});
</script>

<template>
  <DropdownMenu
    v-if="!isFreeEntry"
    ref="dropdownMenu"
    :container="isChipMode ? '#dart-task-creation-modal-wrapper' : isFormMode ? '#dart-form-wrapper' : undefined"
    :sections="dropdownSections"
    :block="isListMode || isFormOrDefaultPropertyMode"
    :height-block="isListMode || isFormOrDefaultPropertyMode"
    :distance="isFormOrDefaultPropertyMode ? 2 : undefined"
    :skidding="isFormOrDefaultPropertyMode ? -1 : undefined"
    :show-on-hover="isContextMenuMode"
    :cover="!isContextMenuMode && !isTaskDetailMode && !isFormOrDefaultPropertyMode"
    :placement="isContextMenuMode ? Placement.RIGHT_TOP : Placement.BOTTOM_LEFT"
    :style="{ '--background': colors.borderVlt, '--highlight': colors.borderMd }"
    :show-recommendation-button="!isFormOrDefaultPropertyMode"
    :on-after-close="handleAfterClose"
    @recommend="generateRecommendation">
    <DropdownMenuItemContent
      v-if="isContextMenuMode"
      :icon="SizeFieldIcon"
      :title="`Change ${property.title.toLowerCase()}`"
      is-submenu />
    <Tooltip
      v-else
      :disabled="isChipRecommendationMode || isFormOrDefaultPropertyMode"
      :command-id="CommandId.CHANGE_SIZE"
      :block="isListMode || isFormOrDefaultPropertyMode"
      :height-block="isListMode || isFormOrDefaultPropertyMode"
      :class="isTaskDetailMode && 'w-full'">
      <div
        ref="feedbackTooltipNode"
        class="group/size flex size-full max-w-full items-center text-sm"
        :class="{
          'px-1.5': isListMode,
          'justify-start rounded py-1': isTaskDetailMode,
          'hover:bg-lt': isDetailBaseMode,
          'bg-recommendation-base/10 hover:bg-recommendation-base/20 dark:hover:bg-recommendation-base/20':
            isDetailRecommendationMode,
        }">
        <div v-if="loading" class="flex items-center py-0.5">
          <AiSpinner class="icon-sm" />
        </div>
        <SizeChip
          v-else
          :value="selectedSize"
          :property="props.property"
          :show-border="isChipMode"
          :show-if-empty="isTaskDetailMode || isChipMode || isFormOrDefaultPropertyMode"
          :editor-mode="editorMode"
          @accept="emit('update')"
          @reject="rejectRecommendation" />
        <div
          v-if="isListMode && selectedSize === null"
          class="flex size-full items-center justify-center text-transparent group-hover/size:text-vlt">
          <SizeFieldIcon class="icon-xs" />
        </div>
      </div>
    </Tooltip>
  </DropdownMenu>
  <NumberEditor
    v-else
    :property="props.property"
    :tasks="props.tasks"
    :editor-mode="props.editorMode"
    :default-value="props.defaultValue"
    :value="props.value"
    @update="emit('update')" />
</template>
