<script setup lang="ts">
import { computed, nextTick } from "vue";
import { useRouter } from "vue-router";
import { DynamicScrollerItem } from "vue-virtual-scroller";

import Button from "~/components/dumb/Button.vue";
import LoadingSpinner from "~/components/dumb/LoadingSpinner.vue";
import UpdateTime from "~/components/dumb/UpdateTime.vue";
import DartLink from "~/components/text/nodes/DartLink.vue";
import { FullscreenIcon, ReadIcon, UnreadIcon } from "~/icons";
import { ButtonSize, ButtonStyle, EntityName, TextSentinel } from "~/shared/enums";
import type { MessageFrag, Notification } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";
import { asyncState } from "~/utils/async";
import { isString } from "~/utils/common";

const ENTITY_KINDS_TO_OPEN_IN_DETAIL = new Set([EntityName.TASK, EntityName.COMMENT]);

const props = defineProps<{
  notification: Notification;
  activeInScroll: boolean;
  selected: boolean;
  justRead: boolean;
}>();

const emit = defineEmits<{
  select: [];
  clear: [];
}>();

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();

const shouldOpenInDetail = computed(() => ENTITY_KINDS_TO_OPEN_IN_DETAIL.has(props.notification.event.mainEntityName));

// Fetches the entity data based on the main entity name
const { state: entity, isLoading: isLoadingEntity } = asyncState(
  async () => dataStore.getNotificationEntity(props.notification),
  { default: undefined }
);

const entityTitle = computed(() => entity.value?.title ?? `(Unknown ${props.notification.event.mainEntityName})`);

const clickNotificationBody = () => {
  emit("select");

  if (!shouldOpenInDetail.value) {
    appStore.selectedTaskDuids = new Set();
    appStore.setTaskDetailOpen(false);
    return;
  }

  if (entity.value === undefined) {
    return;
  }

  appStore.selectedTaskDuids = new Set([entity.value.duid]);
  appStore.setTaskDetailOpen(true);

  if (props.notification.event.mainEntityName === EntityName.COMMENT) {
    nextTick(() => {
      router.push({ query: { c: props.notification.event.commentDuid } });
      appStore.taskDetail?.jumpToComments(false);
    });
  }
};

const clickNotificationOpenButton = () => {
  if (entity.value === undefined) {
    return;
  }

  const { mainEntityName } = props.notification.event;
  const link = dataStore.getEntityLink(entity.value, mainEntityName);
  if (link) {
    router.push(link);
  }
};

const updateNotificationRead = () => {
  if (props.justRead) {
    emit("clear");
    return;
  }

  dataStore.updateNotification({ duid: props.notification.duid, read: !props.notification.read });
};

const isTextSentinel = (frag: MessageFrag): frag is TextSentinel =>
  Object.values(TextSentinel).includes(frag as TextSentinel);

const processFrags = (frags: MessageFrag[] | null) => {
  const res: [Exclude<MessageFrag, TextSentinel>, boolean][] = [];
  if (frags === null) {
    return res;
  }
  frags.forEach((frag, index) => {
    if (isTextSentinel(frag)) {
      return;
    }
    res.push([frag, index < frags.length - 1 && frags[index + 1] !== TextSentinel.NO_SPACE]);
  });
  return res;
};
</script>

<template>
  <DynamicScrollerItem
    :item="notification"
    :active="activeInScroll"
    :size-dependencies="[]"
    class="group/notification flex cursor-pointer justify-between gap-1 border-b py-3 pl-4 pr-2.5 border-md"
    :class="[
      selected ? 'bg-primary-base/15 hover:bg-primary-base/25' : 'hover:bg-lt',
      isLoadingEntity && 'pointer-events-none',
    ]"
    @click.stop="clickNotificationBody"
    @keydown.enter.stop="clickNotificationBody">
    <LoadingSpinner v-if="isLoadingEntity" />
    <div v-else class="flex grow select-none flex-col justify-between gap-1">
      <div class="font-semibold text-md">
        {{ entityTitle }}
      </div>
      <div class="flex grow gap-2">
        <div class="mr-2.5 flex w-full min-w-0 flex-wrap items-center text-sm">
          <template
            v-for="([item, hasSpaceAfter], index) in processFrags(notification.event.messageFrags)"
            :key="index">
            <div v-if="item === '\n'" class="w-full" />
            <span v-else-if="isString(item)" :class="hasSpaceAfter && 'mr-1'" class="mb-px flex items-center text-lt">
              {{ item.replaceAll(/ /g, "&nbsp;") }}
            </span>
            <DartLink
              v-else
              is-notification-mode
              :name="item.entityName"
              :duid="item.duid"
              :class="hasSpaceAfter ? 'mr-0.5' : '-mr-1'"
              class="-ml-0.5"
              @open="clickNotificationBody"
              @click.stop
              @keydown.enter.stop />
          </template>
        </div>
      </div>
    </div>

    <div class="-mt-0.5 mb-0.5 mr-1.5 flex shrink-0 flex-col items-end justify-between gap-2">
      <div class="flex items-center gap-1.5">
        <UpdateTime :at="notification.createdAt" cursor-pointer />
        <div
          v-if="!notification.read"
          class="-mr-1.5 flex items-center justify-center rounded-full focus-ring-none icon-md">
          <div class="size-2 rounded-full bg-primary-base" />
        </div>
      </div>

      <Button
        v-if="selected && !shouldOpenInDetail"
        :btn-style="ButtonStyle.PRIMARY"
        :icon="FullscreenIcon"
        text="Open"
        :size="ButtonSize.SMALL"
        class="h-7 !px-2"
        @click="clickNotificationOpenButton" />

      <Button
        v-else
        :btn-style="ButtonStyle.PRIMARY"
        :icon="justRead || !notification.read ? ReadIcon : UnreadIcon"
        :text="justRead ? 'Clear' : `Mark ${notification.read ? 'un' : ''}read`"
        :size="ButtonSize.SMALL"
        class="h-7 !px-2 opacity-0 group-hover/notification:opacity-100"
        @click.stop="updateNotificationRead" />
    </div>
  </DynamicScrollerItem>
</template>
