import { IconKind, TaskKindKind } from "~/shared/enums";
import type { TaskKind, TaskKindUpdate } from "~/shared/types";
import { makeRandomColorHex } from "~/utils/color";
import { makeDuid, randomSample } from "~/utils/common";
import { taskKindComparator } from "~/utils/comparator";

import type { PiniaActionAdaptor, PiniaGetterAdaptor } from "../shared";
import type { DataStore } from ".";

export type Getters = {
  taskKindList: TaskKind[];
  getTaskKindByDuid: (duid: string) => TaskKind | undefined;
  getTaskKindByTitle: (title: string) => TaskKind | undefined;
  getTaskKindsByDuidsOrdered: (duids: string[]) => TaskKind[];
  defaultTaskKind: TaskKind;
};

export type Actions = {
  /* Create a new task kind. */
  createTaskKind: (
    title: string,
    order: string,
    partialTaskKind?: Partial<TaskKind>,
    options?: { awaitBackend?: boolean }
  ) => Promise<TaskKind | undefined>;
  /* Update an existing task kind. */
  updateTaskKind: (update: TaskKindUpdate, options?: { awaitBackend?: boolean }) => Promise<TaskKind | undefined>;
  /* Update many existing task kinds. */
  updateTaskKinds: (
    updates: TaskKindUpdate[],
    options?: { awaitBackend?: boolean }
  ) => Promise<(TaskKind | undefined)[]>;
  /** Delete a task kind. */
  deleteTaskKind: (taskKind: TaskKind, options?: { awaitBackend?: boolean }) => Promise<void>;
  /** Create a task kind without changing the backend.
   * @PRIVATE */
  $createTaskKindNoBackend: (title: string, order: string, partialTaskKind?: Partial<TaskKind>) => TaskKind | undefined;
  /** Sort task kinds.
   * @PRIVATE */
  $sortTaskKinds: () => void;
  /** Set task kinds internally.
   * @PRIVATE */
  $setTaskKinds: (taskKinds: TaskKind[]) => void;
  /** Create or update task kind from WS.
   * @PRIVATE */
  $createOrUpdateTaskKind: (taskKind: TaskKind) => void;
  /** Delete task kind from WS.
   * @PRIVATE */
  $deleteTaskKind: (taskKind: TaskKind) => void;
};

const getters: PiniaGetterAdaptor<Getters, DataStore> = {
  taskKindList() {
    return [...this._duidsToTaskKinds.values()];
  },
  getTaskKindByDuid() {
    return (duid) => this._duidsToTaskKinds.get(duid);
  },
  getTaskKindByTitle() {
    return (title) => {
      const normTitle = title.trim().toLowerCase();
      return this.taskKindList.find((e: TaskKind) => e.title.toLowerCase() === normTitle);
    };
  },
  getTaskKindsByDuidsOrdered() {
    return (duids) => this.taskKindList.filter((e) => duids.includes(e.duid));
  },
  defaultTaskKind() {
    const taskKind = this.taskKindList.filter((e) => e.locked);
    if (!taskKind) {
      throw new Error("Expected exactly one locked task kind");
    }
    return taskKind[0];
  },
};

const actions: PiniaActionAdaptor<Actions, DataStore> = {
  async createTaskKind(title, order, partialTaskKind = {}, options = {}) {
    const { awaitBackend = false } = options;
    const taskKind = this.$createTaskKindNoBackend(title, order, partialTaskKind);
    if (!taskKind) {
      return undefined;
    }

    const backendAction = this.$backend.taskKind.create(taskKind);
    if (awaitBackend) {
      await backendAction;
    }
    return taskKind;
  },
  async updateTaskKind(update, options = {}) {
    const taskKinds = await this.updateTaskKinds([update], options);
    return taskKinds[0];
  },
  async updateTaskKinds(updates, options = {}) {
    const { awaitBackend = false } = options;
    const taskKinds: TaskKind[] = [];
    updates.forEach((update) => {
      const taskKind = this.getTaskKindByDuid(update.duid);
      if (!taskKind) {
        return;
      }

      taskKinds.push(taskKind);
      Object.assign(taskKind, update);
    });

    if (taskKinds.length === 0) {
      return [];
    }
    this.$sortTaskKinds();

    const backendAction = this.$backend.taskKind.updateMany(updates);
    if (awaitBackend) {
      await backendAction;
    }
    return taskKinds;
  },
  async deleteTaskKind(taskKind, options = {}) {
    const { awaitBackend = false } = options;

    const tasks = this.getTasksByKindDuid(taskKind.duid);
    const newTaskKindDuid = this.defaultTaskKind;
    if (!newTaskKindDuid) {
      return;
    }
    this.updateTasks(
      tasks.map((e) => ({
        duid: e.duid,
        taskKind: newTaskKindDuid,
      })),
      { noUndo: true, noCelebrate: true }
    );

    const layoutUpdates = this.$updateLayoutsRemoveEntityDuidOrFilters(
      [taskKind.duid],
      [this.defaultKindProperty.duid]
    );
    const dartboardUpdates = this.$updateDartboardsPropertyDefault([taskKind.duid], [this.defaultKindProperty.duid]);

    this.$deleteTaskKind(taskKind);

    const backendAction = this.$backend.taskKind.delete(taskKind.duid, layoutUpdates, dartboardUpdates);
    if (awaitBackend) {
      await backendAction;
    }
  },
  $createTaskKindNoBackend(title, order, partialTaskKind = {}) {
    if (title === "" || this.getTaskKindByTitle(title) !== undefined) {
      return undefined;
    }

    const appStore = this.$useAppStore();

    const taskKind: TaskKind = {
      duid: makeDuid(),
      kind: TaskKindKind.DEFAULT,
      locked: false,
      order,
      title: title.trim(),
      iconKind: IconKind.NONE,
      iconNameOrEmoji: randomSample(appStore.pageIconOptions)[0].name,
      colorHex: makeRandomColorHex(),
      hiddenStatusDuids: [],
      ...partialTaskKind,
    };

    this.$createOrUpdateTaskKind(taskKind);

    return taskKind;
  },
  $sortTaskKinds() {
    this._duidsToTaskKinds = new Map([...this._duidsToTaskKinds].sort((a, b) => taskKindComparator(a[1], b[1])));
  },
  $setTaskKinds(taskKinds) {
    this._duidsToTaskKinds = new Map(taskKinds.map((e) => [e.duid, e]));
    this.$sortTaskKinds();
  },
  $createOrUpdateTaskKind(taskKind) {
    const currentTaskKind = this.getTaskKindByDuid(taskKind.duid);

    if (!currentTaskKind) {
      this._duidsToTaskKinds.set(taskKind.duid, taskKind);
    } else {
      Object.assign(currentTaskKind, taskKind);
    }

    this.$sortTaskKinds();
  },
  $deleteTaskKind(taskKind) {
    this._duidsToTaskKinds.delete(taskKind.duid);
  },
};

export { actions, getters };
