import { RelationshipKindKind } from "~/shared/enums";
import type { Relationship, RelationshipKind, Task, TaskAndUpdate } from "~/shared/types";

import type { PiniaActionAdaptor, PiniaGetterAdaptor } from "../shared";
import type { DataStore } from ".";

export type Getters = {
  /* Get all of the relationship kinds. */
  relationshipKindList: RelationshipKind[];
  /* Get RelationshipKind by DUID */
  getRelationshipKindByDuid: (relationshipKindDuid: string) => RelationshipKind | undefined;
  /* Get RelationshipKind DUID from the RelationshipKindKind */
  getRelationshipKindByKind: (relationshipKindKind: RelationshipKindKind) => RelationshipKind;
  /* Get all of the relationships for a task, excluding ones that refer to deleted tasks. */
  getRelationships(task: Task, options?: { includeTrashed?: boolean }): Relationship[];
  /* Get all of the relationships that have the given kind of relationship with the given task
  If forward is supplied, only relationships in that direction are returned, otherwise both directions. */
  getRelationshipsByKindKind: (
    task: Task,
    relationshipKindKind: RelationshipKindKind,
    forward?: boolean,
    options?: { includeTrashed?: boolean }
  ) => Relationship[];
  /* Get parent task of a given subtask */
  getParentTask: (task: Task) => Task | undefined;
  /* Get all of the ancestors (parents, parents of parents, etc., but not the task itself) by DUID of a given task. */
  getAncestorDuids(task: Task, options?: { includeTrashed?: boolean }): string[];
  /* Get all of the descendants (children, children of children, etc., but not the task itself) by DUID of a given task. */
  getDescendantDuids(task: Task): string[];
};

export type Actions = {
  /* Change the parent of a list tasks. */
  changeParent: (
    tasksAndUpdates: TaskAndUpdate[],
    newParent: Task | null,
    options?: { noBackend?: boolean; awaitBackend?: boolean }
  ) => void;
  /** Set relationship kinds internally.
   * @PRIVATE */
  $setRelationshipKinds: (relationshipKinds: RelationshipKind[]) => void;
};

const getters: PiniaGetterAdaptor<Getters, DataStore> = {
  relationshipKindList() {
    return [...this._duidsToRelationshipKinds.values()];
  },
  getRelationshipKindByDuid() {
    return (relationshipKindDuid) => this._duidsToRelationshipKinds.get(relationshipKindDuid);
  },
  getRelationshipKindByKind() {
    return (relationshipKindKind) => {
      const relationshipKind = this.relationshipKindList.filter((e) => e.kind === relationshipKindKind);
      if (relationshipKind.length !== 1) {
        throw new Error(`Expected exactly one relationship kind of kind ${relationshipKindKind}`);
      }
      return relationshipKind[0];
    };
  },
  getParentTask() {
    // TODO make similar helpers and use this in more places
    return (subtask) => {
      const parentTaskRelationship = this.getRelationshipsByKindKind(subtask, RelationshipKindKind.PARENT_OF, false)[0];
      if (!parentTaskRelationship) {
        return undefined;
      }

      return this.getTaskByDuid(parentTaskRelationship.targetDuid);
    };
  },
  getRelationships(state) {
    return (task, options = {}) => {
      const { includeTrashed = false } = options;
      return task.relationships.filter(({ targetDuid }) => {
        const target = [...state._duidsToTasks.values()].find(({ duid }) => targetDuid === duid);
        return target !== undefined && includeTrashed ? true : !target?.inTrash;
      });
    };
  },
  getRelationshipsByKindKind() {
    return (task, relationshipKindKind, forward, options = {}) => {
      const relationshipKindDuid = this.getRelationshipKindByKind(relationshipKindKind).duid;

      return this.getRelationships(task, options).filter(
        (relationship) =>
          relationship.kindDuid === relationshipKindDuid &&
          (forward === undefined || relationship.isForward === forward)
      );
    };
  },
  getAncestorDuids() {
    return (task, options) => {
      const parentKindDuid = this.getRelationshipKindByKind(RelationshipKindKind.PARENT_OF).duid;

      const result: string[] = [];
      const visited = new Set<string>();
      let curr: Task = task;
      // limit linked list traversal to 1000 to avoid while(true) loop
      for (let i = 0; i < 1000; i += 1) {
        const currParentDuid = this.getRelationships(curr, options).find(
          (e) => e.kindDuid === parentKindDuid && !e.isForward
        )?.targetDuid;
        if (!currParentDuid || visited.has(currParentDuid)) {
          break;
        }
        const next = this.getTaskByDuid(currParentDuid);
        if (!next || next.drafterDuid) {
          break;
        }
        result.push(currParentDuid);
        visited.add(currParentDuid);
        curr = next;
      }
      return result.reverse();
    };
  },
  getDescendantDuids() {
    return (task) => {
      const parentKindDuid = this.getRelationshipKindByKind(RelationshipKindKind.PARENT_OF).duid;

      const result: string[] = [];
      const visited = new Set<string>();
      const queue: string[] = [task.duid];
      // limit BFS to 10000 to avoid while(true) loop
      for (let i = 0; i < 10000; i += 1) {
        if (queue.length === 0) {
          break;
        }
        const currDuid = queue.shift() as string;
        if (visited.has(currDuid)) {
          continue;
        }
        result.push(currDuid);
        visited.add(currDuid);
        const curr = this.getTaskByDuid(currDuid);
        if (!curr) {
          continue;
        }
        queue.push(
          ...this.getRelationships(curr)
            .filter((e) => e.kindDuid === parentKindDuid && e.isForward)
            .map((e) => e.targetDuid)
        );
      }
      return result.slice(1);
    };
  },
};

const actions: PiniaActionAdaptor<Actions, DataStore> = {
  changeParent(tasksAndUpdates, newParent, options) {
    const parentKindDuid = this.getRelationshipKindByKind(RelationshipKindKind.PARENT_OF).duid;

    // TODO transactionify
    const updatedTasks: Task[] = [];
    tasksAndUpdates.forEach(({ task, order, partialTask }) => {
      const parentRelationship = this.getRelationshipsByKindKind(task, RelationshipKindKind.PARENT_OF, false)[0];
      const parentChange = (parentRelationship || newParent) && parentRelationship?.targetDuid !== newParent?.duid;
      if (!parentChange && order === task.order && !partialTask) {
        return;
      }
      if (parentChange) {
        updatedTasks.push(task);
      }
      const relationshipDelete =
        parentChange && parentRelationship
          ? { taskDuid: task.duid, relationshipDuid: parentRelationship.duid }
          : undefined;
      const relationshipCreate =
        parentChange && newParent
          ? {
              sourceDuid: newParent.duid,
              targetDuid: task.duid,
              relationshipKindDuid: parentKindDuid,
            }
          : undefined;
      this.deleteAndCreateRelationshipAndUpdateTask(
        relationshipDelete,
        relationshipCreate,
        {
          duid: task.duid,
          order,
          ...(partialTask ?? {}),
        },
        options
      );
    });
  },
  $setRelationshipKinds(relationshipKinds) {
    this._duidsToRelationshipKinds = new Map(relationshipKinds.map((r) => [r.duid, r]));
  },
};

export { actions, getters };
