import { renderToString } from "@vue/server-renderer";
import { computed, h, nextTick, watchEffect } from "vue";

import { backend } from "~/api";
import { UNASSIGNED_ASSIGNEE_LABEL } from "~/components/visualization/constants";
import {
  ADD_ASSIGNEES_COMMAND,
  ADD_ASSIGNEES_KEY,
  CHANGE_ASSIGNEE_COMMAND,
  CHANGE_ASSIGNEE_KEY,
  REMOVE_ASSIGNEES_COMMAND,
  REMOVE_ASSIGNEES_KEY,
} from "~/constants/commandBar";
import { LAYOUT_KIND_TO_NEXT_LAYOUT_KIND, LAYOUT_KIND_TO_PREVIOUS_LAYOUT_KIND } from "~/constants/layout";
import { EXCLUDED_VIEW_KINDS } from "~/constants/page";
import { NUMBER_SIZE_TO_PRETTY_LABEL } from "~/constants/size";
import { DartboardIcon, DashboardIcon, DocIcon, FolderIcon, TaskIcon, ViewIcon } from "~/icons";
import {
  DartboardKind,
  EventKind,
  LayoutKind,
  PageKind,
  Priority,
  SprintMode,
  StatusKind,
  SubtaskDisplayMode,
  Theme,
  UserRole,
  ViewKind,
} from "~/shared/enums";
import { useAppStore, useDataStore, usePageStore, useTenantStore, useUserStore } from "~/stores";
import { getDocLink, getPageDisplayName, getPageLink, getText, run } from "~/utils/common";
import { firework } from "~/utils/fun";
import { getRelativeTimeForDatesDate } from "~/utils/time";

import type { Actions } from "..";

type Command = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (params?: any) => void;
};

let dartboardIcon: string | undefined;
let dashboardIcon: string | undefined;
let docIcon: string | undefined;
let folderIcon: string | undefined;
let taskIcon: string | undefined;
let viewIcon: string | undefined;

run(async () => {
  [dartboardIcon, dashboardIcon, docIcon, folderIcon, taskIcon, viewIcon] = await Promise.all([
    renderToString(h(DartboardIcon)),
    renderToString(h(DashboardIcon)),
    renderToString(h(DocIcon)),
    renderToString(h(FolderIcon)),
    renderToString(h(TaskIcon)),
    renderToString(h(ViewIcon)),
  ]);
});

export default (actions: Actions) => ({
  /** Setup command bar actions. */
  setupCommandBar: () => {
    const appStore = useAppStore();
    const dataStore = useDataStore();
    const tenantStore = useTenantStore();
    const pageStore = usePageStore();
    const userStore = useUserStore();

    let isBooting = true;
    const bar = window.CommandBar;
    bar.boot(
      userStore.duid,
      {
        email: userStore.email,
        name: userStore.name,
        tenantName: tenantStore.name,
        tenantDuid: tenantStore.duid,
      },
      {
        canOpenEditor: tenantStore.isDart,
      }
    );
    bar.setTheme(pageStore.theme);

    const contextualCreate = () => actions.visualization.contextualCreate();

    const toggleTheme = () => {
      const oldThemeDark =
        userStore.theme === Theme.SYSTEM_DEFAULT
          ? window.matchMedia("(prefers-color-scheme: dark)").matches
          : userStore.theme === Theme.DARK;
      userStore.setTheme(oldThemeDark ? Theme.LIGHT : Theme.DARK);
    };

    const toggleSearch = () => appStore.setSearchModalOpen(!appStore.searchModalOpen);

    const toggleSettings = (settingsPage: string) => {
      const router = appStore.$router;
      const routerUtils = appStore.$routerUtils;
      const query = { ...router.currentRoute.value.query };
      const newPage = query.settings !== settingsPage ? settingsPage : undefined;
      router.push(routerUtils.makeLinkToSettingsRef(newPage).value);
    };

    const setLayoutKind = (newKind: LayoutKind) => {
      appStore.setLayoutKind(newKind);
    };

    const goToDartboard = (dartboardDuid: string) => {
      const dartboard = dataStore.getDartboardByDuid(dartboardDuid);
      if (!dartboard) {
        return;
      }
      appStore.$router.push(getPageLink(dartboard, dataStore.getSpaceByDuid));
    };

    const goToDashboard = (dashboardDuid: string) => {
      const dashboard = dataStore.getDashboardByDuid(dashboardDuid);
      if (!dashboard) {
        return;
      }
      appStore.$router.push(getPageLink(dashboard, dataStore.getSpaceByDuid));
    };

    const goToDoc = (link: object) => {
      appStore.$router.push(link);
    };

    const goToFolder = (folderDuid: string) => {
      const folder = dataStore.getFolderByDuid(folderDuid);
      if (!folder) {
        return;
      }
      appStore.$router.push(getPageLink(folder, dataStore.getSpaceByDuid));
    };

    const goToSpace = (spaceDuid: string) => {
      const space = dataStore.getSpaceByDuid(spaceDuid);
      if (!space) {
        return;
      }
      appStore.$router.push(getPageLink(space, dataStore.getSpaceByDuid));
    };

    const goToTask = (taskDuid: string) => {
      actions.visualization.navigateToTask(taskDuid);
    };

    const goToView = (viewDuid: string) => {
      const view = dataStore.getViewByDuid(viewDuid);
      if (!view) {
        return;
      }
      appStore.$router.push(getPageLink(view, dataStore.getSpaceByDuid));
    };

    bar.addArgumentChoices("$fakeArgument", () => {
      if (isBooting) {
        isBooting = false;
        return [];
      }
      backend.event.create(EventKind.USAGE_OPEN_COMMAND_CENTER);
      return [];
    });
    bar.addArgumentChoices("currentAssignees", () => actions.visualization.getCurrentAssignees() ?? []);
    bar.addArgumentChoices("currentTags", () => actions.visualization.getCurrentTags() ?? []);
    bar.addArgumentChoices("possibleAssignees", () => actions.visualization.getPossibleAssignees() ?? []);
    bar.addArgumentChoices("possibleDartboards", () => actions.visualization.getPossibleDartboards() ?? []);
    bar.addArgumentChoices("possiblePriorities", () => actions.visualization.getPossiblePriorities() ?? []);
    bar.addArgumentChoices("possibleSizes", () => actions.visualization.getPossibleSizes());
    bar.addArgumentChoices("possibleStatuses", () => actions.visualization.getPossibleStatuses() ?? []);
    bar.addArgumentChoices("possibleTags", () => actions.visualization.getPossibleTags() ?? []);

    const callbackMap: Command[] = [
      {
        name: "addAssignees",
        callback: (params: { assignees: { key: string }[] }) =>
          actions.visualization.addAssignees(params.assignees.map((e) => e.key)),
      },
      {
        name: "addAttachments",
        callback: () => actions.visualization.addAttachments(),
      },
      {
        name: "addLink",
        callback: () => actions.visualization.addLink(),
      },
      {
        name: "createSubtasks",
        callback: () => {
          if (appStore.currentPage?.kind === ViewKind.TRASH || appStore.currentPage?.kind === DartboardKind.FINISHED) {
            return;
          }
          actions.visualization.createSubtask();
        },
      },
      {
        name: "createTaskQuickly",
        callback: () => actions.visualization.createTaskQuickly(),
      },
      {
        name: "createTaskUnderneath",
        callback: () => {
          if (
            appStore.currentPage?.pageKind === PageKind.VIEW ||
            appStore.currentPage?.kind === DartboardKind.FINISHED
          ) {
            return;
          }
          actions.visualization.createTaskUnderneath();
        },
      },
      {
        name: "copyUrl",
        callback: () => actions.visualization.copyTaskLinks(),
      },
      {
        name: "cycleStatusBlocked",
        callback: () => actions.visualization.cycleStatuses(StatusKind.BLOCKED),
      },
      {
        name: "cycleStatusCanceled",
        callback: () => actions.visualization.cycleStatuses(StatusKind.CANCELED),
      },
      {
        name: "cycleStatusFinished",
        callback: () => actions.visualization.cycleStatuses(StatusKind.FINISHED),
      },
      {
        name: "cycleStatusStarted",
        callback: () => actions.visualization.cycleStatuses(StatusKind.STARTED),
      },
      {
        name: "cycleStatusUnstarted",
        callback: () => actions.visualization.cycleStatuses(StatusKind.UNSTARTED),
      },
      {
        name: "firework",
        callback: firework,
      },
      {
        name: "goToDartboard",
        callback: (params: { dartboard: { id: string } }) => goToDartboard(params.dartboard.id),
      },
      {
        name: "goToDashboard",
        callback: (params: { dashboard: { id: string } }) => goToDashboard(params.dashboard.id),
      },
      {
        name: "goToDoc",
        callback: (params: { doc: { link: object } }) => goToDoc(params.doc.link),
      },
      {
        name: "goToFolder",
        callback: (params: { folder: { id: string } }) => goToFolder(params.folder.id),
      },
      {
        name: "goToSpace",
        callback: (params: { space: { id: string } }) => goToSpace(params.space.id),
      },
      {
        name: "goToTask",
        callback: (params: { task: { id: string } }) => goToTask(params.task.id),
      },
      {
        name: "goToView",
        callback: (params: { view: { id: string } }) => goToView(params.view.id),
      },
      {
        name: "increaseSelectionDown",
        callback: () => actions.visualization.selectRangeDown(),
      },
      {
        name: "increaseSelectionUp",
        callback: () => actions.visualization.selectRangeUp(),
      },
      {
        name: "jumpToDescription",
        callback: () => actions.visualization.jumpToDescription(),
      },
      {
        name: "moveSelectionDown",
        callback: () => actions.visualization.moveSelectionDown(),
      },
      {
        name: "moveSelectionToBottom",
        callback: () => actions.visualization.moveSelectionToBottom(),
      },
      {
        name: "moveSelectionToTop",
        callback: () => actions.visualization.moveSelectionToTop(),
      },
      {
        name: "moveSelectionUp",
        callback: () => actions.visualization.moveSelectionUp(),
      },
      {
        name: "moveTaskDown",
        callback: () => actions.visualization.moveTaskDown(),
      },
      {
        name: "moveTaskToBottom",
        callback: () => actions.visualization.moveTaskToBottom(),
      },
      {
        name: "moveTaskToTop",
        callback: () => actions.visualization.moveTaskToTop(),
      },
      {
        name: "moveTaskUp",
        callback: () => actions.visualization.moveTaskUp(),
      },
      {
        name: "openCloseHighlightedTask",
        callback: () => actions.visualization.selectAndToggleTaskDetail(),
      },
      {
        name: "openDartboard",
        callback: (params) => goToDartboard(params.record.id),
      },
      {
        name: "openDashboard",
        callback: (params) => goToDashboard(params.record.id),
      },
      {
        name: "openDocument",
        callback: (params) => goToDoc(params.record.link),
      },
      {
        name: "openFolder",
        callback: (params) => goToFolder(params.record.id),
      },
      {
        name: "openSpace",
        callback: (params) => goToSpace(params.record.id),
      },
      {
        name: "openTaskInDetail",
        callback: (params) => goToTask(params.record.id),
      },
      {
        name: "openView",
        callback: (params) => goToView(params.record.id),
      },
      {
        name: "recommendProperties",
        callback: () => actions.visualization.recommendProperties(),
      },
      {
        name: "recommendSubtasks",
        callback: () => actions.visualization.recommendSubtasks(),
      },
      {
        name: "replicateTask",
        callback: () => actions.visualization.replicateTasks(),
      },
      {
        name: "removeAssignees",
        callback: (params: { assignees: { key: string }[] }) =>
          actions.visualization.removeAssignees(params.assignees.map((e) => e.key)),
      },
      {
        name: "selectAllAbove",
        callback: () => actions.visualization.selectAllAbove(),
      },
      {
        name: "selectAllBelow",
        callback: () => actions.visualization.selectAllBelow(),
      },
      {
        name: "selectAllVisible",
        callback: () => appStore.getActiveVisualization().selectAll(),
      },
      {
        name: "selectCurrent",
        callback: () => actions.visualization.selectCurrent(),
      },
      {
        name: "sendToDartboard",
        callback: (params: { dartboard: { key: string } }) =>
          actions.visualization.moveTasksToDartboard(params.dartboard.key),
      },
      {
        name: "sendToTrash",
        callback: () => actions.visualization.deleteTask(),
      },
      {
        name: "setAssignee",
        callback: (params: { assignee: { key: string | null } }) =>
          actions.visualization.setAssignee(params.assignee.key),
      },
      {
        name: "setLayoutToNext",
        callback: () => {
          const newKind = LAYOUT_KIND_TO_NEXT_LAYOUT_KIND.get(appStore.layoutKind);
          if (!newKind) {
            return;
          }
          setLayoutKind(newKind);
        },
      },
      {
        name: "setLayoutToPrevious",
        callback: () => {
          const newKind = LAYOUT_KIND_TO_PREVIOUS_LAYOUT_KIND.get(appStore.layoutKind);
          if (!newKind) {
            return;
          }
          setLayoutKind(newKind);
        },
      },
      {
        name: "setLayoutToList",
        callback: () => setLayoutKind(LayoutKind.LIST),
      },
      {
        name: "setLayoutToBoard",
        callback: () => setLayoutKind(LayoutKind.BOARD),
      },
      {
        name: "setLayoutToCalendar",
        callback: () => setLayoutKind(LayoutKind.CALENDAR),
      },
      {
        name: "setLayoutToRoadmap",
        callback: () => setLayoutKind(LayoutKind.ROADMAP),
      },
      {
        name: "setRemindAt",
        callback: (params: { remindAt: Date }) => actions.visualization.setRemindAt(params.remindAt),
      },
      {
        name: "setStatus",
        callback: (params: { status: string }) => actions.visualization.setStatus(params.status),
      },
      {
        name: "setStatusDone",
        callback: () => actions.visualization.setStatusDone(),
      },
      {
        name: "startEditingTitle",
        callback: () => actions.visualization.startEditingTitle(),
      },
      {
        name: "submitFeedback",
        callback: () => appStore.setFeedbackModalOpen(true),
      },
      {
        name: "subtaskIndent",
        callback: () => actions.visualization.subtaskIndent(),
      },
      {
        name: "subtaskOutdent",
        callback: () => actions.visualization.subtaskOutdent(),
      },
      {
        name: "toggleSearch",
        callback: toggleSearch,
      },
      {
        name: "toggleSettingsChatgpt",
        callback: () => toggleSettings("chatgpt"),
      },
      {
        name: "toggleSettingsCli",
        callback: () => toggleSettings("cli"),
      },
      {
        name: "toggleSettingsExport",
        callback: () => toggleSettings("export"),
      },
      {
        name: "toggleSettingsNotifications",
        callback: () => toggleSettings("notifications"),
      },
      {
        name: "toggleSettingsProfile",
        callback: () => toggleSettings("account"),
      },
      {
        name: "toggleShowFilters",
        callback: () => {
          const newOpen = !userStore.getShowFilters();
          userStore.setShowFilters(newOpen);
          if (!newOpen) {
            return;
          }

          nextTick(() => {
            appStore.activeFilterArea?.newFilter();
          });
        },
      },
      {
        name: "undo",
        callback: dataStore.undo,
      },
      {
        name: "redo",
        callback: dataStore.redo,
      },
    ];
    callbackMap.forEach((e) => bar.addCallback(e.name, e.callback));

    bar.addRecords(
      "dartboards",
      () =>
        dataStore.getDartboardList().map((dartboard) => ({
          id: dartboard.duid,
          label: getPageDisplayName(dartboard, dataStore.getSpaceByDuid),
          icon: dartboardIcon,
          description: dartboard.description,
        })),
      { searchableFields: ["description"] }
    );

    bar.addRecords("docs", [], {
      searchableFields: ["folder", "text"],
      onInputChange: async (query) => {
        const { data } = await dataStore.loadDocs({ search: query, order: "-recent" }, 0, 10);
        return data.results.map((doc) => ({
          id: doc.duid,
          label: doc.title,
          icon: docIcon,
          folder: getPageDisplayName(dataStore.getFolderByDuid(doc.folderDuid), dataStore.getSpaceByDuid),
          text: getText(doc.text),
          link: getDocLink(doc),
        }));
      },
    });

    bar.addRecords(
      "folders",
      () =>
        dataStore.getFolderList().map((folder) => ({
          id: folder.duid,
          label: getPageDisplayName(folder, dataStore.getSpaceByDuid),
          icon: folderIcon,
          description: folder.description,
        })),
      { searchableFields: ["description"] }
    );

    const assigneeKey = computed(() => (tenantStore.multipleAssigneesEnabled ? "assignees" : "assignee"));
    bar.addRecords(
      "tasks",
      () =>
        dataStore
          .getTaskList()
          .map(
            ({
              duid,
              dartboardDuid,
              title,
              description,
              statusDuid,
              assigneeDuids,
              tagDuids,
              priority,
              size,
              startAt,
              dueAt,
            }) => {
              const actualAssigneeDuids = tenantStore.multipleAssigneesEnabled
                ? assigneeDuids
                : assigneeDuids.slice(0, 1);
              const assignees =
                actualAssigneeDuids.length > 0
                  ? actualAssigneeDuids.map((e: string) => {
                      const user = dataStore.getUserByDuid(e);
                      if (user === undefined) {
                        return "";
                      }
                      return user.name || user.email;
                    })
                  : UNASSIGNED_ASSIGNEE_LABEL;
              return {
                id: duid,
                label: title,
                icon: taskIcon,
                dartboard: getPageDisplayName(dataStore.getDartboardByDuid(dartboardDuid), dataStore.getSpaceByDuid),
                description: getText(description),
                status: dataStore.getStatusByDuid(statusDuid)?.title ?? "",
                [assigneeKey.value]: assignees,
                tags: dataStore.getOptionsByDuidsOrdered(tagDuids).map((e) => e.title),
                priority: tenantStore.priorityEnabled ? priority : undefined,
                size: tenantStore.sizeEnabled ? NUMBER_SIZE_TO_PRETTY_LABEL.get(size) : undefined,
                startDate: tenantStore.startDateEnabled && startAt ? getRelativeTimeForDatesDate(startAt) : undefined,
                dueDate: tenantStore.dueDateEnabled && dueAt ? getRelativeTimeForDatesDate(dueAt) : undefined,
              };
            }
          ),
      {
        searchableFields: [
          "dartboard",
          "description",
          "status",
          assigneeKey.value,
          "tags",
          "priority",
          "size",
          "dueDate",
        ],
      }
    );

    bar.addRecords(
      "views",
      () =>
        dataStore.viewList
          .filter((e) => !EXCLUDED_VIEW_KINDS.has(e.kind))
          .map((view) => ({
            id: view.duid,
            label: getPageDisplayName(view, dataStore.getSpaceByDuid),
            icon: viewIcon,
            description: view.description,
          })),
      { searchableFields: ["description"] }
    );

    bar.addRecords(
      "dashboards",
      () =>
        dataStore.dashboardList.map((dashboard) => ({
          id: dashboard.duid,
          label: getPageDisplayName(dashboard, dataStore.getSpaceByDuid),
          icon: dashboardIcon,
          description: dashboard.description,
        })),
      { searchableFields: ["description"] }
    );

    watchEffect(() => {
      if (userStore.isRoleGreaterOrEqual(UserRole.ADMIN)) {
        bar.addCallback("toggleSettingsAutomations", () => toggleSettings("automations"));
        bar.addCallback("toggleSettingsBilling", () => toggleSettings("billing"));
        bar.addCallback("toggleSettingsDiscord", () => toggleSettings("discord"));
        bar.addCallback("toggleSettingsGithub", () => toggleSettings("github"));
        bar.addCallback("toggleSettingsIdentity", () => toggleSettings("identity"));
        bar.addCallback("toggleSettingsMail", () => toggleSettings("mail"));
        bar.addCallback("toggleSettingsNotion", () => toggleSettings("notion"));
        bar.addCallback("toggleSettingsPipedream", () => toggleSettings("pipedream"));
        bar.addCallback("toggleSettingsProperties", () => toggleSettings("properties"));
        bar.addCallback("toggleSettingsSlack", () => toggleSettings("slack"));
        bar.addCallback("toggleSettingsWebhooks", () => toggleSettings("webhooks"));
        bar.addCallback("toggleSettingsWorkspace", () => toggleSettings("workspace"));
        bar.addCallback("toggleSettingsZapier", () => toggleSettings("zapier"));
        bar.addCallback("toggleSettingsZohoFlow", () => toggleSettings("zoho-flow"));
      } else {
        bar.removeCallback("toggleSettingsAutomations");
        bar.removeCallback("toggleSettingsBilling");
        bar.removeCallback("toggleSettingsDiscord");
        bar.removeCallback("toggleSettingsGithub");
        bar.removeCallback("toggleSettingsIdentity");
        bar.removeCallback("toggleSettingsMail");
        bar.removeCallback("toggleSettingsNotion");
        bar.removeCallback("toggleSettingsPipedream");
        bar.removeCallback("toggleSettingsProperties");
        bar.removeCallback("toggleSettingsSlack");
        bar.removeCallback("toggleSettingsWebhooks");
        bar.removeCallback("toggleSettingsWorkspace");
        bar.removeCallback("toggleSettingsZapier");
        bar.removeCallback("toggleSettingsZohoFlow");
      }

      if (userStore.isRoleGreaterOrEqual(UserRole.MEMBER)) {
        bar.addCallback("toggleSettingsForms", () => toggleSettings("forms"));
        bar.addCallback("toggleSettingsImport", () => toggleSettings("import"));
        bar.addCallback("toggleSettingsTeammates", () => toggleSettings("teammates"));
      } else {
        bar.removeCallback("toggleSettingsForms");
        bar.removeCallback("toggleSettingsImport");
        bar.removeCallback("toggleSettingsTeammates");
      }
    });

    watchEffect(() => {
      if (appStore.currentPage?.pageKind === PageKind.FOLDER) {
        bar.removeCallback("createTask");
        bar.removeCallback("resumeTaskDraft");
        bar.addCallback("createDoc", contextualCreate);
      } else {
        bar.removeCallback("createDoc");
        if (dataStore.taskDraft) {
          bar.removeCallback("createTask");
          bar.addCallback("resumeTaskDraft", contextualCreate);
        } else {
          bar.removeCallback("resumeTaskDraft");
          bar.addCallback("createTask", contextualCreate);
        }
      }
    });

    watchEffect(() => {
      if (appStore.subtaskDisplayMode === SubtaskDisplayMode.FLAT) {
        bar.removeCallback("hideSubtasks");
        bar.removeCallback("showSubtasks");
      } else {
        bar.addCallback("hideSubtasks", () => actions.visualization.moveSelectionLeft());
        bar.addCallback("showSubtasks", () => actions.visualization.moveSelectionRight());
      }
    });

    watchEffect(() => {
      if (pageStore.theme === "light") {
        bar.removeCallback("toggleThemeToLight");
        bar.addCallback("toggleThemeToDark", toggleTheme);
      } else {
        bar.removeCallback("toggleThemeToDark");
        bar.addCallback("toggleThemeToLight", toggleTheme);
      }
    });

    watchEffect(() => {
      if (tenantStore.backlogEnabled && dataStore.workspaceSpace?.sprintMode === SprintMode.ANBA) {
        bar.addCallback("sendToBacklog", () => {
          const dartboard = dataStore.getWorkspaceDartboardByKind(DartboardKind.BACKLOG);
          if (!dartboard) {
            return;
          }
          actions.visualization.moveTasksToDartboard(dartboard.duid);
        });
      } else {
        bar.removeCallback("sendToBacklog");
      }
    });

    watchEffect(() => {
      if (dataStore.workspaceSpace?.sprintMode === SprintMode.ANBA) {
        bar.addCallbacks({
          sendToActive: () => {
            const dartboard = dataStore.getWorkspaceDartboardByKind(DartboardKind.ACTIVE);
            if (!dartboard) {
              return;
            }
            actions.visualization.moveTasksToDartboard(dartboard.duid);
          },
          sendToNext: () => {
            const dartboard = dataStore.getWorkspaceDartboardByKind(DartboardKind.NEXT);
            if (!dartboard) {
              return;
            }
            actions.visualization.moveTasksToDartboard(dartboard.duid);
          },
        });
      } else {
        bar.removeCallback("sendToActive");
        bar.removeCallback("sendToNext");
      }
    });

    watchEffect(() => {
      let assigneesEnabled;
      let multipleAssigneesEnabled;
      try {
        assigneesEnabled = tenantStore.assigneeEnabled;
        // eslint-disable-next-line prefer-destructuring
        multipleAssigneesEnabled = tenantStore.multipleAssigneesEnabled;
      } catch (error) {
        return;
      }
      if (!assigneesEnabled) {
        bar.removeCommand(ADD_ASSIGNEES_KEY);
        bar.removeCommand(REMOVE_ASSIGNEES_KEY);
        bar.removeCommand(CHANGE_ASSIGNEE_KEY);

        bar.removeCallback("setAssigneeSelf");
        bar.removeCallback("setAssigneeUnassigned");
        return;
      }

      bar.addCallback("setAssigneeSelf", () => actions.visualization.setAssignee(userStore.duid));
      bar.addCallback("setAssigneeUnassigned", () => actions.visualization.setAssignee(null));

      if (multipleAssigneesEnabled) {
        bar.removeCommand(CHANGE_ASSIGNEE_KEY);
        bar.addCommand(ADD_ASSIGNEES_COMMAND);
        bar.addCommand(REMOVE_ASSIGNEES_COMMAND);
      } else {
        bar.removeCommand(ADD_ASSIGNEES_KEY);
        bar.removeCommand(REMOVE_ASSIGNEES_KEY);
        bar.addCommand(CHANGE_ASSIGNEE_COMMAND);
      }
    });

    watchEffect(() => {
      let tagsEnabled;
      try {
        // eslint-disable-next-line prefer-destructuring
        tagsEnabled = tenantStore.tagsEnabled;
      } catch (error) {
        return;
      }
      if (tagsEnabled) {
        bar.addCallback("addTags", (params: { tag: string[] }) => actions.visualization.addTags(params.tag));
        bar.addCallback("removeTags", (params: { tag: string[] }) => actions.visualization.removeTags(params.tag));
      } else {
        bar.removeCallback("addTags");
        bar.removeCallback("removeTags");
      }
    });

    watchEffect(() => {
      let priorityEnabled;
      try {
        // eslint-disable-next-line prefer-destructuring
        priorityEnabled = tenantStore.priorityEnabled;
      } catch (error) {
        return;
      }
      if (priorityEnabled) {
        bar.addCallback("setPriority", (params: { priority: { key: Priority | null } }) =>
          actions.visualization.setPriority(params.priority.key)
        );
        bar.addCallback("setPriorityCritical", () => actions.visualization.setPriority(Priority.CRITICAL));
        bar.addCallback("setPriorityHigh", () => actions.visualization.setPriority(Priority.HIGH));
        bar.addCallback("setPriorityLow", () => actions.visualization.setPriority(Priority.LOW));
        bar.addCallback("setPriorityMedium", () => actions.visualization.setPriority(Priority.MEDIUM));
        bar.addCallback("setPriorityUnprioritized", () => actions.visualization.setPriority(null));
      } else {
        bar.removeCallback("setPriority");
        bar.removeCallback("setPriorityCritical");
        bar.removeCallback("setPriorityHigh");
        bar.removeCallback("setPriorityLow");
        bar.removeCallback("setPriorityMedium");
        bar.removeCallback("setPriorityUnprioritized");
      }
    });

    watchEffect(() => {
      let dueDateEnabled;
      try {
        // eslint-disable-next-line prefer-destructuring
        dueDateEnabled = tenantStore.dueDateEnabled;
      } catch (error) {
        return;
      }
      if (dueDateEnabled) {
        bar.addCallback("setDueAt", (params: { dueAt: Date }) => actions.visualization.setDueAt(params.dueAt));
      } else {
        bar.removeCallback("setDueAt");
      }
    });

    watchEffect(() => {
      let startDateEnabled;
      try {
        // eslint-disable-next-line prefer-destructuring
        startDateEnabled = tenantStore.startDateEnabled;
      } catch (error) {
        return;
      }
      if (startDateEnabled) {
        bar.addCallback("setStartAt", (params: { startAt: Date }) => actions.visualization.setStartAt(params.startAt));
      } else {
        bar.removeCallback("setStartAt");
      }
    });

    watchEffect(() => {
      let sizeEnabled;
      try {
        // eslint-disable-next-line prefer-destructuring
        sizeEnabled = tenantStore.sizeEnabled;
      } catch (error) {
        return;
      }
      if (sizeEnabled) {
        bar.addCallback("setSize", (params: { size: { key: string | null } }) =>
          actions.visualization.setSize(params.size.key)
        );
      } else {
        bar.removeCallback("setSize");
      }
    });

    watchEffect(() => {
      if (pageStore.isMobileApp) {
        bar.removeCallback("downloadMobileApp");
      } else {
        bar.addCallback("downloadMobileApp", () => {
          if (pageStore.isMobile) {
            window.open("/redirect/mobile-app", "_blank"); // TODO split the name and command into get/open in; actually try to open in mobile app--if that fails, do this
          } else {
            appStore.setAppDownloadModalOpen(true);
          }
        });
      }
    });

    watchEffect(() => {
      if (pageStore.isMobile) {
        bar.removeCallback("shortcuts");
      } else {
        bar.addCallback("shortcuts", () => appStore.setShortcutsModalOpen(!appStore.shortcutsModalOpen));
      }
    });

    watchEffect(() => {
      if (pageStore.isMobile) {
        bar.removeCallback("downloadDesktopApp");
        bar.removeCallback("openWebApp");
      } else if (pageStore.isDesktopApp) {
        bar.removeCallback("downloadDesktopApp");
        bar.addCallback("openWebApp", () => window.open(appStore.$router.currentRoute.value.fullPath, "_blank"));
      } else {
        bar.removeCallback("openWebApp");
        bar.addCallback("downloadDesktopApp", () => window.open("/redirect/desktop-app", "_blank"));
      }
    });

    watchEffect(() => {
      if (tenantStore.githubIntegrationEnabled) {
        bar.addCallback("copyBranchName", () => actions.visualization.copyTaskBranchNames());
      } else {
        bar.removeCallback("copyBranchName");
      }
    });
  },
});
