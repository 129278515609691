import type { RouteLocationRaw } from "vue-router";

import { convertToMarkdown, convertToPlainText } from "~/components/text/transformers";
import { EntityName } from "~/shared/enums";
import type { EntityResult, Notification, NotificationUpdate, Page, Status } from "~/shared/types";
import { getCommentLink, getPageLink } from "~/utils/common";
import { notificationComparator } from "~/utils/comparator";

import type { PiniaActionAdaptor, PiniaGetterAdaptor } from "../shared";
import type { DataStore } from ".";

export type Getters = {
  notificationList: Notification[];
  unreadNotificationList: Notification[];
  unreadNotificationCount: number;
  getNotificationByDuid: (duid: string) => Notification | undefined;
  getNotificationsByDuidsOrdered: (duids: string[]) => Notification[];
  _getTenantEntity: () => EntityResult;
  _getUserEntity: (duid: string) => EntityResult | undefined;
  _getCommentEntity: (duid: string) => EntityResult | undefined;
  _getUnknownEntity: () => undefined;
  getEntity(duid: string, name: EntityName): Promise<EntityResult | undefined>;
  getNotificationEntity: (notification: Notification) => Promise<EntityResult | undefined>;
  getCommentEntityLink: (entityResult: EntityResult) => RouteLocationRaw | undefined;
  getEntityLink: (entityResult: EntityResult, entityName: EntityName) => RouteLocationRaw | undefined;
};

export type Actions = {
  /* Update an existing notification. */
  updateNotification: (update: NotificationUpdate, options?: { awaitBackend?: boolean }) => Promise<void>;
  /* Update existing notifications. */
  updateNotifications: (updates: NotificationUpdate[], options?: { awaitBackend?: boolean }) => Promise<void>;
  /** Sort notifications.
   * @PRIVATE */
  $sortNotifications: () => void;
  /** Set notifications internally.
   * @PRIVATE */
  $setNotifications: (notifications: Notification[]) => void;
  /** Create or update notification from WS.
   * @PRIVATE */
  $createOrUpdateNotification: (notification: Notification) => void;
  /** Delete notification from WS.
   * @PRIVATE */
  $deleteNotification: (notification: Notification) => void;
};

const getters: PiniaGetterAdaptor<Getters, DataStore> = {
  notificationList() {
    // TODO incremental, handle clean-up of not found entities
    return [...this._duidsToNotifications.values()];
  },
  unreadNotificationList() {
    // TODO incremental, handle clean-up of not found entities
    return this.notificationList.filter((e) => !e.read);
  },
  unreadNotificationCount() {
    return this.unreadNotificationList.length;
  },
  getNotificationByDuid() {
    return (duid) => this._duidsToNotifications.get(duid);
  },
  getNotificationsByDuidsOrdered() {
    return (duids) => this.notificationList.filter((e) => duids.includes(e.duid));
  },
  _getTenantEntity() {
    return () => {
      const tenant = this.$useTenantStore();

      return {
        title: tenant.name,
        duid: tenant.duid,
      };
    };
  },
  _getUserEntity() {
    return (userDuid) => {
      const user = this.getUserByDuid(userDuid);
      if (!user) {
        return undefined;
      }

      return {
        title: user.name || user.email,
        duid: user.duid,
      };
    };
  },
  _getCommentEntity() {
    return (duid) => {
      const comment = this.getCommentByDuid(duid);
      if (!comment) {
        return undefined;
      }

      return {
        duid: comment.duid,
        title: convertToPlainText(convertToMarkdown(comment.text)) ?? "",
      };
    };
  },
  _getUnknownEntity() {
    return () => undefined;
  },
  getEntity() {
    return async (duid, name) => {
      const ENTITY_NAME_TO_GET_FN_MAP: Record<EntityName, (duid: string) => Promise<EntityResult | undefined>> = {
        [EntityName.COMMENT]: async (e) => this._getCommentEntity(e),
        [EntityName.TASK]: async (e) => this.getTaskByDuid(e),
        [EntityName.DARTBOARD]: async (e) => this.getDartboardByDuid(e),
        [EntityName.DASHBOARD]: async (e) => this.getDashboardByDuid(e),
        [EntityName.VIEW]: async (e) => this.getViewByDuid(e),
        [EntityName.SPACE]: async (e) => this.getSpaceByDuid(e),
        [EntityName.DOC]: this.getDocByDuid,
        [EntityName.FOLDER]: async (e) => this.getFolderByDuid(e),
        [EntityName.FORM]: async (e) => this.getFormByDuid(e),
        [EntityName.STATUS]: async (e) => this.getStatusByDuid(e),
        [EntityName.PROPERTY]: async (e) => this.getPropertyByDuid(e),
        [EntityName.TENANT]: async () => this._getTenantEntity(),
        [EntityName.USER]: async (e) => this._getUserEntity(e),
        // These don't have any FE notifications yet
        [EntityName.BRAINSTORM]: async () => this._getUnknownEntity(),
        [EntityName.UNKNOWN]: async () => this._getUnknownEntity(),
      };

      const getFn = ENTITY_NAME_TO_GET_FN_MAP[name];
      return getFn(duid);
    };
  },
  getNotificationEntity() {
    return async ({ event }) => {
      const { mainEntityName } = event;
      // We still want the task entity for comments
      if (mainEntityName === EntityName.COMMENT) {
        const { taskDuid } = event;
        if (!taskDuid) {
          return undefined;
        }
        return this.getEntity(taskDuid, EntityName.TASK);
      }

      const duidKey = `${mainEntityName.toLowerCase()}Duid` as keyof typeof event;
      const duid = event[duidKey];
      return this.getEntity(duid, mainEntityName);
    };
  },
  getCommentEntityLink() {
    return (entityResult: EntityResult) => {
      const comment = this.getCommentByDuid(entityResult.duid);
      if (!comment) {
        return undefined;
      }

      const task = this.getTaskByDuid(comment.taskDuid);
      if (!task) {
        return undefined;
      }

      return getCommentLink(task, comment);
    };
  },
  getEntityLink() {
    return (entityResult, entityName) => {
      // TODO change this to actually get/have the whole entity so we don't have to cast the entityResult as things that it's definitely not
      if (entityName === EntityName.COMMENT) {
        return this.getCommentEntityLink(entityResult);
      }

      const ENTITY_TO_SETTINGS_LINK_FN_MAP = new Map([
        [EntityName.USER, () => this.$routerUtils.makeLinkToSettingsRef("teammates").value],
        [EntityName.TENANT, () => this.$routerUtils.makeLinkToSettingsRef("workspace").value],
        [EntityName.STATUS, (duid: string) => this.$routerUtils.makeLinkToPropertySettingsRef(duid).value],
        [EntityName.PROPERTY, (duid: string) => this.$routerUtils.makeLinkToPropertySettingsRef(duid).value],
      ]);

      const linkFn = ENTITY_TO_SETTINGS_LINK_FN_MAP.get(entityName);
      if (linkFn !== undefined) {
        const linkDuid = entityName === EntityName.STATUS ? (entityResult as Status).propertyDuid : entityResult.duid;
        return linkFn(linkDuid);
      }

      return getPageLink(entityResult as Page, this.getSpaceByDuid);
    };
  },
};

const actions: PiniaActionAdaptor<Actions, DataStore> = {
  async updateNotification(update, options) {
    this.updateNotifications([update], options);
  },
  async updateNotifications(updates, options = {}) {
    const { awaitBackend = false } = options;
    updates.forEach((update) => {
      const notification = this.getNotificationByDuid(update.duid);
      if (!notification) {
        return;
      }

      Object.assign(notification, update);
    });

    this.$sortNotifications();

    const backendAction = this.$backend.notification.updateMany(updates);
    if (awaitBackend) {
      await backendAction;
    }
  },
  $sortNotifications() {
    this._duidsToNotifications = new Map(
      [...this._duidsToNotifications].sort((a, b) => notificationComparator(a[1], b[1]))
    );
  },
  $setNotifications(notifications) {
    this._duidsToNotifications = new Map(notifications.map((e) => [e.duid, e]));
    this.$sortNotifications();
  },
  $createOrUpdateNotification(notification) {
    // TODO remove this check when we are filtering out spurious updates on BE of WS
    if (notification.userDuid !== this.$useUserStore().duid) {
      return;
    }

    const currentNotification = this.getNotificationByDuid(notification.duid);

    if (!currentNotification) {
      this._duidsToNotifications.set(notification.duid, notification);
    } else {
      Object.assign(currentNotification, notification);
    }

    this.$sortNotifications();
  },
  $deleteNotification(notification) {
    this._duidsToNotifications.delete(notification.duid);
  },
};

export { actions, getters };
