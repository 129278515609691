import { backend, backendOld } from "~/api";
import { copyAndNotify } from "~/components/notifications";
import { EventKind, RelationshipKindKind, TaskSourceType } from "~/shared/enums";
import type { Task } from "~/shared/types";
import { useDataStore, useEnvironmentStore, useUserStore } from "~/stores";
import { isString, makeDuid, slugifyStr, trimSlugStr } from "~/utils/common";
import { getOrdersBetween } from "~/utils/orderManager";

import type { Actions } from "..";

/** Other non-field task actions */
export default (actions: Actions) => ({
  /**
   * Create tasks from text. Returns the last task text or undefined if there is none.
   * @param {ClipboardEvent} event - clipboard event
   * @param {string} dartboardDuid - DUID of dartboard to create tasks in
   * @param {string} aboveTaskOrder - order of the task above the group to create
   * @param {string} belowTaskOrder - order of the task below the group to create
   * @param {object} options - options
   * @returns {string} - remaining text
   */
  createTasksFromPaste: (
    eventOrText: ClipboardEvent | string,
    dartboardDuid: string,
    aboveTaskOrder: string,
    belowTaskOrder: string,
    partialTask?: Partial<Task>,
    options: {
      parentTaskDuid?: string;
      createAll?: boolean;
    } = {}
  ): string | undefined => {
    const { parentTaskDuid, createAll = false } = options;

    const dataStore = useDataStore();

    const parentKindDuid = dataStore.getRelationshipKindByKind(RelationshipKindKind.PARENT_OF).duid;
    const shouldCreateSubtasks = !!parentTaskDuid;

    let finalText;
    if (isString(eventOrText)) {
      finalText = eventOrText;
    } else {
      const pastedText = eventOrText.clipboardData ? eventOrText.clipboardData.getData("text/plain").trim() : "";
      const target = eventOrText.target as HTMLInputElement | HTMLSpanElement;
      const originalText = target instanceof HTMLInputElement ? target.value : target.innerText;
      const selection = getSelection();
      const range = selection?.getRangeAt(0);
      finalText =
        originalText.substring(0, range?.startOffset ?? 0) + pastedText + originalText.substring(range?.endOffset ?? 0);
    }

    const allTaskTexts = finalText.split("\n").filter((e) => e.trim() !== "");
    const remainingText = createAll ? undefined : allTaskTexts.shift();

    const orders = getOrdersBetween(aboveTaskOrder, belowTaskOrder, allTaskTexts.length);
    const titlePrefix = partialTask?.title ? `${partialTask.title} ` : "";
    const taskPartials = allTaskTexts.map((title, i) => ({
      duid: makeDuid(),
      statusDuid: dataStore.defaultDefaultUnstartedStatus.duid,
      dartboardDuid: dartboardDuid ?? "",
      order: orders[i],
      relationships: shouldCreateSubtasks
        ? [
            {
              duid: makeDuid(),
              kindDuid: parentKindDuid,
              targetDuid: parentTaskDuid,
              isForward: false,
            },
          ]
        : [],
      ...partialTask,
      title: titlePrefix + title,
    }));

    if (taskPartials.length > 0) {
      dataStore.createTasks(taskPartials, TaskSourceType.APP_PASTE);
    }

    return remainingText;
  },

  /**
   * Copy the links to tasks to the clipboard
   * @param {Task[]} tasks
   */
  copyLinks: (tasks: Task[]): void => {
    const environmentStore = useEnvironmentStore();

    if (tasks.length === 0) {
      return;
    }

    const content: string[][] = tasks
      .map((e) => [e.title, environmentStore.getTaskUrl(e)])
      .map(([title, url]) => [url, `<a href=${url}>${title}</a>`]);

    const plural = content.length > 1 ? "s" : "";
    copyAndNotify(
      `Link${plural} to task${plural}`,
      content.map((e) => e[0]).join("\n"),
      content.map((e) => e[1]).join("<br />")
    );
    backend.event.create(EventKind.USAGE_COPY_BRANCH, { count: tasks.length });
  },
  /**
   * Copy the branch names for the tasks to the clipboard
   * @param {Task[]} tasks
   */
  copyBranchNames: (tasks: Task[]): void => {
    const userStore = useUserStore();

    const branchNames = tasks.map((task) => {
      // TODO Make this call in bulk
      backendOld.vcs.onBranchLinkCopy(task.duid);
      // TODO allow different calculation methods
      // TODO improve GH username calculation
      const username = slugifyStr(userStore.email.split("@")[0], { lower: true });
      const title = slugifyStr(task.title, { lower: true });
      return trimSlugStr(`${username}/${task.duid}-${title}`, { len: 60 });
    });

    copyAndNotify(`Branch name${branchNames.length > 1 ? "s" : ""}`, branchNames.join("\n"));
    backend.event.create(EventKind.USAGE_COPY_BRANCH);
  },
});
