<script setup lang="ts">
import { computed, nextTick, ref, watch, watchEffect } from "vue";

import Input from "~/components/dumb/Input.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import PageIconPicker from "~/components/dumb/PageIconPicker.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { THROTTLE_MS } from "~/constants/app";
import { IconKind, PageKind, SpaceKind } from "~/shared/enums";
import type { Page, Space, ValidationFunctionResult } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";
import { getEmojiRecommendation } from "~/utils/recommendation";
import { ThrottleManager } from "~/utils/throttleManager";

const DISABLED_STYLE = "pointer-events-none select-none opacity-50";

const props = defineProps<{
  space: Space;
}>();

const appStore = useAppStore();
const dataStore = useDataStore();

const titleInput = ref<InstanceType<typeof Input> | null>(null);

const title = ref(props.space?.title ?? "");
const abbreviation = ref(props.space?.abrev ?? "");
const description = ref(props.space?.description ?? "");

const DEFAULT_NUMERIC_ABREV = "1";

const isGeneralOrPersonalSpace = computed(
  () => props.space.kind === SpaceKind.WORKSPACE || props.space.kind === SpaceKind.PERSONAL
);

const formatAbbreviation = (spaceTitle: string) =>
  spaceTitle
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, "")
    .slice(0, 4);

const updateField = (spaceDuid: string, spacePartial: Partial<Space>) => {
  dataStore.updateSpace({ duid: spaceDuid, ...spacePartial });
};

const generateUniqueAbbreviation = () => {
  if (!title.value.trim()) {
    return "";
  }

  const abbrev = formatAbbreviation(title.value);
  const existingAbbreviations = new Set(
    dataStore.spaceList.filter((space) => space.duid !== props.space.duid && space.abrev).map((space) => space.abrev)
  );

  if (abbrev && !existingAbbreviations.has(abbrev)) {
    return abbrev;
  }

  let index = abbrev ? 2 : 1;
  let newAbbrev = abbrev || DEFAULT_NUMERIC_ABREV;

  while (existingAbbreviations.has(newAbbrev)) {
    if (abbrev) {
      newAbbrev = `${abbrev.slice(0, 4 - index.toString().length)}${index}`;
    } else {
      newAbbrev = `${index}`;
    }
    index += 1;
  }

  return newAbbrev;
};

const saveTitleManager = new ThrottleManager((spaceDuid: string) => {
  updateField(spaceDuid, { title: title.value.trim() });
  if (!props.space.abrev) {
    abbreviation.value = generateUniqueAbbreviation();
    updateField(spaceDuid, { abrev: abbreviation.value });
  }
}, THROTTLE_MS);

const updateTitle = (newValue: string) => {
  if (newValue === title.value) {
    return;
  }
  title.value = newValue.trim() || props.space?.title || "";
  saveTitleManager.run(props.space.duid);
};

const validateAbbreviation = (abrev: string): ValidationFunctionResult => {
  if (abrev.length === 0) {
    return { isValid: false, error: "" };
  }

  const isDuplicateAbbreviation = dataStore.spaceList
    .filter((space) => space.duid !== props.space.duid)
    .some((space) => space.abrev === abrev);

  if (isDuplicateAbbreviation) {
    return { isValid: false, error: "" };
  }

  return { isValid: true };
};

const saveAbrevManager = new ThrottleManager((spaceDuid: string) => {
  if (validateAbbreviation(abbreviation.value).isValid) {
    updateField(spaceDuid, { abrev: abbreviation.value });
  }
}, THROTTLE_MS);

const updateAbbreviation = (newValue: string) => {
  const newAbbrev = formatAbbreviation(newValue);
  if (newAbbrev === abbreviation.value) {
    return;
  }
  abbreviation.value = newAbbrev;
  if (validateAbbreviation(abbreviation.value).isValid) {
    updateField(props.space.duid, { abrev: abbreviation.value });
  }
};

const saveDescriptionManager = new ThrottleManager((spaceDuid: string) => {
  updateField(spaceDuid, { description: description.value.trim() });
}, THROTTLE_MS);

const updateDescription = (newValue: string) => {
  if (newValue === description.value) {
    return;
  }
  description.value = newValue;
  saveDescriptionManager.run(props.space.duid);
};

const getEmojiRecommendationMaybe = async (newValue: string) => {
  if (!props.space || props.space.iconKind !== IconKind.NONE) {
    return;
  }

  saveTitleManager.finish();
  const newTitle = newValue.trim();
  if (newTitle.length === 0) {
    return;
  }

  const spaceDuid = props.space.duid;
  const emojiRecUpdate = await getEmojiRecommendation(spaceDuid, newTitle);
  dataStore.updatePage({ duid: spaceDuid, title: newTitle, ...emojiRecUpdate }, PageKind.SPACE);
};

const focusTitle = () => {
  nextTick(() => {
    if (titleInput.value && props.space.title === "" && !isGeneralOrPersonalSpace.value) {
      titleInput.value.focus();
    }
  });
};

watchEffect(() => {
  if (props.space.duid) {
    focusTitle();
  }
});

const ensureUniqueAbbreviation = (space: Space) => {
  if (!space.abrev || dataStore.spaceList.some((s) => s.duid !== space.duid && s.abrev === space.abrev)) {
    abbreviation.value = generateUniqueAbbreviation();
    updateField(space.duid, { abrev: abbreviation.value });
  }
};

watch(
  () => props.space,
  (newSpace, oldSpace) => {
    if (newSpace.duid === oldSpace?.duid) {
      return;
    }
    saveTitleManager.finish();
    saveAbrevManager.finish();
    saveDescriptionManager.finish();
    title.value = newSpace.title;
    abbreviation.value = newSpace.abrev;
    description.value = newSpace.description;

    ensureUniqueAbbreviation(newSpace);
  },
  { immediate: true }
);

watchEffect(() => {
  if (props.space.abrev === "") {
    updateAbbreviation(props.space.title);
  }
});

watch(
  () => appStore.settingsModalOpen,
  (isOpen) => {
    if (!isOpen && title.value.trim() === "") {
      dataStore.deleteSpace(props.space);
    }
  }
);
</script>

<template>
  <div class="flex flex-col gap-2" :class="isGeneralOrPersonalSpace && DISABLED_STYLE" @click.stop @keydown.stop>
    <div class="font-medium text-md">Basics</div>
    <div class="flex flex-wrap items-center gap-2 lg:flex-row">
      <PageIconPicker :page="space">
        <Tooltip text="Change icon">
          <span class="flex size-9 items-center justify-center rounded border bg-std border-hvy hover:bg-opposite/10">
            <PageIcon :page="space as Page" />
          </span>
        </Tooltip>
      </PageIconPicker>
      <Input
        ref="titleInput"
        :init-value="space.title"
        required
        placeholder="e.g. Marketing"
        label="Title"
       
        class="flex-1"
        @change="updateTitle"
        @finalize="getEmojiRecommendationMaybe" />
      <Input
        :init-value="space.abrev"
        :placeholder="space.title ? formatAbbreviation(space.title) : 'MAR'"
        label="Abbreviation"
        hide-error
        :transform-value="formatAbbreviation"
        :validate="validateAbbreviation"
       
        class="mt-4 w-full lg:mt-0 lg:w-24"
        @finalize="updateAbbreviation" />
    </div>
  </div>
  <div :class="isGeneralOrPersonalSpace && DISABLED_STYLE" class="mt-4">
    <Input
      :init-value="space.description"
      placeholder="Add a description"
      label="Description"
      hide-label-nonempty
      @change="updateDescription" />
  </div>
</template>
