<script setup lang="ts">
import { capitalize, computed } from "vue";

import { backendOld } from "~/api";
import Checkbox from "~/components/dumb/Checkbox.vue";
import RadioCardGroup from "~/components/dumb/RadioCardGroup.vue";
import Toggle from "~/components/dumb/Toggle.vue";
import SettingsRow from "~/components/settings/SettingsRow.vue";
import SettingsSection from "~/components/settings/SettingsSection.vue";
import { EditIcon, UnfavoriteIcon } from "~/icons";
import type { EventSubscription, NotificationSettingEdit } from "~/shared/types";
import { useAppStore, useTenantStore, useUserStore } from "~/stores";

const appStore = useAppStore();
const userStore = useUserStore();
const tenantStore = useTenantStore();

const someMethodEnabled = computed(
  () =>
    userStore.notificationSettings.inApp || userStore.notificationSettings.email || userStore.notificationSettings.slack
);

const sections = computed(() => {
  const categoryMap = new Map();
  appStore.eventKinds.forEach((e) => {
    if (!categoryMap.has(e.category)) {
      categoryMap.set(e.category, []);
    }
    categoryMap.get(e.category).push({ ...e, title: e.subcategory.replace(/_/g, " ") });
  });
  return Array.from(categoryMap.entries()).map(([category, kinds]) => ({ category, kinds }));
});

const toggleIsDefault = (newValue: boolean) => {
  userStore.notificationSettings.default = newValue;
  const edits: NotificationSettingEdit[] = [{ field: "default", value: newValue }];
  // if we are switching to default
  if (newValue) {
    // set default values for in-app, email, and slack (for now, on for email and off for slack)
    if (!userStore.notificationSettings.inApp) {
      userStore.notificationSettings.inApp = true;
      edits.push({ field: "inApp", value: true });
    }
    if (!userStore.notificationSettings.email) {
      userStore.notificationSettings.email = true;
      edits.push({ field: "email", value: true });
    }
    if (userStore.notificationSettings.slack) {
      userStore.notificationSettings.slack = !!tenantStore.slackIntegration;
      edits.push({ field: "slack", value: false });
    }
    // go through all kinds and set them to default
    userStore.notificationSettings.subscriptions.forEach((v, k) => {
      const config = appStore.eventKinds.find((e) => e.id === k);
      if (config === undefined) {
        return;
      }
      if (v.inApp !== config.defaultOn) {
        // eslint-disable-next-line no-param-reassign
        v.inApp = config.defaultOn;
        edits.push({ eventKind: k, field: "inApp", value: config.defaultOn });
      }
      if (v.email !== config.defaultOn) {
        // eslint-disable-next-line no-param-reassign
        v.email = config.defaultOn;
        edits.push({ eventKind: k, field: "email", value: config.defaultOn });
      }
      if (v.slack !== config.defaultOn) {
        // eslint-disable-next-line no-param-reassign
        v.slack = config.defaultOn;
        edits.push({ eventKind: k, field: "slack", value: config.defaultOn });
      }
    });
  }
  backendOld.notifications.edit(edits);
};

const toggleOverallSetting = (field: keyof EventSubscription, newValue: boolean) => {
  userStore.notificationSettings[field] = newValue;
  backendOld.notifications.edit([{ field, value: newValue }]);
};

const toggleSpecificKind = (eventKind: number, field: keyof EventSubscription, newValue: boolean) => {
  const config = userStore.notificationSettings.subscriptions.get(eventKind);
  if (config === undefined) {
    return;
  }
  config[field] = newValue;
  backendOld.notifications.edit([{ eventKind, field, value: newValue }]);
};

const radioCardOptions = computed(() => [
  {
    title: "Recommended settings",
    description: "Stick with the default settings to ensure you never miss an important update and don't get spammed",
    icon: UnfavoriteIcon,
    selected: userStore.notificationSettings.default,
    value: true,
  },
  {
    title: "Custom settings",
    description: "Customize your notification settings to get only the updates you care about",
    icon: EditIcon,
    selected: !userStore.notificationSettings.default,
    value: false,
  },
]);
</script>

<template>
  <div>
    <SettingsSection title="Notification Methods" class="!mt-0">
      <SettingsRow title="In-app" subtitle="Allows you to get in-app notifications about events in Dart">
        <Toggle
          :value="userStore.notificationSettings.inApp"
          @update="(newValue) => toggleOverallSetting('inApp', newValue)" />
      </SettingsRow>

      <SettingsRow title="Email" subtitle="Allows you to get emails about events in Dart">
        <Toggle
          :value="userStore.notificationSettings.email"
          @update="(newValue) => toggleOverallSetting('email', newValue)" />
      </SettingsRow>

      <SettingsRow
        v-if="tenantStore.slackIntegration"
        title="Slack"
        subtitle="Allows you to get instant Slack updates about events in Dart">
        <Toggle
          :value="userStore.notificationSettings.slack"
          @update="(newValue) => toggleOverallSetting('slack', newValue)" />
      </SettingsRow>
    </SettingsSection>

    <SettingsSection title="Notify me about" no-divider>
      <RadioCardGroup :items="radioCardOptions" @select="toggleIsDefault" />
      <template v-if="!userStore.notificationSettings.default">
        <div class="flex flex-col">
          <template v-if="someMethodEnabled">
            <table class="w-full divide-y divide-lt">
              <tbody>
                <tr class="sticky -top-3.5 z-10 bg-std">
                  <th class="h-9 p-0" />
                  <th v-if="userStore.notificationSettings.inApp" class="h-9 p-0">
                    <div class="mb-1 mt-3 flex justify-center text-sm font-normal text-lt">In-app</div>
                  </th>
                  <th v-if="userStore.notificationSettings.email" class="h-9 p-0">
                    <div class="mb-1 mt-3 flex justify-center text-sm font-normal text-lt">Email</div>
                  </th>
                  <th v-if="tenantStore.slackIntegration && userStore.notificationSettings.slack" class="h-9 p-0">
                    <div class="mb-1 mt-3 flex justify-center text-sm font-normal text-lt">Slack</div>
                  </th>
                </tr>
                <template v-for="section in sections" :key="section.category">
                  <tr>
                    <td class="flex h-14 flex-col justify-end py-1 text-lg text-md" colspan="3">
                      {{ capitalize(section.category) }}
                    </td>
                  </tr>

                  <tr v-for="kind in section.kinds" :key="kind.id">
                    <td class="h-8 text-sm text-lt">
                      {{ kind.description }}
                    </td>
                    <td v-if="userStore.notificationSettings.inApp" class="h-8">
                      <Checkbox
                        :value="userStore.notificationSettings.subscriptions.get(kind.id)?.inApp ?? false"
                        :label="`Toggle ${section.category} ${kind.subcategory} in-app notifications`"
                        class="mx-auto"
                        @change="(newValue) => toggleSpecificKind(kind.id, 'inApp', newValue)" />
                    </td>
                    <td v-if="userStore.notificationSettings.email" class="h-8">
                      <Checkbox
                        :value="userStore.notificationSettings.subscriptions.get(kind.id)?.email ?? false"
                        :label="`Toggle ${section.category} ${kind.subcategory} emails`"
                        class="mx-auto"
                        @change="(newValue) => toggleSpecificKind(kind.id, 'email', newValue)" />
                    </td>
                    <td v-if="tenantStore.slackIntegration && userStore.notificationSettings.slack" class="h-8">
                      <Checkbox
                        :value="userStore.notificationSettings.subscriptions.get(kind.id)?.slack ?? false"
                        :label="`Toggle ${section.category} ${kind.subcategory} slacks`"
                        class="mx-auto"
                        @change="(newValue) => toggleSpecificKind(kind.id, 'slack', newValue)" />
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </template>
        </div>
      </template>
    </SettingsSection>
  </div>
</template>
