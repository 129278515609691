<script setup lang="ts">
import moment from "moment";
import { computed, ref, watch } from "vue";

import { backendOld } from "~/api";
import AuthToken from "~/components/AuthToken.vue";
import AvatarUpload from "~/components/AvatarUpload.vue";
import ChangePasswordModal from "~/components/ChangePasswordModal.vue";
import ColorPickerDropdown from "~/components/dumb/ColorPickerDropdown.vue";
import DropdownMenuWithSelection from "~/components/dumb/DropdownMenuWithSelection.vue";
import EditText from "~/components/dumb/EditText.vue";
import Toggle from "~/components/dumb/Toggle.vue";
import SettingsCopyText from "~/components/settings/SettingsCopyText.vue";
import SettingsRow from "~/components/settings/SettingsRow.vue";
import SettingsSection from "~/components/settings/SettingsSection.vue";
import { Theme } from "~/shared/enums";
import { useUserStore } from "~/stores";
import { validateIsNotEmpty } from "~/utils/common";

const userStore = useUserStore();

const selectedTheme = ref(userStore.theme);
const themeOptions = computed(() =>
  Object.values(Theme).map((title) => ({
    title,
    selected: title === selectedTheme.value,
    onClick: () => {
      selectedTheme.value = title;
    },
  }))
);

watch(
  () => userStore.theme,
  (newTheme) => {
    selectedTheme.value = newTheme;
  }
);
watch(
  () => selectedTheme.value,
  (newTheme) => {
    if (!newTheme) {
      return;
    }
    userStore.setTheme(newTheme);
  }
);

const firstDayOfWeekOptions = computed(() =>
  [1, 6, 0].map((day) => ({
    title: moment().weekday(day).format("dddd"),
    selected: userStore.firstDayOfWeek === day,
    onClick: () => {
      userStore.firstDayOfWeek = day;
      backendOld.profile.edit("firstDayOfWeek", day);
    },
  }))
);
const selectedFirstDayOfWeek = computed(() => firstDayOfWeekOptions.value.find((e) => e.selected)?.title);

const onNameChange = (newName: string) => {
  userStore.name = newName;
  backendOld.profile.edit("name", newName);
};

const onColorChange = (newColor: string) => {
  userStore.colorHex = newColor;
  backendOld.profile.edit("colorHex", newColor);
};

const onOpenInNativeAppChange = (newOpenInNativeApp: boolean) => {
  userStore.openInNativeApp = newOpenInNativeApp;
  backendOld.profile.edit("openInNativeApp", newOpenInNativeApp);
};
</script>

<template>
  <div class="w-full">
    <SettingsSection title="Personal information" class="!mt-0">
      <div class="flex gap-4">
        <AvatarUpload kind="profile" />

        <div class="flex flex-col justify-center gap-2">
          <span class="text-sm text-vlt">Full name</span>
          <EditText :value="userStore.name" label="Your full name" :validate="validateIsNotEmpty" @save="onNameChange">
            <div
              :title="userStore.name"
              class="flex w-60 select-none items-center truncate rounded-md border px-3 py-2 text-left text-sm shadow-sm text-md border-md hover:bg-lt">
              <span class="truncate">{{ userStore.name }}</span>
            </div>
          </EditText>
        </div>
      </div>

      <SettingsRow title="Email address">
        <span :title="userStore.email" class="w-60 select-none truncate text-right text-sm font-normal text-md">
          {{ userStore.email }}
        </span>
      </SettingsRow>
      <SettingsRow title="Password">
        <ChangePasswordModal />
      </SettingsRow>
    </SettingsSection>

    <SettingsSection title="Appearance">
      <SettingsRow title="Theme">
        <DropdownMenuWithSelection :options="themeOptions" border is-settings-mode>
          <div class="w-40 select-none rounded px-3 py-1.5 text-left text-sm focus-ring-std hover:bg-lt">
            {{ selectedTheme }}
          </div>
        </DropdownMenuWithSelection>
      </SettingsRow>

      <SettingsRow title="Color" subtitle="Select the color that represents you in Dart">
        <ColorPickerDropdown :value="userStore.colorHex" @select="onColorChange" />
      </SettingsRow>
    </SettingsSection>

    <SettingsSection title="Behavior">
      <SettingsRow title="Open links in app" subtitle="When you click a link to Dart, open it in the app if possible">
        <Toggle :value="userStore.openInNativeApp" @update="onOpenInNativeAppChange" />
      </SettingsRow>
      <SettingsRow title="Start weeks on" subtitle="The first day of the week in your calendars">
        <DropdownMenuWithSelection is-settings-mode :options="firstDayOfWeekOptions" border>
          <div class="w-40 select-none rounded px-3 py-1.5 text-left text-sm shadow-sm focus-ring-std hover:bg-lt">
            {{ selectedFirstDayOfWeek }}
          </div>
        </DropdownMenuWithSelection>
      </SettingsRow>
    </SettingsSection>

    <SettingsSection title="Authentication" no-divider>
      <SettingsRow title="Authentication token" subtitle="Manage your API key, a bearer authentication token">
        <AuthToken />
      </SettingsRow>
      <SettingsRow title="User ID">
        <SettingsCopyText :text="userStore.duid" label="User ID" class="w-72" />
      </SettingsRow>
    </SettingsSection>
  </div>
</template>
