<script setup lang="ts">
import { useElementSize } from "@vueuse/core";
import type {
  IRowNode,
  IsFullWidthRowParams,
  IsGroupOpenByDefaultParams,
  RowGroupOpenedEvent,
  RowHeightParams,
  RowNode,
} from "ag-grid-community";
import { computed, getCurrentInstance, onMounted, onUnmounted, ref, watch } from "vue";

import {
  COLLAPSED_GROUP_CONTAINER_ID,
  CREATE_TASK_ROW_ID,
  GROUP_HEADER_CONTAINER_GROUP_BY_GROUP,
  isCreateTaskRow,
  isFillerRow,
  isGroupContainer,
  isMarginRow,
  MARGIN_ROW_ID,
  UNGROUPED_PSEUDO_GROUP_BY,
} from "~/common/groupBy";
import { getPropertyConfig, getPropertyValueStr } from "~/common/properties";
import PageEmptyState from "~/components/dumb/PageEmptyState.vue";
import { EditorMode, SubtaskDisplayMode } from "~/shared/enums";
import type { RowItem, TaskWithGroup } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { hasClassInPath } from "~/utils/common";

import BaseList from "./BaseList.vue";
import columns from "./columns";
import GroupRootCellRenderer from "./GroupRootCellRenderer.vue";
import { IGNORED_DIV_CLASSES_FOR_DESELECTION } from "./utils";

const COLLAPSED_GROUP_WIDTH = 200;
const COLLAPSED_GROUP_HEIGHT = 80;
const COLLAPSED_GROUP_Y_MARGIN = 48;
const COLLAPSED_GROUP_X_MARGIN = 29;
const COLLAPSED_GROUP_X_GAP = 12;
const COLLAPSED_GROUP_Y_GAP = 12;

const currentInstance = getCurrentInstance();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const listContainer = ref<HTMLElement | null>(null);

const list = ref<InstanceType<typeof BaseList> | null>(null);
const gridApi = computed(() => list.value?.api ?? null);

const listSize = useElementSize(listContainer);

const { columnDefs, defaultColDef, titleColDef } = columns(dataStore);

const columnDefsNorm = computed(() =>
  columnDefs.value.map((e) => {
    const res = { ...e };
    delete res.comparatorFn;
    delete res.getSearchValue;
    return res;
  })
);

const titleColDefNorm = computed(() => {
  const res = { ...titleColDef.value };
  delete res.comparatorFn;
  delete res.getSearchValue;
  return res;
});

const groupBy = computed(() => appStore.groupBy);
const suppressVirtualization = computed(
  () => pageStore.showDebugInfo && pageStore.renderedElementsCounter < pageStore.renderedElementsGoal
);

const isGroupOpenByDefault = (params: IsGroupOpenByDefaultParams<RowItem>) => {
  if (groupBy.value !== UNGROUPED_PSEUDO_GROUP_BY && params.rowNode.data?.isRoot) {
    return !appStore.collapsedGroups.includes(params.rowNode.id ?? "");
  }

  return appStore.subtaskDisplayMode === SubtaskDisplayMode.FLAT || !!dataStore.getTaskByDuid(params.key)?.expanded;
};

const onClick = (event: Event) => {
  if (list.value?.justDragSelected || hasClassInPath(event, IGNORED_DIV_CLASSES_FOR_DESELECTION)) {
    return;
  }

  appStore.getActiveVisualization().deselectAll();
};

const tasks = computed<RowItem[]>(() => {
  const marginRow = {
    id: MARGIN_ROW_ID,
    isRoot: false,
    relationships: [],
    group: "",
  };

  if (groupBy.value === UNGROUPED_PSEUDO_GROUP_BY) {
    const taskRes = appStore.filteredAndSortedTasksInPage.map((e) =>
      Object.assign(e, { id: e.duid, isRoot: false, group: "" })
    );

    if (taskRes.length === 0) {
      return [];
    }

    return [
      ...taskRes,
      {
        id: `${UNGROUPED_PSEUDO_GROUP_BY}/${CREATE_TASK_ROW_ID}`,
        isRoot: false,
        relationships: [],
        group: "",
      },
      marginRow,
    ];
  }

  const { property, groups } = appStore.groupByDefinition;
  const propertyConfig = getPropertyConfig(property.kind);

  const groupRes = groups
    .filter((a) => !new Set(appStore.collapsedGroups).has(getPropertyValueStr(a.value)))
    .map(
      (e) =>
        ({
          ...e,
          id: getPropertyValueStr(e.value),
          isRoot: true,
          relationships: [],
        }) satisfies RowItem
    );

  const createButtonRes = groupRes
    .map((group) =>
      Number(appStore.groupByValueToTasksMap.get(group.id)?.length) > 0
        ? {
            id: `${group.id}/${CREATE_TASK_ROW_ID}`,
            isRoot: false,
            relationships: [],
            group: group.id,
          }
        : undefined
    )
    .filter((e) => e !== undefined) as RowItem[];

  if (appStore.collapsedGroups.length > 0) {
    groupRes.push({
      ...GROUP_HEADER_CONTAINER_GROUP_BY_GROUP,
      isRoot: true,
      relationships: [],
      id: `${COLLAPSED_GROUP_CONTAINER_ID}/${appStore.collapsedGroups.length}/${listSize.width.value}`,
    });
  }

  const tasksForGroups = groupRes.flatMap(
    (group) => appStore.groupByValueToTasksMap.get(getPropertyValueStr(group.value)) ?? []
  );

  const taskRes = tasksForGroups.flatMap((task) => {
    const value = propertyConfig.getValue(property, task);
    const group = getPropertyValueStr(value);

    return Object.assign(task, {
      id: task.duid,
      isRoot: false,
      group,
    });
  });

  return [...groupRes, ...taskRes, ...createButtonRes, marginRow];
});

const getRowId = (params: IRowNode<RowItem>) => params.data?.id;

const isFullWidthRow = (params: IsFullWidthRowParams<RowItem>) =>
  !!params.rowNode.data?.isRoot || !!(params.rowNode.id && isFillerRow(params.rowNode.id));

const getHeightOfContainer = () => {
  const itemsPerRow = Math.floor(
    (listSize.width.value - COLLAPSED_GROUP_X_MARGIN + COLLAPSED_GROUP_X_GAP) /
      (COLLAPSED_GROUP_WIDTH + COLLAPSED_GROUP_X_GAP)
  );
  if (itemsPerRow === 0) {
    return COLLAPSED_GROUP_Y_MARGIN + COLLAPSED_GROUP_HEIGHT;
  }
  const numRows = Math.ceil(appStore.collapsedGroups.length / itemsPerRow);
  return COLLAPSED_GROUP_Y_MARGIN + numRows * (COLLAPSED_GROUP_HEIGHT + COLLAPSED_GROUP_Y_GAP) - COLLAPSED_GROUP_Y_GAP;
};

const getRowHeight = (params: RowHeightParams<RowItem>) =>
  params.data !== undefined && params.node.data?.isRoot
    ? isGroupContainer(params.data.id)
      ? getHeightOfContainer()
      : 101
    : params.data !== undefined && isMarginRow(params.data.id)
      ? 300
      : 37;

const taskDuids = computed(() => new Set(appStore.filteredAndSortedTasksInPage.map((e) => e.duid)));
const getDataPath = (rowItem: RowItem) => {
  if (rowItem.isRoot || isMarginRow(rowItem.id)) {
    return [rowItem.id];
  }

  const isUngrouped = groupBy.value === UNGROUPED_PSEUDO_GROUP_BY;

  if (isCreateTaskRow(rowItem.id)) {
    return [...(isUngrouped ? [] : [rowItem.group]), rowItem.id];
  }

  const task = rowItem as TaskWithGroup;
  const { property } = appStore.groupByDefinition;
  const propertyConfig = getPropertyConfig(property.kind);

  let ancestorDuids = dataStore.getAncestorDuids(task, { includeTrashed: task.inTrash });
  if (!appStore.showAbsentees) {
    const firstAbsent = ancestorDuids.findLastIndex((e) => !taskDuids.value.has(e));
    if (firstAbsent !== -1) {
      ancestorDuids = ancestorDuids.slice(firstAbsent + 1);
    }
  }

  if (isUngrouped || !propertyConfig) {
    return [...ancestorDuids, task.duid];
  }

  const value = propertyConfig.getValue(property, task);
  const firstNotInGroup = ancestorDuids.findLastIndex((e) => {
    const ancestor = dataStore.getTaskByDuid(e);
    if (!ancestor) {
      return true;
    }
    return propertyConfig.getValue(property, ancestor) !== value;
  });
  if (firstNotInGroup !== -1) {
    ancestorDuids = ancestorDuids.slice(firstNotInGroup + 1);
  }

  const isGroupRowRendered = !!gridApi.value?.getRowNode(task.group);
  if (isGroupRowRendered) {
    return [task.group, ...ancestorDuids, task.duid];
  }

  return [...ancestorDuids, task.duid];
};

const onGridReady = () => {
  if (pageStore.isPublicView) {
    return;
  }

  pageStore.pageLoaded = true;
};

const onRowGroupOpened = (event: RowGroupOpenedEvent<RowItem>) => {
  if (!event.data || pageStore.isPublicView || !("duid" in event.data)) {
    return;
  }

  const task = dataStore.getTaskByDuid(event.data.duid);
  const newExpanded = event.expanded;
  if (!task || task.expanded === newExpanded) {
    return;
  }

  dataStore.updateTasks([{ duid: task.duid, expanded: newExpanded }]);
};

const isGroupHidden = (groupId: string) =>
  appStore.hideEmptyGroups && appStore.groupByValueToTasksMap.get(getPropertyValueStr(groupId))?.length === 0;

const groupVisibilityFilter = (node: RowNode<RowItem>) => !node.data?.isRoot || !isGroupHidden(node.data.id);

watch(
  () => appStore.hideEmptyGroups,
  () => {
    gridApi.value?.onFilterChanged();
  }
);

onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appStore.list = (currentInstance?.exposeProxy ?? currentInstance?.exposed ?? null) as any;
});

onUnmounted(() => {
  appStore.list = null;
});

defineExpose({
  api: gridApi,
  width: listSize.width,
});
</script>

<template>
  <div ref="listContainer" class="mx-4 mt-4 h-full" @click="onClick" @keydown.enter="onClick">
    <BaseList
      ref="list"
      :editor-mode="EditorMode.LIST"
      :default-col-def="defaultColDef"
      :columns="columnDefsNorm"
      :tasks="tasks"
      indent-drag
      :get-row-id="getRowId"
      tree-data
      :get-data-path="getDataPath"
      :is-full-width-row="isFullWidthRow"
      :is-group-open-by-default="isGroupOpenByDefault"
      :full-width-cell-renderer="GroupRootCellRenderer"
      :auto-group-column-def="titleColDefNorm"
      :suppress-column-virtualisation="suppressVirtualization"
      :suppress-row-virtualisation="suppressVirtualization"
      :is-external-filter-present="() => appStore.hideEmptyGroups"
      :does-external-filter-pass="groupVisibilityFilter"
      :header-height="37"
      :get-row-height="getRowHeight"
      class="h-full"
      @grid-ready="onGridReady"
      @row-group-opened="onRowGroupOpened">
      <template v-if="appStore.filteredAndSortedTasksInPage.length === 0">
        <div class="absolute inset-x-[17px] bottom-[17px] top-[55px] flex flex-col">
          <template v-if="appStore.groupBy === UNGROUPED_PSEUDO_GROUP_BY">
            <div
              v-for="index in 10"
              :key="index"
              class="h-[37px] w-full border-b border-lt"
              :style="{ opacity: (11 - index) / 10 }" />
          </template>
          <div v-else class="size-full bg-gradient-to-b from-transparent to-[300px] to-std" />
        </div>
        <PageEmptyState :is-filter-mode="appStore.allTasksInPage.length > 0" />
      </template>
    </BaseList>
  </div>
</template>
