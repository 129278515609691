<script setup lang="ts">
import { computed, nextTick } from "vue";

import Button from "~/components/dumb/Button.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { FilterIcon, SortIcon } from "~/icons";
import { ButtonStyle, CommandId, IconSize } from "~/shared/enums";
import { useAppStore, usePageStore, useUserStore } from "~/stores";

const appStore = useAppStore();
const userStore = useUserStore();
const pageStore = usePageStore();

const open = computed(() => userStore.getShowFilters());

const hasSort = computed(() => !!appStore.sorts.length);
const sortContent = computed(() =>
  hasSort.value && !pageStore.isMobile ? appStore.sorts.length.toString() : undefined
);

const hasFilters = computed(() => !!appStore.filters.length);
const filterContent = computed(() =>
  hasFilters.value && !pageStore.isMobile ? appStore.filters.length.toString() : undefined
);

const toggle = async (isSort: boolean) => {
  userStore.setShowFilters(!open.value);
  if (!open.value) {
    return;
  }

  await nextTick();
  if (isSort) {
    appStore.activeFilterArea?.newSort();
  } else {
    appStore.activeFilterArea?.newFilter();
  }
};

const buttonConfigs = computed(() => [
  {
    content: sortContent.value,
    has: hasSort,
    icon: SortIcon,
    command: open.value ? CommandId.TOGGLE_FILTERS_AND_SORTS_TO_HIDDEN : CommandId.TOGGLE_SORTS_TO_SHOWN,
    onClick: () => toggle(true),
  },
  {
    content: filterContent.value,
    has: hasFilters,
    icon: FilterIcon,
    command: open.value ? CommandId.TOGGLE_FILTERS_AND_SORTS_TO_HIDDEN : CommandId.TOGGLE_FILTERS_TO_SHOWN,
    onClick: () => toggle(false),
  },
]);
</script>

<template>
  <Tooltip v-for="(config, index) in buttonConfigs" :key="index" :command-id="config.command">
    <Button
      :btn-style="ButtonStyle.SECONDARY"
      :text="config.content"
      :icon-after="config.icon"
      :icon-size="IconSize.S"
      :icon-after-args="{
        class: config.has ? 'text-primary-base dark:text-primary-base' : '',
      }"
      a11y-label="Toggle showing filter area"
      class="h-6"
      :class="[open ? 'bg-md hover:bg-hvy' : 'hover:bg-lt', pageStore.isMobile ? 'w-6 p-[3px]' : '!px-1']"
      @click="config.onClick" />
  </Tooltip>
</template>
