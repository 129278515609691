<script setup lang="ts">
import type { ApexOptions } from "apexcharts";
import { computed } from "vue";
import ApexCharts from "vue3-apexcharts";

import { colorsByTheme } from "~/constants/style";
import type { StatisticConfigBar, Task } from "~/shared/types";
import { usePageStore } from "~/stores";

import { getSeries } from "../common";
import StatisticNoData from "../StatisticNoData.vue";

const props = defineProps<{
  tasks: Task[];
  width: number;
  height: number;
  config: StatisticConfigBar;
}>();

const pageStore = usePageStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);
const isStacked = computed(() => !!props.config.adtl.stackPropertyDuid);
const args = computed(() => ({
  tasks: props.tasks,
  primaryPropertyDuid: props.config.adtl.xPropertyDuid,
  secondaryPropertyDuid: props.config.adtl.stackPropertyDuid,
  aggregationPropertyDuid: props.config.adtl.aggregationPropertyDuid,
  aggregation: props.config.adtl.aggregation,
}));
const { series, hasData, primaryPropertyOptions: columns, secondaryPropertyOptions: stacks } = getSeries(args);

const options = computed<ApexOptions>(() => ({
  chart: {
    id: props.config.duid,
    type: "bar",
    height: "100%",
    width: "100%",
    stacked: isStacked.value,
    toolbar: {
      show: false,
    },
    events: {
      mounted: (chart) => {
        // Chart is not correct width on first render
        chart.windowResizeHandler();
      },
    },
  },
  plotOptions: {
    bar: {
      distributed: !isStacked.value,
    },
  },
  colors: isStacked.value ? stacks.value.map((e) => e.colorHex) : columns.value.map((e) => e.colorHex),
  legend: {
    show: isStacked.value,
    labels: { colors: colors.value.textLt },
    position: props.height > props.width ? "bottom" : "right",
    horizontalAlign: "right",
    markers: { offsetX: -3, offsetY: 2 },
    itemMargin: { horizontal: 10 },
  },
  xaxis: {
    categories: columns.value.map((e) => e.title),
    labels: {
      style: {
        colors: colors.value.textLt,
      },
    },
  },
  grid: {
    borderColor: colors.value.borderMd,
  },
  yaxis: {
    labels: {
      style: {
        colors: colors.value.textLt,
      },
    },
  },
  tooltip: {
    enabled: true,
    followCursor: true,
  },
}));
</script>

<template>
  <StatisticNoData :has-data="hasData">
    <ApexCharts class="dart-bar-chart" :options="options" :series="series" width="100%" height="100%" />
  </StatisticNoData>
</template>

<style scoped>
.dart-bar-chart {
  :deep(.apexcharts-tooltip) {
    @apply !py-1;
  }
}
</style>
