<script setup lang="ts">
import type { ICellRendererParams } from "ag-grid-community";
import { computed, onMounted } from "vue";

import OptionsEditor from "~/components/options/OptionsEditor.vue";
import TaskClickWrapper from "~/components/visualization/components/TaskClickWrapper.vue";
import { EditorMode } from "~/shared/enums";
import type { PropertyAnyMultiselect, TaskAbsenteeMaybe } from "~/shared/types";
import { useDataStore, usePageStore } from "~/stores";

const props = defineProps<{
  params: ICellRendererParams<TaskAbsenteeMaybe> & {
    property: PropertyAnyMultiselect;
  };
}>();

const dataStore = useDataStore();
const pageStore = usePageStore();

const task = computed(() => (props.params?.data ? dataStore.getTaskByDuid(props.params.data.duid) : undefined));

onMounted(() => {
  pageStore.incrementCellRenderCount();
});
</script>

<template>
  <TaskClickWrapper v-if="task" :task="task" :editor-mode="EditorMode.LIST">
    <template #default="args">
      <OptionsEditor
        v-if="!params.data?.absentee"
        v-bind="args"
        :property="params.property"
        :tasks="[task]"
        :editor-mode="EditorMode.LIST"
        class="h-full" />
    </template>
  </TaskClickWrapper>
</template>
