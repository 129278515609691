import { type Component } from "vue";

import { UNASSIGNED_ASSIGNEE_LABEL, UNASSIGNED_PSEUDO_ASSIGNEE_KEY } from "~/components/visualization/constants";
import { NONNULL_PRIORITY_OPTIONS, PRIORITY_COLOR_MAP } from "~/constants/priority";
import {
  EXPONENTIAL_SIZE,
  FIBONACCI_SIZE,
  LINEAR_SIZE,
  NUMBER_SIZE_TO_PRETTY_LABEL,
  NUMBER_SIZE_TO_TSHIRT_SIZE,
  NUMBER_SIZES,
} from "~/constants/size";
import { DEFAULT_CHIP_COLOR, RECOMMENDATION_COLOR } from "~/constants/style";
import { DART_AI_DISPLAY_VALUE, DART_AI_PSEUDO_USER_KEY } from "~/constants/user";
import {
  CheckedIcon,
  DartAi,
  DueDateFieldIcon,
  MultiselectFieldIcon,
  PriorityIcon,
  SelectFieldIcon,
  SizeFieldIcon,
  StartDateFieldIcon,
  UnassignedIcon,
  UncheckedIcon,
} from "~/icons";
import { IconSize, PropertyKind, SizeFormat } from "~/shared/enums";
import type { PropertyConfigTypeahead, TypeaheadOption } from "~/shared/types";
import type { usePageStore } from "~/stores";
import type { DataStore } from "~/stores/DataStore";
import { getPageDisplayName } from "~/utils/common";
import { getBestDateOptions } from "~/utils/date";
import { getRelativeTimeForDatesDate } from "~/utils/time";

// Initialize
type PageStore = ReturnType<typeof usePageStore>;

let dataStore: DataStore;
let pageStore: PageStore;

let Avatar: Component;
let PageIcon: Component;
let StatusIcon: Component;

export const init = (newDataStore: DataStore, avatar: Component, pageIcon: Component, statusIcon: Component) => {
  dataStore = newDataStore;
  pageStore = dataStore.$usePageStore();

  Avatar = avatar;
  PageIcon = pageIcon;
  StatusIcon = statusIcon;
};

export const KIND_TYPEAHEAD: PropertyConfigTypeahead<PropertyKind.DEFAULT_KIND> = (property) => [
  {
    trigger: property.title.toLowerCase(),
    isMultiple: false,
    getOptions: async () =>
      dataStore.taskKindList.map((kind) => ({
        value: kind.duid,
        label: kind.title,
        property,
        adtlSearchTerms: ["type", property.title],
        chipColorHex: kind.colorHex,
        chipIcon: PageIcon,
        chipIconArgs: { page: kind, size: IconSize.S },
      })),
  },
];

export const DARTBOARD_TYPEAHEAD: PropertyConfigTypeahead<PropertyKind.DEFAULT_DARTBOARD> = (property) => [
  {
    trigger: property.title.toLowerCase(),
    isMultiple: false,
    getOptions: async () =>
      dataStore.getDartboardList({ excludeFinished: true }).map((dartboard) => ({
        value: dartboard.duid,
        label: getPageDisplayName(dartboard, dataStore.getSpaceByDuid),
        property,
        adtlSearchTerms: ["dartboard", property.title],
        icon: PageIcon,
        iconArgs: { page: dartboard },
        chipColorHex: DEFAULT_CHIP_COLOR,
        chipIcon: PageIcon,
        chipIconArgs: { page: dartboard, size: IconSize.S },
      })),
  },
];

export const STATUS_TYPEAHEAD: PropertyConfigTypeahead<PropertyKind.DEFAULT_STATUS | PropertyKind.STATUS> = (
  property
) => [
  {
    trigger: property.title.toLowerCase(),
    isMultiple: false,
    getOptions: async () =>
      dataStore.getStatusList(property).map((status) => ({
        value: status.duid,
        label: status.title,
        property,
        adtlSearchTerms: ["status", status.kind, property.title],
        icon: StatusIcon,
        iconArgs: { class: "icon-md", duid: status.duid, colorHex: status.colorHex },
        chipColorHex: status.colorHex,
        chipIcon: StatusIcon,
        chipIconArgs: { duid: status.duid, isContrast: true },
      })),
  },
];

export const USER_TYPEAHEAD: PropertyConfigTypeahead<PropertyKind.DEFAULT_ASSIGNEES | PropertyKind.USER> = (
  property
) => {
  const options: TypeaheadOption[] = [];

  dataStore.getUserList().forEach((user) => {
    options.push({
      value: user.duid,
      label: user.name || user.email,
      property,
      adtlSearchTerms: [
        property.kind === PropertyKind.DEFAULT_ASSIGNEES ? "assignee" : "user",
        user.email,
        property.title,
      ],
      icon: Avatar,
      iconArgs: {
        abrev: user.abrev,
        circle: true,
        colorHex: user.colorHex,
        hover: false,
        imageUrl: user.imageUrl,
        imgBorder: true,
        class: "icon-md",
      },
      chipColorHex: user.colorHex,
      chipIcon: Avatar,
      chipIconArgs: {
        abrev: user.abrev,
        circle: true,
        colorHex: user.colorHex,
        imageUrl: user.imageUrl,
        imgBorder: true,
        isContrast: pageStore.theme === "light",
      },
    });
  });

  if (property.kind === PropertyKind.DEFAULT_ASSIGNEES) {
    options.push({
      value: DART_AI_PSEUDO_USER_KEY,
      label: DART_AI_DISPLAY_VALUE,
      property,
      adtlSearchTerms: ["assignee"],
      icon: DartAi,
      iconArgs: {
        class: "icon-md",
      },
      chipColorHex: RECOMMENDATION_COLOR,
      chipIcon: DartAi,
      chipIconArgs: {
        class: "icon-md",
      },
    });
    options.push({
      value: UNASSIGNED_PSEUDO_ASSIGNEE_KEY,
      label: UNASSIGNED_ASSIGNEE_LABEL,
      property,
      adtlSearchTerms: ["assignee", "unassigned"],
      icon: UnassignedIcon,
      iconArgs: {
        class: "icon-md",
      },
      chipColorHex: DEFAULT_CHIP_COLOR,
      chipIcon: UnassignedIcon,
      chipIconArgs: {
        class: "icon-md",
      },
    });
  }

  return [
    { trigger: property.title.toLowerCase(), isMultiple: property.adtl.isMultiple, getOptions: async () => options },
  ];
};

export const DATES_TYPEAHEAD: PropertyConfigTypeahead<PropertyKind.DEFAULT_DATES | PropertyKind.DATES> = (property) => {
  const makeOption = (isStart: boolean, dateStr: string, adtlSearchTerms: string[]): TypeaheadOption => ({
    value: dateStr,
    label: getRelativeTimeForDatesDate(dateStr),
    property,
    adtlSearchTerms: ["date", property.title, ...adtlSearchTerms],
    icon: isStart ? StartDateFieldIcon : DueDateFieldIcon,
    iconArgs: {
      class: "icon-md",
    },
    chipIcon: isStart ? StartDateFieldIcon : DueDateFieldIcon,
    chipColorHex: DEFAULT_CHIP_COLOR,
  });

  // TODO reenable this and startDate below when UX is better
  if (property.kind !== PropertyKind.DEFAULT_DATES) {
    return [];
  }

  const propertyTitle = property.title.toLowerCase();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const startDate: ReturnType<PropertyConfigTypeahead<PropertyKind.DEFAULT_DATES | PropertyKind.DATES>>[number] = {
    trigger: `start ${propertyTitle}`,
    isMultiple: false,
    getOptions: async (query) =>
      getBestDateOptions(query.toString()).map(({ dateStr, searchTerms }) =>
        makeOption(true, dateStr, ["start", ...searchTerms])
      ),
  };

  const endDate: ReturnType<PropertyConfigTypeahead<PropertyKind.DEFAULT_DATES | PropertyKind.DATES>>[number] = {
    trigger: property.adtl.isRange
      ? `${property.kind === PropertyKind.DEFAULT_DATES ? "due" : "end"} ${propertyTitle}`
      : propertyTitle,
    isMultiple: false,
    getOptions: async (query) =>
      getBestDateOptions(query.toString()).map(({ dateStr, searchTerms }) =>
        makeOption(false, dateStr, ["end", "due", ...searchTerms])
      ),
  };

  return [endDate];
};

export const PRIORITY_TYPEAHEAD: PropertyConfigTypeahead<PropertyKind.DEFAULT_PRIORITY> = (property) => [
  {
    trigger: property.title.toLowerCase(),
    isMultiple: false,
    getOptions: async () =>
      NONNULL_PRIORITY_OPTIONS.map((priority, i) => ({
        value: priority,
        label: priority.toString(),
        property,
        adtlSearchTerms: ["priority", property.title, `p${i}`],
        icon: PriorityIcon,
        iconArgs: {
          class: "icon-md",
          style: { color: PRIORITY_COLOR_MAP.get(priority) },
        },
        chipColorHex: DEFAULT_CHIP_COLOR,
        chipIcon: PriorityIcon,
        chipIconArgs: {
          style: { color: PRIORITY_COLOR_MAP.get(priority) },
        },
      })),
  },
];

export const SIZE_TYPEAHEAD: PropertyConfigTypeahead<PropertyKind.DEFAULT_SIZE> = (property) => {
  const getSizeOptions = (sizes: number[]): TypeaheadOption[] =>
    sizes.map((size) => ({
      value: size,
      label: size.toString(),
      property,
      adtlSearchTerms: ["size", property.title, size.toString()],
      icon: SizeFieldIcon,
      iconArgs: {
        class: "icon-md",
      },
      chipColorHex: DEFAULT_CHIP_COLOR,
    }));

  return [
    {
      trigger: property.title.toLowerCase(),
      isMultiple: false,
      getOptions: async (query) => {
        switch (property.adtl.format) {
          case SizeFormat.LINEAR: {
            return getSizeOptions(LINEAR_SIZE);
          }
          case SizeFormat.FIBONACCI: {
            return getSizeOptions(FIBONACCI_SIZE);
          }
          case SizeFormat.EXPONENTIAL: {
            return getSizeOptions(EXPONENTIAL_SIZE);
          }
          case SizeFormat.FREE_ENTRY: {
            const number = parseInt(query.toString(), 10);
            return Number.isNaN(number) ? [] : getSizeOptions([number]);
          }
          default: {
            return NUMBER_SIZES.filter((e): e is number => e !== null).map((size) => {
              const label = NUMBER_SIZE_TO_TSHIRT_SIZE.get(size) ?? "";
              const prettyLabel = NUMBER_SIZE_TO_PRETTY_LABEL.get(size) ?? "";

              return {
                value: size,
                label,
                property,
                adtlSearchTerms: ["size", property.title, size.toString(), prettyLabel],
                icon: SizeFieldIcon,
                iconArgs: {
                  class: "icon-md",
                },
                chipColorHex: DEFAULT_CHIP_COLOR,
              };
            });
          }
        }
      },
    },
  ];
};

export const SELECT_TYPEAHEAD: PropertyConfigTypeahead<PropertyKind.SELECT> = (property) => [
  {
    trigger: property.title.toLowerCase(),
    isMultiple: false,
    getOptions: async () =>
      dataStore.getOptionList(property).map((option) => ({
        value: option.duid,
        label: option.title,
        property,
        adtlSearchTerms: ["option", property.title],
        icon: SelectFieldIcon,
        iconArgs: {
          class: "icon-md",
          style: { color: option.colorHex },
        },
        chipColorHex: option.colorHex,
      })),
  },
];

export const MULTISELECT_TYPEAHEAD: PropertyConfigTypeahead<PropertyKind.DEFAULT_TAGS | PropertyKind.MULTISELECT> = (
  property
) => [
  {
    trigger: property.title.toLowerCase(),
    isMultiple: true,
    getOptions: async () =>
      dataStore.getOptionList(property).map((option) => ({
        value: option.duid,
        label: option.title,
        property,
        adtlSearchTerms: [property.kind === PropertyKind.DEFAULT_TAGS ? "tag" : "option", property.title],
        icon: MultiselectFieldIcon,
        iconArgs: {
          class: "icon-md",
          style: { color: option.colorHex },
        },
        chipColorHex: option.colorHex,
      })),
  },
];

export const CHECKBOX_TYPEAHEAD: PropertyConfigTypeahead<PropertyKind.CHECKBOX> = (property) => [
  {
    trigger: property.title.toLowerCase(),
    isMultiple: false,
    getOptions: async () => [
      {
        value: true,
        label: "Checked",
        property,
        adtlSearchTerms: [property.title, "true", "t"],
        icon: CheckedIcon,
        iconArgs: { class: "icon-md" },
        chipColorHex: DEFAULT_CHIP_COLOR,
      },
      {
        value: false,
        label: "Unchecked",
        property,
        adtlSearchTerms: [property.title, "false", "f"],
        icon: UncheckedIcon,
        iconArgs: { class: "icon-md" },
        chipColorHex: DEFAULT_CHIP_COLOR,
      },
    ],
  },
];
