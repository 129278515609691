import { useMediaQuery } from "@vueuse/core";
import { computed, hasInjectionContext, inject } from "vue";

import { getShownPropertyWithConfigList } from "~/common/properties";
import {
  CONFIG_HEADER_RENDERER,
  DARTBOARD_CELL_EDITOR,
  DUID_CELL_EDITOR,
  NEW_TASK_HEADER_RENDERER,
  ORDER_CELL_EDITOR,
  STANDARD_HEADER_RENDERER,
  TITLE_CELL_EDITOR,
} from "~/constants/injection";
import {
  CONFIG_COLUMN_DEF,
  CONFIG_PROPERTY_PSUEDO_DUID,
  DUID_COLUMN_DEF,
  DUID_PROPERTY_PSUEDO_DUID,
  ORDER_COLUMN_DEF,
} from "~/constants/property";
import { DartboardKind, EditorMode, LayoutKind, PageKind, PropertyKind, ViewKind } from "~/shared/enums";
import type { ExtendedColDef, Task } from "~/shared/types";
import type { DataStore } from "~/stores/DataStore";
import { getPageDisplayName, getText } from "~/utils/common";
import { makeDartboardComparator, makePropertyComparator, stringComparator } from "~/utils/comparator";

import { listValueGetter } from "./common";

const columns = (dataStore: DataStore) => {
  const appStore = dataStore.$useAppStore();
  const pageStore = dataStore.$usePageStore();

  const canInject = hasInjectionContext();
  const isPrinting = useMediaQuery("print");

  const DartboardCellEditor = canInject ? inject(DARTBOARD_CELL_EDITOR) : undefined;
  const DuidCellEditor = canInject ? inject(DUID_CELL_EDITOR) : undefined;
  const OrderCellEditor = canInject ? inject(ORDER_CELL_EDITOR) : undefined;
  const TitleCellEditor = canInject ? inject(TITLE_CELL_EDITOR) : undefined;
  const ConfigHeaderRenderer = canInject ? inject(CONFIG_HEADER_RENDERER) : undefined;
  const NewTaskHeaderRenderer = canInject ? inject(NEW_TASK_HEADER_RENDERER) : undefined;
  const StandardHeaderRenderer = canInject ? inject(STANDARD_HEADER_RENDERER) : undefined;

  const properties = computed(() =>
    getShownPropertyWithConfigList().map(([property, config]) => ({
      property,
      config,
      values: config.listColumn(property),
    }))
  );

  const defaultColDef = computed<ExtendedColDef>(() => ({
    headerComponent: StandardHeaderRenderer,
    cellStyle: {
      fontSize: "14px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textOverflow: "clip",
    },
    lockPinned: true,
    resizable: appStore.layoutKind === LayoutKind.LIST && !pageStore.isMobile && !pageStore.isPublicView,
    valueGetter: listValueGetter,
  }));

  const isAnyView = computed(() => appStore.currentPage?.pageKind === PageKind.VIEW);
  const titleHeaderComponent = computed(() => {
    if (appStore.currentPage?.pageKind === PageKind.VIEW || appStore.currentPage?.kind === DartboardKind.FINISHED) {
      return StandardHeaderRenderer;
    }
    return NewTaskHeaderRenderer;
  });

  const titleColDef = computed<ExtendedColDef<string>>(() => {
    const property = dataStore.defaultTitleProperty;
    return {
      field: property.duid as keyof Task,
      headerName: "Title",
      headerComponentParams: {
        property,
      },
      headerComponent: titleHeaderComponent.value,
      cellRenderer: TitleCellEditor,
      cellRendererParams: {
        editorMode: EditorMode.LIST,
      },
      suppressMovable: true,
      lockPosition: "left",
      rowDrag: pageStore.hasTouch,
      sortable: true,
      comparatorFn: (a, b) => stringComparator(a.toLowerCase(), b.toLowerCase()),
      suppressHeaderMenuButton: true,
      suppressKeyboardEvent: () => true,
      ...appStore.getColumnWidthParams(property.duid),
      // Return $duid $title $description
      getSearchValue: (value, task) => `${task.duid} ${value} ${getText(task.description)}`,
    };
  });

  const columnDefs = computed(() => {
    const dartboardProperty = dataStore.defaultDartboardProperty;
    const dartboardHeaderName = appStore.currentPage?.kind === ViewKind.TRASH ? "Former dartboard" : "Dartboard";

    const res: ExtendedColDef[] = [
      {
        ...DUID_COLUMN_DEF,
        hide: pageStore.isMobile || !pageStore.showDebugInfo,
        cellRenderer: DuidCellEditor,
        ...appStore.getColumnWidthParams(DUID_PROPERTY_PSUEDO_DUID),
      },
      {
        ...ORDER_COLUMN_DEF,
        hide: pageStore.isMobile || !pageStore.showDebugInfo,
        cellRenderer: OrderCellEditor,
        ...appStore.getColumnWidthParams(DUID_PROPERTY_PSUEDO_DUID),
      },
      {
        field: dartboardProperty.duid as keyof Task,
        headerName: dartboardHeaderName,
        headerComponentParams: {
          property: dartboardProperty,
        },
        hide: pageStore.isMobile || !isAnyView.value || pageStore.isPublicView,
        cellRenderer: DartboardCellEditor,
        sortable: true,
        comparatorFn: (a: string, b: string) =>
          makeDartboardComparator(dataStore.getSpaceByDuid)(
            dataStore.getDartboardByDuid(a),
            dataStore.getDartboardByDuid(b)
          ),
        suppressHeaderMenuButton: true,
        ...appStore.getColumnWidthParams(dartboardProperty.duid),
        getSearchValue: (value: string) =>
          getPageDisplayName(dataStore.getDartboardByDuid(value), dataStore.getSpaceByDuid),
      },
    ];

    // Other properties
    res.push(
      ...properties.value.map(({ property, config, values }) => {
        const isDefaultStatus = property.kind === PropertyKind.DEFAULT_STATUS;
        return {
          ...values,
          field: property.duid as keyof Task,
          headerName: property.title,
          suppressMovable: isDefaultStatus,
          lockPosition: config.lockPosition,
          headerComponentParams: {
            property,
          },
          cellRendererParams: {
            property,
            ...values.cellRendererParams,
          },
          ...appStore.getColumnWidthParams(property.duid),
        };
      })
    );
    const comparator = makePropertyComparator(appStore.propertyOrderDuids);
    res.sort((a, b) => comparator(a.field, b.field));
    if (!isPrinting.value && !pageStore.isMobile && !pageStore.isPublicView) {
      res.push({
        ...CONFIG_COLUMN_DEF,
        headerComponent: ConfigHeaderRenderer,
        ...appStore.getColumnWidthParams(CONFIG_PROPERTY_PSUEDO_DUID),
      });
    }
    return res;
  });

  return { columnDefs, defaultColDef, titleColDef };
};

export default columns;
