<script setup lang="ts">
import { watchOnce } from "@vueuse/core";
import type { SerializedEditorState } from "lexical";
import { computed, nextTick, ref, watch } from "vue";

import { backendOld } from "~/api";
import Button from "~/components/dumb/Button.vue";
import Modal from "~/components/dumb/Modal.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { notify } from "~/components/notifications";
import { ButtonSize, ButtonStyle, CommandId, ModalWidth, NotificationType } from "~/shared/enums";
import { useAppStore } from "~/stores";
import { isLexicalStateEmpty, makeEmptyLexicalState } from "~/utils/common";

import { DEFAULT_EDITOR_TELEPORT_KEY } from "./text/const";
import DefaultEditor from "./text/DefaultEditor.vue";

const appStore = useAppStore();

const editorRef = ref<InstanceType<typeof DefaultEditor> | null>(null);

const text = ref<SerializedEditorState>(makeEmptyLexicalState());
const canSubmit = computed(() => !isLexicalStateEmpty(text.value));
const submitting = ref(false);
const closeModal = () => appStore.setFeedbackModalOpen(false);

const submit = () => {
  if (!canSubmit.value) {
    return;
  }

  backendOld.profile.contactSupport(text.value);
  text.value = makeEmptyLexicalState();
  closeModal();
  notify({
    message: "Thanks for the message! We'll get back to you as soon as possible.",
    type: NotificationType.SUCCESS,
  });
};

const onChange = (newText: SerializedEditorState) => {
  text.value = newText;
};

watch(
  () => appStore.feedbackModalOpen,
  (open) => {
    if (open) {
      watchOnce(editorRef, (newElement) => {
        if (newElement) {
          nextTick(() => newElement.focus());
        }
      });
    }
  }
);
</script>

<template>
  <Modal
    :entity="appStore.feedbackModalOpen"
    title="Share feedback"
    :width="ModalWidth.L"
    selected
    description="Tell us about any and all problems, ideas, and questions you have!"
    @close="closeModal">
    <template #default>
      <div :data-toolbar="DEFAULT_EDITOR_TELEPORT_KEY" class="-mx-1.5 overflow-y-auto pt-2">
        <DefaultEditor
          ref="editorRef"
          namespace="feedback"
          placeholder="What's on your mind?"
          :toolbar-teleport-key="DEFAULT_EDITOR_TELEPORT_KEY"
          :initial-state="text"
          :disabled="submitting"
          @change="onChange"
          @action-enter="submit" />
      </div>
    </template>

    <template #actions>
      <Tooltip :command-id="CommandId.SHARE_FEEDBACK">
        <Button
          :btn-style="ButtonStyle.PRIMARY"
          text="Share"
          :disabled="!canSubmit"
          :working="submitting"
          :size="ButtonSize.SMALL"
          @click="submit" />
      </Tooltip>
    </template>

    <template #footerLeft>
      <div class="flex select-none items-center text-xs text-lt">
        Or, email
        <a class="ml-1 select-text text-md hover:underline" href="mailto:support@itsdart.com?subject=Feedback">
          support@itsdart.com
        </a>
      </div>
    </template>
  </Modal>
</template>
