<script setup lang="ts">
import { computed } from "vue";

import { backendOld } from "~/api";
import Avatar from "~/components/dumb/Avatar.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import UpgradeConfirmationDialog from "~/components/dumb/UpgradeConfirmationDialog.vue";
import { CheckVerifiedIcon, DotsHorizontalIcon, OpenExternalLinkIcon, ZapierTextIcon } from "~/icons";
import { DropdownMenuItemKind, Entitlement, Placement } from "~/shared/enums";
import type { DropdownMenuItem, User } from "~/shared/types";
import { useDataStore, useEnvironmentStore, useTenantStore, useUserStore } from "~/stores";

const dataStore = useDataStore();
const environmentStore = useEnvironmentStore();
const tenantStore = useTenantStore();
const userStore = useUserStore();

const upgradeRequired = computed(() => !tenantStore.getEntitlementValue(Entitlement.ZAPIER));

const integrationExists = computed(() => !!tenantStore.zapierIntegration);
const integrationEnabled = computed(() => tenantStore.zapierIntegration?.enabled);
const integrationLinked = computed(
  () =>
    tenantStore.zapierIntegration?.linkedUserDuids &&
    tenantStore.zapierIntegration?.linkedUserDuids.includes(userStore.duid)
);

const link = () => {
  if (upgradeRequired.value) {
    return;
  }

  let url = environmentStore.isProd
    ? "https://zapier.com/developer/public-invite/202324/3d6dc32ffd0d5562e2ac66bf1c4fe6eb/"
    : "https://zapier.com/developer/public-invite/201641/026c88277676fde812721845e6438187/";

  if (integrationLinked.value) {
    url = "https://zapier.com/app/zaps";
  }

  window.open(url, "_blank");
};

const dropdownItems = computed(() => {
  const items: DropdownMenuItem[] = [
    {
      title: integrationEnabled.value ? "Disable integration" : "Enable integration",
      kind: DropdownMenuItemKind.BUTTON,
      onClick: () => {
        if (!tenantStore.zapierIntegration) {
          return;
        }
        tenantStore.zapierIntegration.enabled = !integrationEnabled.value;
        backendOld.zapier.edit("enabled", !integrationEnabled.value);
      },
    },
  ];
  return [
    {
      title: "Configure",
      items,
    },
  ];
});

const linkedUsers = computed(() => {
  if (!tenantStore.zapierIntegration?.linkedUserDuids) {
    return [];
  }

  return tenantStore.zapierIntegration.linkedUserDuids
    .map((duid) => dataStore.getUserByDuid(duid))
    .filter((e): e is User => !!e);
});
</script>

<template>
  <div class="flex flex-col gap-y-16">
    <div class="flex flex-col justify-center space-y-3">
      <div class="flex justify-between">
        <div class="flex items-center gap-4">
          <h2 class="select-none text-xl text-md">Zapier integration</h2>
          <UpgradeConfirmationDialog v-if="upgradeRequired" feature-action="use the Zapier integration" />
          <div
            v-if="integrationEnabled && integrationLinked"
            class="flex h-[26px] select-none items-center gap-1 rounded bg-primary-base px-1">
            <CheckVerifiedIcon class="text-oncolor icon-sm" />
            <span class="text-xs text-oncolor">Connected</span>
          </div>
        </div>
        <DropdownMenu v-if="integrationExists" :sections="dropdownItems" :placement="Placement.BOTTOM_RIGHT">
          <Tooltip text="Configure integration">
            <button type="button" class="flex items-center rounded p-0.5 text-lt focus-ring-std hover:bg-md">
              <span class="sr-only">Configure integration</span>
              <DotsHorizontalIcon class="icon-sm" />
            </button>
          </Tooltip>
        </DropdownMenu>
      </div>
      <p class="select-none text-sm/relaxed text-lt">
        Integrate Zapier with Dart to automate your workflows and connect Dart with thousands of other apps.
      </p>
      <p v-if="integrationEnabled && integrationLinked" class="select-none text-sm/relaxed text-lt">
        You can now listen to events in Dart and trigger actions in other apps, like creating a spreadsheet row in
        Google Sheets.
      </p>
    </div>

    <UpgradeConfirmationDialog feature-action="use the Zapier integration" :disabled="!upgradeRequired">
      <button
        type="button"
        class="flex select-none items-center justify-center gap-2 self-center rounded border px-3 py-2 text-center text-base shadow-sm text-md border-md focus-ring-std hover:bg-lt"
        @click="link">
        <ZapierTextIcon class="h-4 w-12" />
        {{ integrationLinked ? "View your Zaps" : "Link Dart and Zapier" }}
        <OpenExternalLinkIcon class="icon-md" />
      </button>
    </UpgradeConfirmationDialog>

    <div v-if="linkedUsers.length > 0" class="flex flex-col gap-4">
      <div class="flex select-none flex-col">
        <span class="font-semibold text-md">Linked users</span>
        <span class="text-xs text-lt">Users who have linked their Dart account with Zapier</span>
      </div>
      <div class="flex flex-col gap-3">
        <div v-for="user in linkedUsers" :key="user.duid" class="flex items-center gap-2 overflow-hidden">
          <Avatar
            :abrev="user.abrev"
            circle
            :color-hex="user.colorHex"
            :image-url="user.imageUrl"
            img-border
            class="icon-lg" />
          <span :title="user.name || user.email" class="truncate text-md">
            {{ user.name || user.email }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
