import { NumberFormat, PropertyKind, SizeFormat, StatusKind } from "~/shared/enums";
import type {
  Property,
  PropertyDefaultAssignees,
  PropertyDefaultAttachments,
  PropertyDefaultCreatedAt,
  PropertyDefaultCreatedBy,
  PropertyDefaultDartboard,
  PropertyDefaultDates,
  PropertyDefaultDescription,
  PropertyDefaultKind,
  PropertyDefaultPriority,
  PropertyDefaultSize,
  PropertyDefaultStatus,
  PropertyDefaultTags,
  PropertyDefaultTimeTracking,
  PropertyDefaultTitle,
  PropertyDefaultUpdatedAt,
  PropertyDefaultUpdatedBy,
  PropertyUpdate,
  Status,
} from "~/shared/types";
import { makeRandomColorHex } from "~/utils/color";
import { makeDuid } from "~/utils/common";
import { propertyComparator } from "~/utils/comparator";

import type { PiniaActionAdaptor, PiniaGetterAdaptor } from "../shared";
import type { DataStore } from ".";

export type Getters = {
  propertyList: Property[];
  getPropertyByDuid: (duid: string) => Property | undefined;
  getPropertyByKind: (kind: PropertyKind) => Property[];
  defaultKindProperty: PropertyDefaultKind;
  defaultTitleProperty: PropertyDefaultTitle;
  defaultDescriptionProperty: PropertyDefaultDescription;
  defaultDartboardProperty: PropertyDefaultDartboard;
  defaultStatusProperty: PropertyDefaultStatus;
  defaultAssigneesProperty: PropertyDefaultAssignees;
  defaultDatesProperty: PropertyDefaultDates;
  defaultPriorityProperty: PropertyDefaultPriority;
  defaultTagsProperty: PropertyDefaultTags;
  defaultSizeProperty: PropertyDefaultSize;
  defaultTimeTrackingProperty: PropertyDefaultTimeTracking;
  defaultAttachmentsProperty: PropertyDefaultAttachments;
  defaultCreatedAtProperty: PropertyDefaultCreatedAt;
  defaultCreatedByProperty: PropertyDefaultCreatedBy;
  defaultUpdatedAtProperty: PropertyDefaultUpdatedAt;
  defaultUpdatedByProperty: PropertyDefaultUpdatedBy;
};

export type Actions = {
  /* Create a new property. */
  createProperty: (
    kind: PropertyKind,
    order: string,
    partialProperty?: Partial<Property>,
    options?: { awaitBackend?: boolean }
  ) => Promise<Property>;
  /* Update an existing property. */
  updateProperty: (update: PropertyUpdate, options?: { awaitBackend?: boolean }) => Promise<Property | undefined>;
  /** Delete a property. */
  deleteProperty: (property: Property, options?: { awaitBackend?: boolean }) => Promise<void>;
  /** Sort properties.
   * @PRIVATE */
  $sortProperties: () => void;
  /** Set properties internally.
   * @PRIVATE */
  $setProperties: (properties: Property[]) => void;
  /** Create or update property from WS.
   * @PRIVATE */
  $createOrUpdateProperty: (property: Property) => void;
  /** Delete property from WS.
   * @PRIVATE */
  $deleteProperty: (property: Property) => void;
};

const findPropertyOrThrow = <T extends PropertyKind>(
  dataStore: { propertyList: Property[] },
  kind: T
): Property & { kind: T } => {
  const res = dataStore.propertyList.find((e): e is Property & { kind: T } => e.kind === kind);
  if (!res) {
    // TODO reenable when we we figure out the root cause of all of the noise
    // throw new Error(`No ${kind} property found`);
    return { adtl: {} } as never;
  }
  return res;
};

const getters: PiniaGetterAdaptor<Getters, DataStore> = {
  propertyList() {
    return [...this._duidsToProperties.values()];
  },
  getPropertyByDuid() {
    return (duid) => this._duidsToProperties.get(duid);
  },
  getPropertyByKind() {
    return (kind) => this.propertyList.filter((e) => e.kind === kind);
  },
  defaultKindProperty() {
    return findPropertyOrThrow(this, PropertyKind.DEFAULT_KIND);
  },
  defaultTitleProperty() {
    return findPropertyOrThrow(this, PropertyKind.DEFAULT_TITLE);
  },
  defaultDescriptionProperty() {
    return findPropertyOrThrow(this, PropertyKind.DEFAULT_DESCRIPTION);
  },
  defaultDartboardProperty() {
    return findPropertyOrThrow(this, PropertyKind.DEFAULT_DARTBOARD);
  },
  defaultStatusProperty() {
    return findPropertyOrThrow(this, PropertyKind.DEFAULT_STATUS);
  },
  defaultAssigneesProperty() {
    return findPropertyOrThrow(this, PropertyKind.DEFAULT_ASSIGNEES);
  },
  defaultDatesProperty() {
    return findPropertyOrThrow(this, PropertyKind.DEFAULT_DATES);
  },
  defaultPriorityProperty() {
    return findPropertyOrThrow(this, PropertyKind.DEFAULT_PRIORITY);
  },
  defaultTagsProperty() {
    return findPropertyOrThrow(this, PropertyKind.DEFAULT_TAGS);
  },
  defaultSizeProperty() {
    return findPropertyOrThrow(this, PropertyKind.DEFAULT_SIZE);
  },
  defaultTimeTrackingProperty() {
    return findPropertyOrThrow(this, PropertyKind.DEFAULT_TIME_TRACKING);
  },
  defaultAttachmentsProperty() {
    return findPropertyOrThrow(this, PropertyKind.DEFAULT_ATTACHMENTS);
  },
  defaultCreatedAtProperty() {
    return findPropertyOrThrow(this, PropertyKind.DEFAULT_CREATED_AT);
  },
  defaultCreatedByProperty() {
    return findPropertyOrThrow(this, PropertyKind.DEFAULT_CREATED_BY);
  },
  defaultUpdatedAtProperty() {
    return findPropertyOrThrow(this, PropertyKind.DEFAULT_UPDATED_AT);
  },
  defaultUpdatedByProperty() {
    return findPropertyOrThrow(this, PropertyKind.DEFAULT_UPDATED_BY);
  },
};

const actions: PiniaActionAdaptor<Actions, DataStore> = {
  async createProperty(kind, order, partialProperty, options = {}) {
    const { awaitBackend = false } = options;
    const property: Property = {
      duid: makeDuid(),
      kind,
      order,
      hidden: true,
      title: "Property",
      description: "",
      adtl: {},
      ...partialProperty,
    } as Property;
    if (
      (property.kind === PropertyKind.DEFAULT_ASSIGNEES || property.kind === PropertyKind.USER) &&
      property.adtl.isMultiple === undefined
    ) {
      property.adtl.isMultiple = false;
    }
    if (property.kind === PropertyKind.DEFAULT_DATES || property.kind === PropertyKind.DATES) {
      if (property.adtl.isRange === undefined) {
        property.adtl.isRange = false;
      }
      if (property.adtl.showDuration === undefined) {
        property.adtl.showDuration = false;
      }
    }
    if (property.kind === PropertyKind.NUMBER && property.adtl.format === undefined) {
      property.adtl.format = NumberFormat.INTEGER;
    }
    if (property.kind === PropertyKind.DEFAULT_SIZE && property.adtl.format === undefined) {
      property.adtl.format = SizeFormat.TSHIRT;
    }

    this.$createOrUpdateProperty(property);

    let newDefaultStatus: Status | undefined;
    if (property.kind === PropertyKind.STATUS) {
      newDefaultStatus = {
        duid: makeDuid(),
        propertyDuid: property.duid,
        kind: StatusKind.UNSTARTED,
        locked: true,
        order: String.fromCharCode(2),
        title: "To-do",
        colorHex: makeRandomColorHex(),
        description: "",
      };

      this.$createOrUpdateStatus(newDefaultStatus);
    }

    const backendAction = this.$backend.property.create(property, newDefaultStatus);
    if (awaitBackend) {
      await backendAction;
    }
    return property;
  },
  async updateProperty(update, options = {}) {
    const { awaitBackend = false } = options;
    const property = this.getPropertyByDuid(update.duid);
    if (!property) {
      return undefined;
    }

    Object.assign(property, update);
    this.$sortProperties();

    const backendAction = this.$backend.property.update(update);
    if (awaitBackend) {
      await backendAction;
    }
    return property;
  },
  async deleteProperty(property, options = {}) {
    const { awaitBackend = false } = options;

    this.$deleteProperty(property);

    const layoutUpdates = this.$updateLayoutsRemoveEntityDuidOrFilters([], [property.duid], {
      removeAllFilters: true,
    });
    const dartboardUpdates = this.$updateDartboardsPropertyDefault([], [property.duid], { removeAllValues: true });

    const backendAction = this.$backend.property.delete(property.duid, layoutUpdates, dartboardUpdates);
    if (awaitBackend) {
      await backendAction;
    }
  },
  $sortProperties() {
    this._duidsToProperties = new Map(
      [...this._duidsToProperties.entries()].sort((a, b) => propertyComparator(a[1], b[1]))
    );
  },
  $setProperties(properties) {
    this._duidsToProperties = new Map(properties.map((e) => [e.duid, e]));
    this.$sortProperties();
  },
  $createOrUpdateProperty(property) {
    const currentProperty = this.getPropertyByDuid(property.duid);

    if (!currentProperty) {
      this._duidsToProperties.set(property.duid, property);
    } else {
      Object.assign(currentProperty, property);
    }

    this.$sortProperties();
  },
  $deleteProperty(property) {
    this._duidsToProperties.delete(property.duid);
  },
};

export { actions, getters };
