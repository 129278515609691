import type { Comparator, Entity } from "~/shared/types";

import type { PiniaActionAdaptor, PiniaGetterAdaptor } from "../shared";
import type { DataStore } from ".";

const makeKey = (key: string, extra: string | null) => (extra ? `${key}:${extra}` : key);
const extractKeyAndExtra = (key: string) => {
  const value = key.split(":");
  return { key: value[0], extra: value.length > 1 ? value[1] : null };
};

export type PaginationState = {
  duids: Set<string>;
  lastLoaded: Entity | null;
  loadedAll: boolean;
  comparator: Comparator<Entity | undefined>;
};
export type Getters = {
  $getPaginationState: (key: string, extra: string | null) => PaginationState | undefined;
};

export type Actions = {
  /** Create a pagination state.
   * @PRIVATE */
  $createPaginationState: (key: string, extra: string | null, comparator: Comparator) => PaginationState;
  /** Add entities to pagination state.
   * @PRIVATE */
  $addEntitiesToPaginationState: (
    key: string,
    extra: string | null,
    entities: Entity[],
    totalCount: number | null
  ) => void;
  /** Add entities to all related pagination states.
   * @PRIVATE */
  $addEntitiesToPaginationStates: (key: string, entities: Entity[]) => void;
  /** Remove entities from all pagination states.
   * @PRIVATE */
  $removeEntitiesFromPaginationStates: (entities: Entity[]) => void;
  /** Delete all related pagination states.
   * @PRIVATE */
  $deletePaginationStates: (key: string) => void;
};

const getters: PiniaGetterAdaptor<Getters, DataStore> = {
  $getPaginationState() {
    return (key, extra) => this._keyToPaginationState.get(makeKey(key, extra));
  },
};

const actions: PiniaActionAdaptor<Actions, DataStore> = {
  $createPaginationState(key, extra, comparator) {
    const paginationState: PaginationState = {
      duids: new Set(),
      lastLoaded: null,
      loadedAll: false,
      comparator,
    };
    this._keyToPaginationState.set(makeKey(key, extra), paginationState);
    return paginationState;
  },
  $addEntitiesToPaginationState(key, extra, entities, totalCount) {
    const paginationState = this.$getPaginationState(key, extra);
    if (!paginationState) {
      return;
    }

    // Add entities if there's no last loaded or if the entities are same or before the last loaded
    // Otherwise, remove the entity from the duids
    entities.forEach((entity) => {
      if (
        paginationState.loadedAll ||
        !paginationState.lastLoaded ||
        paginationState.comparator(entity, paginationState.lastLoaded) <= 0
      ) {
        paginationState.duids.add(entity.duid);
      } else if (totalCount === null) {
        paginationState.duids.delete(entity.duid);
      }
    });

    if (totalCount !== null) {
      paginationState.loadedAll = paginationState.duids.size >= totalCount;
      paginationState.lastLoaded = entities[entities.length - 1];
    }
  },
  $addEntitiesToPaginationStates(key, entities) {
    [...this._keyToPaginationState.entries()].forEach(([fullKey]) => {
      const { key: extractedKey, extra } = extractKeyAndExtra(fullKey);
      if (extractedKey === key) {
        this.$addEntitiesToPaginationState(extractedKey, extra, entities, null);
      }
    });
  },
  $removeEntitiesFromPaginationStates(entities) {
    [...this._keyToPaginationState.entries()].forEach(([, paginationState]) => {
      entities.forEach((entity) => {
        paginationState.duids.delete(entity.duid);
        if (entity === paginationState.lastLoaded) {
          // eslint-disable-next-line no-param-reassign
          paginationState.lastLoaded = null;
        }
      });
    });
  },
  $deletePaginationStates(key) {
    [...this._keyToPaginationState.entries()].forEach(([paginationKey]) => {
      if (extractKeyAndExtra(paginationKey).key === key) {
        this._keyToPaginationState.delete(paginationKey);
      }
    });
  },
};

export { actions, getters };
