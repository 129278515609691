<script setup lang="ts">
import { useMediaQuery } from "@vueuse/core";
import { computed, ref } from "vue";

import actions from "~/actions";
import AttachmentInputButton from "~/components/dumb/AttachmentInputButton.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import TaskAiDropdown from "~/components/dumb/TaskAiDropdown.vue";
import TaskDetailModeDropdown from "~/components/dumb/TaskDetailModeDropdown.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import {
  ChildRelationshipIcon,
  DotsHorizontalIcon,
  FullscreenIcon,
  LinkIcon,
  OverlayIcon,
  RightbarIcon,
  XIcon,
} from "~/icons";
import {
  CommandId,
  DartboardKind,
  DropdownMenuItemKind,
  EditorMode,
  Placement,
  RelationshipKindKind,
  TaskDetailMode,
  ViewKind,
} from "~/shared/enums";
import type { DropdownMenuSection, Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useUserStore } from "~/stores";

const CHIP_BUTTON_STYLE = "flex items-center rounded p-1 text-lt hover:bg-md focus-ring-std";

const props = defineProps<{
  task: Task;
  generatingRecommendations?: boolean;
}>();

const emit = defineEmits<{
  addAttachments: [receivedFiles: File[]];
  setRelationship: [kind: RelationshipKindKind, isForward?: boolean];
  setDocRelationship: [];
  createSubtask: [];
  setSubtask: [];
  recommendProperties: [];
  recommendSubtasks: [];
  improveDescription: [];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const attachmentInput = ref<InstanceType<typeof AttachmentInputButton> | null>(null);
const taskAiDropdown = ref<InstanceType<typeof TaskAiDropdown> | null>(null);
const recommendationButton = computed(() => taskAiDropdown.value?.button);
const dropdownRef = ref<InstanceType<typeof DropdownMenu> | null>(null);

const isTrash = computed(() => appStore.currentPage?.kind === ViewKind.TRASH);
const isFinishedDartboard = computed(() => appStore.currentPage?.kind === DartboardKind.FINISHED);

const titleEmpty = computed(() => !props.task.title);

const fullscreenEnabled = computed(() => !pageStore.isMobile && appStore.taskDetailMode !== TaskDetailMode.FULLSCREEN);

// HANDLERS FOR ICON BUTTONS
const generateSubtaskRecommendations = async () => {
  if (titleEmpty.value) {
    return;
  }

  emit("recommendSubtasks");
};

const assignToAi = () => dataStore.updateTasks([{ duid: props.task.duid, assignedToAi: true }]);

const createSubtask = (event?: Event) => {
  event?.stopPropagation();
  emit("createSubtask");
};

const copyLink = () => actions.visualization.copyTaskLinks();

const onClickCloseButton = () => {
  appStore.setTaskDetailOpen(false);
};

const openFilePicker = () => attachmentInput.value?.open();

const dropdownSections = computed(() =>
  actions.context.task(props.task, EditorMode.DETAIL, false, () => dropdownRef.value?.close())
);

const getDefaultTextMaybe = (mode: TaskDetailMode) => `${mode === userStore.taskDetailMode ? " (default)" : ""}`;

const taskDetailDropdownSections = computed(() => {
  const res: DropdownMenuSection[] = [
    {
      title: "Task detail",
      items: [
        {
          title: `Side panel${getDefaultTextMaybe(TaskDetailMode.RIGHTBAR)}`,
          kind: DropdownMenuItemKind.BUTTON,
          icon: RightbarIcon,
          disabled: appStore.taskDetailMode === TaskDetailMode.RIGHTBAR,
          onClick: () => appStore.setTaskDetailOpen(true, TaskDetailMode.RIGHTBAR),
        },
        {
          title: `Fullscreen${getDefaultTextMaybe(TaskDetailMode.FULLSCREEN)}`,
          kind: DropdownMenuItemKind.BUTTON,
          icon: FullscreenIcon,
          disabled: appStore.taskDetailMode === TaskDetailMode.FULLSCREEN,
          onClick: () => appStore.setTaskDetailOpen(true, TaskDetailMode.FULLSCREEN),
        },
        {
          title: `Overlay${getDefaultTextMaybe(TaskDetailMode.OVERLAY)}`,
          kind: DropdownMenuItemKind.BUTTON,
          icon: OverlayIcon,
          disabled: appStore.taskDetailMode === TaskDetailMode.OVERLAY,
          onClick: () => appStore.setTaskDetailOpen(true, TaskDetailMode.OVERLAY),
        },
      ],
    },
  ];
  if (!pageStore.isPublicView) {
    res.push({
      title: "Change default",
      items: [
        {
          title: "Change default",
          kind: DropdownMenuItemKind.COMPONENT,
          component: TaskDetailModeDropdown,
        },
      ],
    });
  }
  return res;
});

const isPrinting = useMediaQuery("print");

defineExpose({
  recommendationButton,
  generateSubtaskRecommendations,
  openFilePicker,
});
</script>

<template>
  <div v-if="!isPrinting" class="flex w-full items-center justify-between py-0.5">
    <div class="flex items-center py-1.5 pl-3">
      <TaskAiDropdown
        v-if="!pageStore.isPublicView && pageStore.isOnline && !isTrash"
        ref="taskAiDropdown"
        :editor-mode="EditorMode.DETAIL"
        :title-empty="titleEmpty"
        :generating-recommendations="generatingRecommendations"
        @recommend-properties="() => emit('recommendProperties')"
        @recommend-subtasks="() => emit('recommendSubtasks')"
        @improve-description="() => emit('improveDescription')"
        @do-task="assignToAi" />
    </div>
    <div class="flex items-center gap-0.5 px-2 py-1.5">
      <template v-if="!pageStore.isPublicView">
        <Tooltip v-if="!isTrash && !isFinishedDartboard" :command-id="CommandId.CREATE_SUBTASKS">
          <button
            type="button"
            :class="CHIP_BUTTON_STYLE"
            aria-label="Add subtask"
            @click="createSubtask"
            @keydown.enter="createSubtask">
            <ChildRelationshipIcon class="icon-sm" />
          </button>
        </Tooltip>

        <Tooltip v-if="!isTrash" :command-id="CommandId.ADD_ATTACHMENT">
          <button type="button" aria-label="Add attachment" :class="CHIP_BUTTON_STYLE">
            <AttachmentInputButton
              ref="attachmentInput"
              :editor-mode="EditorMode.DETAIL"
              @input="(files: File[]) => emit('addAttachments', files)" />
          </button>
        </Tooltip>

        <Tooltip :command-id="CommandId.COPY_LINK">
          <button
            type="button"
            :class="CHIP_BUTTON_STYLE"
            aria-label="Copy link"
            @click="copyLink"
            @keydown.enter="copyLink">
            <LinkIcon class="icon-sm" />
          </button>
        </Tooltip>

        <DropdownMenu
          ref="dropdownRef"
          :sections="dropdownSections"
          :placement="Placement.BOTTOM_RIGHT"
          :max-height-pixels="960"
          :width-pixels="280"
          :distance="2">
          <Tooltip text="More options">
            <button type="button" :class="CHIP_BUTTON_STYLE">
              <span class="sr-only">More options</span>
              <DotsHorizontalIcon class="icon-sm" />
            </button>
          </Tooltip>
        </DropdownMenu>
      </template>

      <DropdownMenu
        v-if="!pageStore.isMobile"
        :sections="taskDetailDropdownSections"
        :placement="Placement.BOTTOM_RIGHT"
        :width-pixels="200"
        :distance="2">
        <Tooltip text="Change how this task is displayed">
          <button type="button" :class="CHIP_BUTTON_STYLE" aria-label="Change display mode">
            <component :is="fullscreenEnabled ? FullscreenIcon : RightbarIcon" class="icon-sm" />
          </button>
        </Tooltip>
      </DropdownMenu>

      <Tooltip :command-id="CommandId.RESET_VIEW_CLOSE">
        <button
          type="button"
          :class="CHIP_BUTTON_STYLE"
          @click="onClickCloseButton"
          @keydown.enter="onClickCloseButton">
          <span class="sr-only">Close task</span>
          <XIcon class="icon-sm" />
        </button>
      </Tooltip>
    </div>
  </div>
</template>
