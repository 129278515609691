<script setup lang="ts">
import { computed, nextTick, ref } from "vue";

import Button from "~/components/dumb/Button.vue";
import Input from "~/components/dumb/Input.vue";
import { ButtonStyle } from "~/shared/enums";
import type { ValidationFunction } from "~/shared/types";

const props = defineProps<{
  disabled?: boolean;
  value: string;
  label: string;
  block?: boolean;
  validate?: ValidationFunction;
}>();

const emit = defineEmits<{
  save: [newValue: string];
}>();

const editing = ref(false);
const input = ref<InstanceType<typeof Input> | null>(null);
const showButtons = computed(() => input.value?.value !== props.value);

const startEditing = () => {
  if (props.disabled) {
    return;
  }

  editing.value = true;
  nextTick(() => {
    input.value?.focus();
  });
};

const stopEditing = () => {
  editing.value = false;
};

const stopIfNoChanges = () => {
  if (showButtons.value) {
    return;
  }
  stopEditing();
};

const onSave = () => {
  if (!input?.value?.isValid) {
    return;
  }

  emit("save", input.value.value);
  stopEditing();
};

defineExpose({
  stopEditing,
});
</script>

<template>
  <div class="relative h-10">
    <button
      v-if="!editing"
      type="submit"
      class="h-full rounded-md focus-ring-std"
      :class="[block ? 'w-full' : 'w-60', disabled ? 'cursor-not-allowed' : 'cursor-pointer']"
      aria-label="Edit"
      @click="startEditing">
      <slot />
    </button>
    <template v-else>
      <div
        class="flex h-full items-center rounded-md border pl-3 pr-1.5 text-center"
        :class="{
          'border-indigo-400 outline outline-2 outline-indigo-400/30 dark:outline-indigo-400/10': input?.hasFocus,
          'border-red-400 outline outline-2 outline-red-400/40 dark:outline-red-400/10':
            !input?.isValid && input?.isTouched,
          'border-lt': !input?.hasFocus && input?.isValid,
          'w-60': !block,
          'w-full': block,
        }">
        <Input
          ref="input"
          :init-value="value"
          :placeholder="props.label"
          :label="props.label"
          hide-label
          :class="[block ? 'w-full' : 'w-48']"
          input-classes="!text-sm border-0 !p-0 "
          hide-error
          :validate="validate"
          @keydown.esc.stop.prevent="stopEditing"
          @enter="onSave"
          @finalize="stopIfNoChanges" />
        <div v-if="showButtons" class="ml-1.5 flex items-center gap-1.5">
          <Button
            :btn-style="ButtonStyle.SECONDARY"
            text="Cancel"
            class="max-h-6 w-12 !px-0.5"
            text-style="text-xs"
            @click="stopEditing" />
          <Button
            :btn-style="ButtonStyle.PRIMARY"
            text="Save"
            class="max-h-6 w-10 !px-0.5"
            text-style="text-xs"
            :disabled="!input?.isValid"
            @click="onSave" />
        </div>
      </div>
    </template>
  </div>
</template>
