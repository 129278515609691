<script setup lang="ts">
import { computed, ref } from "vue";

import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { copyAndNotify } from "~/components/notifications";
import { CopyIcon, RefreshIcon } from "~/icons";
import { DialogMode } from "~/shared/enums";

const { text, label } = defineProps<{
  text: string;
  label: string;
  rotateDialogDescription?: string;
}>();

const emit = defineEmits<{ rotate: [] }>();

const dialog = ref<InstanceType<typeof ConfirmationDialog> | null>(null);

const copyLabel = computed(() => `Copy ${label}`);
const SettingsCopyText = () => copyAndNotify(label, text);
</script>

<template>
  <button
    type="button"
    class="flex items-center justify-between gap-1 rounded-md border px-1.5 py-0.5 text-lt border-md"
    @click="SettingsCopyText">
    <span class="truncate">{{ text }}</span>

    <div class="flex items-center gap-0.5">
      <Tooltip :text="copyLabel">
        <span class="cursor-pointer rounded p-1 hover:bg-lt" :class="rotateDialogDescription ? '' : '-mr-0.5'">
          <CopyIcon class="size-4" />
        </span>
      </Tooltip>

      <ConfirmationDialog
        v-if="rotateDialogDescription"
        ref="dialog"
        :mode="DialogMode.DELETE"
        :title="`Rotate ${label.toLowerCase()}`"
        :description="rotateDialogDescription"
        confirm-text="Proceed"
        cancel-text="Cancel"
        :icon="RefreshIcon"
        @confirm="emit('rotate')">
        <Tooltip :text="`Rotate ${label.toLowerCase()}`">
          <span
            class="-mr-0.5 cursor-pointer rounded p-1 hover:bg-lt"
            @click.stop="dialog?.openModal()"
            @keydown.stop="dialog?.openModal()">
            <RefreshIcon class="size-4" />
          </span>
        </Tooltip>
      </ConfirmationDialog>
    </div>
  </button>
</template>
