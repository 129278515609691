import type { Component } from "vue";

import { UNGROUPED_PSEUDO_GROUP_BY } from "~/common/groupBy";
import { getPropertyWithConfig } from "~/common/properties";
import GenericSubdropdown from "~/components/dumb/GenericSubdropdown.vue";
import {
  BarChartIcon,
  BurnUpChartIcon,
  LineChartIcon,
  NumberChartIcon,
  PieChartIcon,
  TableChartIcon,
  TextFieldIcon,
} from "~/icons";
import {
  ChartAggregation,
  DropdownMenuItemKind,
  PieChartDisplayMetric,
  PropertyKind,
  StatisticType,
} from "~/shared/enums";
import type { DropdownMenuItem, GroupByGroup, Property, StatisticConfig } from "~/shared/types";
import { useDataStore } from "~/stores";

import { getAllGroupByDefinitionList, TIME_TRACKING_KINDS } from "./common";

export type StatisticDefinition<T = StatisticType> = {
  type: T;
  icon: Component;
  sizes?: { minH?: number; minW?: number; defaultH?: number; defaultW?: number; maxH?: number; maxW?: number };
  configOptions: (config: StatisticConfig<T>, updateConfig: (e: StatisticConfig<T>) => void) => DropdownMenuItem[];
  getDefaultTitle: (config: StatisticConfig<T>) => string;
};

type DropdownItem = {
  title: string;
  selected: boolean;
  icon?: Component;
  iconArgs?: object;
  onClick: () => void;
};

const AGGREGATION_ONLY_KINDS = new Set([...TIME_TRACKING_KINDS, PropertyKind.NUMBER]);

const createOptionSubmenu = (
  title: string,
  items: DropdownItem[],
  options?: {
    disabled?: boolean;
    disabledReason?: string;
    preventCloseOnSelect?: boolean;
  }
): DropdownMenuItem => ({
  title,
  kind: DropdownMenuItemKind.COMPONENT,
  component: GenericSubdropdown,
  disabled: options?.disabled,
  disabledReason: options?.disabledReason,
  componentArgs: {
    preventCloseOnSelect: options?.preventCloseOnSelect,
    title,
    sections: [
      {
        title,
        items: items.map((item) => ({
          title: item.title,
          kind: DropdownMenuItemKind.BUTTON,
          disabled: item.selected,
          icon: item.icon,
          iconArgs: item.iconArgs,
          onClick: item.onClick,
        })),
      },
    ],
  },
});

const createPropertySubmenu = (
  title: string,
  selectedPropertyDuid: string | null,
  onClick: (property: Property, groups: GroupByGroup[]) => void,
  options: { allowNull?: boolean; aggregation?: boolean } = {}
): DropdownMenuItem => {
  const items: DropdownItem[] = getAllGroupByDefinitionList()
    .filter(
      (e) =>
        (options.allowNull || e.property.duid !== UNGROUPED_PSEUDO_GROUP_BY) &&
        (options.aggregation ? e.isNumeric : !AGGREGATION_ONLY_KINDS.has(e.property.kind))
    )
    .map((option) => ({
      title: option.property.title,
      selected: selectedPropertyDuid
        ? option.property.duid === selectedPropertyDuid
        : option.property.duid === UNGROUPED_PSEUDO_GROUP_BY,
      icon: option.icon,
      onClick: () => onClick(option.property, option.groups),
    }));
  return createOptionSubmenu(title, items, { preventCloseOnSelect: true });
};

const BAR_STATISTIC: StatisticDefinition<StatisticType.BAR> = {
  type: StatisticType.BAR,
  icon: BarChartIcon,
  sizes: { minH: 5, minW: 6, defaultH: 6, defaultW: 6 },
  configOptions: (config, updateConfig) => [
    createPropertySubmenu("X axis property", config.adtl.xPropertyDuid, (property) =>
      updateConfig({ ...config, adtl: { ...config.adtl, xPropertyDuid: property.duid } })
    ),
    createPropertySubmenu(
      "Stack property",
      config.adtl.stackPropertyDuid,
      (property) => updateConfig({ ...config, adtl: { ...config.adtl, stackPropertyDuid: property.duid } }),
      { allowNull: true }
    ),
    createPropertySubmenu(
      "Aggregation property",
      config.adtl.aggregationPropertyDuid,
      (property) =>
        updateConfig({
          ...config,
          adtl: {
            ...config.adtl,
            aggregationPropertyDuid: property.duid === UNGROUPED_PSEUDO_GROUP_BY ? null : property.duid,
            aggregation: ChartAggregation.COUNT,
          },
        }),
      { allowNull: true, aggregation: true }
    ),
    createOptionSubmenu(
      "Aggregation",
      [
        {
          title: "Count",
          selected: config.adtl.aggregation === ChartAggregation.COUNT,
          onClick: () => updateConfig({ ...config, adtl: { ...config.adtl, aggregation: ChartAggregation.COUNT } }),
        },
        {
          title: "Sum",
          selected: config.adtl.aggregation === ChartAggregation.SUM,
          onClick: () => updateConfig({ ...config, adtl: { ...config.adtl, aggregation: ChartAggregation.SUM } }),
        },
        {
          title: "Average",
          selected: config.adtl.aggregation === ChartAggregation.AVG,
          onClick: () => updateConfig({ ...config, adtl: { ...config.adtl, aggregation: ChartAggregation.AVG } }),
        },
      ],
      {
        disabled: config.adtl.aggregationPropertyDuid === null,
        disabledReason: "Select a aggregation property to enable this option",
      }
    ),
  ],
  getDefaultTitle: (config) => {
    const dataStore = useDataStore();
    const { xPropertyDuid, stackPropertyDuid, aggregationPropertyDuid, aggregation } = config.adtl;

    const xProperty = dataStore.getPropertyByDuid(xPropertyDuid);
    const stackProperty = stackPropertyDuid ? dataStore.getPropertyByDuid(stackPropertyDuid) : undefined;
    const aggregationProperty = aggregationPropertyDuid
      ? dataStore.getPropertyByDuid(aggregationPropertyDuid)
      : undefined;
    const xTitle = (xProperty?.title ?? "").toLowerCase();
    const stackTitle = (stackProperty?.title ?? "").toLowerCase();
    const aggregationPropertyTitle = (aggregationProperty?.title ?? "").toLowerCase();

    if (aggregationProperty && aggregation !== ChartAggregation.COUNT) {
      const title = `${aggregation === ChartAggregation.AVG ? "Average" : "Sum"} of ${aggregationPropertyTitle} of tasks by ${xTitle}`;
      return stackTitle ? `${title} and ${stackTitle}` : title;
    }

    const title = `Tasks by ${xTitle}`;
    return stackTitle ? `${title} and ${stackTitle}` : title;
  },
};

const BURN_UP_STATISTIC: StatisticDefinition<StatisticType.BURN_UP> = {
  type: StatisticType.BURN_UP,
  icon: BurnUpChartIcon,
  sizes: { minH: 12, minW: 12, defaultH: 12, defaultW: 12 },
  configOptions: () => [],
  getDefaultTitle: () => "Burn-up chart",
};

const LINE_STATISTIC: StatisticDefinition<StatisticType.LINE> = {
  type: StatisticType.LINE,
  icon: LineChartIcon,
  sizes: { minH: 5, minW: 6, defaultH: 6, defaultW: 6 },
  configOptions: (config, updateConfig) => [
    createPropertySubmenu("X axis property", config.adtl.xPropertyDuid, (property) =>
      updateConfig({ ...config, adtl: { ...config.adtl, xPropertyDuid: property.duid } })
    ),
    createPropertySubmenu(
      "Group by property",
      config.adtl.groupByPropertyDuid,
      (property) =>
        updateConfig({
          ...config,
          adtl: {
            ...config.adtl,
            groupByPropertyDuid: property.duid === UNGROUPED_PSEUDO_GROUP_BY ? null : property.duid,
          },
        }),
      { allowNull: true }
    ),
  ],
  getDefaultTitle: (config) => {
    const dataStore = useDataStore();
    const { xPropertyDuid, groupByPropertyDuid } = config.adtl;

    const xProperty = dataStore.getPropertyByDuid(xPropertyDuid);
    const groupByProperty = groupByPropertyDuid ? dataStore.getPropertyByDuid(groupByPropertyDuid) : undefined;
    const xTitle = (xProperty?.title ?? "").toLowerCase();
    const groupByTitle = (groupByProperty?.title ?? "").toLowerCase();

    if (groupByProperty) {
      return `Tasks over ${xTitle} by ${groupByTitle}`;
    }
    return `Tasks by ${xTitle}`;
  },
};

const NUMBER_STATISTIC: StatisticDefinition<StatisticType.NUMBER> = {
  type: StatisticType.NUMBER,
  icon: NumberChartIcon,
  sizes: { minH: 3, minW: 2, defaultH: 3, defaultW: 3 },
  configOptions: (config, updateConfig) => {
    const noPropertySelected = config.adtl.propertyDuid === null;
    const propertyDuid = config.adtl.propertyDuid || UNGROUPED_PSEUDO_GROUP_BY;
    const propertyWithConfig = getPropertyWithConfig(propertyDuid);

    return [
      createPropertySubmenu(
        "Aggregation property",
        config.adtl.propertyDuid,
        (property) =>
          updateConfig({
            ...config,
            adtl: {
              ...config.adtl,
              propertyDuid: property.duid === UNGROUPED_PSEUDO_GROUP_BY ? null : property.duid,
              aggregation: ChartAggregation.COUNT,
            },
          }),
        { allowNull: true, aggregation: true }
      ),
      createOptionSubmenu(
        "Aggregation",
        [
          {
            title: "Count",
            selected: config.adtl.aggregation === ChartAggregation.COUNT,
            onClick: () => updateConfig({ ...config, adtl: { ...config.adtl, aggregation: ChartAggregation.COUNT } }),
          },
          {
            title: "Sum",
            selected: config.adtl.aggregation === ChartAggregation.SUM,
            onClick: () => updateConfig({ ...config, adtl: { ...config.adtl, aggregation: ChartAggregation.SUM } }),
          },
          {
            title: "Average",
            selected: config.adtl.aggregation === ChartAggregation.AVG,
            onClick: () => updateConfig({ ...config, adtl: { ...config.adtl, aggregation: ChartAggregation.AVG } }),
          },
        ],
        {
          disabled: noPropertySelected || !(propertyWithConfig && propertyWithConfig[1].isNumeric),
          disabledReason: "Select a numeric property to enable this option",
        }
      ),
    ];
  },
  getDefaultTitle: (config) => {
    const { propertyDuid, aggregation } = config.adtl;
    if (propertyDuid === null || aggregation === ChartAggregation.COUNT) {
      return "Tasks";
    }

    const propertyWithConfig = getPropertyWithConfig(propertyDuid || "");
    const isTimeTracking = propertyWithConfig && TIME_TRACKING_KINDS.has(propertyWithConfig[1].kind);
    if (!propertyWithConfig) {
      return "Tasks";
    }

    return `${aggregation === ChartAggregation.AVG ? "Average" : "Sum"} of ${isTimeTracking ? "time spent on tasks" : propertyWithConfig[0].title.toLowerCase()}`;
  },
};

const PIE_STATISTIC: StatisticDefinition<StatisticType.PIE> = {
  type: StatisticType.PIE,
  icon: PieChartIcon,
  sizes: { minH: 5, minW: 4, defaultH: 6, defaultW: 6 },
  configOptions: (config, updateConfig) => [
    createPropertySubmenu("Property", config.adtl.propertyDuid, (property) =>
      updateConfig({ ...config, adtl: { ...config.adtl, propertyDuid: property.duid } })
    ),
    createOptionSubmenu("Type", [
      {
        title: "Count",
        selected: config.adtl.displayMetric === PieChartDisplayMetric.COUNT,
        onClick: () =>
          updateConfig({ ...config, adtl: { ...config.adtl, displayMetric: PieChartDisplayMetric.COUNT } }),
      },
      {
        title: "Percentage",
        selected: config.adtl.displayMetric === PieChartDisplayMetric.PCT,
        onClick: () => updateConfig({ ...config, adtl: { ...config.adtl, displayMetric: PieChartDisplayMetric.PCT } }),
      },
    ]),
  ],
  getDefaultTitle: (config) => {
    const { propertyDuid } = config.adtl;

    const property = useDataStore().getPropertyByDuid(propertyDuid);

    const title = (property?.title ?? "").toLowerCase();
    return `Tasks by ${title}`;
  },
};

const TABLE_STATISTIC: StatisticDefinition<StatisticType.TABLE> = {
  type: StatisticType.TABLE,
  icon: TableChartIcon,
  sizes: { minH: 4, minW: 6, defaultH: 6, defaultW: 6 },
  configOptions: (config, updateConfig) => [
    createPropertySubmenu("Column property", config.adtl.columnPropertyDuid, (property) =>
      updateConfig({
        ...config,
        adtl: {
          ...config.adtl,
          columnPropertyDuid: property.duid,
          aggregation: ChartAggregation.COUNT,
          aggregationPropertyDuid: null,
        },
      })
    ),
    createPropertySubmenu(
      "Row property",
      config.adtl.rowPropertyDuid,
      (property) =>
        updateConfig({
          ...config,
          adtl: {
            ...config.adtl,
            rowPropertyDuid: property.duid,
            aggregation: ChartAggregation.COUNT,
            aggregationPropertyDuid: null,
          },
        }),
      { allowNull: true }
    ),
    createPropertySubmenu(
      "Aggregation property",
      config.adtl.aggregationPropertyDuid,
      (property) =>
        updateConfig({
          ...config,
          adtl: {
            ...config.adtl,
            aggregationPropertyDuid: property.duid === UNGROUPED_PSEUDO_GROUP_BY ? null : property.duid,
            aggregation: ChartAggregation.COUNT,
          },
        }),
      { allowNull: true, aggregation: true }
    ),
    createOptionSubmenu(
      "Aggregation",
      [
        {
          title: "Count",
          selected: config.adtl.aggregation === ChartAggregation.COUNT,
          onClick: () => updateConfig({ ...config, adtl: { ...config.adtl, aggregation: ChartAggregation.COUNT } }),
        },
        {
          title: "Sum",
          selected: config.adtl.aggregation === ChartAggregation.SUM,
          onClick: () => updateConfig({ ...config, adtl: { ...config.adtl, aggregation: ChartAggregation.SUM } }),
        },
        {
          title: "Average",
          selected: config.adtl.aggregation === ChartAggregation.AVG,
          onClick: () => updateConfig({ ...config, adtl: { ...config.adtl, aggregation: ChartAggregation.AVG } }),
        },
      ],
      {
        disabled: config.adtl.aggregationPropertyDuid === null,
        disabledReason: "Select a aggregation property to enable this option",
      }
    ),
  ],
  getDefaultTitle: (config) => {
    const dataStore = useDataStore();
    const { columnPropertyDuid, rowPropertyDuid, aggregationPropertyDuid, aggregation } = config.adtl;

    const columnProperty = dataStore.getPropertyByDuid(columnPropertyDuid);
    const rowProperty = rowPropertyDuid ? dataStore.getPropertyByDuid(rowPropertyDuid) : undefined;
    const aggregationProperty = aggregationPropertyDuid
      ? dataStore.getPropertyByDuid(aggregationPropertyDuid)
      : undefined;
    const columnTitle = (columnProperty?.title ?? "").toLowerCase();
    const rowTitle = (rowProperty?.title ?? "").toLowerCase();
    const aggregationPropertyTitle = (aggregationProperty?.title ?? "").toLowerCase();

    if (aggregationProperty && aggregation !== ChartAggregation.COUNT) {
      const title = `${aggregation === ChartAggregation.AVG ? "Average" : "Sum"} of ${aggregationPropertyTitle} of tasks by ${columnTitle}`;
      return rowProperty ? `${title} and ${rowTitle}` : title;
    }

    const title = `Tasks by ${columnTitle}`;
    return rowTitle ? `${title} and ${rowTitle}` : title;
  },
};

const TEXT_BLOCK_STATISTIC: StatisticDefinition<StatisticType.TEXT_BLOCK> = {
  type: StatisticType.TEXT_BLOCK,
  icon: TextFieldIcon,
  sizes: { minH: 1, minW: 2, defaultH: 2, defaultW: 6 },
  configOptions: () => [],
  getDefaultTitle: () => "Text",
};

export const STATISTIC_DEFINITIONS: { [T in StatisticType]: StatisticDefinition<T> } = {
  [StatisticType.BAR]: BAR_STATISTIC,
  [StatisticType.BURN_UP]: BURN_UP_STATISTIC,
  [StatisticType.LINE]: LINE_STATISTIC,
  [StatisticType.NUMBER]: NUMBER_STATISTIC,
  [StatisticType.PIE]: PIE_STATISTIC,
  [StatisticType.TABLE]: TABLE_STATISTIC,
  [StatisticType.TEXT_BLOCK]: TEXT_BLOCK_STATISTIC,
};

export const getStatisticDefinition = <T extends StatisticType = StatisticType>(type: T): StatisticDefinition<T> =>
  STATISTIC_DEFINITIONS[type];
