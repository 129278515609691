import type { Comparator, Doc } from "~/shared/types";
import { makeDocComparator, makeStringComparator } from "~/utils/comparator";

import type { PiniaActionAdaptor, PiniaGetterAdaptor } from "../shared";
import type { DataStore } from ".";

export type Getters = {
  $getDocSortingFn: (order: string) => Comparator<Doc | undefined>;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type Actions = {};

const getters: PiniaGetterAdaptor<Getters, DataStore> = {
  $getDocSortingFn() {
    return (order) => {
      const ORDER_TO_SORT_FN: Record<string, Comparator> = {
        "-recent": makeStringComparator((e: Doc) => e.updatedAt, true),
        title: makeStringComparator((e: Doc) => e.title.toLowerCase()),
        order: makeDocComparator(this.getSpaceByDuid, this.getFolderByDuid),
      };
      return ORDER_TO_SORT_FN[order] || ORDER_TO_SORT_FN["-order"];
    };
  },
};

const actions: PiniaActionAdaptor<Actions, DataStore> = {};

export { actions, getters };
