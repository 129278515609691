<script setup lang="ts">
import { watchOnce } from "@vueuse/core";
import { nextTick, ref, watch } from "vue";

import Button from "~/components/dumb/Button.vue";
import Input from "~/components/dumb/Input.vue";
import Modal from "~/components/dumb/Modal.vue";
import { LinkFieldIcon } from "~/icons";
import { ButtonSize, ButtonStyle, EntityName, ModalWidth, RelationshipKindKind, TaskLinkKind } from "~/shared/enums";
import { useAppStore, useDataStore, useEnvironmentStore } from "~/stores";
import { getEntityKindAndDuidFromUrl, isValidDartUrl, isValidUrlIncludingLocal } from "~/utils/common";

const appStore = useAppStore();
const dataStore = useDataStore();
const environmentStore = useEnvironmentStore();

const urlInput = ref<InstanceType<typeof Input> | null>(null);
const titleInput = ref<InstanceType<typeof Input> | null>(null);

const isEditing = ref(false);
const initUrl = ref("");
const initTitle = ref("");

const validateUrl = (value: string) => {
  const isValid = value === "" || isValidUrlIncludingLocal(value);
  if (isValid) {
    return { isValid };
  }
  return { isValid, error: "Enter a valid URL" };
};

const createRelatedTaskRelationship = (taskDuid: string) => {
  if (!appStore.linkOpenInModal) {
    return;
  }

  const relatesToRelationshipKindDuid = dataStore.getRelationshipKindByKind(RelationshipKindKind.RELATES_TO).duid;
  dataStore.createRelationship(appStore.linkOpenInModal.taskDuid, taskDuid, relatesToRelationshipKindDuid);
};

const createTaskDocRelationship = (docDuid: string) => {
  if (!appStore.linkOpenInModal) {
    return;
  }

  dataStore.createTaskDocRelationship(appStore.linkOpenInModal.taskDuid, docDuid);
};

const onClose = () => {
  appStore.setLinkOpenInModal(null);
};

const addLink = () => {
  if (!appStore.linkOpenInModal || !urlInput.value || !titleInput.value) {
    return;
  }

  const url = urlInput.value.value;
  if (!isValidDartUrl(url, environmentStore.isLocal)) {
    dataStore.addLink(appStore.linkOpenInModal.taskDuid, TaskLinkKind.STANDARD, url, titleInput.value.value);
    return;
  }

  const { kind, duid } = getEntityKindAndDuidFromUrl(url);
  if (kind === EntityName.TASK) {
    createRelatedTaskRelationship(duid);
    return;
  }
  if (kind === EntityName.DOC) {
    createTaskDocRelationship(duid);
  }
};

const updateLink = () => {
  if (!appStore.linkOpenInModal || !urlInput.value || !titleInput.value) {
    return;
  }

  const { link, taskDuid } = appStore.linkOpenInModal;
  if (!link) {
    return;
  }
  const { duid } = link;

  const url = urlInput.value.value;
  if (!isValidDartUrl(url, environmentStore.isLocal)) {
    dataStore.updateLink(taskDuid, {
      duid,
      title: titleInput.value.value,
      url,
    });
    return;
  }

  const { kind, duid: entityDuid } = getEntityKindAndDuidFromUrl(url);
  if (kind === EntityName.TASK) {
    createRelatedTaskRelationship(entityDuid);
    dataStore.deleteLink(taskDuid, duid);
    return;
  }
  if (kind === EntityName.DOC) {
    createTaskDocRelationship(entityDuid);
    dataStore.deleteLink(taskDuid, duid);
  }
};

const addOrUpdateLink = () => {
  if (!urlInput.value?.isValid) {
    return;
  }
  if (isEditing.value) {
    updateLink();
  }
  if (!isEditing.value) {
    addLink();
  }
  onClose();
};

watch(
  () => appStore.linkOpenInModal,
  (linkInModal) => {
    if (!linkInModal) {
      return;
    }
    isEditing.value = !!linkInModal.link;
    initUrl.value = linkInModal.link?.url ?? "";
    initTitle.value = linkInModal.link?.title ?? "";

    watchOnce(urlInput, (newUrlInput) => {
      if (newUrlInput) {
        nextTick(() => newUrlInput.focus());
      }
    });
  }
);
</script>

<template>
  <Modal
    :entity="appStore.linkOpenInModal"
    :title="`${isEditing ? 'Update' : 'Add'} link`"
    :width="ModalWidth.M"
    :title-icon="LinkFieldIcon"
    @close="onClose">
    <template #default>
      <form class="mb-1 mt-4 flex flex-col gap-6" @submit.prevent>
        <Input
          ref="urlInput"
          :init-value="initUrl"
          input-type="url"
          required
          label="Link URL"
          placeholder="Type or paste in any URL"
          :validate="validateUrl"
          @enter="titleInput?.focus()" />
        <Input
          ref="titleInput"
          :init-value="initTitle"
          label="Title (optional)"
          placeholder="Type a title, or leave blank to use the link's title"
          @enter="addOrUpdateLink" />
      </form>
    </template>

    <template #actions>
      <div class="flex items-center gap-2">
        <Button
          :btn-style="ButtonStyle.PRIMARY"
          :text="isEditing ? 'Update' : 'Add'"
          :size="ButtonSize.LARGE"
          :disabled="!urlInput?.isValid"
          @click.stop="addOrUpdateLink" />
      </div>
    </template>
  </Modal>
</template>
