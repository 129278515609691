<template>
  <svg viewBox="23 23 54 54" fill="currentColor" style="display: block" aria-hidden="true">
    <g>
      <g transform="rotate(0 50 50)">
        <rect height="14" width="4" ry="1.96" rx="1.96" y="23" x="48" opacity="0.2">
          <animate
            repeatCount="indefinite"
            begin="-2.75s"
            dur="3s"
            keyTimes="0;1"
            values="1;0.2"
            attributeName="opacity" />
        </rect>
      </g>
      <g transform="rotate(30 50 50)">
        <rect height="14" width="4" ry="1.96" rx="1.96" y="23" x="48" opacity="0.2">
          <animate
            repeatCount="indefinite"
            begin="-2.5"
            dur="3s"
            keyTimes="0;1"
            values="1;0.2"
            attributeName="opacity" />
        </rect>
      </g>
      <g transform="rotate(60 50 50)">
        <rect height="14" width="4" ry="1.96" rx="1.96" y="23" x="48" opacity="0.2">
          <animate
            repeatCount="indefinite"
            begin="-2.25s"
            dur="3s"
            keyTimes="0;1"
            values="1;0.2"
            attributeName="opacity" />
        </rect>
      </g>
      <g transform="rotate(90 50 50)">
        <rect height="14" width="4" ry="1.96" rx="1.96" y="23" x="48" opacity="0.2">
          <animate
            repeatCount="indefinite"
            begin="-2s"
            dur="3s"
            keyTimes="0;1"
            values="1;0.2"
            attributeName="opacity" />
        </rect>
      </g>
      <g transform="rotate(120 50 50)">
        <rect height="14" width="4" ry="1.96" rx="1.96" y="23" x="48" opacity="0.2">
          <animate
            repeatCount="indefinite"
            begin="-1.75s"
            dur="3s"
            keyTimes="0;1"
            values="1;0.2"
            attributeName="opacity" />
        </rect>
      </g>
      <g transform="rotate(150 50 50)">
        <rect height="14" width="4" ry="1.96" rx="1.96" y="23" x="48" opacity="0.2">
          <animate
            repeatCount="indefinite"
            begin="-1.5s"
            dur="3s"
            keyTimes="0;1"
            values="1;0.2"
            attributeName="opacity" />
        </rect>
      </g>
      <g transform="rotate(180 50 50)">
        <rect height="14" width="4" ry="1.96" rx="1.96" y="23" x="48" opacity="0.2">
          <animate
            repeatCount="indefinite"
            begin="-1.25s"
            dur="3s"
            keyTimes="0;1"
            values="1;0.2"
            attributeName="opacity" />
        </rect>
      </g>
      <g transform="rotate(210 50 50)">
        <rect height="14" width="4" ry="1.96" rx="1.96" y="23" x="48" opacity="0.2">
          <animate
            repeatCount="indefinite"
            begin="-1s"
            dur="3s"
            keyTimes="0;1"
            values="1;0.2"
            attributeName="opacity" />
        </rect>
      </g>
      <g transform="rotate(240 50 50)">
        <rect height="14" width="4" ry="1.96" rx="1.96" y="23" x="48" opacity="0.2">
          <animate
            repeatCount="indefinite"
            begin="-0.75s"
            dur="3s"
            keyTimes="0;1"
            values="1;0.2"
            attributeName="opacity" />
        </rect>
      </g>
      <g transform="rotate(270 50 50)">
        <rect height="14" width="4" ry="1.96" rx="1.96" y="23" x="48" opacity="0.2">
          <animate
            repeatCount="indefinite"
            begin="-0.5s"
            dur="3s"
            keyTimes="0;1"
            values="1;0.2"
            attributeName="opacity" />
        </rect>
      </g>
      <g transform="rotate(300 50 50)">
        <rect height="14" width="4" ry="1.96" rx="1.96" y="23" x="48" opacity="0.2">
          <animate
            repeatCount="indefinite"
            begin="-0.25s"
            dur="3s"
            keyTimes="0;1"
            values="1;0.2"
            attributeName="opacity" />
        </rect>
      </g>
      <g transform="rotate(330 50 50)">
        <rect height="14" width="4" ry="1.96" rx="1.96" y="23" x="48" opacity="0.2">
          <animate repeatCount="indefinite" begin="0s" dur="3s" keyTimes="0;1" values="1;0.2" attributeName="opacity" />
        </rect>
      </g>
    </g>
  </svg>
</template>
