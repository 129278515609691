import moment from "moment";
import { markRaw, reactive } from "vue";

import { getPropertyConfig, getPropertyPartialTask, getPropertyValueFromTask } from "~/common/properties";
import {
  overrideTaskAndAdjustFilters,
  overrideTaskWithDefaults,
  removeFiltersThatDoNotPassForTask,
} from "~/components/filters/utils";
import { COMPLETED_STATUS_KINDS } from "~/components/visualization/constants";
import type { TransactionResponse } from "~/shared/common";
import {
  ModelType,
  RelationshipKindKind,
  StatusKind,
  StoreDataPreservationMode,
  TaskLinkKind,
  TaskSourceType,
  TutorialName,
} from "~/shared/enums";
import type {
  AttachmentCreate,
  Option,
  PropertyAnyMultiselect,
  PropertyAnyUserWithMultipleMaybe,
  Relationship,
  RelationshipCreate,
  RemapModalConfigItem,
  Task,
  TaskAbsenteeMaybe,
  TaskAndUpdate,
  TaskCreate,
  TaskDocRelationshipCreate,
  TaskLink,
  TaskLinkCreate,
  TaskLinkUpdate,
  TaskUpdate,
} from "~/shared/types";
import { createFilesFromAttachments } from "~/utils/api";
import {
  deepCopy,
  filterInPlace,
  getItemCountText,
  getNextTitleInSequence,
  isLexicalStateEmpty,
  makeDuid,
  makeEmptyLexicalState,
  prettyFormatList,
} from "~/utils/common";
import { makeStringComparator, makeTaskComparator, statusComparator, stringComparator } from "~/utils/comparator";
import { firework } from "~/utils/fun";
import { getOrdersBetween } from "~/utils/orderManager";

import type { PiniaActionAdaptor, PiniaGetterAdaptor } from "../shared";
import type { DataStore } from ".";

export type Getters = {
  /* Get draft task */
  taskDraft: Task | undefined;
  /* Get all tasks */
  getTaskList: (options?: { includeTrashed?: boolean; includeDraft?: boolean }) => Task[];
  /* Get task by DUID */
  getTaskByDuid: (duid: string) => Task | undefined;
  /* Get all tasks by DUIDs ordered */
  getTasksByDuids: (
    duids: string[],
    options?: { includeTrashed?: boolean; includeDraft?: boolean; absenteeDuids?: string[] }
  ) => TaskAbsenteeMaybe[];
  /* Get all tasks by DUIDs ordered */
  getTasksByDuidsOrdered: (
    duids: string[],
    options?: { includeTrashed?: boolean; includeDraft?: boolean; absenteeDuids?: string[] }
  ) => TaskAbsenteeMaybe[];
  /* Get tasks by task kind DUID */
  getTasksByKindDuid: (taskKindDuid: string) => Task[];
  /* Get tasks by status DUID */
  getTasksByStatusDuid: (statusDuid: string) => Task[];
  /* Get all of the trashed tasks */
  tasksInTrash: Task[];
  /* Get all of the tasks in a given dartboard by DUID. */
  getTasksByDartboardDuidOrdered: (
    dartboardDuid: string,
    options?: { includeTrashed?: boolean; includeDraft?: boolean; includeAncestors?: boolean }
  ) => TaskAbsenteeMaybe[];
};

export type Actions = {
  /* Check if a task is empty. */
  isTaskEmpty: (task: Task) => boolean;
  /* Create a new task. */
  createTask: (
    title: string,
    dartboardDuid: string,
    order: string,
    source: TaskSourceType,
    partialTask?: Partial<Task>,
    options?: { noUndo?: boolean; awaitBackend?: boolean }
  ) => Promise<Task>;
  /* Create multiple tasks. */
  createTasks: (
    tasks: TaskCreate[],
    source: TaskSourceType,
    options?: { noUndo?: boolean; relatedDocDuid?: string; awaitBackend?: boolean }
  ) => Promise<Task[]>;
  /* Update existing tasks. */
  updateTasks: (
    updates: TaskUpdate[],
    options?: {
      noUndo?: boolean;
      noBackend?: boolean;
      noCelebrate?: boolean;
      celebrateOverride?: boolean;
      makeDescription?: (isActive: boolean, content: string) => string;
      awaitBackend?: boolean;
      onRemapFailed?: () => void;
    }
  ) => Promise<void>;
  /* Update a task from an NLP partial. */
  updateTaskFromNlp: (task: Task, update: Partial<Task>, options?: { awaitBackend?: boolean }) => Promise<void>;
  /* Replicate a task. */
  replicateTasks: (
    tasksToReplicate: TaskAndUpdate[],
    options?: {
      noUndo?: boolean;
      propertiesAndParentOnly?: boolean;
      includeDuplicate?: boolean;
      awaitBackend?: boolean;
      maintainTitle?: boolean;
      maintainStatus?: boolean;
    }
  ) => Promise<Task[]>;
  /** Delete tasks. */
  deleteTasks: (tasks: Task[], options?: { awaitBackend?: boolean }) => Promise<void>;
  /* Update and delete tasks. */
  updateAndDeleteTasks: (
    updates: TaskUpdate[],
    deletes: Task[],
    options?: {
      noUndo?: boolean;
      noBackend?: boolean;
      noCelebrate?: boolean;
      celebrateOverride?: boolean;
      makeDescription?: (isActive: boolean, content: string) => string;
      overrideFilters?: boolean;
      awaitBackend?: boolean;
      onRemapFailed?: () => void;
    }
  ) => Promise<void>;
  /* Add users to task. */
  addUsers: (
    property: PropertyAnyUserWithMultipleMaybe,
    taskDuids: string[],
    userDuids: string[],
    options?: { noUndo?: boolean; awaitBackend?: boolean }
  ) => Promise<void>;
  /* Remove users from task. */
  removeUsers: (
    property: PropertyAnyUserWithMultipleMaybe,
    taskDuids: string[],
    userDuids: string[],
    options?: { noUndo?: boolean; awaitBackend?: boolean }
  ) => Promise<void>;
  /* Add and remove users from task. */
  replaceUsers: (
    property: PropertyAnyUserWithMultipleMaybe,
    taskDuids: string[],
    userDuids: string[],
    options?: { noUndo?: boolean; awaitBackend?: boolean }
  ) => Promise<void>;
  /* Add subscribers to task. */
  addSubscribers: (
    taskDuids: string[],
    subscriberDuids: string[],
    options?: { noUndo?: boolean; awaitBackend?: boolean }
  ) => Promise<void>;
  /* Remove subscribers from task. */
  removeSubscribers: (
    taskDuids: string[],
    subscriberDuids: string[],
    options?: { noUndo?: boolean; awaitBackend?: boolean }
  ) => Promise<void>;
  /* Add options to task. */
  addOptions: (
    property: PropertyAnyMultiselect,
    taskDuids: string[],
    optionDuids: string[],
    options?: { noUndo?: boolean; createTitle?: string; awaitBackend?: boolean }
  ) => Promise<TransactionResponse>;
  /* Remove options from task. */
  removeOptions: (
    property: PropertyAnyMultiselect,
    taskDuids: string[],
    optionDuids: string[],
    options?: { noUndo?: boolean; awaitBackend?: boolean }
  ) => Promise<void>;
  /* Add and remove options from task. */
  replaceOptions: (
    property: PropertyAnyMultiselect,
    taskDuids: string[],
    optionDuids: string[],
    options?: { noUndo?: boolean; awaitBackend?: boolean }
  ) => Promise<void>;
  /* Add attachments to a task. */
  addAttachments: (taskDuid: string, files: File[], options?: { awaitBackend?: boolean }) => Promise<void>;
  /* Delete attachment. */
  removeAttachment: (taskDuid: string, attachmentDuid: string, options?: { awaitBackend?: boolean }) => Promise<void>;
  /* Add link to task. */
  addLink: (
    taskDuid: string,
    kind: TaskLinkKind,
    url: string,
    title: string | null,
    options?: { awaitBackend?: boolean }
  ) => Promise<void>;
  /* Update the link of a task. */
  updateLink: (taskDuid: string, linkUpdate: TaskLinkUpdate, options?: { awaitBackend?: boolean }) => Promise<void>;
  /* Delete link from task. */
  deleteLink: (taskDuid: string, linkDuid: string, options?: { awaitBackend?: boolean }) => Promise<void>;
  /* Create some links, update others, and delete others from task. */
  addUpdateAndDeleteLinks: (
    taskDuid: string,
    creates: TaskLinkCreate[],
    updates: TaskLinkUpdate[],
    deletes: string[],
    options?: { awaitBackend?: boolean }
  ) => Promise<void>;
  /* Add Notion document to task. */
  addNotionDocument: (
    taskDuid: string,
    pageId: string,
    options?: { awaitBackend?: boolean }
  ) => Promise<Task | undefined>;
  /* Get Notion document for task ( cached if possible ). */
  getNotionDocument: (taskDuid: string) => Promise<Task | undefined>;
  /* Refresh Notion document for task. */
  refreshNotionDocument: (taskDuid: string) => Promise<Task | undefined>;
  /* Delete Notion document from task. */
  removeNotionDocument: (taskDuid: string, options?: { awaitBackend?: boolean }) => Promise<Task | undefined>;
  /* Create a relationship between two tasks. */
  createRelationship: (
    sourceDuid: string,
    targetDuid: string,
    relationshipKindDuid: string,
    options?: { noBackend?: boolean; awaitBackend?: boolean }
  ) => Promise<Relationship | undefined>;
  /** Replicate a task recursively.
   * @PRIVATE */
  $replicateTaskRecursive: (
    task: Task,
    order: string,
    partialTask?: Partial<Task>,
    options?: {
      propertiesAndParentOnly?: boolean;
      includeDuplicate?: boolean;
      maintainTitle?: boolean;
      maintainStatus?: boolean;
    }
  ) => Promise<{
    replicatedTask: Task;
    taskCreates: TaskCreate[];
    relationshipCreates: RelationshipCreate[];
    taskDocRelationshipCreates: TaskDocRelationshipCreate[];
    linkCreates: TaskLinkCreate[];
    attachmentCreates: AttachmentCreate[];
  }>;
  deleteRelationship: (
    taskDuid: string,
    relationshipDuid: string,
    options?: { noBackend?: boolean; awaitBackend?: boolean }
  ) => Promise<void>;
  /* Delete a relationship and then create a new one. */
  deleteAndCreateRelationshipAndUpdateTask: (
    relationshipDelete?: { taskDuid: string; relationshipDuid: string },
    relationshipCreate?: { sourceDuid: string; targetDuid: string; relationshipKindDuid: string },
    taskUpdate?: TaskUpdate,
    options?: { noBackend?: boolean; celebrateOverride?: boolean; awaitBackend?: boolean }
  ) => Promise<void>;
  /** Create one direction of a relationship internally.
   * @PRIVATE */
  $createRelationshipOneDirectionNoBackend: (
    relationshipDuid: string,
    modTaskDuid: string,
    otherTaskDuid: string,
    relationshipKindDuid: string,
    isForward: boolean
  ) => Relationship | undefined;
  /** Get which of the subscribers need be added to the BE.
   * @PRIVATE */
  $updateTaskSubscribersNoBackend: (task: Task, potentialSubscriberDuids: string[]) => string[];
  /** Add attachments FE only.
   * @PRIVATE */
  $addLinksNoBackend: (task: Task, linkCreates: TaskLinkCreate[]) => void;
  $updateLinksNoBackend: (task: Task, linkUpdates: TaskLinkUpdate[]) => void;
  $deleteLinksNoBackend: (task: Task, linkDuids: string[]) => void;
  /** Check if a given task is a draft and is the primary (i.e. not a child of a draft).
   * @PRIVATE */
  $isPrimaryDraft: (task: Task) => boolean;
  /** Set tasks internally.
   * @PRIVATE */
  $setTasks: (tasks: Task[], preservationMode: StoreDataPreservationMode) => void;
  /** Create or update task from WS.
   * @PRIVATE */
  $createOrUpdateTask: (task: Task) => void;
  /** Delete task from WS.
   * @PRIVATE */
  $deleteTask: (task: Task) => void;
  /** Remap incompatible properties of tasks using a modal, returns the updated task updates or an empty array if the user cancels
   * @PRIVATE */
  $remapPropertiesOfTasksAsNeeded: (updates: TaskUpdate[], onRemapFailed: () => void) => Promise<TaskUpdate[]>;
};

const SINGULAR_TASK_FIELD_NAME_REMAP = new Map([
  ["statusDuid", "status"],
  ["assigneeDuids", "assignee"],
  ["subscriberDuids", "subscriber"],
  ["tagDuids", "tag"],
  ["startAt", "start date"],
  ["dueAt", "due date"],
  ["remindAt", "reminder"],
]);
const PLURAL_TASK_FIELD_NAME_REMAP = new Map([
  ["statusDuid", "statuses"],
  ["priority", "priorities"],
]);

const makeDefaultMakeDescription =
  (field: string | undefined, count: number) => (isActive: boolean, content: string) => {
    if (field === undefined) {
      return `${isActive ? "updating" : "updated"} ${content}`;
    }
    const singularField = SINGULAR_TASK_FIELD_NAME_REMAP.get(field) ?? field;
    const pluralField = PLURAL_TASK_FIELD_NAME_REMAP.get(field) ?? `${field}s`;
    return `set${isActive ? "ting" : ""} ${count !== 1 ? pluralField : singularField} for ${content}`;
  };

const getters: PiniaGetterAdaptor<Getters, DataStore> = {
  taskDraft() {
    if (!this.taskDraftDuid) {
      return undefined;
    }

    return this.getTaskByDuid(this.taskDraftDuid);
  },
  getTaskList() {
    return (options = {}) => {
      const { includeTrashed = false, includeDraft = false } = options;
      const taskComparator = makeTaskComparator(this.getSpaceByDuid, this.getDartboardByDuid);
      return [...this._duidsToTasks.values()]
        .filter((e) => (includeTrashed || !e.inTrash) && (includeDraft || !e.drafterDuid))
        .sort(taskComparator);
    };
  },
  getTaskByDuid() {
    return (duid) => this._duidsToTasks.get(duid);
  },
  getTasksByDuids() {
    return (duids, options = {}) => {
      const { includeTrashed = false, includeDraft = false, absenteeDuids = [] } = options;
      const getAll = (duidsInner: string[]) =>
        [...new Set(duidsInner)]
          .map((e) => this.getTaskByDuid(e))
          .filter((e): e is Task => e !== undefined)
          .filter((e) => (includeTrashed || !e.inTrash) && (includeDraft || !e.drafterDuid));
      const absentees = getAll(absenteeDuids).map((e) => ({ ...e, absentee: true }));
      return [...getAll(duids), ...absentees];
    };
  },
  getTasksByDuidsOrdered() {
    return (duids, options) => this.getTasksByDuids(duids, options).sort(makeStringComparator((e) => e.order));
  },
  getTasksByKindDuid() {
    return (taskKindDuid) => this.getTaskList().filter((e) => e.kindDuid === taskKindDuid);
  },
  getTasksByStatusDuid() {
    return (statusDuid) => this.getTaskList().filter((e) => e.statusDuid === statusDuid);
  },
  tasksInTrash() {
    return this.getTaskList({ includeTrashed: true }).filter((e) => e.inTrash);
  },
  getTasksByDartboardDuidOrdered() {
    return (dartboardDuid, options = {}) => {
      const { includeAncestors = false } = options;
      const tasks = this.getTaskList(options).filter((e) => e.dartboardDuid === dartboardDuid);
      if (!includeAncestors) {
        return tasks.sort(makeStringComparator((e) => e.order));
      }
      const taskDuids = new Set(tasks.map((e) => e.duid));
      const ancestorDuids = [...new Set(tasks.map((e) => this.getAncestorDuids(e)).flat())].filter(
        (e) => !taskDuids.has(e)
      );
      return this.getTasksByDuidsOrdered([...taskDuids], { ...options, absenteeDuids: ancestorDuids });
    };
  },
};

const actions: PiniaActionAdaptor<Actions, DataStore> = {
  isTaskEmpty(task) {
    return (
      task.title.trim() === "" &&
      isLexicalStateEmpty(task.description) &&
      task.tagDuids.length === 0 &&
      task.links.length === 0 &&
      task.attachmentDuids.length === 0 &&
      this.getRelationshipsByKindKind(task, RelationshipKindKind.PARENT_OF, true).length === 0 &&
      task.priority === null &&
      task.size === null &&
      task.startAt === null &&
      task.dueAt === null &&
      task.notionDocument == null
    );
  },
  async createTask(title, dartboardDuid, order, source, partialTask, options) {
    const tasks = await this.createTasks(
      [
        {
          duid: makeDuid(),
          title,
          dartboardDuid,
          order,
          ...partialTask,
        },
      ],
      source,
      options
    );
    return tasks[0];
  },
  async createTasks(tasks, source, options = {}) {
    const { noUndo = false, relatedDocDuid, awaitBackend = false } = options;

    const appStore = this.$useAppStore();
    const userStore = this.$useUserStore();

    const taskKindDuid = this.defaultTaskKind.duid;
    const statusDuid = this.defaultDefaultUnstartedStatus.duid;
    const userDuid = userStore.duid;
    const now = new Date().toISOString();
    const newTasks = tasks.map((taskCreate) => {
      const newTask: Task = {
        sourceType: source,
        createdAt: now,
        createdByDuid: userDuid,
        updatedAt: now,
        updatedByDuid: userDuid,
        drafterDuid: null,
        inTrash: false,
        assignedToAi: false,
        recommendationDuid: null,
        dartboardDuid: "",
        order: "",
        expanded: true,
        kindDuid: taskKindDuid,
        title: "",
        description: makeEmptyLexicalState(),
        statusDuid,
        assigneeDuids: [userDuid],
        subscriberDuids: [userDuid],
        tagDuids: [],
        relationships: [],
        links: [],
        attachmentDuids: [],
        priority: null,
        size: null,
        startAt: null,
        dueAt: null,
        timeTracking: [],
        remindAt: null,
        recurrence: null,
        recursNextAt: null,
        properties: {},
        notionDocument: null,
        ...taskCreate,
      };
      const taskWithDefaults = overrideTaskWithDefaults(this, newTask);
      const overriddenTask = overrideTaskAndAdjustFilters(this, taskWithDefaults, taskCreate, source);
      if (overriddenTask.assigneeDuids) {
        overriddenTask.subscriberDuids = [
          ...new Set([...overriddenTask.subscriberDuids, ...overriddenTask.assigneeDuids]),
        ];
      }

      this.$createOrUpdateTask(overriddenTask);
      appStore.addRecentTask(overriddenTask);
      return overriddenTask;
    });

    const newTaskRelationships = newTasks
      .map((newTask) =>
        newTask.relationships
          .map(({ duid, kindDuid, targetDuid, isForward }) => [
            this.$createRelationshipOneDirectionNoBackend(duid, targetDuid, newTask.duid, kindDuid, !isForward),
            targetDuid,
          ])
          .filter((e): e is [Relationship, string] => !!e[0])
          .map(([relationship, sourceDuid]) => ({ ...relationship, sourceDuid }))
      )
      .flat();

    const newDocRelationships = relatedDocDuid
      ? newTasks.map((newTask) => {
          const taskDocRelationshipCreate = { duid: makeDuid(), taskDuid: newTask.duid, docDuid: relatedDocDuid };
          this.$addTaskDocRelationshipNoBackend(taskDocRelationshipCreate);
          return taskDocRelationshipCreate;
        })
      : [];

    const backendAction = this.$backend.task.createMany(newTasks, newTaskRelationships, newDocRelationships);
    if (awaitBackend) {
      await backendAction;
    }

    if (!noUndo) {
      const content = newTasks.length > 1 ? getItemCountText(newTasks.length, "task") : newTasks[0].title;
      this.saveToUndo(
        { modelType: ModelType.TASK, duid: newTasks.map((e) => e.duid) },
        [`created ${content} tasks`, `creating ${content}`],
        () => this.deleteTasks(newTasks),
        () => this.createTasks(tasks, source, { noUndo: true })
      );
    }

    if (newTasks.some((e) => e.drafterDuid === null)) {
      userStore.updateTutorialStatuses([{ name: TutorialName.MAKE_A_TASK, status: 2 }]);
    }

    return newTasks;
  },
  async updateTasks(updates, options) {
    this.updateAndDeleteTasks(updates, [], options);
  },
  async updateTaskFromNlp(task, update, options) {
    const { duid, assigneeDuids, tagDuids, subscriberDuids } = task;
    const updateNorm: TaskUpdate = {
      ...update,
      tagDuids: [...new Set([...tagDuids, ...(update.tagDuids ?? [])])],
      duid,
      title: update.title ?? "",
    };
    if (update.assigneeDuids && update.assigneeDuids.length > 0) {
      updateNorm.subscriberDuids = [...new Set([...subscriberDuids, ...update.assigneeDuids])];
      if (this.$useTenantStore().multipleAssigneesEnabled) {
        updateNorm.assigneeDuids = [...new Set([...assigneeDuids, ...update.assigneeDuids])];
      }
    }
    this.updateTasks([updateNorm], { ...options, noUndo: true });
  },
  async replicateTasks(tasksToReplicate, options = {}) {
    const {
      noUndo = false,
      propertiesAndParentOnly = false,
      includeDuplicate = false,
      awaitBackend = false,
      maintainTitle = false,
      maintainStatus = false,
    } = options;

    if (tasksToReplicate.length === 0) {
      return [];
    }

    const parentKindDuid = this.getRelationshipKindByKind(RelationshipKindKind.PARENT_OF).duid;

    let filteredTasksToReplicate = tasksToReplicate;
    if (!propertiesAndParentOnly) {
      const taskDuids = new Set(tasksToReplicate.map((e) => e.task.duid));
      filteredTasksToReplicate = filteredTasksToReplicate.filter(({ task }) =>
        this.getAncestorDuids(task).every((ancestor) => !taskDuids.has(ancestor))
      );
    }

    const tasksToCreate: TaskCreate[] = [];
    const relationshipsToCreate: RelationshipCreate[] = [];
    const taskDocRelationshipsToCreate: TaskDocRelationshipCreate[] = [];
    const linksToCreate: TaskLinkCreate[] = [];
    const attachmentsToCreate: AttachmentCreate[] = [];

    const replicatedTasks: Task[] = [];
    await Promise.all(
      filteredTasksToReplicate.map(async ({ task, order, partialTask }) => {
        const normPartialTask = partialTask ?? {};
        normPartialTask.relationships = normPartialTask.relationships ?? [];

        const parentRelationship = this.getRelationshipsByKindKind(task, RelationshipKindKind.PARENT_OF, false)[0];
        if (parentRelationship && !normPartialTask.relationships.some((e) => e.kindDuid === parentKindDuid)) {
          // TODO unclear why reactive is needed here. should figure it out and remove
          const replicateParentRelationship = reactive({ ...parentRelationship, duid: makeDuid() });
          normPartialTask.relationships.push(replicateParentRelationship);
        }

        const {
          replicatedTask,
          taskCreates,
          relationshipCreates,
          taskDocRelationshipCreates,
          linkCreates,
          attachmentCreates,
        } = await this.$replicateTaskRecursive(task, order, normPartialTask, {
          propertiesAndParentOnly,
          includeDuplicate,
          maintainTitle,
          maintainStatus,
        });

        replicatedTasks.push(replicatedTask);
        tasksToCreate.push(...taskCreates);
        relationshipsToCreate.push(...relationshipCreates);
        taskDocRelationshipsToCreate.push(...taskDocRelationshipCreates);
        linksToCreate.push(...linkCreates);
        attachmentsToCreate.push(...attachmentCreates);

        if (this.$isPrimaryDraft(replicatedTask)) {
          this.taskDraftDuid = replicatedTask.duid;
        }
      })
    );

    const taskCreateAction = this.$backend.task.createMany(
      tasksToCreate,
      relationshipsToCreate,
      taskDocRelationshipsToCreate,
      linksToCreate,
      attachmentsToCreate
    );

    if (awaitBackend) {
      await taskCreateAction;
    }

    const content =
      replicatedTasks.length > 1
        ? getItemCountText(replicatedTasks.length, "task")
        : `task ${replicatedTasks[0].title}`;

    if (!noUndo) {
      this.saveToUndo(
        { modelType: ModelType.TASK, duid: replicatedTasks.map((e) => e.duid) },
        [`Replicated ${content}`, `replicating ${content}`],
        () => this.deleteTasks(this.getTasksByDuidsOrdered(tasksToCreate.map((e) => e.duid))),
        () => this.replicateTasks(tasksToReplicate, options)
      );
    }

    return replicatedTasks;
  },
  async deleteTasks(tasks, options) {
    this.updateAndDeleteTasks([], tasks, { ...options, noUndo: true });
  },
  async updateAndDeleteTasks(updates, deletes, options = {}) {
    const {
      noUndo = false,
      noBackend = false,
      noCelebrate = false,
      celebrateOverride = false,
      makeDescription,
      overrideFilters = false,
      awaitBackend = false,
      onRemapFailed = () => {},
    } = options;

    // eslint-disable-next-line no-param-reassign
    updates = await this.$remapPropertiesOfTasksAsNeeded(updates, onRemapFailed);
    if (updates.length === 0 && deletes.length === 0) {
      return;
    }

    const appStore = this.$useAppStore();
    const tenantStore = this.$useTenantStore();
    const userStore = this.$useUserStore();
    const updaterDuid = userStore.duid;

    // TODO if there are automated updates that update the same task as a normal update or as each other, combine them
    const automatedUpdates: TaskUpdate[] = [];
    if (tenantStore.closeParentOnCloseAllSubtasks) {
      const completedStatusDuids = new Set(
        this.getStatusesByKinds([...COMPLETED_STATUS_KINDS], this.defaultStatusProperty).map((e) => e.duid)
      );
      const newlyCompletedDuidsList = updates
        .filter((e) => e.statusDuid && completedStatusDuids.has(e.statusDuid))
        .map((e) => e.duid);

      const finishedStatusDuid = this.defaultDefaultFinishedStatus.duid;
      const newlyCompletedDuids = new Set(newlyCompletedDuidsList);
      const queue = this.getTasksByDuids(newlyCompletedDuidsList);
      while (queue.length !== 0) {
        const curr = queue.shift() as Task;
        const parent = this.getParentTask(curr);
        if (!parent || completedStatusDuids.has(parent.statusDuid) || newlyCompletedDuids.has(parent.duid)) {
          continue;
        }
        const siblings = this.getTasksByDuids(
          this.getRelationshipsByKindKind(parent, RelationshipKindKind.PARENT_OF, true).map((e) => e.targetDuid)
        );
        if (siblings.some((e) => !completedStatusDuids.has(e.statusDuid) && !newlyCompletedDuids.has(e.duid))) {
          continue;
        }
        newlyCompletedDuids.add(parent.duid);
        queue.push(parent);
        automatedUpdates.push({ duid: parent.duid, statusDuid: finishedStatusDuid });
      }
    }
    if (tenantStore.moveSubtasksOnMoveParent) {
      updates.forEach((update) => {
        if (!update.dartboardDuid) {
          return;
        }
        const oldTask = this.getTaskByDuid(update.duid);
        if (!oldTask) {
          return;
        }
        const oldDartboardDuid = oldTask.dartboardDuid;
        this.getTasksByDuids(this.getDescendantDuids(oldTask)).forEach((task) => {
          if (task.dartboardDuid !== oldDartboardDuid) {
            return;
          }
          automatedUpdates.push({ duid: task.duid, dartboardDuid: update.dartboardDuid });
        });
      });
    }
    if (tenantStore.updateSubtasksStatusOnUpdateParentStatus) {
      updates.forEach((update) => {
        if (!update.statusDuid) {
          return;
        }
        const oldTask = this.getTaskByDuid(update.duid);
        if (!oldTask) {
          return;
        }
        const newStatus = this.getStatusByDuid(update.statusDuid);
        this.getTasksByDuids(this.getDescendantDuids(oldTask)).forEach((task) => {
          if (statusComparator(this.getStatusByDuid(task.statusDuid), newStatus) !== -1) {
            return;
          }
          automatedUpdates.push({ duid: task.duid, statusDuid: update.statusDuid });
        });
      });
    }
    if (tenantStore.updateBlockeeDatesOnUpdateBlockerDueDate) {
      const newlyUpdatedDuidToDueAt = new Map(
        updates
          .filter((e) => e.dueAt)
          .map(({ duid, startAt, dueAt }) => [duid, { startAt: startAt ?? undefined, dueAt: dueAt ?? undefined }])
      );

      const newlyUpdateDuidsList = [...newlyUpdatedDuidToDueAt.keys()];
      const queue = this.getTasksByDuids(newlyUpdateDuidsList);
      while (queue.length !== 0) {
        const curr = queue.shift() as Task;
        const dueAt = newlyUpdatedDuidToDueAt.get(curr.duid)?.dueAt;
        if (!dueAt) {
          continue;
        }

        this.getTasksByDuidsOrdered(
          this.getRelationshipsByKindKind(curr, RelationshipKindKind.BLOCKS, true).map((e) => e.targetDuid)
        ).forEach((blockee) => {
          const currentStartDueAt = newlyUpdatedDuidToDueAt.get(blockee.duid);
          const currentStartAt = currentStartDueAt?.startAt ?? blockee.startAt;
          const currentDueAt = currentStartDueAt?.dueAt ?? blockee.dueAt;
          if (!currentStartAt) {
            if (currentDueAt && currentDueAt < dueAt) {
              newlyUpdatedDuidToDueAt.set(blockee.duid, { startAt: undefined, dueAt });
              queue.push(blockee);
            }
            return;
          }
          if (currentStartAt > dueAt) {
            return;
          }
          const newDueAt = currentDueAt
            ? moment(dueAt).add(moment(currentDueAt).diff(currentStartAt)).toISOString()
            : undefined;
          newlyUpdatedDuidToDueAt.set(blockee.duid, { startAt: dueAt, dueAt: newDueAt });
          if (newDueAt) {
            queue.push(blockee);
          }
        });
      }

      automatedUpdates.push(...[...newlyUpdatedDuidToDueAt.entries()].map(([duid, e]) => ({ duid, ...e })));
    }
    updates.push(...automatedUpdates);

    const subscriberListAdds: TaskUpdate[] = [];
    const inverseUpdates: TaskUpdate[] = [];

    updates.forEach((update) => {
      const task = this.getTaskByDuid(update.duid);
      if (!task) {
        return;
      }

      if (overrideFilters) {
        removeFiltersThatDoNotPassForTask(this, { ...task, ...update });
      }

      const subscriberDuids = this.$updateTaskSubscribersNoBackend(task, [updaterDuid]);
      if (subscriberDuids.length > 0) {
        subscriberListAdds.push({ duid: task.duid, subscriberDuids });
      }

      const inverseUpdate: TaskUpdate = { duid: task.duid };

      // eslint-disable-next-line no-restricted-syntax
      for (const [field, value] of Object.entries(update)) {
        if (field === "duid") {
          continue;
        }
        if (field === "inTrash" && value === true) {
          appStore.selectedTaskDuids.delete(task.duid);
        }
        if (
          !noCelebrate &&
          field === "statusDuid" &&
          this.getStatusByDuid(value as string)?.kind === StatusKind.FINISHED &&
          (this.getStatusByDuid(task.statusDuid)?.kind !== StatusKind.FINISHED || celebrateOverride)
        ) {
          firework();
        }

        const fieldAsKeyOfTask = field as keyof Task;
        (inverseUpdate[field as keyof TaskUpdate] as unknown) = task[fieldAsKeyOfTask];
        (task[fieldAsKeyOfTask] as unknown) = value;
      }

      inverseUpdates.push(inverseUpdate);
      task.updatedAt = new Date().toISOString();
      task.updatedByDuid = updaterDuid;

      appStore.addRecentTask(task);

      if (this.$isPrimaryDraft(task)) {
        this.taskDraftDuid = task.duid;
      } else if (task.duid === this.taskDraft?.duid) {
        this.taskDraftDuid = null;
        userStore.updateTutorialStatuses([{ name: TutorialName.MAKE_A_TASK, status: 2 }]);
      }
    });

    deletes.forEach((task) => {
      const subscriberDuids = this.$updateTaskSubscribersNoBackend(task, [updaterDuid]);
      if (subscriberDuids.length > 0) {
        subscriberListAdds.push({ duid: task.duid, subscriberDuids });
      }

      this._duidsToTasks.delete(task.duid);

      if (task.duid === this.taskDraft?.duid) {
        this.taskDraftDuid = null;
      }

      this.$useAppStore().selectedTaskDuids.delete(task.duid);
    });

    if (!noBackend) {
      const backendAction = this.$backend.task.updateUpdateListAddAndDeleteMany(
        updates,
        deletes.map((e) => e.duid),
        subscriberListAdds
      );
      if (awaitBackend) {
        await backendAction;
      }
    }

    if (!noUndo) {
      const fields = [...new Set(updates.map((e) => Object.keys(e)).flat())].filter((e) => e !== "duid");
      const field = fields.length === 0 ? fields[0] : undefined;

      const tasks = [...new Set(updates.map((e) => e.duid))]
        .map((e) => this.getTaskByDuid(e))
        .filter((e): e is Task => !!e);
      const content = tasks.length === 1 ? tasks[0].title : getItemCountText(tasks.length, "task");
      const makeDescriptionNorm = makeDescription ?? makeDefaultMakeDescription(field, tasks.length);
      const descriptionPast = makeDescriptionNorm(false, content);
      const descriptionActive = makeDescriptionNorm(true, content);

      this.saveToUndo(
        {
          modelType: ModelType.TASK,
          duid: updates.map((u) => u.duid),
          field,
        },
        [descriptionPast, descriptionActive],
        () => this.updateTasks(inverseUpdates, { noUndo: true, noBackend }),
        () => this.updateTasks(updates, { noUndo: true, noBackend })
      );
    }
  },
  async addUsers(property, taskDuids, userDuids, options = {}) {
    const { noUndo = false, awaitBackend = false } = options;

    const appStore = this.$useAppStore();

    const { isDefault } = getPropertyConfig(property.kind);
    const updaterDuid = this.$useUserStore().duid;

    const taskUpdates: TaskUpdate[] = [];
    const subscriberListAdds: TaskUpdate[] = [];

    taskDuids.forEach((taskDuid) => {
      const task = this.getTaskByDuid(taskDuid);
      if (!task) {
        return;
      }

      const currentUserDuids = getPropertyValueFromTask(property, task);
      const newUserDuidsOrdered = this.getUsersByDuidsOrdered([...new Set([...currentUserDuids, ...userDuids])]).map(
        (e) => e.duid
      );
      const partialTaskUpdate = getPropertyPartialTask(property, task, newUserDuidsOrdered);
      Object.assign(task, partialTaskUpdate);
      task.updatedAt = new Date().toISOString();
      task.updatedByDuid = updaterDuid;

      appStore.addRecentTask(task);

      const taskUpdate: TaskUpdate = {
        duid: task.duid,
        ...(isDefault ? { assigneeDuids: userDuids } : partialTaskUpdate),
      };

      const subscriberDuids = this.$updateTaskSubscribersNoBackend(task, [updaterDuid, ...userDuids]);
      if (subscriberDuids.length > 0) {
        subscriberListAdds.push({ duid: task.duid, subscriberDuids });
      }

      taskUpdates.push(taskUpdate);
    });

    let backendAction: Promise<TransactionResponse>;

    if (isDefault) {
      backendAction = this.$backend.task.updateListAddMany([...taskUpdates, ...subscriberListAdds]);
    } else {
      backendAction = this.$backend.task.updateAndUpdateListAddMany(taskUpdates, subscriberListAdds);
    }

    if (awaitBackend) {
      await backendAction;
    }

    if (!noUndo) {
      const addedUsersStr = prettyFormatList(this.getUsersByDuidsOrdered(userDuids).map((e) => e.name || e.email));

      const content =
        taskDuids.length > 1
          ? getItemCountText(taskDuids.length, "task")
          : `task ${this.getTaskByDuid(taskDuids[0])?.title ?? ""}`;
      this.saveToUndo(
        { modelType: ModelType.TASK, duid: taskDuids, field: property.duid },
        [
          `${isDefault ? "Assigned" : `Added ${property.title.toLowerCase()}`} ${addedUsersStr} to ${content}`,
          `${isDefault ? "assigning" : `adding ${property.title.toLowerCase()}`} ${addedUsersStr} to ${content}`,
        ],
        () => this.removeUsers(property, taskDuids, userDuids, { noUndo: true }),
        () => this.addUsers(property, taskDuids, userDuids, { noUndo: true })
      );
    }
  },
  async removeUsers(property, taskDuids, userDuids, options = {}) {
    const { noUndo = false, awaitBackend = false } = options;

    const appStore = this.$useAppStore();

    const { isDefault } = getPropertyConfig(property.kind);
    const updaterDuid = this.$useUserStore().duid;

    const taskUpdates: TaskUpdate[] = [];
    taskDuids.forEach((taskDuid) => {
      const task = this.getTaskByDuid(taskDuid);
      if (!task) {
        return;
      }

      const currentUserDuids = getPropertyValueFromTask(property, task);
      const partialTaskUpdate = getPropertyPartialTask(
        property,
        task,
        currentUserDuids.filter((e) => !userDuids.includes(e))
      );
      Object.assign(task, partialTaskUpdate);
      task.updatedAt = new Date().toISOString();
      task.updatedByDuid = updaterDuid;

      appStore.addRecentTask(task);

      taskUpdates.push({ duid: task.duid, ...(isDefault ? { assigneeDuids: userDuids } : partialTaskUpdate) });
    });

    let backendAction: Promise<TransactionResponse>;
    // TODO subscribe updater
    if (isDefault) {
      backendAction = this.$backend.task.updateListRemoveMany(taskUpdates);
    } else {
      backendAction = this.$backend.task.updateMany(taskUpdates);
    }

    if (awaitBackend) {
      await backendAction;
    }

    if (!noUndo) {
      const removedUsersStr = prettyFormatList(this.getUsersByDuidsOrdered(userDuids).map((e) => e.name || e.email));

      const content =
        taskDuids.length > 1
          ? getItemCountText(taskDuids.length, "task")
          : `task ${this.getTaskByDuid(taskDuids[0])?.title ?? ""}`;
      this.saveToUndo(
        { modelType: ModelType.TASK, duid: taskDuids, field: property.duid },
        [
          `${isDefault ? "Unassigned" : `Removed ${property.title.toLowerCase()}`} ${removedUsersStr} from ${content}`,
          `${
            isDefault ? "unassigning" : `removing ${property.title.toLowerCase()}`
          } ${removedUsersStr} from ${content}`,
        ],
        () => this.addUsers(property, taskDuids, userDuids, { noUndo: true }),
        () => this.removeUsers(property, taskDuids, userDuids, { noUndo: true })
      );
    }
  },
  async replaceUsers(property, taskDuids, userDuids, options = {}) {
    const { noUndo = false, awaitBackend = false } = options;

    const appStore = this.$useAppStore();

    const { isDefault } = getPropertyConfig(property.kind);
    const updaterDuid = this.$useUserStore().duid;

    const taskUpdates: TaskUpdate[] = [];
    const subscriberListAdds: TaskUpdate[] = [];

    const oldUserDuids: string[] = [];

    taskDuids.forEach((taskDuid) => {
      const task = this.getTaskByDuid(taskDuid);
      if (!task) {
        return;
      }

      const currentUserDuids = getPropertyValueFromTask(property, task);
      oldUserDuids.push(...currentUserDuids);
      const newUserDuidsOrdered = this.getUsersByDuidsOrdered([...new Set(userDuids)]).map((e) => e.duid);
      const partialTaskUpdate = getPropertyPartialTask(property, task, newUserDuidsOrdered);
      Object.assign(task, partialTaskUpdate);
      task.updatedAt = new Date().toISOString();
      task.updatedByDuid = updaterDuid;

      appStore.addRecentTask(task);

      const taskUpdate: TaskUpdate = { duid: task.duid, ...partialTaskUpdate };
      if (task.assignedToAi) {
        taskUpdate.assignedToAi = false;
        task.assignedToAi = false;
      }

      const subscriberDuids = this.$updateTaskSubscribersNoBackend(task, [updaterDuid, ...userDuids]);
      if (subscriberDuids.length > 0) {
        subscriberListAdds.push({ duid: task.duid, subscriberDuids });
      }

      taskUpdates.push(taskUpdate);
    });

    const backendAction = this.$backend.task.updateAndUpdateListAddMany(taskUpdates, subscriberListAdds);
    if (awaitBackend) {
      await backendAction;
    }

    if (!noUndo) {
      const addedUsersStr = prettyFormatList(this.getUsersByDuidsOrdered(userDuids).map((e) => e.name || e.email));
      const pastAction = userDuids
        ? `${isDefault ? "Assigned" : `Added ${property.title.toLowerCase()}`} ${addedUsersStr} to`
        : isDefault
          ? "Unassigned"
          : "Removed";
      const presentAction = userDuids
        ? `${isDefault ? "assigning" : `adding ${property.title.toLowerCase()}`} ${addedUsersStr} to`
        : isDefault
          ? "unassigning"
          : "removing";
      const content =
        taskDuids.length > 1
          ? getItemCountText(taskDuids.length, "task")
          : `task ${this.getTaskByDuid(taskDuids[0])?.title ?? ""}`;
      this.saveToUndo(
        { modelType: ModelType.TASK, duid: taskDuids, field: property.duid },
        [`${pastAction} ${content}`, `${presentAction} ${content}`],
        () => this.replaceUsers(property, taskDuids, oldUserDuids, { noUndo: true }),
        () => this.replaceUsers(property, taskDuids, userDuids, { noUndo: true })
      );
    }
  },
  async addSubscribers(taskDuids, subscriberDuids, options = {}) {
    const { noUndo = false, awaitBackend = false } = options;

    const appStore = this.$useAppStore();

    const updaterDuid = this.$useUserStore().duid;

    const taskUpdates: TaskUpdate[] = [];
    taskDuids.forEach((taskDuid) => {
      const task = this.getTaskByDuid(taskDuid);
      if (!task) {
        return;
      }

      task.subscriberDuids = [...new Set([...task.subscriberDuids, ...subscriberDuids])];
      task.updatedAt = new Date().toISOString();
      task.updatedByDuid = updaterDuid;

      appStore.addRecentTask(task);

      taskUpdates.push({ duid: taskDuid, subscriberDuids });
    });

    const backendAction = this.$backend.task.updateListAddMany(taskUpdates);
    if (awaitBackend) {
      await backendAction;
    }

    if (!noUndo) {
      const addedSubscribersStr = prettyFormatList(
        this.getUsersByDuidsOrdered(subscriberDuids).map((e) => e.name || e.email)
      );
      const content =
        taskDuids.length > 1
          ? getItemCountText(taskDuids.length, "task")
          : `task ${this.getTaskByDuid(taskDuids[0])?.title ?? ""}`;
      this.saveToUndo(
        { modelType: ModelType.TASK, duid: taskDuids, field: "subscriberDuids" },
        [`Subscribed ${addedSubscribersStr} to ${content}`, `subscribing ${addedSubscribersStr} to ${content}`],
        () => this.removeSubscribers(taskDuids, subscriberDuids, { noUndo: true }),
        () => this.addSubscribers(taskDuids, subscriberDuids, { noUndo: true })
      );
    }
  },
  async removeSubscribers(taskDuids, subscriberDuids, options = {}) {
    const { noUndo = false, awaitBackend = false } = options;

    const appStore = this.$useAppStore();

    const updaterDuid = this.$useUserStore().duid;

    const taskUpdates: TaskUpdate[] = [];
    taskDuids.forEach((taskDuid) => {
      const task = this.getTaskByDuid(taskDuid);
      if (!task) {
        return;
      }

      task.subscriberDuids = task.subscriberDuids.filter((e) => !subscriberDuids.includes(e));
      task.updatedAt = new Date().toISOString();
      task.updatedByDuid = updaterDuid;

      appStore.addRecentTask(task);

      taskUpdates.push({ duid: taskDuid, subscriberDuids });
    });

    const backendAction = this.$backend.task.updateListRemoveMany(taskUpdates);
    if (awaitBackend) {
      await backendAction;
    }

    if (!noUndo) {
      const removedSubscribersStr = prettyFormatList(
        this.getUsersByDuidsOrdered(subscriberDuids).map((e) => e.name || e.email)
      );
      const content =
        taskDuids.length > 1
          ? getItemCountText(taskDuids.length, "task")
          : `task ${this.getTaskByDuid(taskDuids[0])?.title ?? ""}`;
      this.saveToUndo(
        { modelType: ModelType.TASK, duid: taskDuids, field: "subscriberDuids" },
        [
          `Unsubscribed ${removedSubscribersStr} from ${content}`,
          `unsubscribing ${removedSubscribersStr} from ${content}`,
        ],
        () => this.addSubscribers(taskDuids, subscriberDuids, { noUndo: true }),
        () => this.removeSubscribers(taskDuids, subscriberDuids, { noUndo: true })
      );
    }
  },
  async addOptions(property, taskDuids, optionDuids, options = {}) {
    const { noUndo = false, createTitle, awaitBackend = false } = options;

    const appStore = this.$useAppStore();

    const { isDefault } = getPropertyConfig(property.kind);
    const updaterDuid = this.$useUserStore().duid;

    let createdOption: Option | undefined;
    if (createTitle) {
      createdOption = this.$createOptionNoBackend(createTitle, property);
      if (createdOption) {
        optionDuids.push(createdOption.duid);
      }
    }

    const taskUpdates: TaskUpdate[] = [];
    taskDuids.forEach((taskDuid) => {
      const task = this.getTaskByDuid(taskDuid);

      if (!task) {
        return;
      }

      // Sort options alphabetically
      const currentOptionDuids = getPropertyValueFromTask(property, task);
      const newDuids = [...currentOptionDuids, ...optionDuids];

      const partialTaskUpdate = getPropertyPartialTask(
        property,
        task,
        this.getOptionsByDuidsOrdered(newDuids).map((e) => e.duid)
      );
      Object.assign(task, partialTaskUpdate);
      task.updatedAt = new Date().toISOString();
      task.updatedByDuid = updaterDuid;

      appStore.addRecentTask(task);

      taskUpdates.push({
        duid: taskDuid,
        ...(isDefault ? { tagDuids: optionDuids } : partialTaskUpdate),
      });
    });

    // TODO subscribe updater

    let transactionResponse: Promise<TransactionResponse>;
    if (isDefault) {
      if (createdOption) {
        transactionResponse = this.$backend.option.createAndTaskUpdateListAddMany(createdOption, taskUpdates);
      } else {
        transactionResponse = this.$backend.task.updateListAddMany(taskUpdates);
      }
    } else if (createdOption) {
      transactionResponse = this.$backend.option.createAndTaskUpdateMany(createdOption, taskUpdates);
    } else {
      transactionResponse = this.$backend.task.updateMany(taskUpdates);
    }

    if (awaitBackend) {
      await transactionResponse;
    }

    if (!noUndo) {
      const addedOptionsStr = prettyFormatList(this.getOptionsByDuidsOrdered(optionDuids).map((t) => t.title));
      const content =
        taskDuids.length > 1
          ? getItemCountText(taskDuids.length, "task")
          : `task ${this.getTaskByDuid(taskDuids[0])?.title ?? ""}`;
      this.saveToUndo(
        { modelType: ModelType.TASK, duid: taskDuids, field: property.duid },
        [
          `Added ${property.title.toLowerCase()} ${addedOptionsStr} to ${content}`,
          `adding  ${property.title.toLowerCase()} ${addedOptionsStr} to ${content}`,
        ],
        () => this.removeOptions(property, taskDuids, optionDuids, { noUndo: true }),
        () => this.addOptions(property, taskDuids, optionDuids, { noUndo: true })
      );
    }
    return transactionResponse;
  },
  async removeOptions(property, taskDuids, optionDuids, options = {}) {
    const { noUndo = false, awaitBackend = false } = options;

    const appStore = this.$useAppStore();

    const { isDefault } = getPropertyConfig(property.kind);
    const updaterDuid = this.$useUserStore().duid;

    const taskUpdates: TaskUpdate[] = [];
    taskDuids.forEach((taskDuid) => {
      const task = this.getTaskByDuid(taskDuid);
      if (!task) {
        return;
      }

      const currentOptionDuids = getPropertyValueFromTask(property, task);
      const partialTaskUpdate = getPropertyPartialTask(
        property,
        task,
        currentOptionDuids.filter((e) => !optionDuids.includes(e))
      );
      Object.assign(task, partialTaskUpdate);
      task.updatedAt = new Date().toISOString();
      task.updatedByDuid = updaterDuid;

      appStore.addRecentTask(task);

      taskUpdates.push({ duid: taskDuid, ...(isDefault ? { tagDuids: optionDuids } : partialTaskUpdate) });
    });

    // TODO subscribe updater
    let backendAction: Promise<TransactionResponse>;

    if (isDefault) {
      backendAction = this.$backend.task.updateListRemoveMany(taskUpdates);
    } else {
      backendAction = this.$backend.task.updateMany(taskUpdates);
    }

    if (awaitBackend) {
      await backendAction;
    }

    if (!noUndo) {
      const removedOptionsStr = prettyFormatList(this.getOptionsByDuidsOrdered(optionDuids).map((t) => t.title));
      const content =
        taskDuids.length > 1
          ? getItemCountText(taskDuids.length, "task")
          : `task ${this.getTaskByDuid(taskDuids[0])?.title ?? ""}`;
      this.saveToUndo(
        { modelType: ModelType.TASK, duid: taskDuids, field: property.duid },
        [
          `Removed ${property.title.toLowerCase()} ${removedOptionsStr} from ${content}`,
          `removing ${property.title.toLowerCase()} ${removedOptionsStr} from ${content}`,
        ],
        () => this.addOptions(property, taskDuids, optionDuids, { noUndo: true }),
        () => this.removeOptions(property, taskDuids, optionDuids, { noUndo: true })
      );
    }
  },
  async replaceOptions(property, taskDuids, optionDuids, options = {}) {
    const { noUndo = false, awaitBackend = false } = options;

    const appStore = this.$useAppStore();

    const updaterDuid = this.$useUserStore().duid;

    const taskUpdates: TaskUpdate[] = [];
    const oldOptionDuids: string[] = [];
    taskDuids.forEach((taskDuid) => {
      const task = this.getTaskByDuid(taskDuid);
      if (!task) {
        return;
      }

      // Sort options alphabetically
      const currentOptionDuids = getPropertyValueFromTask(property, task);
      oldOptionDuids.push(...currentOptionDuids);

      const partialTaskUpdate = getPropertyPartialTask(
        property,
        task,
        this.getOptionsByDuidsOrdered([...new Set(optionDuids)]).map((e) => e.duid)
      );
      Object.assign(task, partialTaskUpdate);
      task.updatedAt = new Date().toISOString();
      task.updatedByDuid = updaterDuid;

      appStore.addRecentTask(task);

      taskUpdates.push({ duid: taskDuid, ...partialTaskUpdate });
    });

    // TODO subscribe updater

    const backendAction = this.$backend.task.updateMany(taskUpdates);
    if (awaitBackend) {
      await backendAction;
    }

    if (!noUndo) {
      const addedOptionsStr = prettyFormatList(this.getOptionsByDuidsOrdered(optionDuids).map((t) => t.title));
      const content =
        taskDuids.length > 1
          ? getItemCountText(taskDuids.length, "task")
          : `task ${this.getTaskByDuid(taskDuids[0])?.title ?? ""}`;
      this.saveToUndo(
        { modelType: ModelType.TASK, duid: taskDuids, field: property.duid },
        [
          `Set ${property.title.toLowerCase()} ${addedOptionsStr} on ${content}`,
          `setting  ${property.title.toLowerCase()} ${addedOptionsStr} on ${content}`,
        ],
        () => this.replaceOptions(property, taskDuids, oldOptionDuids, { noUndo: true }),
        () => this.replaceOptions(property, taskDuids, optionDuids, { noUndo: true })
      );
    }
  },
  async createRelationship(sourceDuid, targetDuid, relationshipKindDuid, options = {}) {
    const { noBackend = false, awaitBackend = false } = options;

    const appStore = this.$useAppStore();

    const sourceTask = this.getTaskByDuid(sourceDuid);
    const targetTask = this.getTaskByDuid(targetDuid);
    if (!sourceTask || !targetTask) {
      return undefined;
    }
    const preexistingRelationship = sourceTask.relationships.filter(
      (e) => e.targetDuid === targetDuid && e.kindDuid === relationshipKindDuid
    )[0];
    if (preexistingRelationship && preexistingRelationship.isForward) {
      return preexistingRelationship;
    }

    const duid = makeDuid();
    const forwardRelationship = this.$createRelationshipOneDirectionNoBackend(
      duid,
      sourceDuid,
      targetDuid,
      relationshipKindDuid,
      true
    );
    const backwardRelationship = this.$createRelationshipOneDirectionNoBackend(
      duid,
      targetDuid,
      sourceDuid,
      relationshipKindDuid,
      false
    );
    if (!forwardRelationship || !backwardRelationship) {
      return undefined;
    }

    appStore.addRecentTask(sourceTask);
    appStore.addRecentTask(targetTask);

    // TODO subscribe updater to both tasks

    if (!noBackend) {
      let backendAction: Promise<TransactionResponse>;
      const createObj = { ...forwardRelationship, sourceDuid };
      if (preexistingRelationship) {
        this.deleteRelationship(sourceDuid, preexistingRelationship.duid, { noBackend: true });
        backendAction = this.$backend.relationship.deleteAndCreate(preexistingRelationship.duid, createObj);
      } else {
        backendAction = this.$backend.relationship.create(createObj);
      }

      if (awaitBackend) {
        await backendAction;
      }
    }

    return forwardRelationship;
  },
  async deleteRelationship(sourceDuid, relationshipDuid, options = {}) {
    const { noBackend = false, awaitBackend = false } = options;

    const appStore = this.$useAppStore();

    const now = new Date().toISOString();
    const updaterDuid = this.$useUserStore().duid;

    const sourceTask = this.getTaskByDuid(sourceDuid);
    if (!sourceTask) {
      return;
    }
    const relationship = sourceTask.relationships.find((e) => e.duid === relationshipDuid);
    if (!relationship) {
      return;
    }
    const targetTask = this.getTaskByDuid(relationship.targetDuid);
    if (!targetTask) {
      return;
    }

    sourceTask.relationships = sourceTask.relationships.filter((e) => e.duid !== relationshipDuid);
    sourceTask.updatedAt = now;
    sourceTask.updatedByDuid = updaterDuid;
    targetTask.relationships = targetTask.relationships.filter((e) => e.duid !== relationshipDuid);
    targetTask.updatedAt = now;
    targetTask.updatedByDuid = updaterDuid;

    appStore.addRecentTask(sourceTask);
    appStore.addRecentTask(targetTask);

    // TODO subscribe updater to both tasks

    let backendAction: Promise<TransactionResponse> | undefined;
    if (!noBackend) {
      backendAction = this.$backend.relationship.delete(relationshipDuid);
    }

    if (awaitBackend && backendAction) {
      await backendAction;
    }
  },
  async deleteAndCreateRelationshipAndUpdateTask(relationshipDelete, relationshipCreate, taskUpdate, options = {}) {
    if (!relationshipDelete && !relationshipCreate && !taskUpdate) {
      return;
    }

    const { noBackend = false, celebrateOverride = false, awaitBackend = false } = options;

    let deleteDuid: string | undefined;
    let relationshipCreateFormal: RelationshipCreate | undefined;

    if (relationshipDelete) {
      const { taskDuid, relationshipDuid } = relationshipDelete;
      this.deleteRelationship(taskDuid, relationshipDuid, { noBackend: true, awaitBackend });
      deleteDuid = relationshipDuid;
    }

    if (relationshipCreate) {
      const { sourceDuid, targetDuid, relationshipKindDuid } = relationshipCreate;
      const forwardRelationship = await this.createRelationship(sourceDuid, targetDuid, relationshipKindDuid, {
        noBackend: true,
      });
      if (forwardRelationship) {
        relationshipCreateFormal = { ...forwardRelationship, sourceDuid };
      }
    }

    if (taskUpdate) {
      this.updateTasks([taskUpdate], { noUndo: true, noBackend, celebrateOverride, awaitBackend });
    }

    // TODO subscribe updater to all (up to 5) tasks

    if (!noBackend) {
      if (deleteDuid || relationshipCreateFormal) {
        const backendAction = this.$backend.relationship.deleteAndCreateAndUpdateTask(
          deleteDuid,
          relationshipCreateFormal
        );

        if (awaitBackend) {
          await backendAction;
        }
      }
    }
  },
  async addAttachments(taskDuid, files, options = {}) {
    const { awaitBackend = false } = options;

    const appStore = this.$useAppStore();

    const task = this.getTaskByDuid(taskDuid);
    if (!task) {
      return;
    }

    const allOrders = this.getAttachmentsByDuidsOrdered(task.attachmentDuids).map((e) => e.order);
    const orders = getOrdersBetween(allOrders[allOrders.length - 1], undefined, files.length);
    const attachmentConfigs = files.map((file, i) => ({
      file,
      order: orders[i],
      partialAttachment: { duid: makeDuid() },
    }));
    const attachmentDuids = attachmentConfigs.map((e) => e.partialAttachment.duid);

    task.attachmentDuids.push(...attachmentDuids);
    task.updatedAt = new Date().toISOString();
    task.updatedByDuid = this.$useUserStore().duid;

    appStore.addRecentTask(task);

    // TODO subscribe updater

    const attachmentAndCreates = await this.createAttachments(attachmentConfigs, { noBackend: true });

    const taskUpdate: TaskUpdate = {
      duid: taskDuid,
      attachmentDuids,
    };

    const backendAction = this.$backend.task.createAttachmentsAndAdd(
      attachmentAndCreates.map((e) => e.create),
      taskUpdate
    );

    if (awaitBackend) {
      await backendAction;
    }
  },
  async removeAttachment(taskDuid, attachmentDuid, options = {}) {
    const { awaitBackend = false } = options;

    const appStore = this.$useAppStore();

    const task = this.getTaskByDuid(taskDuid);
    const attachment = this.getAttachmentByDuid(attachmentDuid);
    if (!task || !attachment) {
      return;
    }

    task.attachmentDuids = task.attachmentDuids.filter((e) => e !== attachmentDuid);
    task.updatedAt = new Date().toISOString();
    task.updatedByDuid = this.$useUserStore().duid;

    appStore.addRecentTask(task);

    // TODO subscribe updater

    const taskUpdate: TaskUpdate = {
      duid: taskDuid,
      attachmentDuids: [attachmentDuid],
    };
    const backendAction = this.$backend.task.removeAttachmentAndDelete(taskUpdate, attachmentDuid);
    if (awaitBackend) {
      await backendAction;
    }
  },
  async addLink(taskDuid, kind, url, title, options = {}) {
    const { awaitBackend = false } = options;

    const appStore = this.$useAppStore();

    const task = this.getTaskByDuid(taskDuid);
    if (!task) {
      return;
    }
    const allOrders = task.links.map((e) => e.order).sort(stringComparator);
    const order = getOrdersBetween(allOrders[allOrders.length - 1], undefined)[0];

    const taskLinkCreate = { duid: makeDuid(), taskDuid: task.duid, kind, order, url, title };

    this.$addLinksNoBackend(task, [taskLinkCreate]);
    task.updatedAt = new Date().toISOString();
    task.updatedByDuid = this.$useUserStore().duid;

    appStore.addRecentTask(task);

    // TODO subscribe updater

    const backendAction = this.$backend.task.link.create(taskLinkCreate);
    if (awaitBackend) {
      await backendAction;
    }
  },
  async updateLink(taskDuid, linkUpdate, options = {}) {
    const { awaitBackend = false } = options;

    const appStore = this.$useAppStore();

    const task = this.getTaskByDuid(taskDuid);
    if (!task) {
      return;
    }

    this.$updateLinksNoBackend(task, [linkUpdate]);
    task.updatedAt = new Date().toISOString();
    task.updatedByDuid = this.$useUserStore().duid;

    appStore.addRecentTask(task);

    const backendAction = this.$backend.task.link.update(linkUpdate);
    if (awaitBackend) {
      await backendAction;
    }
  },
  async deleteLink(taskDuid, linkDuid, options = {}) {
    const { awaitBackend = false } = options;

    const appStore = this.$useAppStore();

    const task = this.getTaskByDuid(taskDuid);
    if (!task) {
      return;
    }

    this.$deleteLinksNoBackend(task, [linkDuid]);
    task.updatedAt = new Date().toISOString();
    task.updatedByDuid = this.$useUserStore().duid;

    appStore.addRecentTask(task);

    // TODO subscribe updater

    const backendAction = this.$backend.task.link.delete(linkDuid);
    if (awaitBackend) {
      await backendAction;
    }
  },
  async addUpdateAndDeleteLinks(taskDuid, creates, updates, deletes, options = {}) {
    const { awaitBackend = false } = options;

    const appStore = this.$useAppStore();

    const task = this.getTaskByDuid(taskDuid);
    if (!task) {
      return;
    }

    this.$addLinksNoBackend(task, creates);
    this.$updateLinksNoBackend(task, updates);
    this.$deleteLinksNoBackend(task, deletes);
    task.updatedAt = new Date().toISOString();
    task.updatedByDuid = this.$useUserStore().duid;

    appStore.addRecentTask(task);

    // TODO subscribe updater to all tasks

    const backendAction = this.$backend.task.link.addUpdateAndDeleteMany(creates, updates, deletes);
    if (awaitBackend) {
      await backendAction;
    }
  },
  async addNotionDocument(taskDuid, pageId, options = {}) {
    const { awaitBackend = false } = options;

    const appStore = this.$useAppStore();

    const task = this.getTaskByDuid(taskDuid);
    if (!task) {
      return undefined;
    }

    task.notionDocument = {
      pageId,
      refreshing: false,
      existsAndAccessGranted: null,
      lastRefreshAt: null,
      blockMap: null,
      blockChildrenMap: null,
      pageMap: null,
    };
    task.updatedAt = new Date().toISOString();
    task.updatedByDuid = this.$useUserStore().duid;

    appStore.addRecentTask(task);

    // TODO subscribe updater

    const backendAction = this.$backendOld.tasks.addNotionDocument(taskDuid, pageId);
    if (awaitBackend) {
      await backendAction;
    }

    return task;
  },
  async getNotionDocument(taskDuid) {
    const task = this.getTaskByDuid(taskDuid);
    if (!task || !task.notionDocument) {
      return undefined;
    }

    // If the notion document is already cached, return it
    if (task.notionDocument.pageMap) {
      return Promise.resolve(task);
    }

    // Get and cache the notion document
    const { data } = await this.$backendOld.tasks.getNotionDocument(taskDuid);
    task.notionDocument = data.item;
    return task;
  },
  async refreshNotionDocument(taskDuid) {
    const task = this.getTaskByDuid(taskDuid);
    if (!task || !task.notionDocument) {
      return undefined;
    }

    // Indicate that the notion document is being refreshed
    task.notionDocument.refreshing = true;
    await this.$backendOld.tasks.refreshNotionDocument(taskDuid);
    if (task.notionDocument) {
      task.notionDocument.refreshing = false;
    }

    return task;
  },
  async removeNotionDocument(taskDuid, options = {}) {
    const { awaitBackend = false } = options;

    const appStore = this.$useAppStore();

    const task = this.getTaskByDuid(taskDuid);
    if (!task) {
      return undefined;
    }

    task.notionDocument = null;
    task.updatedAt = new Date().toISOString();
    task.updatedByDuid = this.$useUserStore().duid;

    appStore.addRecentTask(task);

    // TODO subscribe updater

    const backendAction = this.$backendOld.tasks.removeNotionDocument(taskDuid);
    if (awaitBackend) {
      await backendAction;
    }

    return task;
  },
  async $replicateTaskRecursive(task, order, partialTask, options = {}) {
    const {
      propertiesAndParentOnly = false,
      includeDuplicate = false,
      maintainTitle = false,
      maintainStatus = false,
    } = options;
    const res = {
      taskCreates: [] as TaskCreate[],
      relationshipCreates: [] as RelationshipCreate[],
      taskDocRelationshipCreates: [] as TaskDocRelationshipCreate[],
      linkCreates: [] as TaskLinkCreate[],
      attachmentCreates: [] as AttachmentCreate[],
    };

    const parentKindDuid = this.getRelationshipKindByKind(RelationshipKindKind.PARENT_OF).duid;
    const duplicateKindDuid = this.getRelationshipKindByKind(RelationshipKindKind.DUPLICATES).duid;

    // Add the task itself
    const now = new Date().toISOString();
    const replicatedTask: Task = {
      ...deepCopy(task),
      duid: makeDuid(),
      createdAt: now,
      updatedAt: now,
      order,
      recommendationDuid: null,
      subscriberDuids: [...new Set([...task.subscriberDuids, this.$useUserStore().duid])].sort(),
      links: [],
      attachmentDuids: [],
      relationships: [],
      notionDocument: null,
      ...partialTask,
    };
    if (!maintainTitle && partialTask?.title === undefined) {
      replicatedTask.title = getNextTitleInSequence(
        task.title,
        this.getTasksByDartboardDuidOrdered(replicatedTask.dartboardDuid).map((e) => e.title)
      );
    }
    if (!maintainStatus && partialTask?.statusDuid === undefined) {
      replicatedTask.statusDuid = this.defaultDefaultUnstartedStatus.duid;
    }
    replicatedTask.relationships = replicatedTask.relationships.map((e) => ({
      ...e,
      duid: makeDuid(),
      sourceDuid: replicatedTask.duid,
    }));

    this.$createOrUpdateTask(replicatedTask);
    res.taskCreates.push({ ...replicatedTask, sourceType: TaskSourceType.APP_REPLICATE });

    res.relationshipCreates.push(
      ...replicatedTask.relationships
        .map(({ duid, kindDuid, targetDuid, isForward }) => [
          this.$createRelationshipOneDirectionNoBackend(duid, targetDuid, replicatedTask.duid, kindDuid, !isForward),
          targetDuid,
        ])
        .filter((e): e is [Relationship, string] => !!e[0])
        .map(([relationship, sourceDuid]) => ({ ...relationship, sourceDuid }))
    );

    if (!propertiesAndParentOnly) {
      // Add the task - doc relationships
      res.taskDocRelationshipCreates.push(
        ...(await this.getDocsRelatedToTaskOrdered(task.duid)).map((doc) => {
          const taskDocRelationshipCreate = { duid: makeDuid(), taskDuid: replicatedTask.duid, docDuid: doc.duid };
          this.$addTaskDocRelationshipNoBackend(taskDocRelationshipCreate);
          return taskDocRelationshipCreate;
        })
      );

      // Add the links
      res.linkCreates.push(
        ...task.links
          .filter((e) => e.kind === TaskLinkKind.STANDARD)
          .map((link) => {
            const taskLinkCreate = { ...link, duid: makeDuid(), taskDuid: replicatedTask.duid };
            this.$addLinksNoBackend(replicatedTask, [taskLinkCreate]);
            return taskLinkCreate;
          })
      );

      // Add the attachments
      const oldAttachments = this.getAttachmentsByDuidsOrdered(task.attachmentDuids);
      const files = await createFilesFromAttachments(oldAttachments);
      const attachmentConfigs = oldAttachments.map((attachment, i) => ({
        file: files[i],
        order: attachment.order,
        partialAttachment: { colorHex: oldAttachments[i].colorHex },
      }));
      const attachmentsAndCreates = await this.createAttachments(attachmentConfigs, { noBackend: true });
      replicatedTask.attachmentDuids.push(...attachmentsAndCreates.map((e) => e.attachment.duid));
      res.attachmentCreates.push(...attachmentsAndCreates.map((e) => e.create));

      // Replicate the non parent and non duplicate relationships
      const newRelationships = this.getRelationships(task)
        .filter((e) => e.kindDuid !== parentKindDuid && e.kindDuid !== duplicateKindDuid)
        .map((relationship) => {
          // TODO unclear why reactive is needed here. should figure it out and remove
          const newRelationship = reactive({ ...relationship, duid: makeDuid() });
          replicatedTask.relationships.push(newRelationship);
          return [
            this.$createRelationshipOneDirectionNoBackend(
              newRelationship.duid,
              newRelationship.targetDuid,
              replicatedTask.duid,
              newRelationship.kindDuid,
              !newRelationship.isForward
            ),
            relationship.targetDuid,
          ];
        })
        .filter((e): e is [Relationship, string] => !!e[0])
        .map(([relationship, sourceDuid]) => ({ ...relationship, sourceDuid }));
      res.relationshipCreates.push(...newRelationships);

      // Add child tasks recursively
      const subtaskRelationships = this.getRelationshipsByKindKind(task, RelationshipKindKind.PARENT_OF, true);
      const subtaskOrders = getOrdersBetween(replicatedTask.order, undefined, subtaskRelationships.length);

      await Promise.all(
        subtaskRelationships.map(async (relationship, i) => {
          const subtask = this.getTaskByDuid(relationship.targetDuid);
          if (!subtask) {
            return;
          }

          const subtaskRes = await this.$replicateTaskRecursive(
            subtask,
            subtaskOrders[i],
            { ...partialTask, relationships: [] },
            {
              ...options,
              maintainTitle: true,
              maintainStatus,
            }
          );
          res.taskCreates.push(...subtaskRes.taskCreates);
          res.relationshipCreates.push(...subtaskRes.relationshipCreates);
          res.taskDocRelationshipCreates.push(...subtaskRes.taskDocRelationshipCreates);
          res.linkCreates.push(...subtaskRes.linkCreates);
          res.attachmentCreates.push(...subtaskRes.attachmentCreates);

          const replicatedSubtask = subtaskRes.replicatedTask;
          const newRelationship = await this.createRelationship(
            replicatedTask.duid,
            replicatedSubtask.duid,
            parentKindDuid,
            { noBackend: true }
          );
          if (newRelationship) {
            res.relationshipCreates.push({ ...newRelationship, sourceDuid: replicatedTask.duid });
          }
        })
      );
    }

    if (!propertiesAndParentOnly || includeDuplicate) {
      // TODO unclear why reactive is needed here. should figure it out and remove
      const newRelationshipRaw = reactive({
        duid: makeDuid(),
        kindDuid: duplicateKindDuid ?? "",
        targetDuid: task.duid,
        isForward: true,
      });
      const newRelationship = this.$createRelationshipOneDirectionNoBackend(
        newRelationshipRaw.duid,
        newRelationshipRaw.targetDuid,
        replicatedTask.duid,
        newRelationshipRaw.kindDuid,
        !newRelationshipRaw.isForward
      );
      if (newRelationship) {
        replicatedTask.relationships.push(newRelationshipRaw);
        res.relationshipCreates.push({ ...newRelationship, sourceDuid: task.duid });
      }
    }

    return { ...res, replicatedTask };
  },
  $createRelationshipOneDirectionNoBackend(
    relationshipDuid,
    modTaskDuid,
    otherTaskDuid,
    relationshipKindDuid,
    isForward
  ) {
    const task = this.getTaskByDuid(modTaskDuid);
    if (!task) {
      return undefined;
    }

    // TODO unclear why reactive is needed here. should figure it out and remove
    const relationship = reactive({
      duid: relationshipDuid,
      kindDuid: relationshipKindDuid,
      targetDuid: otherTaskDuid,
      isForward,
    });
    task.relationships.push(relationship);
    task.updatedAt = new Date().toISOString();
    task.updatedByDuid = this.$useUserStore().duid;

    return relationship;
  },
  $updateTaskSubscribersNoBackend(task, potentialSubscriberDuids) {
    const currentSubscriberDuids = new Set(task.subscriberDuids);
    const newSubscriberDuids = potentialSubscriberDuids.filter((e) => !currentSubscriberDuids.has(e));

    // eslint-disable-next-line no-param-reassign
    task.subscriberDuids = [...currentSubscriberDuids, ...newSubscriberDuids];

    return newSubscriberDuids;
  },
  $addLinksNoBackend(task, linkCreates) {
    // TODO unclear why reactive is needed here. should figure it out and remove
    task.links.push(
      ...linkCreates.map((e) =>
        reactive({
          kind: TaskLinkKind.STANDARD,
          title: null,
          iconUrl: null,
          adtl: {},
          ...e,
        })
      )
    );
  },
  $updateLinksNoBackend(task, linkUpdates) {
    linkUpdates.forEach((linkUpdate) => {
      const link = task.links.find((e) => e.duid === linkUpdate.duid);
      if (!link) {
        return;
      }

      Object.assign(link, linkUpdate);
    });
  },
  $deleteLinksNoBackend(task, linkDuids) {
    filterInPlace(task.links, (e: TaskLink) => !linkDuids.includes(e.duid));
  },
  $setTasks(tasks, preservationMode) {
    const recentCutoff = moment().subtract(30, "second");

    const newTasks: Task[] = [];
    tasks.forEach((newTask) => {
      const oldTask = this.getTaskByDuid(newTask.duid);
      if (!oldTask) {
        newTasks.push(newTask);
        return;
      }
      if (preservationMode === StoreDataPreservationMode.NONE) {
        Object.assign(oldTask, newTask);
        newTasks.push(oldTask);
        return;
      }
      if (preservationMode === StoreDataPreservationMode.ALL) {
        newTasks.push(oldTask);
        return;
      }
      if (moment(oldTask.updatedAt).isAfter(recentCutoff)) {
        newTasks.push(oldTask);
        return;
      }
      Object.assign(oldTask, newTask);
      newTasks.push(oldTask);
    });
    const newTaskDuids = new Set(newTasks.map((e) => e.duid));
    newTasks.push(
      ...[...this._duidsToTasks.values()].filter(
        (e) => !newTaskDuids.has(e.duid) && moment(e.createdAt).isAfter(recentCutoff)
      )
    );
    this._duidsToTasks = new Map(newTasks.map((e) => [e.duid, e]));

    this.taskDraftDuid = this.getTaskList({ includeDraft: true }).find((e) => this.$isPrimaryDraft(e))?.duid ?? null;
  },
  $createOrUpdateTask(task) {
    /* Shared */
    const currentTask = this.getTaskByDuid(task.duid);

    if (
      !this.$useAppStore().tcmOpen &&
      !!this.$useAppStore().currentActiveBrainstorm &&
      !!currentTask &&
      currentTask.sourceType === TaskSourceType.RECOMMENDATION &&
      currentTask.recommendationDuid
    ) {
      const visualization = this.$useAppStore().getActiveVisualization();
      const taskElement = visualization?.getHtml(currentTask.duid);
      if (!taskElement) {
        return;
      }
      this.$useAppStore().showFeedbackTooltip(taskElement, [currentTask.recommendationDuid]);
    }

    // TODO remove this check when we are filtering out spurious tasks on BE of WS
    if (
      !this.getDartboardByDuid(task.dartboardDuid) ||
      (task.drafterDuid && task.drafterDuid !== this.$useUserStore().duid)
    ) {
      if (currentTask) {
        this.$deleteTask(currentTask);
      }
      return;
    }

    /* Create */
    if (!currentTask) {
      this._duidsToTasks.set(task.duid, task);
      if (this.$isPrimaryDraft(task)) {
        this.taskDraftDuid = task.duid;
      }
      return;
    }

    /* Update */
    Object.assign(currentTask, task);
    if (this.$isPrimaryDraft(currentTask)) {
      this.taskDraftDuid = currentTask.duid;
    } else if (this.taskDraft && this.taskDraft.duid === currentTask.duid) {
      this.taskDraftDuid = null;
    }
  },
  $deleteTask(task) {
    this._duidsToTasks.delete(task.duid);

    if (this.taskDraft && this.taskDraft.duid === task.duid) {
      this.taskDraftDuid = null;
    }

    this.$useAppStore().selectedTaskDuids.delete(task.duid);
  },
  $isPrimaryDraft(task) {
    if (!task.drafterDuid || task.sourceType === TaskSourceType.RECOMMENDATION) {
      return false;
    }
    const parentDuids = this.getRelationshipsByKindKind(task, RelationshipKindKind.PARENT_OF, false);
    if (parentDuids.length === 0) {
      return true;
    }
    const parent = this.getTaskByDuid(parentDuids[0].targetDuid);
    if (!parent) {
      return true;
    }
    return !parent.drafterDuid;
  },
  $remapPropertiesOfTasksAsNeeded(updates, onRemapFailed) {
    if (updates.length === 0) {
      return Promise.resolve([]);
    }

    const appStore = this.$useAppStore();

    // Find all updates that need remapping
    const remapModalItems: Map<string, RemapModalConfigItem> = new Map();
    updates.forEach((update) => {
      // Remap default status for task kind updates
      const taskKindDuid = update.kindDuid ?? this.getTaskByDuid(update.duid)?.kindDuid;
      if (taskKindDuid) {
        // Get available statuses for the new task kind
        const newTaskKind = this.getTaskKindByDuid(taskKindDuid);
        if (!newTaskKind) {
          return;
        }
        const hiddenStatusDuids = new Set(newTaskKind.hiddenStatusDuids);
        const availableStatuses = this.getStatusList(this.defaultStatusProperty, [newTaskKind]);

        const task = this.getTaskByDuid(update.duid);
        if (!task) {
          return;
        }
        const statusDuid = update.statusDuid ?? task.statusDuid;
        if (availableStatuses.some((e) => e.duid === statusDuid)) {
          // No remapping needed
          return;
        }

        const groupByDefinition = appStore.groupByDefinitionList.find(
          (e) => e.property.duid === this.defaultStatusProperty.duid
        );
        if (!groupByDefinition) {
          return;
        }
        const groups = groupByDefinition.groups.map((e) => ({ ...e, icon: markRaw(e.icon) }));
        const oldOption = groups.find((e) => e.value === statusDuid);
        if (!oldOption) {
          return;
        }

        // Find status in same kind or default to first available status
        const status = this.getStatusByDuid(statusDuid);
        const proposedValue = availableStatuses.find((e) => e.kind === status?.kind)?.duid ?? groups[0].value;

        remapModalItems.set(`${this.defaultStatusProperty.duid}-${statusDuid}`, {
          propertyDuid: this.defaultStatusProperty.duid,
          oldOption,
          options: groups.filter((e) => typeof e.value === "string" && !hiddenStatusDuids.has(e.value)),
          proposedValue,
        });
      }
    });

    // No remapping needed
    if (remapModalItems.size === 0) {
      return Promise.resolve(updates);
    }

    // Show the remap modal
    const remapPromise = new Promise<TaskUpdate[]>((resolve) => {
      appStore.remapModal = {
        items: Array.from(remapModalItems.values()),
        remap: (items) => {
          resolve(
            [...updates].map((update) => {
              const res = { ...update };
              const task = this.getTaskByDuid(update.duid);
              if (!task) {
                return res;
              }

              let remappedUpdate;
              // Remap default status for task kind updates
              const taskKindDuid = update.kindDuid ?? this.getTaskByDuid(update.duid)?.kindDuid;
              if (taskKindDuid) {
                const oldStatusDuid = update.statusDuid ?? task.statusDuid;
                remappedUpdate = items.find(
                  (e) => e.propertyDuid === this.defaultStatusProperty.duid && e.oldValue === oldStatusDuid
                );
                if (remappedUpdate) {
                  res.statusDuid = remappedUpdate.newValue as string;
                }
              }

              return res;
            })
          );
        },
        cancel: () => {
          resolve([]);
          onRemapFailed();
        },
      };
    });

    return remapPromise;
  },
};

export { actions, getters };
