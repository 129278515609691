import { type Component } from "vue";

import { UNASSIGNED_ASSIGNEE_LABEL, UNASSIGNED_PSEUDO_ASSIGNEE_KEY } from "~/components/visualization/constants";
import { PRIORITY_COLOR_MAP, PRIORITY_OPTIONS, UNPRIORITIZED_PRIORITY_LABEL } from "~/constants/priority";
import {
  EXPONENTIAL_SIZE,
  EXPONENTIAL_SIZE_COLOR_MAP,
  FIBONACCI_SIZE,
  FIBONACCI_SIZE_COLOR_MAP,
  LINEAR_SIZE,
  LINEAR_SIZE_COLOR_MAP,
  TSHIRT_SIZE_COLOR_MAP,
  TSHIRT_SIZE_TO_NUMBER_SIZE,
  TSHIRT_SIZE_TO_PRETTY_LABEL,
  TSHIRT_SIZES,
  UNSIZED_SIZE_LABEL,
} from "~/constants/size";
import { DEFAULT_BLOCK_COLOR, PRIMARY_COLOR } from "~/constants/style";
import { DART_AI_PSEUDO_USER, DART_AI_PSEUDO_USER_KEY } from "~/constants/user";
import {
  CheckedIcon,
  PriorityFieldIcon,
  PriorityIcon,
  SelectFieldIcon,
  SizeFieldIcon,
  UnassignedIcon,
  UncheckedIcon,
  UngroupedIcon,
} from "~/icons";
import { IconSize, LayoutKind, PageKind, PropertyKind, SizeFormat } from "~/shared/enums";
import type { GroupByDefinition, GroupByGroup, Property, PropertyConfig, PropertyConfigGroupBy } from "~/shared/types";
import type { useAppStore } from "~/stores";
import type { DataStore } from "~/stores/DataStore";
import { getPageDisplayName } from "~/utils/common";
import { makeDartboardComparator } from "~/utils/comparator";
// Initialize
type AppStore = ReturnType<typeof useAppStore>;

let appStore: AppStore;
let dataStore: DataStore;

let Avatar: Component;
let PageIcon: Component;
let StatusIcon: Component;

export const init = (newDataStore: DataStore, avatar: Component, pageIcon: Component, statusIcon: Component) => {
  dataStore = newDataStore;
  appStore = dataStore.$useAppStore();

  Avatar = avatar;
  PageIcon = pageIcon;
  StatusIcon = statusIcon;
};

// Constants
export const UNGROUPED_PSEUDO_GROUP_BY = "@unset";
export const UNGROUPED_GROUP_LABEL = "None";
export const NO_VALUE_GROUP_BY_KEY = "@null";
export const COLLAPSED_GROUP_CONTAINER_ID = "@container";
export const CREATE_TASK_ROW_ID = "@create";
export const MARGIN_ROW_ID = "@margin";

export const GROUP_BY_PROPERTY_KINDS_EXCLUDED_FROM_LIST = new Set([
  PropertyKind.DEFAULT_ASSIGNEES,
  PropertyKind.DEFAULT_TAGS,
  PropertyKind.USER,
  PropertyKind.MULTISELECT,
]);

export const isGroupContainer = (id: string) => id.startsWith(COLLAPSED_GROUP_CONTAINER_ID);
export const isCreateTaskRow = (id: string) => id.endsWith(CREATE_TASK_ROW_ID);
export const isMarginRow = (id: string) => id === MARGIN_ROW_ID;
export const isFillerRow = (id: string) => isCreateTaskRow(id) || isMarginRow(id);

export const getDefaultListGroupBy = () => UNGROUPED_PSEUDO_GROUP_BY;

export const getDefaultBoardGroupBy = () => dataStore.defaultStatusProperty.duid;

export const GROUP_HEADER_CONTAINER_GROUP_BY_GROUP: GroupByGroup = {
  id: COLLAPSED_GROUP_CONTAINER_ID,
  value: COLLAPSED_GROUP_CONTAINER_ID,
  title: "",
  colorHex: "",
  icon: CheckedIcon,
};

export const KIND_GROUP_BY: PropertyConfigGroupBy<PropertyKind.DEFAULT_KIND> = () => ({
  groups: dataStore.taskKindList.map((kind) => ({
    id: kind.duid,
    value: kind.duid,
    title: kind.title,
    colorHex: kind.colorHex,
    icon: PageIcon,
    iconArgs: { page: kind, size: IconSize.S },
  })),
});

export const DARTBOARD_GROUP_BY: PropertyConfigGroupBy<PropertyKind.DEFAULT_DARTBOARD> = () => {
  const isDashboard = appStore.currentPage?.pageKind === PageKind.DASHBOARD;
  const dartboards = dataStore.getDartboardList({ excludeFinished: !isDashboard });
  const groups = (
    isDashboard
      ? dartboards.sort(makeDartboardComparator(dataStore.getSpaceByDuid, { chronologicalOrder: true }))
      : dartboards
  ).map((dartboard) => ({
    id: dartboard.duid,
    value: dartboard.duid,
    title: getPageDisplayName(dartboard, dataStore.getSpaceByDuid),
    colorHex: dartboard.colorHex,
    icon: PageIcon,
    iconArgs: { page: dartboard, size: IconSize.S },
  }));
  return { groups };
};

export const STATUS_GROUP_BY: PropertyConfigGroupBy<PropertyKind.DEFAULT_STATUS | PropertyKind.STATUS> = (property) => {
  const isDashboard = appStore.currentPage?.pageKind === PageKind.DASHBOARD;
  return {
    groups: dataStore.getStatusList(property).map((status) => ({
      id: status.duid,
      value: status.duid,
      title: status.title,
      colorHex: status.colorHex,
      icon: StatusIcon,
      iconArgs: { duid: status.duid, class: isDashboard ? "icon-sm" : "icon-lg" },
    })),
  };
};

export const USER_GROUP_BY: PropertyConfigGroupBy<
  PropertyKind.DEFAULT_ASSIGNEES | PropertyKind.DEFAULT_CREATED_BY | PropertyKind.DEFAULT_UPDATED_BY | PropertyKind.USER
> = (property) => {
  const isDashboard = appStore.currentPage?.pageKind === PageKind.DASHBOARD;
  return {
    groups: [
      {
        id: UNASSIGNED_PSEUDO_ASSIGNEE_KEY,
        value: null,
        title: UNASSIGNED_ASSIGNEE_LABEL,
        colorHex: DEFAULT_BLOCK_COLOR,
        icon: UnassignedIcon,
        iconArgs: {
          class: isDashboard ? "icon-sm text-vlt focus:outline-none" : "icon-lg text-vlt focus:outline-none",
        },
      },
      ...(property.kind === PropertyKind.DEFAULT_ASSIGNEES
        ? dataStore.getUserList().concat(DART_AI_PSEUDO_USER)
        : dataStore.getUserList()
      ).map((user) => ({
        id: user.duid,
        value: user.duid,
        title: user.name !== "" ? user.name : user.email,
        colorHex: user.colorHex,
        icon: Avatar,
        iconArgs: {
          abrev: user.abrev,
          isAi: user.duid === DART_AI_PSEUDO_USER_KEY,
          circle: true,
          colorHex: user.colorHex,
          hover: false,
          imageUrl: user.imageUrl,
          imgBorder: true,
          class: isDashboard ? "icon-sm" : "icon-lg",
        },
      })),
    ],
  };
};

export const PRIORITY_GROUP_BY: PropertyConfigGroupBy<PropertyKind.DEFAULT_PRIORITY> = () => {
  const isDashboard = appStore.currentPage?.pageKind === PageKind.DASHBOARD;
  return {
    groups: PRIORITY_OPTIONS.map((priority) => {
      const hasPriority = priority !== null;
      const colorHex = hasPriority ? (PRIORITY_COLOR_MAP.get(priority) ?? DEFAULT_BLOCK_COLOR) : DEFAULT_BLOCK_COLOR;
      return {
        id: hasPriority ? priority : "@unprioritized",
        value: priority,
        title: hasPriority ? priority.toString() : UNPRIORITIZED_PRIORITY_LABEL,
        colorHex,
        icon: hasPriority ? PriorityIcon : PriorityFieldIcon,
        iconArgs: { class: isDashboard ? "icon-sm" : "icon-lg", style: { color: colorHex } },
      };
    }),
  };
};

export const SIZE_GROUP_BY: PropertyConfigGroupBy<PropertyKind.DEFAULT_SIZE> = (property) => {
  const isDashboard = appStore.currentPage?.pageKind === PageKind.DASHBOARD;

  const createSizeGroups = (sizes: number[], colorMap: Map<number, string>) => {
    const unsizedGroup = {
      id: "@unsized",
      value: null,
      title: UNSIZED_SIZE_LABEL,
      colorHex: DEFAULT_BLOCK_COLOR,
      icon: SizeFieldIcon,
      iconArgs: {
        class: isDashboard ? "icon-sm" : "icon-lg",
        style: { color: DEFAULT_BLOCK_COLOR },
      },
    };

    return {
      groups: [
        unsizedGroup,
        ...sizes.map((size) => ({
          id: size.toString(),
          value: size,
          title: size.toString(),
          colorHex: colorMap.get(size) ?? DEFAULT_BLOCK_COLOR,
          icon: SizeFieldIcon,
          iconArgs: {
            class: isDashboard ? "icon-sm" : "icon-lg",
            style: { color: colorMap.get(size) ?? DEFAULT_BLOCK_COLOR },
          },
        })),
      ],
    };
  };

  if (property.adtl.format === SizeFormat.FIBONACCI) {
    return createSizeGroups(FIBONACCI_SIZE, FIBONACCI_SIZE_COLOR_MAP);
  }

  if (property.adtl.format === SizeFormat.EXPONENTIAL) {
    return createSizeGroups(EXPONENTIAL_SIZE, EXPONENTIAL_SIZE_COLOR_MAP);
  }

  if (property.adtl.format === SizeFormat.LINEAR) {
    return createSizeGroups(LINEAR_SIZE, LINEAR_SIZE_COLOR_MAP);
  }

  if (property.adtl.format === SizeFormat.FREE_ENTRY) {
    const tasks = new Set(
      appStore.filteredAndSortedTasksInPage.map((task) => task.size).filter((size): size is number => size !== null)
    );
    return createSizeGroups(Array.from(tasks), new Map());
  }

  return {
    groups: TSHIRT_SIZES.map((size) => {
      const prettyLabel = size ? TSHIRT_SIZE_TO_PRETTY_LABEL.get(size) : null;
      const numberSize = TSHIRT_SIZE_TO_NUMBER_SIZE.get(size) ?? null;
      const colorHex = size !== null ? (TSHIRT_SIZE_COLOR_MAP.get(size) ?? DEFAULT_BLOCK_COLOR) : DEFAULT_BLOCK_COLOR;
      return {
        id: size ?? "@unsized",
        value: numberSize,
        title: (isDashboard ? prettyLabel : size)?.toString() ?? UNSIZED_SIZE_LABEL,
        colorHex,
        icon: SizeFieldIcon,
        iconArgs: { class: isDashboard ? "icon-sm" : "icon-lg", style: { color: colorHex } },
      };
    }),
  };
};

export const CHECKBOX_GROUP_BY: PropertyConfigGroupBy<PropertyKind.CHECKBOX> = () => {
  const isDashboard = appStore.currentPage?.pageKind === PageKind.DASHBOARD;
  return {
    groups: [
      {
        id: "@unchecked",
        value: false,
        title: "Unchecked",
        colorHex: DEFAULT_BLOCK_COLOR,
        icon: UncheckedIcon,
        iconArgs: { class: isDashboard ? "icon-sm" : "icon-lg", style: { color: DEFAULT_BLOCK_COLOR } },
      },
      {
        id: "@checked",
        value: true,
        title: "Checked",
        colorHex: PRIMARY_COLOR,
        icon: CheckedIcon,
        iconArgs: { class: isDashboard ? "icon-sm" : "icon-lg", style: { color: PRIMARY_COLOR } },
      },
    ],
  };
};

export const OPTION_GROUP_BY: PropertyConfigGroupBy<
  PropertyKind.DEFAULT_TAGS | PropertyKind.MULTISELECT | PropertyKind.SELECT
> = (property) => {
  const isDashboard = appStore.currentPage?.pageKind === PageKind.DASHBOARD;
  return {
    groups: [
      {
        id: "@null",
        value: null,
        title: "(None)",
        colorHex: DEFAULT_BLOCK_COLOR,
        icon: SelectFieldIcon,
        iconArgs: { class: isDashboard ? "icon-sm" : "icon-lg", style: { color: DEFAULT_BLOCK_COLOR } },
      },
      ...dataStore.getOptionList(property).map((option) => ({
        id: option.duid,
        value: option.duid,
        title: option.title,
        colorHex: option.colorHex,
        icon: SelectFieldIcon,
        iconArgs: { class: isDashboard ? "icon-sm" : "icon-lg", style: { color: option.colorHex } },
      })),
    ],
  };
};

export const getGroupByDefinitionList = (propertiesWithConfig: [Property, PropertyConfig][]): GroupByDefinition[] => {
  const options: GroupByDefinition[] = propertiesWithConfig
    .filter(([property, config]) => {
      if (config.groupBy !== null) {
        return true;
      }

      // Show numeric options only in dashboard
      const isDashboard = appStore.currentPage?.pageKind === PageKind.DASHBOARD;
      const isNumeric = [PropertyKind.DEFAULT_TIME_TRACKING, PropertyKind.TIME_TRACKING, PropertyKind.NUMBER].includes(
        property.kind
      );
      return isDashboard && isNumeric;
    })
    .map(([property, config]) => ({
      property,
      icon: config.icon,
      isNumeric: !!config.isNumeric,
      ...(config.groupBy ? config.groupBy(property) : { groups: [] }),
    }));

  // Show dartboard option only in views and dashboard
  const dartboardOption = options.find((e) => e.property.kind === PropertyKind.DEFAULT_DARTBOARD);
  if (
    dartboardOption &&
    (!appStore.currentPage || ![PageKind.VIEW, PageKind.DASHBOARD].includes(appStore.currentPage.pageKind))
  ) {
    options.splice(options.indexOf(dartboardOption), 1);
  }

  // Show ungrouped if not in board
  if (appStore.layoutKind !== LayoutKind.BOARD) {
    options.unshift({
      property: {
        duid: UNGROUPED_PSEUDO_GROUP_BY,
        title: UNGROUPED_GROUP_LABEL,
        hidden: false,
        kind: null,
      } as unknown as Property,
      icon: UngroupedIcon,
      isNumeric: true,
      groups: [],
    });
  }

  return options;
};
