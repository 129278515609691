import { capitalize } from "vue";

import { TshirtSize } from "~/shared/enums";

export const UNSIZED_SIZE_LABEL = "(Unsized)";

export const TSHIRT_SIZES = [null, ...Object.values(TshirtSize)];

export const TSHIRT_SIZE_TO_LABEL = new Map(Object.entries(TshirtSize).map(([k, v]) => [v, k]));

export const TSHIRT_SIZE_TO_NUMBER_SIZE = new Map([
  [null, null],
  [TshirtSize.EXTRA_SMALL, 1],
  [TshirtSize.SMALL, 2],
  [TshirtSize.MEDIUM, 3],
  [TshirtSize.LARGE, 5],
  [TshirtSize.EXTRA_LARGE, 8],
]);

export const NUMBER_SIZES = Array.from(TSHIRT_SIZE_TO_NUMBER_SIZE.values());

export const NUMBER_SIZE_TO_TSHIRT_SIZE = new Map(
  Array.from(TSHIRT_SIZE_TO_NUMBER_SIZE.entries()).map(([k, v]) => [v, k])
);

export const TSHIRT_SIZE_TO_PRETTY_LABEL = new Map(
  Array.from(TSHIRT_SIZE_TO_NUMBER_SIZE.keys()).map((e) => {
    const title = e ? TSHIRT_SIZE_TO_LABEL.get(e) : null;
    return [e, title ? capitalize(title.replace("_", " ").toLowerCase()) : null];
  })
);

export const NUMBER_SIZE_TO_PRETTY_LABEL = new Map(
  Array.from(NUMBER_SIZE_TO_TSHIRT_SIZE.entries()).map(([k, v]) => [k, TSHIRT_SIZE_TO_PRETTY_LABEL.get(v)])
);

export const TSHIRT_SIZE_ORDER_FOR_NLP = [
  TshirtSize.EXTRA_SMALL,
  TshirtSize.EXTRA_LARGE,
  TshirtSize.SMALL,
  TshirtSize.MEDIUM,
  TshirtSize.LARGE,
];

export const TSHIRT_SIZE_COLOR_MAP = new Map([
  [TshirtSize.EXTRA_SMALL, "#c7d2fe"],
  [TshirtSize.SMALL, "#818cf8"],
  [TshirtSize.MEDIUM, "#6366f1"],
  [TshirtSize.LARGE, "#4f46e5"],
  [TshirtSize.EXTRA_LARGE, "#3730a3"],
]);

export const FIBONACCI_SIZE_COLOR_MAP = new Map([
  [1, "#c7d2fe"],
  [2, "#818cf8"],
  [3, "#6366f1"],
  [5, "#4f46e5"],
  [8, "#3730a3"],
]);

export const LINEAR_SIZE_COLOR_MAP = new Map([
  [1, "#c7d2fe"],
  [2, "#818cf8"],
  [3, "#6366f1"],
  [4, "#4f46e5"],
  [5, "#3730a3"],
]);

export const EXPONENTIAL_SIZE_COLOR_MAP = new Map([
  [1, "#c7d2fe"],
  [2, "#818cf8"],
  [4, "#6366f1"],
  [8, "#4f46e5"],
  [16, "#3730a3"],
]);

export const TSHIRT_SIZE = ["XS", "S", "M", "L", "XL"];

export const FIBONACCI_SIZE = [1, 2, 3, 5, 8];

export const EXPONENTIAL_SIZE = [1, 2, 4, 8, 16];

export const LINEAR_SIZE = [1, 2, 3, 4, 5];

export const getSizeOptions = (sizes: number[]) => [
  { size: null, label: UNSIZED_SIZE_LABEL },
  ...sizes.map((size) => ({ size, label: size.toString() })),
];
