import PageIcon from "~/components/dumb/PageIcon.vue";
import StatusIcon from "~/components/dumb/StatusIcon.vue";
import { COMPLETED_STATUS_KINDS } from "~/components/visualization/constants";
import { DEFAULT_CHIP_COLOR } from "~/constants/style";
import { IconSize, TypeaheadSpecialEntity } from "~/shared/enums";
import type { TypeaheadOption } from "~/shared/types";
import { useDataStore } from "~/stores";

/** Tasks */
const makeTaskOption = (taskDuid: string): TypeaheadOption | undefined => {
  const dataStore = useDataStore();
  const task = dataStore.getTaskByDuid(taskDuid);
  if (!task) {
    return undefined;
  }
  const statusKind = dataStore.getStatusByDuid(task.statusDuid)?.kind;
  if (!statusKind) {
    return undefined;
  }

  return {
    value: taskDuid,
    label: task.title,
    specialEntity: TypeaheadSpecialEntity.TASK,
    adtlSearchTerms: ["task"],
    icon: StatusIcon,
    iconArgs: {
      class: "icon-md",
      duid: task.statusDuid,
    },
    chipColorHex: DEFAULT_CHIP_COLOR,
    strikeThrough: COMPLETED_STATUS_KINDS.has(statusKind),
  };
};

export const getTaskOptions = (): TypeaheadOption[] => {
  const dataStore = useDataStore();

  return dataStore
    .getTaskList()
    .map((e) => makeTaskOption(e.duid))
    .filter((e): e is TypeaheadOption => !!e);
};

export const getDocOptions = async (
  query: string,
  offset?: number
): Promise<{ options: TypeaheadOption[]; total: number }> => {
  const dataStore = useDataStore();

  const { data } = await dataStore.loadDocs({ search: query.toString(), order: "title" }, offset ?? 0, 20);
  return {
    options: data.results.map((doc, index) => ({
      value: doc.duid,
      label: doc.title,
      specialEntity: TypeaheadSpecialEntity.DOC,
      adtlSearchTerms: ["doc"],
      icon: PageIcon,
      iconArgs: {
        page: doc,
      },
      chipColorHex: DEFAULT_CHIP_COLOR,
      chipIcon: PageIcon,
      chipIconArgs: {
        page: doc,
        size: IconSize.S,
      },
      lastOfKind: index === data.results.length - 1,
    })),
    total: data.count,
  };
};
