<script setup lang="ts">
import { $isLinkNode } from "@lexical/link";
import { mergeRegister } from "@lexical/utils";
import { OnClickOutside } from "@vueuse/components";
import { useElementBounding } from "@vueuse/core";
import {
  $getNodeByKey,
  $getSelection,
  $isRangeSelection,
  type BaseSelection,
  COMMAND_PRIORITY_EDITOR,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from "vue";

import { hasClassInPath } from "~/utils/common";

import { TASK_DETAIL_TITLE_WRAPPER_ID } from "../const";
import { getSelectedNode } from "../utils";
import LinkEditor from "./LinkEditor.vue";
import TextFormatOptions from "./TextFormatOptions.vue";

enum ToolbarKind {
  ABSOLUTE = "absolute",
  FIXED = "fixed",
  NONE = "none",
}

const TOOLBAR_WIDTH_WITHOUT_AI = 400;
const TOOLBAR_WIDTH_WITH_AT = 435;
const TOOLBAR_HEIGHT = 34;
const VERTICAL_OFFSET = 10;
const HORIZONTAL_OFFSET = 20;
const HORIZONTAL_PADDING = 4;
const TOOLBAR_VERTICAL_OFFSET_ABOVE_SELECTION = 70;
const TASK_DETAIL_TOOLBAR_PADDING = 50;
const FIXED_TOOLBAR_BOTTOM_MARGIN = 20;
const FIXED_TOOLBAR_LEFT_OFFSET = 12;

const IGNORED_CLASSES_FOR_STOPPING = new Set(["dart-editor"]);

const props = defineProps<{
  hideAiActionButton?: boolean;
  teleportKey: string;
  showOnScroll?: boolean;
}>();

const editor = useLexicalComposer();

const wrapper = ref<HTMLDivElement | null>(null);

const showLinkEditor = ref(false);

const width = computed(() => (props.hideAiActionButton ? TOOLBAR_WIDTH_WITHOUT_AI : TOOLBAR_WIDTH_WITH_AT));

const linkKey = ref<string | null>(null);
const linkUrl = ref<string | null>(null);

const teleportParentElement = ref<HTMLElement | null>(null);
const editorRootElement = ref<HTMLElement | null>(null);
const taskDetailTitleWrapper = ref<HTMLElement | null>(null);

const isMounted = ref(false);
const currentToolbarKind = ref<ToolbarKind>(ToolbarKind.NONE);
const toolbarLocation = ref({ top: 0, left: 0 });

const isAbsoluteToolbar = computed(() => currentToolbarKind.value === ToolbarKind.ABSOLUTE);
const isFixedToolbar = computed(() => currentToolbarKind.value === ToolbarKind.FIXED);
const isTaskDetailMode = computed(() => !!taskDetailTitleWrapper.value && props.showOnScroll);

const toolbarPropsPx = computed(() => ({
  top: `${toolbarLocation.value.top}px`,
  left: `${toolbarLocation.value.left}px`,
  width: `${width.value}px`,
  height: `${TOOLBAR_HEIGHT}px`,
}));

const { top: rootTopRef, left: rootLeftRef } = useElementBounding(editorRootElement);
const { bottom: taskDetailWrapperBottom } = useElementBounding(taskDetailTitleWrapper);
const { left: toolbarLeft } = useElementBounding(wrapper);

const absoluteToolbarLastLeft = ref(0);

const setToolbarKind = (kind: ToolbarKind) => {
  currentToolbarKind.value = kind;
};

const isElementInView = (top: number, bottom: number): boolean => {
  const height = window.innerHeight || document.documentElement.clientHeight;
  // Using 50 to trigger elements out of view sooner in task detail mode
  return bottom >= (isTaskDetailMode.value ? 50 : 0) && top < height;
};
const showToolbarMaybe = (selection: Selection, location: { top: number; left: number } | null) => {
  const { top: selectionTop, bottom: selectionBottom } = selection.getRangeAt(0).getBoundingClientRect();
  const isSelectionInViewport = isElementInView(selectionTop, selectionBottom);

  const isSelectionBelowTaskDetailTitle = isTaskDetailMode.value && selectionBottom > taskDetailWrapperBottom.value;

  if (!isSelectionInViewport || (isTaskDetailMode.value && !isSelectionBelowTaskDetailTitle)) {
    setToolbarKind(ToolbarKind.NONE);
    return;
  }

  const toolbarBottom = selectionTop - TOOLBAR_VERTICAL_OFFSET_ABOVE_SELECTION;
  const toolbarTop = toolbarBottom - TOOLBAR_HEIGHT;

  const isToolbarBelowTaskDetailTitle =
    isTaskDetailMode.value && toolbarBottom + TASK_DETAIL_TOOLBAR_PADDING > taskDetailWrapperBottom.value;

  const showAbsoluteToolbar = isTaskDetailMode.value
    ? isToolbarBelowTaskDetailTitle
    : isElementInView(toolbarTop, toolbarBottom);

  if (showAbsoluteToolbar && location) {
    absoluteToolbarLastLeft.value = toolbarLeft.value;
    setToolbarKind(ToolbarKind.ABSOLUTE);
    toolbarLocation.value = location;
    return;
  }

  toolbarLocation.value.left =
    absoluteToolbarLastLeft.value <= 0 ? rootLeftRef.value - FIXED_TOOLBAR_LEFT_OFFSET : absoluteToolbarLastLeft.value;

  toolbarLocation.value.top = taskDetailWrapperBottom.value - FIXED_TOOLBAR_BOTTOM_MARGIN;
  setToolbarKind(ToolbarKind.FIXED);
};

const getLocation = (
  nativeSelection: Selection,
  selectedElement?: HTMLElement
): { top: number; left: number } | null => {
  let rect;
  if (!teleportParentElement.value || !editorRootElement.value) {
    return null;
  }
  if (selectedElement) {
    rect = selectedElement.getBoundingClientRect();
  } else if (nativeSelection.anchorNode !== editorRootElement.value) {
    const domRange = nativeSelection.getRangeAt(0);
    rect = domRange.getBoundingClientRect();
  } else {
    let inner = editorRootElement.value;
    while (inner.firstElementChild !== null) {
      inner = inner.firstElementChild as HTMLElement;
    }
    rect = inner.getBoundingClientRect();
  }

  const parentRect = teleportParentElement.value.getBoundingClientRect();
  const newTop = rect.top - parentRect.top + teleportParentElement.value.offsetTop - TOOLBAR_HEIGHT - VERTICAL_OFFSET;
  // TODO add in a calculation so the toolbar disappears if it is off the description, not off the parent
  if (newTop < 0 || newTop + TOOLBAR_HEIGHT > parentRect.height) {
    return null;
  }

  const newLeft = Math.min(
    Math.max(
      HORIZONTAL_PADDING + teleportParentElement.value.offsetLeft - 1,
      rect.left - parentRect.left + teleportParentElement.value.offsetLeft - HORIZONTAL_OFFSET
    ),
    parentRect.width - width.value - HORIZONTAL_PADDING + 1 + teleportParentElement.value.offsetLeft
  );

  return { top: newTop, left: newLeft };
};

const getSelectedLinkNode = (selection: BaseSelection) => {
  if (!$isRangeSelection(selection)) {
    return null;
  }

  const node = getSelectedNode(selection);
  const parent = node.getParent();
  if ($isLinkNode(parent)) {
    return parent;
  }
  if ($isLinkNode(node)) {
    return node;
  }
  return null;
};

const updateToolbar = () => {
  linkKey.value = null;
  linkUrl.value = null;
  showLinkEditor.value = false;

  const nativeSelection = window.getSelection();
  if (!nativeSelection) {
    setToolbarKind(ToolbarKind.NONE);
    return;
  }

  const { activeElement } = document;
  if (activeElement === wrapper.value) {
    setToolbarKind(ToolbarKind.NONE);
    return;
  }

  const selection = $getSelection();

  if (
    editorRootElement.value === null ||
    selection === null ||
    !editorRootElement.value.contains(nativeSelection.anchorNode)
  ) {
    setToolbarKind(ToolbarKind.NONE);
    return;
  }

  let selectedElement;

  const selectedLinkNode = getSelectedLinkNode(selection);
  if (selectedLinkNode !== null) {
    selectedElement = editor.getElementByKey(selectedLinkNode.getKey()) ?? undefined;
    linkKey.value = selectedLinkNode.getKey();
    linkUrl.value = selectedLinkNode.getURL();
    showLinkEditor.value = true;
  } else if (nativeSelection.isCollapsed) {
    setToolbarKind(ToolbarKind.NONE);
    return;
  }

  showToolbarMaybe(nativeSelection, getLocation(nativeSelection, selectedElement));
};

const onSaveLinkUrl = (nodeKey: string, newLinkUrl: string | null) => {
  editor.update(() => {
    const lastLinkNode = $getNodeByKey(nodeKey);
    if (!$isLinkNode(lastLinkNode)) {
      return;
    }
    if (newLinkUrl !== null) {
      lastLinkNode.setURL(newLinkUrl);
      return;
    }
    lastLinkNode.getChildren().forEach((child) => {
      lastLinkNode?.insertBefore(child);
    });
    lastLinkNode.remove();
  });
};

const hideMaybe = (event: Event) => {
  const windowSelection = window.getSelection();
  const textSelected = windowSelection && windowSelection.rangeCount > 0 && !windowSelection.isCollapsed;

  if (
    currentToolbarKind.value === ToolbarKind.NONE ||
    hasClassInPath(event, IGNORED_CLASSES_FOR_STOPPING) ||
    textSelected
  ) {
    return;
  }
  setToolbarKind(ToolbarKind.NONE);
};

let unregisterListener: () => void;

onMounted(() => {
  nextTick(() => {
    teleportParentElement.value = document.querySelector(`[data-toolbar="${props.teleportKey}"]`);
    taskDetailTitleWrapper.value = document.getElementById(TASK_DETAIL_TITLE_WRAPPER_ID);
    isMounted.value = true;
  });

  editorRootElement.value = editor.getRootElement();
  editor.getEditorState().read(() => {
    updateToolbar();
  });
  unregisterListener = mergeRegister(
    editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        updateToolbar();
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    )
  );
});

watch(rootTopRef, () => {
  editor.update(() => {
    updateToolbar();
  });
});

onUnmounted(() => {
  unregisterListener?.();
});
</script>

<template>
  <Teleport v-if="isMounted" :to="teleportParentElement">
    <OnClickOutside @trigger="hideMaybe">
      <div
        v-if="isMounted"
        ref="wrapper"
        :class="!isAbsoluteToolbar && 'hidden'"
        class="absolute rounded border shadow-sm bg-std border-md"
        :style="toolbarPropsPx">
        <TextFormatOptions v-if="!showLinkEditor" :hide-ai-action-button="hideAiActionButton" />
        <LinkEditor
          v-else-if="linkKey && linkUrl !== null"
          :key="linkKey"
          :url="linkUrl"
          :node-key="linkKey"
          @save="onSaveLinkUrl" />
      </div>
      <div
        v-if="isFixedToolbar && showOnScroll"
        class="fixed rounded border shadow-sm bg-std border-md"
        :style="{ ...toolbarPropsPx, top: isTaskDetailMode ? toolbarPropsPx.top : undefined }">
        <TextFormatOptions v-if="!showLinkEditor" :hide-ai-action-button="hideAiActionButton" />
        <LinkEditor
          v-else-if="linkKey && linkUrl !== null"
          :key="linkKey"
          :url="linkUrl"
          :node-key="linkKey"
          @save="onSaveLinkUrl" />
      </div>
    </OnClickOutside>
  </Teleport>
</template>
