<script setup lang="ts">
import { $isAutoLinkNode, $isLinkNode, AutoLinkNode, LinkNode } from "@lexical/link";
import { $dfs, mergeRegister } from "@lexical/utils";
import { $getNodeByKey, $getRoot } from "lexical";
import { LexicalAutoLinkPlugin, useLexicalComposer } from "lexical-vue";
import { nextTick, onMounted, onUnmounted } from "vue";

import {
  EVENT_CREATE_DOC_DOC_RELATIONSHIP,
  EVENT_CREATE_TASK_DOC_RELATIONSHIP,
  EVENT_CREATE_TASK_TASK_RELATES_TO_RELATIONSHIP,
  URL_MATCHERS,
} from "~/components/text/const";
import { $createDartLinkNode } from "~/components/text/nodes/DartLinkNode";
import { EntityName, TypeaheadSpecialEntity } from "~/shared/enums";
import { useEnvironmentStore } from "~/stores";
import { getEntityKindAndDuidFromUrl, isString, isValidDartUrl } from "~/utils/common";

const emit = defineEmits<{
  linkChanges: [creates: string[], destroys: string[], updates: { old: string; new: string }[]];
}>();

const environmentStore = useEnvironmentStore();
const editor = useLexicalComposer();

const isDoc = editor._config.namespace.startsWith("doc");

const nodeKeyToUrl = new Map<string, string>();
editor.read(() =>
  $dfs($getRoot()).forEach(({ node }) => {
    if (!($isLinkNode(node) || $isAutoLinkNode(node))) {
      return;
    }
    nodeKeyToUrl.set(node.getKey(), node.getURL());
  })
);

const getNodeUrlByKey = (key: string) => {
  const node = $getNodeByKey(key);
  if (node === null || !($isLinkNode(node) || $isAutoLinkNode(node))) {
    return undefined;
  }

  return node.__url;
};

const handleMutations = (mutatedNodes: Map<string, string>) =>
  nextTick(() =>
    editor.update(() => {
      const mutatedNodeEntries = [...mutatedNodes.entries()];
      const keysOfNodesToReplace = new Set<string>();

      mutatedNodeEntries.forEach(([key, action]) => {
        if (keysOfNodesToReplace.has(key)) {
          return;
        }

        const node = $getNodeByKey(key);
        if (node === null || action === "destroyed" || !($isLinkNode(node) || $isAutoLinkNode(node))) {
          return;
        }
        const url = node.getURL();

        if (!isValidDartUrl(url, environmentStore.isLocal)) {
          return;
        }

        keysOfNodesToReplace.add(key);

        const { kind, duid } = getEntityKindAndDuidFromUrl(url);
        const entityLinkNode = $createDartLinkNode(url, kind, duid);
        node.insertBefore(entityLinkNode);
        node.remove();

        if (kind === EntityName.TASK) {
          if (!isDoc) {
            editor.dispatchCommand(EVENT_CREATE_TASK_TASK_RELATES_TO_RELATIONSHIP, duid);
          } else {
            editor.dispatchCommand(EVENT_CREATE_TASK_DOC_RELATIONSHIP, {
              specialEntity: TypeaheadSpecialEntity.TASK,
              entityDuid: duid,
            });
          }
        }

        if (kind === EntityName.DOC) {
          if (!isDoc) {
            editor.dispatchCommand(EVENT_CREATE_TASK_DOC_RELATIONSHIP, {
              specialEntity: TypeaheadSpecialEntity.DOC,
              entityDuid: duid,
            });
          } else {
            editor.dispatchCommand(EVENT_CREATE_DOC_DOC_RELATIONSHIP, duid);
          }
        }
      });

      const filteredMutatedNodeEntries = mutatedNodeEntries.filter((e) => !keysOfNodesToReplace.has(e[0]));
      const updatedNodes = filteredMutatedNodeEntries.filter((e) => e[1] === "updated").map((e) => e[0]);
      const createdNodes = filteredMutatedNodeEntries.filter((e) => e[1] === "created").map((e) => e[0]);
      const destroyedNodes = filteredMutatedNodeEntries.filter((e) => e[1] === "destroyed").map((e) => e[0]);

      const pairLength = Math.min(createdNodes.length, destroyedNodes.length);
      const updates = updatedNodes
        .map((e) => [e, e])
        .concat(
          Array.from(Array(pairLength).keys())
            .map((i) => i)
            .map((i) => [destroyedNodes[i], createdNodes[i]])
        )
        .map(([oldNodeKey, newNodeKey]) => {
          const old = nodeKeyToUrl.get(oldNodeKey);
          const newUrl = getNodeUrlByKey(newNodeKey);
          nodeKeyToUrl.delete(oldNodeKey);
          if (newUrl) {
            nodeKeyToUrl.set(newNodeKey, newUrl);
          }
          return { old, new: newUrl };
        })
        .filter((e): e is { old: string; new: string } => isString(e.old) && isString(e.new));
      const creates = createdNodes
        .slice(pairLength)
        .map((e) => {
          const url = getNodeUrlByKey(e);
          if (url) {
            nodeKeyToUrl.set(e, url);
          }
          return url;
        })
        .filter(isString);
      const destroys = destroyedNodes
        .slice(pairLength)
        .map((e) => {
          const url = nodeKeyToUrl.get(e);
          nodeKeyToUrl.delete(e);
          return url;
        })
        .filter(isString);
      emit("linkChanges", creates, destroys, updates);
    })
  );

const handleAutoLinkNodeMutations = (mutatedNodes: Map<string, string>) => handleMutations(mutatedNodes);
const handleLinkNodeMutations = (mutatedNodes: Map<string, string>) => handleMutations(mutatedNodes);

let unregisterListeners: () => void;

onMounted(() => {
  unregisterListeners = mergeRegister(
    editor.registerMutationListener(AutoLinkNode, handleAutoLinkNodeMutations),
    editor.registerMutationListener(LinkNode, handleLinkNodeMutations)
  );
});

onUnmounted(() => {
  unregisterListeners?.();
});
</script>

<template>
  <LexicalAutoLinkPlugin :matchers="URL_MATCHERS" />
</template>
