import {
  CellApiModule,
  CellStyleModule,
  ClientSideRowModelApiModule,
  ClientSideRowModelModule,
  ColumnApiModule,
  ColumnAutoSizeModule,
  CustomEditorModule,
  DragAndDropModule,
  EventApiModule,
  ExternalFilterModule,
  GridStateModule,
  ModuleRegistry,
  PinnedRowModule,
  provideGlobalGridOptions,
  QuickFilterModule,
  RenderApiModule,
  RowApiModule,
  RowDragModule,
  RowSelectionModule,
  RowStyleModule,
  ScrollApiModule,
  ValidationModule,
} from "ag-grid-community";
import { RowGroupingModule, TreeDataModule } from "ag-grid-enterprise";

import { useEnvironmentStore } from "~/stores";

const prepareAgGrid = () => {
  const environmentStore = useEnvironmentStore();

  // Register all modules we need
  const modules = [
    CellApiModule,
    CellStyleModule,
    ClientSideRowModelApiModule,
    ClientSideRowModelModule,
    ColumnApiModule,
    ColumnAutoSizeModule,
    CustomEditorModule,
    DragAndDropModule,
    EventApiModule,
    ExternalFilterModule,
    GridStateModule,
    PinnedRowModule,
    QuickFilterModule,
    RenderApiModule,
    RowApiModule,
    RowDragModule,
    RowGroupingModule,
    RowSelectionModule,
    RowStyleModule,
    ScrollApiModule,
    TreeDataModule,
  ];

  // Adds helpful console warnings/errors during development
  if (!environmentStore.isProd) {
    modules.push(ValidationModule);
  }

  ModuleRegistry.registerModules(modules);

  // Mark all grids as using legacy themes
  // TODO https://www.ag-grid.com/javascript-data-grid/theming-migration/
  provideGlobalGridOptions({ theme: "legacy" });
};

export default prepareAgGrid;
