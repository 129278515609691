<script setup lang="ts">
import { computed } from "vue";

import { PomodoroBackgroundKind } from "~/shared/enums";
import type { PomodoroBackground } from "~/shared/types";

const BREAKPOINTS = [120, 1280, 1920, 2560, 3840, 5120];

const props = defineProps<{
  bg: PomodoroBackground;
  selected?: boolean;
  disableHover?: boolean;
  thumbnail?: boolean;
}>();

const emit = defineEmits<{
  select: [PomodoroBackground];
}>();

const isColor = computed(() => props.bg.kind === PomodoroBackgroundKind.COLOR);
const isGradient = computed(() => props.bg.kind === PomodoroBackgroundKind.GRADIENT);
const isImage = computed(() => props.bg.kind === PomodoroBackgroundKind.IMAGE);

const baseName = computed(() => `/static/img/pomodoro/optimized/${props.bg.fileNameOrColorHex}`);
const cssVars = computed(() =>
  !isImage.value
    ? {}
    : Object.fromEntries(BREAKPOINTS.map((bp) => [`--bg-${bp}`, `url('${baseName.value}-${bp}w.webp')`]))
);

const gradientOrColorStyle = computed(() => ({
  backgroundColor: isColor.value ? props.bg.fileNameOrColorHex : undefined,
  backgroundImage: isGradient.value ? `url('/static/img/pomodoro/${props.bg.fileNameOrColorHex}.svg')` : undefined,
}));
</script>

<template>
  <div
    class="relative bg-cover bg-center"
    :style="[cssVars, gradientOrColorStyle]"
    :class="[
      selected ? 'outline outline-offset-2 outline-indigo-500' : '',
      disableHover ? 'cursor-default' : 'cursor-pointer',
      thumbnail && isImage ? 'dart-pomodoro-bg-thumb' : 'dart-pomodoro-bg',
    ]"
    @click="emit('select', bg)"
    @keydown.enter="emit('select', bg)">
    <div v-if="!disableHover" class="absolute inset-0 size-full rounded-lg bg-white/20 opacity-0 hover:opacity-100" />
    <slot />
  </div>
</template>

<style scoped>
/* always use smallest image for the thumbnail */
.dart-pomodoro-bg-thumb {
  background-image: var(--bg-120, none) !important;
}

.dart-pomodoro-bg {
  background-image: var(--bg-1280, none);
}

@media (min-width: 1280px) {
  .dart-pomodoro-bg {
    background-image: var(--bg-1920, none);
  }
}

@media (min-width: 1920px) {
  .dart-pomodoro-bg {
    background-image: var(--bg-2560, none);
  }
}

@media (min-width: 2560px) {
  .dart-pomodoro-bg {
    background-image: var(--bg-3840, none);
  }
}

@media (min-width: 3840px) {
  .dart-pomodoro-bg {
    background-image: var(--bg-5120, none);
  }
}
</style>
