<script setup lang="ts">
import { computed } from "vue";

import LoadingSpinner from "~/components/dumb/LoadingSpinner.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import type { Doc } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";
import { asyncState } from "~/utils/async";
import { getDocLink } from "~/utils/common";

import HomeTaskRow from "./HomeTaskRow.vue";

const appStore = useAppStore();
const dataStore = useDataStore();

const recentTasks = computed(() => dataStore.getTasksByDuids(appStore.recentTaskStack).slice(0, 10));

const { state: recentDocs, isLoading: isLoadingDocs } = asyncState(
  async () => {
    const docs = await Promise.all(appStore.recentDocStack.map((doc) => dataStore.getDocByDuid(doc)));
    return docs.filter((e): e is Doc => !!e && !e.inTrash).slice(0, 10);
  },
  { default: [] }
);

const areRecentTasks = computed(() => recentTasks.value.length > 0);
const areRecentDocs = computed(() => recentDocs.value.length > 0);
</script>

<template>
  <div class="h-80 w-full flex-col rounded-xl py-4 pl-4 bg-lt">
    <div v-if="areRecentTasks || areRecentDocs" class="flex size-full flex-col gap-5 overflow-y-scroll pr-px">
      <!-- Tasks -->
      <template v-if="areRecentTasks">
        <div class="flex flex-col gap-3">
          <div class="flex h-6 items-center">
            <span class="select-none truncate text-sm/[18px] text-lt">Tasks</span>
          </div>
          <div class="flex select-none flex-col gap-2">
            <HomeTaskRow v-for="task in recentTasks" :key="task.duid" :task="task" />
          </div>
        </div>
      </template>

      <!-- Docs -->
      <LoadingSpinner v-if="isLoadingDocs" />
      <div v-else-if="areRecentDocs" class="flex flex-col gap-3">
        <div class="flex h-6 items-center">
          <span class="select-none truncate text-sm/[18px] text-lt">Docs</span>
        </div>
        <div class="flex flex-col gap-2">
          <RouterLink
            v-for="doc in recentDocs"
            :key="doc.duid"
            class="flex h-9 w-full cursor-pointer items-center gap-3 truncate rounded px-3 bg-std hover:bg-md"
            :to="getDocLink(doc)">
            <span class="grow select-none truncate text-sm font-medium text-md">
              {{ doc.title }}
            </span>

            <PageIcon :page="doc" class="icon-md" />
          </RouterLink>
        </div>
      </div>

      <div class="min-h-12 w-full" />
    </div>
    <div v-else class="flex h-full select-none items-center justify-center px-4 pb-6 text-center text-lg text-vlt">
      You haven't worked on any tasks or docs recently
    </div>
  </div>
</template>
