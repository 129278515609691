<script setup lang="ts">
import moment from "moment";
import { DatePicker as DatePickerExternal } from "v-calendar";
import { computed, ref, watch } from "vue";

import Button from "~/components/dumb/Button.vue";
import { ChevronDownIcon } from "~/icons";
import { ButtonStyle } from "~/shared/enums";
import { usePageStore, useUserStore } from "~/stores";
import { isBrowserLocale24h } from "~/utils/time";

const pageStore = usePageStore();
const userStore = useUserStore();

const props = defineProps<{
  value: string;
  intervalMin?: number;
  custom?: boolean;
}>();

const emit = defineEmits<{
  select: [selectedDate: string];
}>();

const model = ref(moment(props.value).toDate());
const isPM = ref(moment(props.value).format("A") === "PM");

const is24hrMode = computed(() => isBrowserLocale24h());

watch(
  () => props.value,
  (newValue) => {
    model.value = moment(newValue).toDate();
    isPM.value = moment(newValue).format("A") === "PM";
  }
);

const handleTimeUpdate = (date: Date) => {
  model.value = date;
  emit("select", date.toISOString());
};

const togglePeriod = (isPm: boolean) => {
  const hours = moment(model.value).hour() % 12;
  const newDate = moment(model.value)
    .set("hour", isPm ? hours + 12 : hours)
    .toDate();

  model.value = newDate;
  isPM.value = isPm;
  emit("select", newDate.toISOString());
};
</script>

<template>
  <div v-if="props.custom" class="dart-time-picker flex items-center gap-2">
    <div class="flex items-center">
      <span class="mr-4 text-sm text-md">At</span>
      <div class="relative">
        <DatePickerExternal
          v-model="model"
          mode="time"
          color="indigo"
          hide-time-header
          :rules="intervalMin ? { minutes: { interval: intervalMin } } : undefined"
          :is-dark="pageStore.theme === 'dark'"
          :is24hr="is24hrMode"
          :first-day-of-week="(userStore.firstDayOfWeek ?? 1) + 1"
          :style="{ fontFamily: 'Inter var' }"
          @update:model-value="handleTimeUpdate" />
        <ChevronDownIcon class="pointer-events-none absolute right-[5px] top-1/2 -translate-y-1/2 text-lt icon-sm" />
        <ChevronDownIcon class="pointer-events-none absolute left-[38px] top-1/2 -translate-y-1/2 text-lt icon-sm" />
      </div>
    </div>

    <div v-if="!is24hrMode" class="inline-flex">
      <div class="overflow-hidden rounded-l-md border border-r-0 border-md">
        <Button
          :btn-style="isPM ? ButtonStyle.SECONDARY : ButtonStyle.PRIMARY"
          text="AM"
          class="flex size-[30px] !items-center justify-center !p-0 !text-xs !transition-colors"
          borderless
          :class="{
            'bg-transparent hover:rounded-r-md': isPM,
            'rounded-r-md bg-primary-base': !isPM,
          }"
          @click="togglePeriod(false)" />
      </div>

      <div class="overflow-hidden rounded-r-md border border-l-0 border-md">
        <Button
          :btn-style="isPM ? ButtonStyle.PRIMARY : ButtonStyle.SECONDARY"
          text="PM"
          borderless
          class="flex size-[30px] !items-center justify-center !p-0 !text-xs !transition-colors"
          :class="{
            'bg-transparent hover:rounded-l-md': !isPM,
            'rounded-l-md bg-primary-base': isPM,
          }"
          @click="togglePeriod(true)" />
      </div>
    </div>
  </div>
  <DatePickerExternal
    v-else
    v-model="model"
    mode="time"
    color="indigo"
    hide-time-header
    :rules="intervalMin ? { minutes: { interval: intervalMin } } : undefined"
    :is-dark="pageStore.theme === 'dark'"
    :is24hr="isBrowserLocale24h()"
    :first-day-of-week="(userStore.firstDayOfWeek ?? 1) + 1"
    :style="{ fontFamily: 'Inter var' }"
    @update:model-value="emit('select', model.toISOString())" />
</template>

<style>
.vc-time-picker {
  @apply py-0;
}

.vc-time-picker svg {
  @apply hidden;
}

.vc-time-select-group {
  @apply border-0 bg-transparent px-0;
}

.vc-base-select select {
  @apply rounded border-0 px-0.5 text-center text-sm tabular-nums text-lt focus-ring-none hover:bg-hvy;
}

.vc-time-colon {
  @apply text-vlt;
}

.dart-time-picker .vc-base-select select {
  @apply h-8 w-[61px] rounded-md border text-center font-mono text-xs tabular-nums text-lt border-md focus-ring-none hover:bg-lt;
}

.dart-time-picker .vc-time-colon {
  @apply m-0 px-1 text-lt;
}

.dart-time-picker .vc-base-select select.vc-focus[value="false"],
.dart-time-picker .vc-base-select select.vc-focus[value="true"] {
  @apply hidden;
}

.dart-time-picker .vc-time-select-hours,
.dart-time-picker .vc-time-select-minutes {
  @apply !pl-3 !pr-[30px] !text-start;
}
</style>
